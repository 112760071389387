import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {IInitialState} from '../../../shared-utilities/models-old/initial-state/initial-state';
import {IError} from '../../../shared-utilities/models-old/error/error';
import * as SharedActions from '../store-shared/shared.actions';
import {ISupplier} from '../../../shared/shared-models/stock/suppliers';
import {IColleagues} from '../../../shared/shared-models/user-access/user-access';
import {IStorage} from '../../../shared/shared-models/storage/storage';
import {IMessage, IMessageAutoOrderUpdate} from '../models/message';

export interface SharedState extends IInitialState {
  errors: IError[];
  // Suppliers ------------------------------
  isSuppliersLoading: boolean;
  suppliers: {
    [storeId: string]: { [supplierId: string]: ISupplier }
  };
  // Colleagues --------------------------------
  isColleaguesLoading: boolean;
  colleagues: IColleagues;
  // Storage -----------------------------------
  isStorageLoading: boolean;
  storageUrl: IStorage;
  // Messages ----------------------------------
  messagesFromApp: {
    [storeId: string]: {
      [messageType: string]: IMessage<IMessageAutoOrderUpdate[]>[]
    }
  };
}

export const initialSharedState: SharedState = {
  // Initial --------------------------------
  errors: [],
  // Suppliers ------------------------------
  isSuppliersLoading: false,
  suppliers: {},
  // Colleagues --------------------------------
  colleagues: {},
  isColleaguesLoading: false,
  // Storage -----------------------------------
  isStorageLoading: false,
  storageUrl: undefined,
  // Messages ----------------------------------
  messagesFromApp: {},
};

const createSharedReducer: ActionReducer<SharedState> = createReducer(
  initialSharedState,
  // ====================================================================================================
  // Suppliers
  // ====================================================================================================
  on(SharedActions.getStoreSuppliersByUserSelectedStore, (state: SharedState) => ({
    ...state,
    isSuppliersLoading: true,
  })),
  on(SharedActions.getStoreSuppliersByStore, (state: SharedState) => ({
    ...state,
    isSuppliersLoading: true,
  })),
  on(SharedActions.getStoreSuppliersByStoreSuccess, (state: SharedState, {suppliers, store}) => ({
    ...state,
    isSuppliersLoading: false,
    suppliers: {
      ...state.suppliers,
      [store.storeId]: suppliers,
    },
  })),
  on(SharedActions.getStoreSuppliersByStoreFailure, (state: SharedState, {error}) => ({
    ...state,
    isSuppliersLoading: false,
    error: [...state.errors, error],
  })),
  // ====================================================================================================
  // Get Colleagues
  // ====================================================================================================
  on(SharedActions.getColleagues, (state) => ({
    ...state,
    isColleaguesLoading: true,
  })),
  on(SharedActions.getColleaguesSuccess, (state, {users}) => ({
    ...state,
    colleagues: users,
    isColleaguesLoading: false,
  })),
  on(SharedActions.getColleaguesFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
    isColleaguesLoading: false,
  })),
  // ====================================================================================================
  // Firestore Storage
  // ====================================================================================================
  on(SharedActions.getDownloadUrlFromStorage, (state, {storage}) => ({
    ...state,
    isStorageLoading: true,
    storageUrl: {} as IStorage,
  })),
  on(SharedActions.getDownloadUrlFromStorageSuccess, (state, {storage}) => ({
    ...state,
    isStorageLoading: false,
    storageUrl: storage,
  })),
  on(SharedActions.getDownloadUrlFromStorageFailure, (state, {error}) => ({
    ...state,
    isStorageLoading: false,
    storageUrl: {} as IStorage,
    errors: [...state.errors, error],
  })),
  on(SharedActions.clearDownloadUrlFromStorage, (state) => ({
    ...state,
    isStorageLoading: false,
    storageUrl: undefined,
  })),
  // ====================================================================================================
  // Messages From App
  // ====================================================================================================
  on(SharedActions.getMessagesFromAppForAutoOrderUpdates, (state) => ({
    ...state,
  })),
  on(SharedActions.getMessagesFromAppForAutoOrderUpdatesSuccess, (state, {store, messages, messageType}) => ({
    ...state,
    messagesFromApp: {
      ...state.messagesFromApp,
      [store.storeId]: {
        ...state.messagesFromApp[store.storeId],
        [messageType]: messages,
      },
    },
  })),
  on(SharedActions.getMessagesFromAppForAutoOrderUpdatesFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
  })),
);

export const sharedReducer = (state: SharedState, action: Action): SharedState => createSharedReducer(state, action);
