import {NGPReport} from '../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {FiltersAndTools} from '../../../shared-utilities/models-old/ngp-reports/filters-tools-ngp';
import {HeaderMenuColumnData} from '../../../shared-utilities/models-old/ngp-report-grid/header-menu-data';
import {IAdvancedFilterGroup} from '../../../shared/shared-components/components/shared-advanced-filters/utils/advanced-filter-groups';
import {applyAdvancedFilterGroupToData} from '../../../shared/shared-components/components/shared-advanced-filters/utils/advanced-filters.utils';

export function getSelectNGPReportsFiltered(
  ngpReports: NGPReport[],
  filtersAndTools: FiltersAndTools,
): NGPReport[] {
  if (!filtersAndTools) {
    return ngpReports || [];
  }
  let copyNGPReports: NGPReport[] = [...ngpReports];


  /** Negative Values Only ------------------------------------------------------------------ */
  if (filtersAndTools.isGpDifferenceNegativeOn && !filtersAndTools.isGpDifferencePositiveOn) {
    copyNGPReports = ngpReports.filter((row: NGPReport): boolean => Number(row.originalValue['diffGP'].value) < 0);
  }
  /** Positive Values Only ------------------------------------------------------------------ */
  else if (filtersAndTools.isGpDifferencePositiveOn && !filtersAndTools.isGpDifferenceNegativeOn) {
    copyNGPReports = ngpReports.filter((row: NGPReport): boolean => Number(row.originalValue['diffGP'].value) >= 0);
  }
  /** Show Preview ----------------------------------------------------------------------------- */
  else if (filtersAndTools.isGpDifferencePositiveOn && filtersAndTools.isGpDifferenceNegativeOn) {
    if (filtersAndTools.showSubmissionPreview) {
      const copyEditedNGPReports = ngpReports.filter((row: NGPReport): boolean => row.isEdited);
      const copySelectedNGPReports = ngpReports.filter(
        (row: NGPReport): boolean => row.isSelected && !row.isEdited,
      );
      copyNGPReports = copyEditedNGPReports.concat(copySelectedNGPReports);
    } else if (filtersAndTools.isEditedItemsOn) {
      copyNGPReports = ngpReports.filter((row: NGPReport): boolean => row.isEdited);
    } else {
      copyNGPReports = ngpReports;
    }
  }
  /** No Values ----------------------------------------------------------------------------- */
  else {
    copyNGPReports = [];
  }
  return copyNGPReports;
}


export function selectGetSelectNGPReportsFiltered(
  ngpReports: NGPReport[],
  storeHeaderMenuData: { [key: string]: HeaderMenuColumnData; },
): NGPReport[] {
  let found = '';
  let sortType = '';

  Object.keys(storeHeaderMenuData).forEach((key: string): void => {
    if (storeHeaderMenuData[key]?.sortType !== null && storeHeaderMenuData[key]?.sortType !== undefined && storeHeaderMenuData[key]?.sortType !== "sort-unsort") {
      found = key;
      sortType = storeHeaderMenuData[key]?.sortType;
    }
  });

  if (found !== '' && storeHeaderMenuData) {
    switch (sortType) {
      case 'sort-asc':
        ngpReports.sort((a: NGPReport, b: NGPReport): number => (a[found] > b[found] ? 1 : -1));
        break;
      case 'sort-desc':
        ngpReports.sort((a: NGPReport, b: NGPReport): number => (a[found] < b[found] ? 1 : -1));
        break;
    }
  }
  return ngpReports;
}

export function applyAdvancedFilterOnNGPReports(
  ngpReports: NGPReport[],
  appliedFilter: IAdvancedFilterGroup,
): NGPReport[] {
  if (appliedFilter?.filterGroup) {
    return applyAdvancedFilterGroupToData(ngpReports, appliedFilter);
  } else {
    return ngpReports;
  }
}
