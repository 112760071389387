import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-shared-accordion-item',
  templateUrl: './shared-accordion-item.component.html',
  styleUrls: ['./shared-accordion-item.component.scss'],
})
export class SharedAccordionItemComponent {

  @Input() isOpen: boolean = false;

  @Input() class?: string;

  @Output() readonly accordionHeaderClicked = new EventEmitter<void>();

  toggle(): void {
    this.isOpen = !this.isOpen;
    this.accordionHeaderClicked.emit();
  }

}
