import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ShelfTalkersState} from './shelf-talkers.reducer';
import {selectSelectedUserStore} from '../../../features-as-modules/feature-core/store/core.selectors';
import {IStore} from '../../../shared/shared-models/store/store';
import {IAddStockItems} from '../../../shared/shared-models/auto-ordering/auto-order-order';
import {IPaginationData} from '../../../shared/shared-models/pagination/pagination-data';
import {StockItem} from '../../../shared-utilities/models-old/datastructures';

export const selectShelfTalkersState = createFeatureSelector<ShelfTalkersState>('shelf-talkers');


export const selectShelfTalkersStockItems = createSelector(
  selectShelfTalkersState,
  selectSelectedUserStore,
  (state: ShelfTalkersState, store: IStore): StockItem[] => (state.stockItems[store.storeId]),
);

export const selectShelfTalkersStockItemsExceptions = createSelector(
  selectShelfTalkersState,
  selectSelectedUserStore,
  (state: ShelfTalkersState, store: IStore): StockItem[] => (state.exceptions[store.storeId]),
);

export const selectShelfTalkersPaginationData = createSelector(
  selectShelfTalkersState,
  selectSelectedUserStore,
  (state: ShelfTalkersState, store: IStore): IPaginationData => (state.paginationData[store.storeId]
  ),
);

export const selectShelfTalkersSortMethodForStoreId = createSelector(
  selectShelfTalkersState,
  selectSelectedUserStore,
  (state: ShelfTalkersState, store: IStore): string => (state.sortMethod[store.storeId] ?? ''),
);

export const selectPrintOtherShelfTalkers = createSelector(
  selectShelfTalkersState,
  selectSelectedUserStore,
  (state: ShelfTalkersState, store: IStore): IAddStockItems => ({
    stockItems: state.otherStockItems[store.storeId],
    paginationData: state.otherStockItemsPaginationData[store.storeId],
  }),
);

export const selectIsShelfTalkersTagLoading = createSelector(
  selectShelfTalkersState,
  (state: ShelfTalkersState): boolean => (state.isRemoveTagsLoading),
);


