import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SharedState} from './shared.reducer';
import {selectSelectedUserStore, selectUser} from '../../../features-as-modules/feature-core/store/core.selectors';
import {IStore} from '../../../shared/shared-models/store/store';
import {IMessage, IMessageAutoOrderUpdate, MessageTypesEnum} from "../models/message";
import {
  IAutoOrderOrder,
  IAutoOrderOrderSettingsFirestore
} from "../../../shared/shared-models/auto-ordering/auto-order-order";
import {IUser} from "../../../shared/shared-models/user-access/user";
import {firebaseTimeStampToDate} from "../../../shared/shared-utils/firestore/firestore.utils";

export const selectSharedState = createFeatureSelector<SharedState>('core-shared');

// ====================================================================================================
// Suppliers
// ====================================================================================================
export const selectIsSuppliersLoading = createSelector(
  selectSharedState,
  (state: SharedState) => state.isSuppliersLoading,
);

export const selectSuppliersByUserSelectedStore = createSelector(
  selectSharedState,
  selectSelectedUserStore,
  (state: SharedState, selectedStore: IStore) => state.suppliers[selectedStore.storeId] || {}
);

// ====================================================================================================
// Colleagues
// ====================================================================================================

export const selectColleagues = createSelector(
  selectSharedState,
  (state: SharedState) => state.colleagues,
);

export const selectIsColleaguesLoading = createSelector(
  selectSharedState,
  (state: SharedState) => state.isColleaguesLoading,
);

// ====================================================================================================
// Storage
// ====================================================================================================

export const selectStorageUrl = createSelector(
  selectSharedState,
  (state: SharedState) => state.storageUrl,
);

// ====================================================================================================
// Messages From App
// ====================================================================================================

export const selectMessagesFromAppAutoOrderUpdate = createSelector(
  selectSharedState,
  selectSelectedUserStore,
  (state: SharedState, store) => {
    if (!state.messagesFromApp[store.storeId]?.[MessageTypesEnum.AUTO_ORDERS_U_UPDATE]) {
      return [] as IMessage<IMessageAutoOrderUpdate[]>[];
    }
    return state.messagesFromApp[store.storeId][MessageTypesEnum.AUTO_ORDERS_U_UPDATE] || [] as IMessage<IMessageAutoOrderUpdate[]>[]
  }
);

export const selectMessagesFromAppAutoOrderUpdateAsOrders = createSelector(
  selectMessagesFromAppAutoOrderUpdate,
  selectUser,
  (messages: IMessage<IMessageAutoOrderUpdate[]>[], user: IUser) => {
    const orders: IAutoOrderOrder[] = [];
    messages.forEach((message:  IMessage<IMessageAutoOrderUpdate[]>) => {
      Object.keys(message.payload.data).forEach((supplierId: string): void => {
        const settings: IAutoOrderOrderSettingsFirestore = message.payload.data[supplierId] as unknown as IAutoOrderOrderSettingsFirestore;
        const order: IAutoOrderOrder = {
          userId: user.id,
          owned: user.id,
          supplierId: supplierId,
          supplierName: '',
          generated: firebaseTimeStampToDate(message?.timestamp as { seconds: number, nanoseconds: number }),
          orderTotal: 0,
          orderId: 'Created Order',
          status: "CREATED",
          pdfStatus: "READY",
          emailStatus: "READY",
          iqStatus: "READY",
          scheduled: "SCHEDULED",
          errorMsg: null,
          orderItems: [],
          createdSequence: -1,
          purchaseOrderNo: 'Not Submitted',
          orderSettings: {
            ...settings,
          }
        };
        orders.push(order);
      })
    });
    return orders;
  }
);
