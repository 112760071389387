import {IFirebaseQuery} from '../../shared-models/firebase/firebase-queries';
import {CollectionReference} from '@angular/fire/compat/firestore';
import {Timestamp} from '@angular/fire/firestore';

export function generateFirebaseCollectionReference(
  firebaseQueries: IFirebaseQuery[],
  currentReference: CollectionReference,
): CollectionReference {
  let currentRef = currentReference;
  for (const query of firebaseQueries) {
    switch (query.q) {
      case 'where':
        currentRef = currentRef.where.apply(currentRef, query.p);
        break;
      case 'orderBy':
        currentRef = currentRef.orderBy.apply(currentRef, query.p);
        break;
      case 'limit':
        currentRef = currentRef.limit.apply(currentRef, query.p);
        break;
      case 'startAt':
        currentRef = currentRef.startAt.apply(currentRef, query.p);
        break;
      case 'startAfter':
        currentRef = currentRef.startAfter.apply(currentRef, query.p);
        break;
      case 'endAt':
        currentRef = currentRef.endAt.apply(currentRef, query.p);
        break;
      case 'endBefore':
        currentRef = currentRef.endBefore.apply(currentRef, query.p);
        break;
    }
  }
  return currentRef;
}


export function firebaseTimeStampToDate(value: { seconds: number, nanoseconds: number } | Timestamp): Date {
  if (!value) {
    return;
  }
  return new Date(value.seconds * 1000 + value.nanoseconds / 1000000);
}

export function dateToFirebaseTimestamp(date: Date): Timestamp {
  if (!date) {
    return Timestamp.fromDate(new Date(0));
  }
  return Timestamp.fromDate(date);
}
