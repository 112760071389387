import {DOCUMENT} from '@angular/common';
import {Directive, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {distinctUntilChanged, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {EMPTY, fromEvent, Subscription} from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[resizable]',
})
export class ResizableDirective implements OnInit, OnDestroy {

  @Output() readonly resizeStart = new EventEmitter<void>();
  @Output() readonly resizeEnd = new EventEmitter<void>();

  @Output()
  readonly resizable = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'mousedown')
    .pipe(
      tap((mouseEvent: MouseEvent) => {
        const target = mouseEvent.target as HTMLElement;
        if (target.className !== 'bar') {
          return;
        }
        mouseEvent.preventDefault();
        this.resizeStart.emit();
      }),
      switchMap((mouseEvent: MouseEvent) => {
        const headerCell = this.elementRef.nativeElement.closest('th');
        if (!headerCell) return EMPTY;

        const {width, right} = headerCell.getBoundingClientRect();

        return fromEvent<MouseEvent>(this.documentRef, 'mousemove').pipe(
          map(({clientX}) => width + clientX - right),
          distinctUntilChanged(),
          takeUntil(
            fromEvent(this.documentRef, 'mouseup').pipe(
              tap(() => {
                this.resizeEnd.emit();
              }),
            ),
          ),
        );
      }),
    );
  private subscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private readonly documentRef: Document,
    @Inject(ElementRef) private readonly elementRef: ElementRef<HTMLElement>) {
    this.resizable.subscribe();
  }

  ngOnInit(): void {
    this.subscription = this.resizable.subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
