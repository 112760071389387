import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

// 1️⃣ Angular & Ionic Modules
import {IonicModule} from '@ionic/angular';

// 2️⃣ NgRx Modules
import {PushModule} from '@ngrx/component';

// 3️⃣ Routing Module
import {SettingsPageRoutingModule} from './settings-page.routing';

// 4️⃣ Feature Modules
import {FeatureCoreModule} from 'src/app/features-as-modules/feature-core/feature-core.module';
import {SettingsModule} from '../settings-old/settings.module';

// 5️⃣ Shared Modules
import {SharedModule} from 'src/app/shared-modules/shared-module/shared-module.module';
import {SharedIconsModule} from 'src/app/shared-modules/shared-icons/shared-icons.module';
import {SharedComponentsModule} from 'src/app/shared/shared-components/shared-components.module';

// 6️⃣ Store (NgRx) Features

// 7️⃣ UI Components & Views
import {SETTINGS_COMPONENTS, UiComponentsModule} from './components';
import {SETTINGS_VIEWS} from './views';
import {SettingsPageComponent} from './settings-page.component';

@NgModule({
  declarations: [
    SettingsPageComponent,
    ...SETTINGS_VIEWS,
    ...SETTINGS_COMPONENTS
  ],
  imports: [
    // 1️⃣ Angular & Ionic Modules
    CommonModule,
    FormsModule,
    IonicModule,

    // 2️⃣ NgRx Modules
    PushModule,

    // 3️⃣ Routing Module
    SettingsPageRoutingModule,

    // 4️⃣ Feature Modules
    FeatureCoreModule,
    SettingsModule,

    // 5️⃣ Shared Modules
    SharedModule,
    SharedIconsModule,
    SharedComponentsModule,

    // 6️⃣ UI Components
    UiComponentsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SettingsPageModule {
}
