import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IStore} from '../../../../shared/shared-models/store/store';
import {StoreDataFreshnessAge} from '../../../../shared-utilities/services-old/data-freshness.service';
import {setUserSelectedStore} from '../../../../features-as-modules/feature-core/store/core.actions';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-shared-store-tabs',
  templateUrl: './shared-store-tabs.component.html',
  styleUrls: ['./shared-store-tabs.component.scss'],
})
export class SharedStoreTabsComponent {

  @Input() showFreshnessIndicator?: boolean;
  @Input() dataTimeStampKey?: string[] = [];
  @Input() isModal?: boolean = false;
  @Input() isMessagesModal = false;
  @Output() readonly emitStoreChange: EventEmitter<IStore> = new EventEmitter<IStore>();

  stores: IStore[] = [];
  selectedStore: IStore | null = null;

  constructor(
    private readonly store: Store,
  ) {
  }

  get getStores(): IStore[] {
    return this.stores;
  }

  get getDataKey(): (keyof StoreDataFreshnessAge)[] {
    return [this.dataTimeStampKey[0] as keyof StoreDataFreshnessAge];
  }

  @Input() set setUserSelectedStore(store: IStore | null) {
    if (store) {
      const findStore = this.getStores.find((storeItem) => storeItem.storeId === store.storeId);
      if (findStore) {
        this.selectedStore = findStore;
      } else {
        this.selectedStore = this.getStores.length > 0 ? this.getStores[0] : null;
      }
    } else {
      this.selectedStore = this.getStores.length > 0 ? this.getStores[0] : null;
    }
    this.emitStoreChange.emit(this.selectedStore);
  }

  @Input() set setStores(stores: IStore[]) {
    this.stores = stores;
  }

  onStoreChange(event: Event): void {
    const customEvent = event as CustomEvent<{ value: IStore }>;
    const newStore = customEvent?.detail?.value;

    if (newStore) {
      this.selectedStore = newStore;
      this.emitStoreChange.emit(newStore);
      if (!this.isModal) {
        this.store.dispatch(setUserSelectedStore({selectedStore: newStore}));
      }
    }
  }

  getTabMaxWidth(): string {
    const tabCount = this.stores.length;
    if (tabCount > 6) {
      return '100px';
    }
    return `calc(600px / ${tabCount})`;
  }

  protected trackByStoreId(_: number, store: IStore): string {
    return store.storeId;
  }
}
