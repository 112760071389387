import {createAction, props} from '@ngrx/store';
import {
  AutoOrderEmailStatusTypes,
  AutoOrderIQStatusTypes,
  AutoOrderPDFStatusTypes,
  AutoOrderStatusTypes,
  IAutoOrderOrder,
  IAutoOrderOrderItemFirestore,
} from '../../../shared/shared-models/auto-ordering/auto-order-order';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {IStore} from '../../../shared/shared-models/store/store';
import {FbQuery} from '../../../shared-utilities/models-old/firebase-queries';
import {DisabledRules, LineColour, StockItem} from '../../../shared-utilities/models-old/datastructures';
import {IFirebaseQuery} from '../../../shared/shared-models/firebase/firebase-queries';
import {IAutoOrderStatusChips} from '../../../shared/shared-models/auto-ordering/auto-order-status-chips';
import {IAutoOrderingOrderSettings} from '../../../features/settings-old/models/settings-models';
import {IPaginationData} from '../../../shared/shared-models/pagination/pagination-data';
import {
  IAutoOrderingScheduleDeleteSettings,
} from '../../../shared/shared-models/auto-ordering/auto-ordering-schedule-delete-settings';
import {ISearchableFields} from '../../../shared/shared-models/type-sense/default-searchable-fields';
import {ISupplier} from '../../../shared/shared-models/stock/suppliers';
import {IFacetCounts} from '../../../shared/shared-models/type-sense/type-sense-types';
import {IAutoOrderingOpenOrderFilter} from '../../../shared-utilities/models-old/auto-ordering/order-open-order-filter';

// ====================================================================================================
// Send Auto Order
// ====================================================================================================
export const setRetryAutoOrder = createAction(
  '[Auto Order][Orders] Set Retry Auto Order',
  props<{ order: IAutoOrderOrder }>(),
);

export const setRetryAutoOrderSuccess = createAction(
  '[Auto Order][Orders] Set Retry Auto Order Success'
);

export const setRetryAutoOrderFailure = createAction(
  '[Auto Order][Orders] Set Retry Auto Order Failure',
  props<{ error: IError }>(),
);

export const updateAutoOrderStatusField = createAction(
  '[Auto Order][Email] Update Auto Order Order Status Field',
  props<{
    orderId: string;
    status: AutoOrderEmailStatusTypes | AutoOrderStatusTypes | AutoOrderPDFStatusTypes | AutoOrderIQStatusTypes,
    field: 'emailStatus' | 'iqStatus' | 'pdfStatus' | 'status'
  }>(),
);

export const updateAutoOrderStatusFieldSuccess = createAction(
  '[Auto Order][Email] Update Auto Order Order Status Field Success'
);

export const updateAutoOrderStatusFieldFailure = createAction(
  '[Auto Order][Email] Update Auto Order Order Status Field Failure',
  props<{ error: IError }>(),
);


// ====================================================================================================
// Auto Order Orders
// ====================================================================================================
export const setAutoOrderListSortingOrder = createAction(
  '[Auto Order][Orders] Set Auto Orders List Sorting Direction',
  props<{ sortBy: { property: string; direction: 'asc' | 'desc' } }>(),
);

export const setAutoOrderSelectedOrders = createAction(
  '[Auto Order][Orders] Set Selected Auto Orders',
  props<{ orderIds: string[] }>(),
);

export const getAutoOrderOrders = createAction(
  '[Auto Order][Orders] Get Auto Order Orders',
  props<{ firebaseQueries: IFirebaseQuery[], statusChip: IAutoOrderStatusChips }>(),
);

export const getAutoOrderOrdersSuccess = createAction(
  '[Auto Order][Orders] Get Auto Order Orders Success',
  props<{
    autoOrderOrders: IAutoOrderOrder[],
    store: IStore,
    firebaseQueries: IFirebaseQuery[],
    statusChip: IAutoOrderStatusChips
  }>(),
);

export const getAutoOrderOrdersFailure = createAction(
  '[Auto Order][Orders] Get Auto Order Orders Failure',
  props<{ error: IError }>(),
);

export const getAutoOrderOrderItems = createAction(
  '[Auto Order][Orders] Get Auto Order Order Items',
  props<{ orderId: string }>(),
);

export const getAutoOrderOrderItemsSuccess = createAction(
  '[Auto Order][Orders] Get Auto Order Order Items Success',
  props<{ orderId: string, orderItems: IAutoOrderOrderItemFirestore[], store: IStore }>(),
);

export const getAutoOrderOrderItemsFailure = createAction(
  '[Auto Order][Orders] Get Auto Order Order Items Failure',
  props<{ error: IError }>(),
);

export const updateAutoOrderOrderEmailStatus = createAction(
  '[Auto Order][Email] Update Auto Order Order Email Status',
  props<{ orderId: string; status: AutoOrderEmailStatusTypes }>(),
);

export const updateAutoOrderOrderEmailRecipients = createAction(
  '[Auto Order][Email] Update Auto Order Order Email Recipients',
  props<{ orderId: string; recipients: string[] }>(),
);

export const updateAutoOrderOrderEmailRecipientsSuccess = createAction(
  '[Auto Order][Email] Update Auto Order Order Email Recipients Success'
);

export const updateAutoOrderOrderEmailRecipientsFailure = createAction(
  '[Auto Order][Email] Update Auto Order Order Email Recipients Failure',
  props<{ error: IError }>(),
);

export const sendAutoOrderEmails = createAction(
  '[Auto Order][Email] Send Auto Order Emails',
  props<{ orderId: string }>(),
);

export const sendAutoOrderEmailsSuccess = createAction(
  '[Auto Order][Email] Send Auto Order Emails Success'
);

export const sendAutoOrderEmailsFailure = createAction(
  '[Auto Order][Email] Send Auto Order Emails Failure',
  props<{ error: IError }>(),
);

export const setAutoOrderingOrderFilter = createAction(
  '[Auto Order][Order] Set Auto Ordering Order Filter',
  props<{ filter: IAutoOrderingOpenOrderFilter }>(),
);

export const updateAutoOrderItemAtPropertyOnOrder = createAction(
  '[Auto Order][Order] Update Auto Order Item At Property On Order',
  props<{ stockCode: string, property: string, newValue: number }>(),
);

export const updateAutoOrderItemAtPropertyOnOrderWithStoreId = createAction(
  '[Auto Order][Order] Update Auto Order Item At Property On Order With Store',
  props<{ stockCode: string, property: string, newValue: number, store: IStore, selectedOrderId: string }>(),
);


// ====================================================================================================
// Delete Auto Orders
// ====================================================================================================
export const deleteAutoOrderOrder = createAction(
  '[Auto Order][Delete] Delete Auto Order Order',
  props<{ orderIds: string[], doNotAskAgain: boolean }>(),
);

export const deleteAutoOrderOrderWithStoreId = createAction(
  '[Auto Order][Delete] Delete Auto Order Order With Store Id',
  props<{ orderIds: string[], store: IStore }>(),
);

export const deleteAutoOrderOrderSuccess = createAction(
  '[Auto Order][Delete] Delete Auto Order Order Success',
  props<{ orderIds: string[], store: IStore }>(),
);

export const deleteAutoOrderOrderFailure = createAction(
  '[Auto Order][Delete] Delete Auto Order Order Failure',
  props<{ error: IError }>(),
);

// ====================================================================================================
// Schedule Auto Delete Auto Orders Settings
// ====================================================================================================
export const getAutoOrderAutoDeleteSettings = createAction(
  '[Auto Order][Auto Delete] Get Auto Order Auto Delete Settings',
);

export const getAutoOrderAutoDeleteSettingsSuccess = createAction(
  '[Auto Order][Auto Delete] Get Auto Order Auto Delete Settings Success',
  props<{ settings: IAutoOrderingScheduleDeleteSettings }>(),
);

export const getAutoOrderAutoDeleteSettingsFailure = createAction(
  '[Auto Order][Auto Delete] Get Auto Order Auto Delete Settings Failure',
  props<{ error: IError }>(),
);

export const setAutoOrderAutoDeleteSettings = createAction(
  '[Auto Order][Auto Delete] Set Auto Order Auto Delete Settings',
  props<{ settings: IAutoOrderingScheduleDeleteSettings }>(),
);

export const setAutoOrderAutoDeleteSettingsSuccess = createAction(
  '[Auto Order][Auto Delete] Set Auto Order Auto Delete Settings Success',
);

export const setAutoOrderAutoDeleteSettingsFailure = createAction(
  '[Auto Order][Auto Delete] Set Auto Order Auto Delete Settings Failure',
  props<{ error: IError }>(),
);

// ====================================================================================================
// Get Auto Order Stock Items Using Queries
// ====================================================================================================
export const getAutoOrderStockItems = createAction(
  '[Auto Order][Stock Items] Get Auto Order Stock Items',
  props<{ query: FbQuery[], orderId: string, stream: string }>(),
);

export const getAutoOrderStockItemsWithUserSelectedStore = createAction(
  '[Auto Order][Stock Items] Get Auto Order Stock Items with User Selected Store',
  props<{ query: FbQuery[], store: IStore, orderId: string, stream: string }>(),
);

export const getAutoOrderStockItemsWithUserSelectedStoreSuccess = createAction(
  '[Auto Order][Stock Items] Get Auto Order Stock Items with User Selected Store Success',
  props<{ stockItems: StockItem[], store: IStore, orderId: string, stream: string }>(),
);

export const getAutoOrderStockItemsWithUserSelectedStoreFailure = createAction(
  '[Auto Order][Stock Items] Get Auto Order Stock Items with User Selected Store Failure',
  props<{ error: IError, store: IStore, orderId: string, stream: string }>(),
);

// ====================================================================================================
// Stock Item Deletion Rules
// ====================================================================================================
export const getAutoOrderStockItemDeletionRules = createAction(
  '[Auto Order][Deletion Rules] Get Auto Order Stock Item Deletion Rules',
);

export const getAutoOrderStockItemDeletionRulesSuccess = createAction(
  '[Auto Order][Deletion Rules] Get Auto Order Stock Item Deletion Rules Success',
  props<{ store: IStore, disabledRules: DisabledRules }>(),
);

export const getAutoOrderStockItemDeletionRulesFailure = createAction(
  '[Auto Order][Deletion Rules] Get Auto Order Stock Item Deletion Rules Failure',
  props<{ error: IError, store: IStore }>(),
);

// ====================================================================================================
// Recipients and Suppliers
// ====================================================================================================
export const clearAutoOrderSupplierEmails = createAction(
  '[Auto Order][Recipients] Clear Auto Order Supplier Emails'
);

export const getAutoOrderSupplierEmails = createAction(
  '[Auto Order][Recipients] Get Auto Order Supplier Emails',
  props<{ store: IStore, supplierIds: string[] }>(),
);

export const getAutoOrderSupplierEmailsByUserSelectedStore = createAction(
  '[Auto Order][Recipients] Get Auto Order Supplier Emails by User Selected Store',
  props<{ supplierIds: string[] }>(),
);

export const getAutoOrderSupplierEmailsSuccess = createAction(
  '[Auto Order][Recipients] Get Auto Order Supplier Emails Success',
  props<{
    emails: {
      [suppId: string]: {
        selected: string[],
        additional: string[],
      }
    }
  }>(),
);

export const getAutoOrderSupplierEmailsFailure = createAction(
  '[Auto Order][Recipients] Get Auto Order Supplier Emails Failure',
  props<{ error: IError }>(),
);

export const setAutoOrderSupplierAddRecipientEmails = createAction(
  '[Auto Order][Recipients] Set Auto Order Supplier Additional Recipient Emails',
  props<{ store: IStore, supplierId: string, recipientEmails: string[] }>(),
);

export const setAutoOrderSupplierAddRecipientEmailsSuccess = createAction(
  '[Auto Order][Recipients] Set Auto Order Supplier Additional Recipient Emails Success',
);

export const setAutoOrderSupplierAddRecipientEmailsFailure = createAction(
  '[Auto Order][Recipients] Set Auto Order Supplier Additional Recipient Emails Failure',
  props<{ error: IError }>(),
);

export const setAutoOrderSupplierEmails = createAction(
  '[Auto Order][Recipients] Set Auto Order Supplier Emails',
  props<{ store: IStore, supplierId: string, recipientEmails: string[], typeOfEmail: 'selected' | 'additional' }>(),
);

export const setAutoOrderSupplierEmailsWithSelectedUserStore = createAction(
  '[Auto Order][Recipients] Set Auto Order Supplier Emails With Selected User Store',
  props<{ supplierId: string, recipientEmails: string[], typeOfEmail: 'selected' | 'additional' }>(),
);

export const setAutoOrderSupplierEmailsSuccess = createAction(
  '[Auto Order][Recipients] Set Auto Order Supplier Emails Success',
);

export const setAutoOrderSupplierEmailsFailure = createAction(
  '[Auto Order][Recipients] Set Auto Order Supplier Emails Failure',
  props<{ error: IError }>(),
);

export const deleteAutoOrderSupplierEmails = createAction(
  '[Auto Order][Recipients] Delete Auto Order Supplier Emails',
  props<{ store: IStore, supplierId: string }>(),
);

export const deleteAutoOrderSupplierEmailsSuccess = createAction(
  '[Auto Order][Recipients] Delete Auto Order Supplier Emails Success',
);

export const deleteAutoOrderSupplierEmailsFailure = createAction(
  '[Auto Order][Recipients] Delete Auto Order Supplier Emails Failure',
  props<{ error: IError }>(),
);

// ====================================================================================================
// Auto Order Settings
// ====================================================================================================
export const getAutoOrderOrderSettings = createAction(
  '[Auto Order][Order Settings] Get Auto Order Order Settings',
);

export const getAutoOrderOrderSettingsSuccess = createAction(
  '[Auto Order][Order Settings] Get Auto Order Order Settings Success',
  props<{ settings: IAutoOrderingOrderSettings }>(),
);

export const getAutoOrderOrderSettingsFailure = createAction(
  '[Auto Order][Order Settings] Get Auto Order Order Settings Failure',
  props<{ error: IError }>(),
);

// ====================================================================================================
// Add Stock Items
// ====================================================================================================
export const getTypesenseStockItems = createAction(
  '[Auto Order][Add Item] Get Typesense Stock Items',
);

export const getTypesenseStockItemsSuccess = createAction(
  '[Auto Order][Add Item] Get Typesense Stock Items Success',
  props<{ stockItems: StockItem[]; paginationData: IPaginationData }>(),
);

export const getTypesenseStockItemsFailure = createAction(
  '[Auto Order][Add Item] Get Typesense Stock Items Failure',
  props<{ error: IError }>(),
);

export const getTypesenseFacetCounts = createAction(
  '[Auto Order][Edit Schedule] Get Typesense Facet Counts',
);

export const getTypesenseFacetCountsSuccess = createAction(
  '[Auto Order][Edit Schedule] Get Typesense Facet Counts Success',
  props<{ facetCounts: IFacetCounts }>(),
);

export const getTypesenseFacetCountsFailure = createAction(
  '[Auto Order][Edit Schedule] Get Typesense Facet Counts Failure',
  props<{ error: IError }>(),
);

export const getTypesenseStockItemsSearchResults = createAction(
  '[Auto Order][Add Item] Get Typesense Stock Items Search Results',
  props<{ idField: string }>(),
);

export const getTypesenseStockItemsSearchResultsSuccess = createAction(
  '[Auto Order][Add Item] Get Typesense Stock Items Search Results Success',
  props<{ stockItems: StockItem[]; paginationData: IPaginationData }>(),
);

export const getTypesenseStockItemsSearchResultsFailure = createAction(
  '[Auto Order][Add Item] Get Typesense Stock Items Search Results Failure',
  props<{ error: IError }>(),
);

export const getAddItemLineColours = createAction(
  '[Auto Order][Add Item] Get Add Item Line Colours',
  props<{ pageStoreDocument: string }>(),
);

export const getAddItemLineColoursSuccess = createAction(
  '[Auto Order][Add Item] Get Add Item Line Colours Success',
  props<{ lineColours: LineColour }>(),
);

export const getAddItemLineColoursFailure = createAction(
  '[Auto Order][Add Item] Get Add Item Line Colours Failure',
  props<{ error: IError }>(),
);

// =======================================================================
// Pagination
// =======================================================================
export const getPaginationResultsForAddStockItem = createAction(
  '[Auto Order][Add Item][Pagination] Get Pagination Results Add For Stock Item [Grid]',
  props<{ pageNumber: number }>(),
);

export const getPaginationResultsForAddStockItemSuccess = createAction(
  '[Auto Order][Add Item][Pagination] Get Pagination Results For Add Stock Items Success [Grid]',
  props<{ stockItems: StockItem[]; paginationData: IPaginationData }>(),
);

export const getPaginationResultsForAddStockItemFailure = createAction(
  '[Auto Order][Add Item][Pagination] Get Pagination Results For Add Stock Items Failure [Grid]',
  props<{ error: IError }>(),
);

export const setStockTagsForAddStockItem = createAction(
  '[Auto Order][Add Item][Tags] Set Stock Tags For Add Stock Item',
  props<{ taggedItems: { [stockId: string]: string[] } }>(),
);

export const setStockTagsForAddStockItemSuccess = createAction(
  '[Auto Order][Add Item][Tags] Set Stock Tags For Add Stock Item Success',
);

export const setStockTagsForAddStockItemFailure = createAction(
  '[Auto Order][Add Item][Tags] Set Stock Tags For Add Stock Item Failure',
  props<{ error: IError }>(),
);

export const setStockSearchAppliedFilter = createAction(
  '[Auto Order][Add Item] Set Filter For Stock Search',
  props<{ filter: { [key: string]: ISearchableFields } }>(),
);

export const setCustomOrder = createAction(
  '[Auto Order][Custom Order] Set Custom Order',
  props<{ supplierData: ISupplier, stockItemData: StockItem[] }>(),
);

export const setCustomOrderSuccess = createAction(
  '[Auto Order][Custom Order] Set Custom Order Success',
  props<{ supplierData: ISupplier, stockItemData: StockItem[] }>(),
);


export const setCustomOrderFailure = createAction(
  '[Auto Order][Custom Order] Set Custom Order Failure',
  props<{ error: IError }>(),
);




