import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StockManagerState} from './stock-manager.reducer';
import {IStore} from '../../../shared/shared-models/store/store';
import {selectSelectedUserStore} from '../../../features-as-modules/feature-core/store/core.selectors';
import {StoreHeaderMenuData} from '../../../shared-utilities/models-old/ngp-report-grid/header-menu-data';
import {LineColour, sItemKeyToInt} from '../../../shared-utilities/models-old/datastructures';
import {selectAppliedAdvancedFilterGroup} from '../../core/store-shared-filter/shared-filter.selectors';
import {TypesenseQueryData} from '../../../shared/shared-models/type-sense/type-sense-types';


export const selectStockManagerState = createFeatureSelector<StockManagerState>('stock-manager');

export const selectAllStockItemsForCurrentSelectedStore = createSelector(
  selectStockManagerState,
  selectSelectedUserStore,
  (state: StockManagerState, store: IStore) => state.stockItems[store.storeId],
);


export const selectPaginationDataForUserSelectedStore = createSelector(
  selectStockManagerState,
  selectSelectedUserStore,
  (state: StockManagerState, store: IStore) => state.paginationData[store.storeId],
);

export const selectIsStockManagerLoading = createSelector(
  selectStockManagerState,
  (state: StockManagerState) => state.isStockManagerLoading,
);

export const selectHaveStockItemsUpdated = createSelector(
  selectStockManagerState,
  (state: StockManagerState) => state.haveStockItemsUpdated,
);

export const selectSearchableFields = createSelector(
  selectStockManagerState,
  (state: StockManagerState) => state.searchableFields,
);

export const selectSuppliersForUserSelectedStore = createSelector(
  selectStockManagerState,
  selectSelectedUserStore,
  (state: StockManagerState, store: IStore) => state.storeSuppliers[store.storeId],
);

export const selectFacetCountsForUserSelectedStore = createSelector(
  selectStockManagerState,
  selectSelectedUserStore,
  (state: StockManagerState, store: IStore) => state.facetCounts[store.storeId],
);

export const selectDepAndSubDepForUserSelectedStore = createSelector(
  selectStockManagerState,
  selectSelectedUserStore,
  (state: StockManagerState, store: IStore) => state.departments[store.storeId],
);

export const selectStoreSettingsForSelectedUserStore = createSelector(
  selectStockManagerState,
  selectSelectedUserStore,
  (state: StockManagerState, store: IStore) => state.storeSettings[store.storeId],
);

export const selectFiltersForUserSelectedStore = createSelector(
  selectStockManagerState,
  selectSelectedUserStore,
  (state: StockManagerState, store: IStore) => state.filters[store.storeId],
);

export const selectItemDisablingRules = createSelector(
  selectStockManagerState,
  selectSelectedUserStore,
  (state: StockManagerState, store: IStore) => state.disabledRules?.[store.storeId],
);

export const selectEditedItems = createSelector(
  selectStockManagerState,
  selectSelectedUserStore,
  (state: StockManagerState, store: IStore) => state.editedItems[store.storeId],
);

export const selectEditedItemsForPreview = createSelector(
  selectStockManagerState,
  selectSelectedUserStore,
  (state: StockManagerState, store: IStore) => {
    return state.editedItems?.[store.storeId] ? Object.values(state.editedItems?.[store.storeId]) : undefined;
  },
);


export const selectStockMangerMenuData = createSelector(
  selectStockManagerState,
  (state: StockManagerState) => state ? state.headerMenuData : {},
);

export const selectStockManagerMenuDataByStoreId = createSelector(
  selectStockMangerMenuData,
  selectSelectedUserStore,
  (headerMenuData: StoreHeaderMenuData, selectedStore: IStore) => {
    if (!selectedStore?.storeId || !headerMenuData[selectedStore.storeId]) {
      return {} as StoreHeaderMenuData;
    } else {
      return headerMenuData[selectedStore.storeId];
    }
  },
);

export const selectStockMangerVisibleFields = createSelector(
  selectStockManagerState,
  (state: StockManagerState) => state ? state.visibleFields : {},
);

export const selectStockManagerSortType = createSelector(
  selectStockManagerState,
  selectSelectedUserStore,
  (state: StockManagerState, store: IStore) => {
    const headerMenuDataForStore = state.headerMenuData[store.storeId];

    if (!headerMenuDataForStore) {
      return '1:asc';
    }

    const matchingEntry = Object.entries(headerMenuDataForStore).find(
      ([key, item]) => item.sortType && item.sortType !== 'sort-unsort',
    );

    if (matchingEntry) {
      const [key, item] = matchingEntry;
      const sortType = item.sortType === 'sort-asc' ? 'asc' : 'desc';
      return `${sItemKeyToInt[key]}:${sortType}`;
    } else {
      return '1:asc';
    }
  },
);

export const selectTypesenseQueryData = createSelector(
  selectSelectedUserStore,
  selectPaginationDataForUserSelectedStore,
  selectSearchableFields,
  selectStockManagerSortType,
  selectFiltersForUserSelectedStore,
  selectAppliedAdvancedFilterGroup,
  selectSuppliersForUserSelectedStore,
  (
    store,
    paginationData,
    searchableFields,
    sortType,
    filters,
    advancedFilterGroup,
    suppliers,
  ): TypesenseQueryData => {
    return {
      store,
      paginationData,
      searchableFields,
      sortType,
      filters,
      advancedFilterGroup,
      suppliers,
    }
  },
);

// ====================================================================================================
// Get Line Colours For Selected Store
// ====================================================================================================
export const selectLineColours = createSelector(
  selectStockManagerState,
  selectSelectedUserStore,
  (state: StockManagerState, selectedStore: IStore): LineColour => state.lineColours[selectedStore.storeId],
);

// ====================================================================================================
export const selectEnablePriceBandingForStockManager = createSelector(
  selectStockManagerState,
  (state: StockManagerState) => state ? state.isPriceBanding : false,
);

export const selectApiKeyForSelectedStore = createSelector(
  selectStockManagerState,
  selectSelectedUserStore,
  (state: StockManagerState, store: IStore) => state.apiDocs?.[store.storeId]?.searchApiKey,
);

export const selectStockManagerPreviewColumns = createSelector(
  selectStockManagerState,
  (state: StockManagerState) => state.previewColumnData,
);

export const selectDontDisplayModalAgain = createSelector(
  selectStockManagerState,
  (state: StockManagerState) => state.dontDisplayModalAgain,
);
