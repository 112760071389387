<div
  [class.loading]="isSubmitting"
  class="settings-container">
  <div class="loading-overlay">
    <ion-spinner></ion-spinner>
  </div>

  <ion-accordion-group [value]="['tableSettings']">
    <ion-accordion value="tableSettings">
      <ion-item
        class="section-header-dropdown"
        slot="header">
        <ion-label>Table Settings</ion-label>
      </ion-item>
      <div
        class="section-body-dropdown"
        slot="content">
        <div class="section-setting">
          <app-setting-section
            (selectedOptionChange)="onTabEndOptionChange($event)"
            [isSubmitting]="isSubmitting"
            [optionType]="tabEndOptionsConstants.optionType"
            [selectOptions]="tabEndOptions"
            [selectedOption]="tabEndSelectedOption"
            slug="tabEndOptions">
            <span selectTitle>When on the last cell of a page and <b>tab</b> is pressed:</span>
          </app-setting-section>
          <app-setting-section
            (selectedOptionChange)="onEnterEndOptionChange($event)"
            [isSubmitting]="isSubmitting"
            [optionType]="enterEndOptionsConstants.optionType"
            [selectOptions]="enterEndOptions"
            [selectedOption]="enterEndSelectedOption"
            slug="enterEndOptions">
            <span selectTitle>When on the last cell of a column and <b>enter</b> is pressed:</span>
          </app-setting-section>
          <app-setting-section
            (multiSelectValuesChange)="onNgpPreviewChange($event)"
            [isSubmitting]="isSubmitting"
            [multiSelectValues$]="ngpPreviewSelectedColumns$"
            [optionTip]="ngpPreviewColumnsConstants.optionTip"
            [optionType]="ngpPreviewColumnsConstants.optionType"
            slug="ngpPreviewColumns">
            <span selectMultipleTitle>{{ ngpPreviewColumnsConstants.title }}</span>
          </app-setting-section>
          <app-setting-section
            (multiSelectValuesChange)="onStockPreviewChange($event)"
            [isSubmitting]="isSubmitting"
            [multiSelectValues$]="stockPreviewSelectedColumns$"
            [optionTip]="stockManagerPreviewColumnsConstants.optionTip"
            [optionType]="stockManagerPreviewColumnsConstants.optionType"
            slug="stockManagerPreviewColumns">
            <span selectMultipleTitle>{{ stockManagerPreviewColumnsConstants.title }}</span>
          </app-setting-section>
        </div>
      </div>
    </ion-accordion>
  </ion-accordion-group>

  <div class="save-button-container">
    <button
      (click)="onSubmit($event)"
      type="button">
      Save
    </button>
  </div>

</div>
