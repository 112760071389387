import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {FirebaseService} from '../../../shared-utilities/services-old/firebase.service';
import * as NGPReportActions from './ngp-report.actions';
import {catchError, map, mergeMap, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {EMPTY, forkJoin, from, of} from 'rxjs';
import {
  DisabledRules,
  DisabledRulesSupp,
  LineColour,
  StockItem,
  Supplier,
  VatRates,
} from '../../../shared-utilities/models-old/datastructures';
import {Action, select, Store} from '@ngrx/store';
import {
  selectSelectedUserStore,
  selectUser,
  selectUserStores,
} from '../../../features-as-modules/feature-core/store/core.selectors';
import {NGPReport} from '../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {
  selectAllVatRates,
  selectItemDisablingRules,
  selectNGPReports,
  selectNGPState,
  selectSuppliersByUserSelectedStore,
} from './ngp-report.selectors';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {IDepartment} from '../../../shared/shared-models/stock/departments';
import {IStore} from '../../../shared/shared-models/store/store';
import {ReportUtils} from '../../../shared-utilities/utils-old/shared-utils-old/report-utils';
import {CollectionSharedStockService} from '../../../shared/shared-services/firebase/collection-shared-stock.service';
import {selectNGPReportsPriceBanded} from '../../../shared-modules/shared-grid/store/shared-grid.selectors';
import {
  CollectionUsersSettingsService,
} from '../../../shared/shared-services/firebase/collection-users-settings.service';
import {
  path_shared_stores_data_storeId_data_singular_documents_store_settings,
  path_stores_storeId_settings_disable_stock_item_rules,
  path_users_userId_settings_ngp_edits_preview,
} from '../../../shared/shared-services/database-paths';
import {getDeepCopyOfObject} from '../../../shared/shared-utils/object/object.utils';
import {
  CollectionStoresSettingsService,
} from '../../../shared/shared-services/firebase/collection-stores-settings.service';
import {
  clearEditedItemsInLocalStorage,
  removeNewlyRemovedItemsFromNgpReports,
  updateEditedItemsInLocalStorage,
} from './ngp-report.effects.utils';
import {ISharedStoreSettings} from '../../../shared/shared-models/firebase/shared-store-settings';
import {
  CollectionSharedSingularDocumentsService,
} from '../../../shared/shared-services/firebase/collection-shared-singular-documents.service';
import {StockFunctions} from '../../../shared-utilities/functions-old/stock-functions';
import {FiltersAndTools} from '../../../shared-utilities/models-old/ngp-reports/filters-tools-ngp';
import {FilterAndToolUtils} from '../../../shared-utilities/models-old/utils-old/filterAndToolUtils';

@Injectable()
export class NgpReportEffects {
  // ====================================================================================================
  // Get Line Colours
  // ====================================================================================================
  getLineColours$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.getLineColours),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      mergeMap(([{pageStoreDocument}, selectedStore]) =>
        from(this.firebaseService.getStoreDataDoc(pageStoreDocument, selectedStore.storeId)).pipe(
          switchMap((lineColours: LineColour) =>
            from(
              this.collectionSharedSingularDocumentsService.getDocument(
                path_shared_stores_data_storeId_data_singular_documents_store_settings(selectedStore.storeId),
              ),
            ).pipe(
              map((storeSettings: ISharedStoreSettings) =>
                NGPReportActions.getLineColoursSuccess({
                  lineColours,
                  selectedStore,
                  storeSettings,
                }),
              ),
              catchError((error: IError) =>
                of(NGPReportActions.getLineColoursFailure({error})),
              ),
            ),
          ),
          catchError((error: IError) =>
            of(NGPReportActions.getLineColoursFailure({error})),
          ),
        ),
      ),
    ),
  );


  // ====================================================================================================
  // Get Stock Items
  // ====================================================================================================
  getStockItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.getStockItem),
      withLatestFrom(this.store.pipe(select(selectSuppliersByUserSelectedStore))),
      switchMap(([getStockItemAction, selectedStoreSuppliers]) => {
        const actions: Action[] = [];
        if (Object.keys(selectedStoreSuppliers).length > 0) {
          actions.push(
            NGPReportActions.getStockItemWithExistingSuppliers({
              ...getStockItemAction,
              suppliers: selectedStoreSuppliers,
            }),
          );
        } else {
          actions.push(NGPReportActions.getStockItemWithoutExistingSuppliers(getStockItemAction));
        }
        return actions;
      }),
    ),
  );

  // '[NGP Report] Get Stock Item With Existing Suppliers'
  getStockItemWithExistingSuppliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.getStockItemWithExistingSuppliers),
      mergeMap(({store, firebaseQueries, idField, suppliers}) =>
        this.collectionSharedStockService.getStockItemsCast(store, firebaseQueries, idField).pipe(
          map((stockItemsRaw: StockItem[]) => {
            const stockItems = stockItemsRaw.map((item: StockItem): StockItem => ({
              ...item,
              newlyAdded: true,
              storeId: store.storeId,
              store: store.name,
              suppName: suppliers[item.regularSuppCode]?.name || null,
            }));
            return NGPReportActions.getStockItemSuccess({stockItems, store});
          }),
          catchError((error: IError) => of(NGPReportActions.getStockItemFailure({error}))),
        ),
      ),
    ),
  );

  // '[NGP Report] Get Stock Item Without Existing Suppliers'
  getStockItemWithoutExistingSuppliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.getStockItemWithoutExistingSuppliers),
      mergeMap(({store, firebaseQueries, idField}) =>
        this.collectionSharedStockService.getStockItemsCast(store, firebaseQueries, idField).pipe(
          switchMap((ngpReports: StockItem[]) =>
            from(this.firebaseService.getSuppliers(store.storeId)).pipe(
              switchMap((rawSuppliers: { [p: string]: Supplier }) => {
                const suppliers = this.createSupplierMap(rawSuppliers);
                const stockItems = ngpReports.map((item: StockItem): StockItem => ({
                  ...item,
                  newlyAdded: true,
                  storeId: store.storeId,
                  store: store.name,
                  suppName: suppliers[item.regularSuppCode]?.name || null,
                }));
                return from([
                  NGPReportActions.getStoreSuppliersByStoreIdSuccess({suppliers, storeId: store.storeId}),
                  NGPReportActions.getStockItemSuccess({stockItems, store}),
                ]);
              }),
              catchError((error: IError) =>
                of(
                  NGPReportActions.getStockItemFailure({error}),
                  NGPReportActions.getStoreSuppliersByStoreIdFailure({error}),
                ),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  // ====================================================================================================
  // Get Items Disabling Rules
  // ====================================================================================================
  getStockItemDisablingRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.getStockItem, NGPReportActions.getStockItemDisablingRules),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      mergeMap(([action, selectedStore]) =>
        from(
          this.collectionStoresSettingsService.getDocument<DisabledRulesSupp>(
            path_stores_storeId_settings_disable_stock_item_rules(selectedStore.storeId),
          ),
        ).pipe(
          map((rulesDoc: DisabledRules) =>
            NGPReportActions.getStockItemDisablingRulesSuccess({store: selectedStore, rulesDoc}),
          ),
          catchError((errors: IError) =>
            of(NGPReportActions.getStockItemDisablingRulesFailure({errors})),
          ),
        ),
      ),
    ),
  );

  // ====================================================================================================
  // Set Disabled/Enabled items
  // ====================================================================================================
  setItemDisabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.setItemDisabledStatus),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectItemDisablingRules)),
      ),
      switchMap(([action, currentSelectedStore, disabledRules]) => {
        const updatedNgpReport = {...action.ngpReport};
        ReportUtils.enableReport(updatedNgpReport, disabledRules, action.enable, {
          onHoldCode: +updatedNgpReport.originalValue['onHoldCode'].value,
          lineColourCode: +updatedNgpReport.originalValue['lineColourCode'].value,
        });
        return [
          NGPReportActions.updateSingleNGPReportWithStoreId({
            ngpReport: updatedNgpReport,
            storeId: currentSelectedStore.storeId,
          }),
        ];
      }),
    ),
  );

  // ====================================================================================================
  // Set Filters and Tools
  // ====================================================================================================
  setNGPReportFiltersAndToolsWithStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.setNGPReportFiltersAndTools),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      map(([filtersAndTools, store]) =>
        NGPReportActions.setNGPReportFiltersAndToolsWithStore({
          filtersAndTools: filtersAndTools.filtersAndTools,
          store,
        }),
      ),
    ),
  );

  // ====================================================================================================
  // Set Grid Menu Action
  // ====================================================================================================
  setNGPReportMenuActionsWithStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.setNGPReportMenuActions),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      switchMap(([action, userStores]) => [
        NGPReportActions.setNGPReportMenuActionsWithStore({
          ...action,
          store: userStores,
        }),
      ]),
    ),
  );

  // ====================================================================================================
  // Get Store Departments
  // ====================================================================================================
  fetchStoreDepartments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.getStoreDepartments),
      mergeMap(({store}) =>
        this.firebaseService.getDepartmentsByStore(store).pipe(
          map((departments: IDepartment[]) =>
            NGPReportActions.getStoreDepartmentsSuccess({store, departments}),
          ),
          catchError((error: IError) => of(NGPReportActions.getStoreDepartmentsFailure({error}))),
        ),
      ),
    ),
  );

  // ====================================================================================================
  // Remove Selected Items From NGP Report
  // ====================================================================================================
  removeSelectedNgpReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.removeSelectedNgpReports),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectNGPReports)),
      ),
      mergeMap(([{selectedReports, itemsToUpdate, editedItems, taggedItems}, store, currentNgpReport]) => {
        let promises: Promise<void>[] = [];
        if (Object.keys(itemsToUpdate).length) {
          promises.push(
            new Promise<void>((resolve) =>
              this.firebaseService.stockUpdate(store.storeId, itemsToUpdate).then((): void => {
                resolve();
              }),
            ),
          );
        }
        if (taggedItems) {
          void this.collectionSharedStockService.updateStockTags(taggedItems, store);
        }
        const stockIds = selectedReports.map((report: NGPReport) => report.stockId);
        promises.push(this.firebaseService.updateStockTags(store.storeId, stockIds, 'in_ngp', true));
        return from(Promise.all(promises)).pipe(
          map(() => {
            let allNgpReports: NGPReport[] = [];
            const editedNgpReports = getDeepCopyOfObject(editedItems);
            editedNgpReports.forEach((item: NGPReport) => {
              item.originalValue = getDeepCopyOfObject(item.originalValue);
              Object.keys(item).forEach((key: string) => {
                if (item.originalValue?.[key]?.value) {
                  item.originalValue[key].value = item[key];
                }
                item.isEdited = false;
              });
            });
            const storeReportsMap = currentNgpReport.get(store.storeId) || new Map();
            allNgpReports = [...Array.from(storeReportsMap.values()), ...editedNgpReports];
            this.store.dispatch(NGPReportActions.updateAllNGPReports({ngpReports: [...allNgpReports]}));
            this.store.dispatch(NGPReportActions.clearSavedNgpReportEditsForStore());
            return NGPReportActions.removeSelectedNgpReportsWithStoreID({
              selectedReports,
              storeId: store.storeId,
            });
          }),
        );
      }),
    ),
  );

  updateSingleNGPReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.updateSingleNGPReport),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      switchMap(([action, currentSelectedStore]) => {
        const updatedNGPReport = {...action.ngpReport};
        Object.keys(updatedNGPReport).forEach((key: string) => {
          if (updatedNGPReport[key] === '') {
            updatedNGPReport[key] = action.ngpReport.originalValue[key]?.value;
          }
        });
        return [
          NGPReportActions.updateSingleNGPReportWithStoreId({
            ngpReport: updatedNGPReport,
            storeId: currentSelectedStore.storeId,
          }),
          NGPReportActions.saveSingleNgpItemToLocalStorage({ngpReport: updatedNGPReport}),
        ];
      }),
    ),
  );

  // '[NGP Report][Update] Save Single Ngp Item To Local Storage'
  saveSingleNgpItemToLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          NGPReportActions.saveSingleNgpItemToLocalStorage,
          NGPReportActions.updatePriceGpForNgpReportWithStoreIDSuccess),
        withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
        tap(([{ngpReport}, store]) => {
          if (!ngpReport?.stockId) {
            console.warn('Invalid NGPReport or missing stockId:', ngpReport);
            return;
          }
          updateEditedItemsInLocalStorage(ngpReport, store, 'savedNgpReportEdits');
        }),
        catchError((error: IError) => {
          console.error('Error in saveNgpReport$ effect:', error);
          return of();
        }),
      ),
    {dispatch: false},
  );

  // [NGPReport] Clear Saved Edits For Store
  clearSavedNgpReportEditsForStore$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NGPReportActions.clearSavedNgpReportEditsForStore),
        withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
        tap(([_, store]) => {
          clearEditedItemsInLocalStorage(store, 'savedNgpReportEdits');
        }),
      ),
    {dispatch: false},
  );

  // '[NGP Report][Price Banding] Update The Price NGP and GpDiff for NGP Reports With PriceBanding'
  setApplyPriceBandingToNGPReportItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.setApplyPriceBandingToNGPReportItems),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectNGPReportsPriceBanded)),
      ),
      switchMap(([_, currentSelectedStore, ngpReports]) => {
        const ngpReportsByStockId = ngpReports.reduce(
          (acc: { [stockId: string]: NGPReport }, ngpReport: NGPReport) => {
            acc[ngpReport.stockId] = ngpReport;
            return acc;
          },
          {},
        );
        return [
          NGPReportActions.setApplyPriceBandingToNGPReportItemsAtStoreId({
            ngpReports: ngpReportsByStockId,
            store: currentSelectedStore,
          }),
        ];
      }),
    ),
  );

  updateIsSelectedForSingleNGPReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.updateIsSelectedForSingleNGPReport),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectNGPReports)),
      ),
      switchMap(([action, currentSelectedStore, mostRecentNGPReport]) => {
        const storeMap = mostRecentNGPReport.get(currentSelectedStore.storeId);
        const matchingNGPItem = storeMap ? storeMap.get(action.ngpReport.stockId) : undefined;
        if (!matchingNGPItem) return EMPTY;
        const updatedNGPItem = {...matchingNGPItem, isSelected: action.ngpReport.isSelected};
        return [
          NGPReportActions.updateIsSelectedForSingleNGPReportWithStoreId({
            ngpReport: updatedNGPItem,
            storeId: currentSelectedStore.storeId,
          }),
        ];
      }),
    ),
  );

  updateAllNGPReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.updateAllNGPReports),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      switchMap(([action, currentSelectedStore]) => {
        return [
          NGPReportActions.updateAllNGPReportsWithStoreID({
            ngpReports: [...action.ngpReports],
            storeId: currentSelectedStore.storeId,
          }),
        ];
      }),
    ),
  );

  updateNewlyAddedItemsVisibility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.setNewlyAddedItemsVisibility),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      map(([_, currentSelectedStore]) =>
        NGPReportActions.setNewlyAddedItemsVisibilityWithStoreID({
          storeId: currentSelectedStore.storeId,
        }),
      ),
    ),
  );

  updateNewlyRemovedItemsVisibility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.setNewlyRemovedItemsVisibility),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectNGPState)),
      ),
      map(([_, currentSelectedStore, state]) => {
        const storeId = currentSelectedStore.storeId;
        const storeReports: Map<string, NGPReport> = state.ngpReports.get(storeId) || new Map();
        const newlyRemoved: Map<string, NGPReport> = state.ngpReportsNewlyRemoved.get(storeId) || new Map();
        const updatedStoreReports = removeNewlyRemovedItemsFromNgpReports(storeReports, newlyRemoved);

        let filtersAndTools: FiltersAndTools = state.filtersAndTools;
        if (updatedStoreReports.size > 0) {
          filtersAndTools = FilterAndToolUtils.determineFiltersAndTools(
            Array.from(updatedStoreReports.values()),
            filtersAndTools,
          );
          filtersAndTools = FilterAndToolUtils.changeFilterAndToolsCounts(filtersAndTools);
        }

        let copiedMenuData = getDeepCopyOfObject(state.headerMenuData);
        Object.keys(state.headerMenuData).forEach((sId: string) => {
          Object.keys(state.headerMenuData[sId]).forEach((colId: string) => {
            delete copiedMenuData[sId][colId].sortType;
          });
        });

        filtersAndTools.total =
          (state.ngpReportsNewlyAdded.get(storeId)?.size || 0) + (state.ngpReports.get(storeId)?.size || 0);

        const newNgpReportsNewlyRemoved = new Map(state.ngpReportsNewlyRemoved);
        newNgpReportsNewlyRemoved.set(storeId, new Map());

        const newNgpReports = new Map(state.ngpReports);
        newNgpReports.set(storeId, updatedStoreReports);

        return NGPReportActions.setNewlyRemovedItemsVisibilitySuccess({
          filtersAndTools,
          headerMenuData: copiedMenuData,
          ngpReports: newNgpReports,
          ngpReportsNewlyRemoved: newNgpReportsNewlyRemoved,
        });
      }),
    ),
  );


  updateNGPReportGridColDefs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.setNGPReportGridColDefs),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      map(([action, currentSelectedStore]) =>
        NGPReportActions.setNGPReportGridColDefsWithStoreID({
          colDefs: action.colDefs,
          storeId: currentSelectedStore.storeId,
        }),
      ),
    ),
  );

  setNGPReportVisibility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.setNGPReportVisibility),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      map(([action, currentSelectedStore]) =>
        NGPReportActions.setNGPReportVisibilityWithStoreID({
          colDef: action.colDef,
          menuData: action.menuData,
          storeId: currentSelectedStore.storeId,
        }),
      ),
    ),
  );

  // ====================================================================================================
  // make changes to Price incl || NPG || Gp Diff when one of them is changed
  // ====================================================================================================
  updatePriceGpForNgpReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.updatePriceGpForNgpReport),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      switchMap(([action, currentSelectedStore]) => {
        const storeId = currentSelectedStore.storeId;
        return [
          NGPReportActions.updatePriceGpForNgpReportWithStoreID({
            ngpReport: action.ngpReport,
            field: action.field,
            storeId: storeId,
          }),
        ];
      }),
    ),
  );

  updatePriceGpForNgpReportWithStoreId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.updatePriceGpForNgpReportWithStoreID),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      switchMap(([action, currentSelectedStore]) => {
        const field = action.field;
        let ngpReport = action.ngpReport;
        const storeId = currentSelectedStore.storeId;
        const nomGP = +StockFunctions.calcGP(storeId, ngpReport as unknown as StockItem, ngpReport.sellPriIncl1).toFixed(2);
        const copiedNgpReport = {...ngpReport};
        const oldNom = copiedNgpReport.nominalGP;
        const diffGP = nomGP === copiedNgpReport.nominalGP ? copiedNgpReport.diffGP : nomGP - oldNom;
        copiedNgpReport.prevCostPrice = +copiedNgpReport.originalValue['prevCostPrice'].value;
        switch (field) {
          case 'sellPriIncl1':
            copiedNgpReport.nominalGP = nomGP;
            copiedNgpReport.diffGP = diffGP;
            if (copiedNgpReport.nominalGP < 0.01) {
              copiedNgpReport.sellPriIncl1 = ReportUtils.calculatePriceFromGP(storeId, ngpReport);
              copiedNgpReport.nominalGP = +StockFunctions.calcGP(
                storeId,
                ngpReport as unknown as StockItem,
                ReportUtils.calculatePriceFromGP(storeId, ngpReport),
              ).toFixed(2);
            }
            break;
          case 'nominalGP':
            const newPrice = ReportUtils.calculatePriceFromGP(storeId, ngpReport);
            copiedNgpReport.sellPriIncl1 = newPrice;
            const nomGP2 = +StockFunctions.calcGP(storeId, ngpReport as unknown as StockItem, newPrice).toFixed(2);
            copiedNgpReport.nominalGP = nomGP2;
            copiedNgpReport.diffGP = nomGP2 - +ngpReport.originalValue.nominalGP.value;
            break;
        }
        return [
          NGPReportActions.updatePriceGpForNgpReportWithStoreIDSuccess({
            ngpReport: copiedNgpReport,
            field: field,
            storeId: storeId,
          }),
        ];
      }),
    ),
  );

  getVatRateConversion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.getVatRateConversion),
      withLatestFrom(
        this.store.pipe(select(selectUserStores)),
        this.store.pipe(select(selectAllVatRates)),
      ),
      switchMap(([action, userStores, vatRates]) => {
        const actions: Action[] = [];
        if (Object.keys(vatRates).length > 0) {
          actions.push(
            NGPReportActions.getVatRateConversionWithExistingVatRates({
              userStores,
              vatRates: vatRates,
            }),
          );
        } else {
          actions.push(NGPReportActions.getVatRateConversionWithoutExistingVatRates({userStores}));
        }
        return actions;
      }),
    ),
  );

  getVatRateConversionWithExistingVatRates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.getVatRateConversionWithExistingVatRates),
      map(({userStores, vatRates}) => {
        const vatRatesResult = userStores.reduce((acc, userStore) => {
          acc[userStore.storeId] = vatRates[userStore.storeId];
          return acc;
        }, {});
        return NGPReportActions.getVatRateConversionSuccess({vatRates: vatRatesResult});
      }),
    ),
  );

  getVatRateConversionWithoutExistingVatRates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.getVatRateConversionWithoutExistingVatRates),
      switchMap(({userStores}) => {
        const vatRatesObservables = userStores.map((userStore: IStore) =>
          this.firebaseService
            .subStoreDoc('data/singular_documents/vat_rates', userStore.storeId)
            .pipe(
              take(1),
              map((vr: VatRates) => ({storeId: userStore.storeId, vatRate: vr})),
            ),
        );
        return forkJoin(vatRatesObservables).pipe(
          map((vatRatesArray) => {
            const vatRates = vatRatesArray.reduce((acc, {storeId, vatRate}) => {
              acc[storeId] = vatRate;
              return acc;
            }, {});
            return NGPReportActions.getVatRateConversionSuccess({vatRates});
          }),
          catchError((error: IError) => of(NGPReportActions.getVatRateConversionFailure({error}))),
        );
      }),
    ),
  );

  // ====================================================================================================
  // Set the NGP Preview Column Data in firebase
  // ====================================================================================================
  setNgpPreviewColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.setNgpPreviewColumns),
      withLatestFrom(this.store.pipe(select(selectUser))),
      switchMap(([action, {id}]) => {
        return from(
          this.collectionUsersSettingsService.setDocument<{ [key: number]: string }>(
            path_users_userId_settings_ngp_edits_preview(id),
            action.columnData,
          ),
        ).pipe(
          map(() => NGPReportActions.setNgpPreviewColumnsSuccess({columnData: action.columnData})),
          catchError((error: IError) => of(NGPReportActions.setNgpPreviewColumnsFailure({error}))),
        );
      }),
    ),
  );

  getNgpPreviewColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.getNgpPreviewColumns),
      withLatestFrom(this.store.pipe(select(selectUser))),
      switchMap(([_, {id}]) => {
        return from(
          this.collectionUsersSettingsService.getDocument<{ [key: number]: string }>(
            path_users_userId_settings_ngp_edits_preview(id),
          ),
        ).pipe(
          map((columnData: { [key: number]: string } | undefined) =>
            NGPReportActions.setNgpPreviewColumnsSuccess({columnData: columnData ? columnData : {}}),
          ),
          catchError((error: IError) => of(NGPReportActions.setNgpPreviewColumnsFailure({error}))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
    private collectionSharedStockService: CollectionSharedStockService,
    private collectionStoresSettingsService: CollectionStoresSettingsService,
    private collectionUsersSettingsService: CollectionUsersSettingsService,
    private collectionSharedSingularDocumentsService: CollectionSharedSingularDocumentsService,
    private readonly store: Store,
  ) {
  }

  private createSupplierMap(suppliers: { [p: string]: Supplier }): { [key: string]: Supplier } {
    const supplierMap = {};
    for (const key in suppliers) {
      if (suppliers.hasOwnProperty(key)) {
        supplierMap[key] = suppliers[key];
      }
    }
    return supplierMap;
  }
}
