<div class="notification-stock-update-container">

  <ng-container
    *ngIf="getIsComponentLoading; else notificationStockUpdateLoading">

    <div class="header">Stock Information</div>

    <!-- TODO: TECHNICAL DEBT - DONT USE IONIC BUTTONS -->
    <div class="content-container-main main-header">
      <span></span>
      <div class="content-container">
        <ion-button
          (click)="onViewChangesClick()"
          [disabled]="true"
          fill="outline">
          View Changes
        </ion-button>
      </div>
    </div>

  </ng-container>
  <ng-template #notificationStockUpdateLoading>
    <div class="notification-loader">
      <app-shared-loading-indicator
        [animation]="notificationStockUpdateLoader">
      </app-shared-loading-indicator>
    </div>

  </ng-template>

</div>

