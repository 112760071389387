<ion-content>
  <div class="options">

    <div (click)="navigateToSettings()" class="item">
      <span>Edit Table Settings</span>
      <app-display-icon [icon]="icons.settings" class="icon"></app-display-icon>
    </div>
    <!--  We must look at this again in the future-->
    <!--    <div class="item toggle">-->
    <!--      <span>Fit To Table</span>-->
    <!--      <ion-toggle (click)="onToggleFitToTableClick()" [(ngModel)]="showAllColDef"></ion-toggle>-->
    <!--    </div>-->

    <div class="item toggle">
      <span>Price Banding</span>
      <ion-toggle (click)="onTogglePriceBandingClick($event)" [checked]="isCurrentlyPriceBanding"></ion-toggle>
    </div>

    <div (click)="navigateToSettings()" class="item">
      <span>Set Preview Columns</span>
      <app-display-icon [icon]="icons.eyeOffOutline" class="icon"></app-display-icon>
    </div>

    <div (click)="navigateToSettings()" class="item disabled">
      <span>Edit Item Disable Rules</span>
      <app-display-icon [icon]="icons.lockClosedOutline" class="icon"></app-display-icon>
    </div>

  </div>
</ion-content>
