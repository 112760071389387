import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmailValidationManager {
  isValidEmail(email: string): string | null {
    if (!email) return 'Email is required';
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) ? null : 'Invalid email format';
  }

  isValidUser(user: string): string | null {
    if (!user) return 'Username is required';
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(user) ? null : 'Invalid email format';
  }

  isValidPassword(pwd: string): string | null {
    return !pwd ? 'Password is required' : null;
  }

  isValidSmtpServer(server: string): string | null {
    return !server ? 'SMTP server is required' : null;
  }

  isValidSmtpPort(port: number | string): string | null {
    const numPort = typeof port === 'string' ? parseInt(port, 10) : port;
    if (!numPort) return 'SMTP port is required';
    return numPort < 1 || numPort > 65535 ? 'Port must be between 1 and 65535' : null;
  }

  isValidImapServer(server: string): string | null {
    return !server ? 'IMAP server is required' : null;
  }

  isValidImapPort(port: number | string): string | null {
    const numPort = typeof port === 'string' ? parseInt(port, 10) : port;
    if (!numPort) return 'IMAP port is required';
    return numPort < 1 || numPort > 65535 ? 'Port must be between 1 and 65535' : null;
  }
}
