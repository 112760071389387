import {Component, Input, OnInit} from '@angular/core';
import {ModalController, PopoverController} from '@ionic/angular';
import {SelectPopoverComponent} from '../select-popover/select-popover.component';
import {StandardAlertsService} from '../../../../shared-utilities/services-old/standard-alerts.service';
import {arrayRemove, initObj, objLen} from '../../../../shared-utilities/functions-old/object-functions';

interface ContentSelection {
  values: { [key: string]: string };
  check2Remove?: boolean;
  btnLabel?: string;
  title?: string;
  multiple?: boolean;
  selectAll?: boolean;
  embedded?: boolean;
  valuesOrder?: string[];
}

interface ValueInputs {
  type: 'EMAIL'; // Can expand input types in the future, booleans, dates, users, etc?
  label?: string;
  placeholder?: string;
}

interface Content {
  heading: string;
  subHeader?: string;
  message?: string;
  selection?: ContentSelection;
  inputs?: ValueInputs[];
  storeName?: string;
}

export interface ReattemptInput {
  itemsToIgnore?: string[];
  recipients?: string[];
}

@Component({
  selector: 'app-ao-reattempt-modal',
  templateUrl: './ao-reattempt-modal.component.html',
  styleUrls: ['./ao-reattempt-modal.component.scss'],
})
export class AoReattemptModalComponent implements OnInit {
  @Input() responses: { [i: number]: { [orderId: string]: any } };
  @Input() storeNames?: { [i: number]: string } = {};
  @Input() timeStamps?: { [i: number]: { d: string; t: string } } = {};

  content: { [i: number]: { [orderId: string]: Content } } = {};

  numRequired: number;
  inputValues: { [orderId: string]: ReattemptInput } = {};
  numInputValues = 0;
  sortedContent: { idx: number; orderIds: string[] }[] = [];
  valueInvalid: { [orderId: string]: boolean } = {};
  submitting: boolean;

  constructor(
    private modalController: ModalController,
    private popControl: PopoverController,
    private stdAlerts: StandardAlertsService,
  ) {
  }

  async ngOnInit() {
    for (const i of Object.keys(this.responses)) {
      const idx = +i;
      this.content[idx] = {};

      for (const orderId of Object.keys(this.responses[i])) {
        this.content[idx][orderId] = this.createContent(idx, orderId, this.responses[idx][orderId]);
      }
    }
    this.numRequired = 0;
    this.sortedContent = (Object.keys(this.content)).sort().map((idx) => {
      const pack = {idx: +idx, orderIds: Object.keys(this.content[idx]).sort()};

      // Clean Content if needed
      for (const oID of pack.orderIds) {
        if (this.content[idx][oID].selection) {
          const selection = this.content[idx][oID].selection;

          if (!selection.header) {
            selection.header = 'Adjust Selection';
          }
          if (!selection.btnLabel) {
            selection.btnLabel = 'Adjust Selection';
          }
          if (!selection.valuesOrder) {
            selection.valuesOrder = Object.keys(selection.values)
              .sort((a, b) => selection.values[a] > selection.values[b] ? -1 : 1);
          }
        }
      }
      this.numRequired += pack.orderIds.length;
      return pack;
    });
  }

  close = async () => {
    const confirm = await this.stdAlerts.simpleConfirmation({message: 'All changes will be lost.'});

    if (confirm) {
      this.modalController.dismiss().then();
    }
  };

  async submit() {
    this.submitting = true;
    const confirm = await this.stdAlerts.simpleConfirmation({message: 'Confirm Changes?'});

    if (!confirm) {
      this.submitting = false;
      return;
    }
    const dataToReturn: { [i: number]: { [orderId: string]: ReattemptInput } } = {};

    for (const i of Object.keys(this.content)) {
      for (const orderId of Object.keys(this.content[i])) {
        if (this.inputValues[orderId]) {
          if (!dataToReturn[i]) {
            dataToReturn[i] = {};
          }
          dataToReturn[i][orderId] = this.inputValues[orderId];
        }
      }
    }
    this.submitting = false;
    this.modalController.dismiss(dataToReturn).then();
  }

  async makeSelection(idx: number, orderId: string, event) {
    const opts = this.content[idx][orderId].selection;

    const pc = await this.popControl.create({
      component: SelectPopoverComponent, componentProps: {
        title: opts.title, selection: opts.values, multiple: opts.multiple, selectAll: opts.selectAll,
        order: opts.valuesOrder, okText: 'CONFIRM SELECTION', event,
      },
    });
    await pc.present();
    const {data} = await pc.onDidDismiss();

    if (data) {
      switch (this.responses[idx][orderId].pos) {
        case 'AO_EMAIL_FAIL':
          if (data.length) {
            this.inputValues[orderId] = {recipients: data};
          } else {
            delete this.inputValues[orderId];
          }
          break;
        // case 'AO_SUBMISSION_FAIL':
        //   break;
        default:
          this.stdAlerts.errorAlert({
            header: 'You should not be seeing this', subHeader: 'Values where passed ' +
              'to AoReattemptComponent for an unsupported "ps".', message: 'Please inform Techodactyl Support',
          }).then();
      }
      this.numInputValues = objLen(this.inputValues);
    }
  }

  isValidEmail(email: string): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email); // Simple email validation regex
  }

  valueInputChange(event, idx, orderId) {
    const inputType = event.target.type;
    const checked = inputType === 'checkbox' ? event.target.checked : null;
    const value = inputType === 'checkbox' ? event.target.value : event.detail?.value;

    switch (this.responses[idx][orderId].pos) {
      case 'AO_EMAIL_FAIL':
        if (this.isValidEmail(value)) {
          initObj([orderId, 'recipients'], [value], this.inputValues);
          delete this.valueInvalid[orderId];
        } else {
          this.valueInvalid[orderId] = true;
        }
        break;
      case 'AO_SUBMISSION_FAIL':
        if (checked) {
          // value is undefined
          if (!this.inputValues[orderId]) {
            this.inputValues[orderId] = {itemsToIgnore: [value]};
          } else if (!this.inputValues[orderId].itemsToIgnore) {
            this.inputValues[orderId].itemsToIgnore = [value];
          } else {
            this.inputValues[orderId].itemsToIgnore.push(value);
          }
        } else {
          arrayRemove(this.inputValues[orderId].itemsToIgnore, value);

          if (this.inputValues[orderId].itemsToIgnore.length === 0) {
            delete this.inputValues[orderId].itemsToIgnore;
            if (objLen(this.inputValues[orderId]) === 0) {
              delete this.inputValues[orderId];
            }
          }
        }
        break;
      default:
        this.stdAlerts.errorAlert({
          header: 'You should not be seeing this', subHeader: 'A value was passed ' +
            'to AoReattemptComponent for an unsupported "ps".', message: 'Please inform Techodactyl Support',
        }).then();
    }
    this.numInputValues = objLen(this.inputValues);
  }

  private createContent(idx: number, orderId: string, response: any): Content {
    switch (response.pos) {
      case 'AO_EMAIL_FAIL':
        return this.emailFailReattempt(response, orderId, idx);
      case 'AO_SUBMISSION_FAIL':
        return this.submissionFailReattempt(response, orderId, idx);
      case 'AO_PDF_FAIL':
        return this.pdfFailReattempt(response, orderId, idx);
      default:
        this.stdAlerts.errorAlert({
          header: 'You should not be seeing this', subHeader: 'Un unhandled AO file ' +
            '"pos" has been processed.', message: 'Please inform Techodactyl Support',
        }).then();
    }
  }

  private emailFailReattempt(response: any, orderId, i): Content {
    if (response && response.recipients && response.recipients.length) {
      const selection = {};

      for (const email of response.recipients) {
        selection[email] = email;
      }
      return {
        heading: 'Adjust Recipients',
        subHeader: 'Please select one or more recipients',
        selection: {
          title: 'Adjust Recipients', values: selection, multiple: true, selectAll: true,
        },
      };
      // TODO: Note, Ambiguous result on address isn't a thing anymore because, we are no longer using the email
      //  validation package that returned that error
      // if (this.failedAOMsgs[i].payload.data.msg) {
      //   const expr = RegExp(/(?:Address '(?<email1>.+)' invalid. Address is invalid)|/.source +
      //     /(?:Ambiguous result on address '(?<email2>.+))'/.source);
      //   const m = expr.exec(this.failedAOMsgs[i].payload.data.msg);
      //
      //   const email = m ?
      //     ((data.recipients).includes(m.groups.email1) ? m.groups.email1 : m.groups.email2) :
      //     null;
      //
      //   if (email) {
      //     content.reason = 'whiteList';
      //     content.heading = 'Potentially Invalid Email Address';
      //     content.subHeader = 'Please note the email address ' +
      //       `"${email}" caused the email to not be delivered.`;
      //     content.message = 'You may reattempt this ' +
      //       'address or, if you are certain it is correct, you can white list it to bypass its validity ' +
      //       'check from now on.';
      //     // content.email = email;
      //     // content.response = this.failedAOMsgs[i].payload.data;
      //     // content.orderId = orderId;
      //     content.storeName = this.storesInfo.stores[this.failedAOMsgs[i].sender].name;
      //   }
      // }
    } else {
      return {
        heading: `${this.responses[i][orderId].supplier}: Enter Recipient Email`,
        subHeader: `No recipients are selected for ${response.supplier}`,
        message: 'Please enter a valid email address below.',
        inputs: [{
          type: 'EMAIL',
          label: 'Recipient',
          placeholder: 'example@email.com',
        }],
      };
    }
  }

  private submissionFailReattempt(response: any, orderId, i): Content {
    const eo = response.errorObj;

    if (eo && eo.itemErrors) {
      // let checkboxes = [];
      const items = Object.keys(eo.itemErrors).sort();
      const cs: ContentSelection = {values: {}, embedded: true};
      items.forEach((code) => {
        cs.values[code] = `${code}: Error code ${eo.itemErrors[code].error}, "${eo.itemErrors[code].desc}"`;
      });
      // content.reason = 'AO_SUBMISSION_FAIL';
      return {
        heading: `Failed Stock Items`,
        subHeader: 'The following stock items caused the order submission to fail',
        message: 'By default these items will be removed on reattempt. If you want to retry ' +
          'an item, check it.',
        selection: cs,
      };
    }
  }

  private pdfFailReattempt(response: any, orderId, i): Content {
    return null;
  }
}
