<div [class.loading]="isSubmittingOnHandForm"
     class="settings-container">

  <div class="loading-overlay">
    <ion-spinner></ion-spinner>
  </div>

  <ion-accordion-group [value]="['itemDisablingRules']">
    <ion-accordion value="itemDisablingRules">
      <ion-item class="section-header-dropdown" slot="header">
        <ion-label>Item Disabling Rules</ion-label>
      </ion-item>
      <div class="section-body-dropdown" slot="content">
        <div class="section-setting">
          <app-setting-item-disabling-rules>
          </app-setting-item-disabling-rules>
        </div>
      </div>
    </ion-accordion>

    <ion-accordion value="stockItemTags">
      <ion-item class="section-header-dropdown" slot="header">
        <ion-label>Stock Item Tags</ion-label>
      </ion-item>
      <div class="section-body-dropdown" slot="content">
        <div class="section-setting">
          <app-settings-stock-tags
            [setTags]="allTags">
          </app-settings-stock-tags>
        </div>
      </div>
    </ion-accordion>

    <ion-accordion value="priceThresholds">
      <ion-item class="section-header-dropdown" slot="header">
        <ion-label>Price Thresholds</ion-label>
      </ion-item>
      <div class="section-body-dropdown" slot="content">
        <div class="section-setting">
          <app-setting-price-thresholds>
          </app-setting-price-thresholds>
        </div>
      </div>
    </ion-accordion>

  </ion-accordion-group>

</div>
