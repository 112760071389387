import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {IUser} from './shared/shared-models/user-access/user';
import {INavigationPage, IPageObj, ISubPageObj} from './shared-utilities/models-old/page/page';
import {
  AnimationDefault,
  AnimationDirection,
  Animations,
  IAnimation,
} from './shared/shared-models/animations/animation';
import {InactivityService} from './shared-utilities/services-old/inactivity.service';
import {PAGES, PAGES_SUB} from './shared-utilities/models-old/page/pages-const';
import {Store} from '@ngrx/store';
import {getUser, getUserAccess} from './features-as-modules/feature-core/store/core.actions';
import {FirebaseService} from './shared-utilities/services-old/firebase.service';
import {initDepartmentFunctions} from './shared-utilities/functions-old/department-functions';
import {
  selectCurrentPageAndTabObject,
  selectUser,
  selectUserAccess,
} from './features-as-modules/feature-core/store/core.selectors';
import {IUserAccess} from './shared/shared-models/user-access/user-access';
import {getDeepCopyOfObject} from './shared/shared-utils/object/object.utils';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {selectNotificationTotals} from './features/notifications/store/notification.selectors';
import {INotificationTotals} from './features/notifications/models/notification';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

  protected currentSelectedPageObj: INavigationPage = {} as INavigationPage;
  protected isModuleLoading = false;
  protected selectedPage: string[];
  protected user: IUser;

  protected currentNavigation$: Observable<{ currentSelectedPage: INavigationPage; }>;
  protected notificationTotals$: Observable<INotificationTotals>;
  protected user$: Observable<IUser>;
  protected userAccess$: Observable<IUserAccess>;

  protected readonly menuPagesSubs: ISubPageObj = getDeepCopyOfObject(PAGES_SUB);
  protected readonly menuPagesSubsAll: ISubPageObj = getDeepCopyOfObject(PAGES_SUB);
  protected readonly animationLogo: IAnimation = {
    ...AnimationDefault,
    animation: Animations.CIRCLE_DISAPPEAR_AND_REAPPEAR,
    direction: AnimationDirection.REVERSE,
    id: 'app-component-animation-logo',
  };

  private menuPages: IPageObj[] = [];

  constructor(
    private titleService: Title,
    private firebaseService: FirebaseService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private inactivityService: InactivityService,
    private readonly store: Store,
  ) {
    initDepartmentFunctions(firebaseService);
    this.inactivityService.startWatching();
  }

  get currentSelectedPage(): string[] {
    return [this.currentSelectedPageObj.currentPage, this.currentSelectedPageObj.currentTab];
  }

  @HostListener('document:touchstart', ['$event'])
  onDocumentTouch(event: Event): void {
    this.handleOutsideInteraction(event);
  }

  ngOnInit(): void {
    this.setTitle();
    this.selectedPage = [this.currentSelectedPageObj.currentPage || 'home', this.currentSelectedPageObj.currentTab || null];
    this.menuPages = PAGES;
    this.store.dispatch(getUser());
    this.store.dispatch(getUserAccess());

    this.user$ = this.store.select(selectUser);
    this.userAccess$ = this.store.select(selectUserAccess);

    this.notificationTotals$ = this.store.select(selectNotificationTotals);
    this.currentNavigation$ = this.store.select(selectCurrentPageAndTabObject);

    this.currentNavigation$.subscribe((navigation: { currentSelectedPage: INavigationPage; }): void => {
      this.setNavigation(navigation);
    });

    this.user$.subscribe((user: IUser): void => {
      this.user = user;
    });

  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  changeMenuSize(value: string): void {
    if (value === 'expand') {
      document.querySelector('.side-nav').classList.add('expanded-nav');
    } else {
      document.querySelector('.side-nav').classList.remove('expanded-nav');
    }
  }

  showSideNav(value: string): void {
    if (value === 'expand') {
      document.querySelector('.side-nav').classList.add('expanded-nav');
    } else {
      document.querySelector('.side-nav').classList.remove('expanded-nav');
    }
  }

  getCurrentPageText(): string {
    const page = this.currentSelectedPage[0];
    const tab = this.currentSelectedPage[1];
    const pageObj: IPageObj = this.menuPages.find((menuPage: IPageObj): boolean => menuPage.page === page);
    const subPageObj = tab && this.menuPagesSubsAll[page] ?
      this.menuPagesSubsAll[page].find((subPage: IPageObj): boolean => subPage.tab === tab) :
      undefined;
    if (subPageObj) {
      return pageObj ? `${pageObj.text} - ${subPageObj.text}` : '';
    } else {
      return pageObj ? pageObj.text : '';
    }
  }

  private handleOutsideInteraction(event: Event): void {
    const sideNav = document.querySelector('.side-nav');
    const toggleButton = document.querySelector('.toggle-button');
    if (sideNav?.classList.contains('expanded-nav')) {
      if (
        sideNav.contains(event.target as Node) ||
        (toggleButton?.contains(event.target as Node))
      ) {
        return;
      }
      sideNav.classList.remove('expanded-nav');
    }
  }

  private setNavigation(selectedStorePageObj: { currentSelectedPage: INavigationPage }): void {
    this.currentSelectedPageObj = selectedStorePageObj.currentSelectedPage;
    this.selectedPage = [
      this.currentSelectedPageObj.currentPage,
      this.currentSelectedPageObj.currentTab,
    ];
    const tab = this.currentSelectedPageObj.currentTab;
    const queryParams = this.currentSelectedPageObj.currentTab ? {queryParams: {tab}} : {};
    this.router.navigate([this.currentSelectedPageObj.currentPage], queryParams)
      .then()
      .catch((error): void => {
          console.error(`Navigation failed to ${this.currentSelectedPageObj.currentPage}.`, error);
        },
      );
  }

  private setTitle(): void {
    let title = this.titleService.getTitle();
    switch (environment.environment) {
      case 'Dev':
        title = `${title} - Dev`;
        break;
      case 'QA':
        title = `${title} - QA`;
        break;
      case 'Manage IT':
        title = `${title} - MI`;
        break;
      case 'Gallix':
      default:
        break;
    }
    this.titleService.setTitle(title);
  }

}
