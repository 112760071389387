<div id="app-notification-modal-div">

  <ng-container *ngIf="getIsComponentLoading; else userNotificationsLoaded">

    <div class="notification-loader">
      <app-shared-loading-indicator
        [animation]="userNotificationsAnimation">
      </app-shared-loading-indicator>
    </div>

  </ng-container>

  <ng-template #userNotificationsLoaded>

    <!-- Shared store tabs to display the store selector tabs at the top of the modal ----------------------------- -->

    <app-shared-store-tabs
      (emitStoreChange)="selectStore($event)"
      [isMessagesModal]="true"
      [isModal]="true"
      [setStores]="(userStores$ | ngrxPush)"
      [setUserSelectedStore]="(userSelectedStore$ | ngrxPush)">
    </app-shared-store-tabs>

    <!-- The filter chips that filters the notifications based on the selected criteria---------------------------- -->

    <div class="filter-chips">
<!--      <ion-item>-->
<!--        <ion-label>All ({{ notifications.total }})</ion-label>-->
<!--        <ion-toggle-->
<!--          (ionChange)="onToggleClick('ALL')"-->
<!--          [(ngModel)]="notificationChips.allMessages"-->
<!--          slot="end">-->
<!--        </ion-toggle>-->
<!--      </ion-item>-->
      <app-shared-chip
        (click)="onToggleClick('ALL')"
        [chipId]="'notification-all-messages'"
        [selected]="notificationChips.allMessages">
        <div>All ({{ notifications.total }})</div>
      </app-shared-chip>
      <app-shared-chip
        (click)="onToggleClick('READ')"
        [chipId]="'notification-read-messages'"
        [selected]="notificationChips.readMessages">
        <div>Read ({{ notifications.read }})</div>
      </app-shared-chip>
      <app-shared-chip
        (click)="onToggleClick('UNREAD')"
        [chipId]="'notification-unread-messages'"
        [selected]="notificationChips.unreadMessages">
        <div>Unread ({{ notifications.unread }})</div>
      </app-shared-chip>

      <span></span>

      <app-shared-chip
        (click)="onToggleClick('SUCCESS')"
        [chipId]="'notification-success-messages'"
        [selected]="notificationChips.success">
        <div>SUCCESS</div>
      </app-shared-chip>

      <app-shared-chip
        (click)="onToggleClick('FAIL')"
        [chipId]="'notification-fail-messages'"
        [selected]="notificationChips.fail">
        <div>FAILED</div>
      </app-shared-chip>

      <span></span>

      <app-shared-chip
        (click)="onToggleClick('AUTO_ORDERS')"
        [chipId]="'notification-auto-orders-messages'"
        [selected]="notificationChips.autoOrder">
        <div>Auto Orders</div>
      </app-shared-chip>

      <app-shared-chip
        (click)="onToggleClick('STOCK_UPDATE')"
        [chipId]="'notification-stock-update-messages'"
        [selected]="notificationChips.stockUpdate">
        <div>Stock Update</div>
      </app-shared-chip>

    </div>

    <!-- The accordion display for each notification -------------------------------------------------------------- -->
    <div class="accordion-container">

      <app-shared-accordion>

        <ng-container *ngFor="let notify of notificationsDisplay; trackBy: trackByNotification">

          <app-shared-accordion-item
            (accordionHeaderClicked)="selectNotification(notify)"
            [class]=" notify?.read?.length < 1 ? 'notification-unread' : ''">
            <div class="header">
              <div
                [ngClass]="notify.success ? 'dot-success' : 'dot-fail'">
                <div class="dot"></div>
              </div>
              <div>{{ firebaseTimeStampToDate(notify.timestamp) | date:'yyyy-MM-dd HH:mm' }}</div>
              <div>{{ getHumanReadableNotificationType(notify) }}</div>
              <div>{{ getAuthor(notify) }}</div>
              <Span></Span>
            </div>
            <div class="body">
              <app-notification-container
                [setNotification]="notify"
                [setUserStores]="(userStores$ | ngrxPush)"
                [setUser]="user">
              </app-notification-container>
            </div>
          </app-shared-accordion-item>

        </ng-container>

      </app-shared-accordion>

    </div>

  </ng-template>

</div>
