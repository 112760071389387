import {UrlSegment, UrlTree} from '@angular/router';
import {validateAccess} from './guard-utils';

/**
 * Guards route loading by checking if the user has access to the requested page.
 *
 * This function is used in routing to determine whether the user can load a route
 * based on their access permissions. It checks access by passing the path of the
 * first URL segment to the `validateAccess` function and returns the result.
 *
 * @param {UrlSegment} segments - The URL segment containing information about
 *        the requested route, specifically the path part of the URL.
 * @returns {Promise<boolean | UrlTree>} - A promise that resolves to either
 *         `true` if the user has access to the page, or a `UrlTree` if redirection
 *         is needed due to insufficient access.
 */
export const canLoadGuard = (
  segments: UrlSegment
): Promise<boolean | UrlTree> => {
  const page: string = segments?.path || '';
  return validateAccess(page);
};
