import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {CoreState} from './core.reducer';
import {IStore} from '../../../shared/shared-models/store/store';
import {IUser} from '../../../shared/shared-models/user-access/user';

export const selectCoreState = createFeatureSelector<CoreState>('core');

// ====================================================================================================
// Get User
// ====================================================================================================
export const selectUser = createSelector(
  selectCoreState,
  (state: CoreState) => state.user || {} as IUser,
);

export const selectIsUserLoading = createSelector(
  selectCoreState,
  (state: CoreState) => state.isUserLoading,
);

export const selectIsPagesFinishedLoading = createSelector(
  selectCoreState,
  (state: CoreState) => state.isPagesFinishedLoading,
);

export const hasUserLoggedIn = createSelector(
  selectCoreState,
  (state: CoreState) => state.isUserLoggedIn,
);

// ====================================================================================================
// Get User Access
// ====================================================================================================
export const selectUserAccess = createSelector(
  selectCoreState,
  (state: CoreState) => state.userAccess,
);

export const selectUserAccessStoreList = createSelector(
  selectCoreState,
  (state: CoreState) => state.userAccess?.storeList || [],
);

export const selectIsUserAccessLoading = createSelector(
  selectCoreState,
  (state: CoreState) => state.isUserAccessLoading,
);

// ====================================================================================================
// Set ITA (Is Techo Admin)
// ====================================================================================================
export const selectITA = createSelector(
  selectCoreState,
  (state: CoreState) => state.ita,
);

// ====================================================================================================
// Get User Stores
// ====================================================================================================
export const selectUserStores = createSelector(
  selectCoreState,
  (state: CoreState) => state.userStores || [],
);

export const selectSelectedUserStore = createSelector(
  selectCoreState,
  (state: CoreState): IStore =>
    state.userLastSelectedStore
    ?? state.userSelectedStore
    ?? state.userStores?.[0]
    ?? {} as IStore,
);

export const selectCurrentPageAndTabObject = createSelector(
  selectCoreState,
  (state: CoreState) => ({
    currentSelectedPage: state.currentSelectedPage,
  }),
);

export const selectCurrentPageAndTab = createSelector(
  selectCoreState,
  (state: CoreState) => state.currentSelectedPage,
);

export const selectCurrentPage = createSelector(
  selectCoreState,
  (state: CoreState) => state.currentSelectedPage.currentPage,
);

// ====================================================================================================
// Get Image URL
// ====================================================================================================
export const selectImageURL = (image: string): MemoizedSelector<CoreState, string> => createSelector(
  selectCoreState,
  (state: CoreState): string | undefined => {
    if (state.images[image]) {
      return state.images[image];
    }
    return undefined;
  },
);

// ====================================================================================================
// User Messages
// ====================================================================================================
export const selectUserMessageCount = createSelector(
  selectCoreState,
  (state: CoreState) => state.userMessagesCount,
);

export const selectUserMessages = createSelector(
  selectCoreState,
  (state: CoreState) => state.userMessages,
);

export const selectDataTimestamps = createSelector(
  selectCoreState,
  (state: CoreState) => state.dataTimeStamps,
);

