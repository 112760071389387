import {
  IItemDisablingFormState,
  ItemDisablingOnHand,
} from './setting-item-disabling.models';

export const initialFormState: IItemDisablingFormState = {
  isSubmitting: false,
  onHandZero: {
    descPrefix: '',
    descSuffix: '',
    onHoldType: {},
    lineColour: [],
  },
  onHandNotZero: {
    descPrefix: '',
    descSuffix: '',
    onHoldType: {},
    lineColour: [],
  },
};

export const initialItemDisablingOnHand: ItemDisablingOnHand = {
  descPrefix: '',
  descSuffix: '',
  lineColour: [],
  selectedLineColour: '',
};
