import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, EMPTY, Observable, Subscription } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { updateStockEditThresholds } from "src/app/features/settings-old/store/settings.actions";
import { selectPriceThresholds, selectUserStoreSettingsIsSubmitting } from "src/app/features/settings-old/store/settings.selectors";
import type { IStockEditThresholds } from "src/app/features/settings-old/models/settings-models";

import { IPriceThresholdsFormState } from "./setting-price-thresholds.models";


@Injectable({
  providedIn: 'root'
})
export class SettingPriceThresholdsFormService implements OnDestroy {
  #stateSubscription: Subscription;
  #currentState: IPriceThresholdsFormState | null = null;
  readonly #formStateSubject = new BehaviorSubject<IPriceThresholdsFormState>({
    percentage: 0,
    negativePercentage: 0,
    isSubmitting: false
  });
  readonly #formState$: Observable<IPriceThresholdsFormState> = this.#formStateSubject.asObservable();

  constructor(private readonly store: Store) {}

  ngOnDestroy(): void {
    if (this.#stateSubscription) {
      this.#stateSubscription.unsubscribe();
    }
  }

  public initialize(): Observable<IPriceThresholdsFormState> {
    const currentFormState = this.#formStateSubject.value;
    this.#stateSubscription = this.store.select(selectPriceThresholds).pipe(
      map((thresholds: IStockEditThresholds) => ({
        percentage: this.convertThresholdToPercentage(thresholds?.sellPriIncl1.pct),
        negativePercentage: this.convertThresholdToPercentage(thresholds?.sellPriIncl1.negPct)
      })),
      catchError((error) => {
        console.error('Failed to load price thresholds', error);
        return EMPTY;
      })
    ).subscribe((state) => {
      this.#currentState = state;
      currentFormState ?
        this.#formStateSubject.next({ ...currentFormState, ...state }) :
        this.#formStateSubject.next(state);
    });
    this.#stateSubscription.add(
      this.store.select(selectUserStoreSettingsIsSubmitting).subscribe((isSubmitting: boolean) => {
        if (currentFormState) {
          this.#formStateSubject.next({
            ...currentFormState,
            isSubmitting: isSubmitting
          });
        }
      })
    );
    return this.#formState$;
  }

  public onSubmit(event: Event): void {
    event.preventDefault();
    if (!this.#currentState || this.#currentState.isSubmitting || !this.formHasChanges()) return;
    const updatedThresholds = this.convertThresholdsToFloat();
    this.store.dispatch(updateStockEditThresholds({ thresholds: {
      sellPriIncl1: {
        pct: updatedThresholds.percentage,
        negPct: updatedThresholds.negativePercentage
      }
    }}));
  }

  public onPctChange(event: Event): void {
    event.preventDefault();
    const percentage = (event.target as HTMLInputElement).valueAsNumber;
    this.updateFormState('percentage', percentage);
  }

  public onNegPctChange(event: Event): void {
    event.preventDefault();
    const negativePercentage = (event.target as HTMLInputElement).valueAsNumber;
    this.updateFormState('negativePercentage', negativePercentage);
  }

  private updateFormState(property: keyof IPriceThresholdsFormState, value: number): void {
    let newValue = value;
    if (newValue === undefined || isNaN(newValue)) newValue = 0;
    if (newValue < 0) newValue = 0;
    if (newValue > 100) newValue = 100;
    
    const currentFormState = this.#formStateSubject.value;
    this.#formStateSubject.next({
      ...currentFormState,
      [property]: newValue
    });    
  }

  private formHasChanges(): boolean {
    return this.#currentState &&
      JSON.stringify(this.#currentState) !== JSON.stringify(this.#formStateSubject.value);
  }

  private convertThresholdsToFloat(): IPriceThresholdsFormState {
    const thresholds: IPriceThresholdsFormState = { percentage: 0, negativePercentage: 0 };
    thresholds['pct'] = this.#formStateSubject.value.percentage / 100;
    thresholds['negPct'] = this.#formStateSubject.value.negativePercentage / 100;
    return thresholds;
  }

  private convertThresholdToPercentage(threshold: number): number {
    if (threshold > 0 && threshold <= 1) return threshold * 100;
    return 0;
  }
}