import {Component, EventEmitter, Input, Output} from '@angular/core';


@Component({
  selector: 'app-switch-ui',
  templateUrl: './switch-ui.component.html',
  styleUrls: ['./switch-ui.component.scss'],
})
export class SwitchUIComponent {

  //#region Properties
  @Input() checked: boolean;
  @Input() disabled = false;
  @Input() size = 1;
  @Input() ariaLabel?: string;
  @Output() readonly checkedChange = new EventEmitter<boolean>();
  //#endregion

  //#region onToggle
  protected onToggle(event: Event): void {
    if (!this.disabled) {
      const checkState = (event.target as HTMLInputElement).checked;
      this.checkedChange.emit(checkState);
    }
  }

  //#endregion

  //#region onKeyDown
  protected onKeyDown(event: Event): void {
    const keyboardEvent = event as KeyboardEvent;
    if (!this.disabled && (keyboardEvent.code === 'Space' || keyboardEvent.code === 'Enter')) {
      event.preventDefault();
      this.checkedChange.emit(!this.checked);
    }
  }

  //#endregion
}
