<label
  (click)="$event.stopPropagation()"
  [style.--switch-size]="size"
  class="switch-container">

  <input
    (change)="onToggle($event)"
    (keydown)="onKeyDown($event)"
    [attr.aria-checked]="checked"
    [attr.aria-label]="ariaLabel || 'Toggle switch'"
    [checked]="checked"
    [disabled]="disabled"
    role="switch"
    tabindex="0"
    type="checkbox">
  <span [class.disabled]="disabled" class="slider"></span>

</label>
