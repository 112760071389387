<svg
  viewBox="0 0 512 512"
  xmlns="http://www.w3.org/2000/svg">
  <title *ngIf="tooltip">{{ tooltip }}</title>
  <path
    d="M400 328L256 184 112 328"
    fill="none"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="48"/>
</svg>
