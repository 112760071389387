import {keyToType, sItemIntToKey} from "../../../shared-utilities/models-old/datastructures";
import {Timestamp} from "../../../shared-utilities/services-old/firebase.service";


export function convertObjectToJSON<ObjectType>(obj: ObjectType): string {
  return JSON.stringify(obj);
}

export function convertJSONtoObject<ObjectType>(json: string): ObjectType {
  return JSON.parse(json) as ObjectType;
}

export function isNonEmptyObject<ObjectType>(obj: ObjectType | null | undefined): boolean {
  return !!obj && Object.keys(obj).length > 0;
}

/**
 * Creates a deep copy of the provided object.
 *
 * This function takes an object of any type and returns a deep copy of it.
 * The deep copy is created by converting the object to a JSON string and then parsing it back into an object.
 * This method ensures that all nested objects and arrays are fully cloned, but it does not preserve functions, undefined values, or non-serializable properties.
 *
 * @template ObjectType - The type of the object to be copied.
 * @param {ObjectType} obj - The object to be deep-copied.
 * @returns {ObjectType} - A deep copy of the provided object.
 *
 * @example
 * const original = { name: 'John', details: { age: 30 } };
 * const copy = getDeepCopyOfObject(original);
 * console.warn(copy); // Output: { name: 'John', details: { age: 30 } }
 * console.warn(copy === original); // Output: false (different references)
 */
export function getDeepCopyOfObject<ObjectType>(obj: ObjectType): ObjectType {
  return JSON.parse(JSON.stringify(obj)) as ObjectType;
}

/**
 * Function to cast an object to a specified type.
 *
 * @template CastType - The type to which the object should be cast.
 * @template ObjectType - The type of the input object.
 *
 * @param {ObjectType} object - The object to be cast to the specified type.
 * @returns {CastType} - The object cast to the specified type.
 */
export function castObjectToType<CastType, ObjectType>(object: ObjectType): CastType {
  const newObject: CastType = {} as CastType;
  Object.keys(object).forEach((key: string): void => {
    newObject[sItemIntToKey[key]] = object[key];
    if (keyToType[sItemIntToKey[key]] === 'date' && object[key]) {
      newObject[sItemIntToKey[key]] = (object[key] as Timestamp).toDate();
    } else {
      newObject[sItemIntToKey[key]] = object[key];
    }
  });
  return newObject;
}

/**
 * Creates a map from an object where the values are objects of a generic type,
 * and each value is a specific property of that object.
 *
 * @template ObjectType - The type of the objects within the input object.
 *
 * @param {{ [p: string]: ObjectType }} suppliers - An object where the keys are strings and the values are of type `ObjectType`.
 * @param {string} property - The specific property to map from each object.
 *
 * @returns {{ [key: string]: any }} A map where the keys are the same as the input object, but the values are the specific property from each object.
 */
export function createMapFromObjectsObject<ObjectType>(suppliers: { [p: string]: ObjectType }, property: string): {
  [key: string]: ObjectType
} {
  const supplierMap: { [key: string]: ObjectType } = {};
  for (const key in suppliers) {
    if (suppliers.hasOwnProperty(key)) {
      supplierMap[key] = suppliers[key][property];
    }
  }
  return supplierMap;
}
