import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {IStore} from '../../../../shared/shared-models/store/store';
import {Store} from '@ngrx/store';
import {
  selectSelectedUserStore,
  selectUserStores
} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {setUserSelectedStore} from '../../../../features-as-modules/feature-core/store/core.actions';
import {
  defaultNotificationChips,
  INotification,
  INotificationChips,
  INotificationPerStore,
  INotificationRead,
  NotificationChipTypes,
  NotificationTypeEnum,
  NotificationTypeEnumHumanReadable
} from '../../models/notification';
import {sortArrayByProperty} from '../../../../shared/shared-utils/sort/sort-array-by-property';
import {getDeepCopyOfObject} from '../../../../shared/shared-utils/object/object.utils';
import {
  dateToFirebaseTimestamp,
  firebaseTimeStampToDate
} from '../../../../shared/shared-utils/firestore/firestore.utils';
import {
  AnimationDefault,
  AnimationDirection,
  Animations,
  IAnimation
} from '../../../../shared/shared-models/animations/animation';
import {
  getNotificationAutoOrder,
  getNotificationStockUpdate,
  updateNotification
} from '../../store/notification.actions';
import {IUser} from '../../../../shared/shared-models/user-access/user';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit {

  userStores$: Observable<IStore[]>;
  userSelectedStore$: Observable<IStore>;

  isUserNotificationsLoading: boolean = true;

  notifications: INotificationPerStore = {
    total: 0,
    read: 0,
    notifications: [],
    unread: 0,
  };

  notificationChips: INotificationChips = {...defaultNotificationChips};
  notificationsDisplay: INotification[] = [];
  user: IUser = undefined;

  protected readonly firebaseTimeStampToDate = firebaseTimeStampToDate;

  protected readonly userNotificationsAnimation: IAnimation = {
    ...AnimationDefault,
    animation: Animations.CIRCLE_DISAPPEAR_AND_REAPPEAR,
    backgroundColour: 'var(--shared-accordion-body-animation-background)',
    direction: AnimationDirection.REVERSE,
    id: 'app-component-animation-user-notifications',
  };

  private notificationsPerStore: { [storeId: string]: INotificationPerStore } = {};
  private userSelectedStore: IStore;

  constructor(
    private store: Store
  ) {
  }

  get getIsComponentLoading(): boolean {
    return this.isUserNotificationsLoading;
  }

  get getNotifications(): INotificationPerStore {
    const selectedStore = this.getUserSelectedStore;
    let noticePerStore = {
      total: 0,
      read: 0,
      notifications: [],
      unread: 0,
    } as INotificationPerStore;
    if (selectedStore?.storeId) {
      noticePerStore = this.notificationsPerStore[selectedStore.storeId];
    }
    this.onFilterNotifications();
    return noticePerStore;
  }

  get getUserSelectedStore(): IStore {
    return this.userSelectedStore;
  }

  get getUser(): IUser {
    return this.user;
  }

  @Input() set setIsUserNotificationsLoading(isLoading: boolean) {
    this.isUserNotificationsLoading = isLoading;
  }

  @Input() set setNotifications(notifications: { [storeId: string]: INotificationPerStore }) {
    this.notificationsPerStore = notifications;
    this.notifications = this.getNotifications;
  }

  @Input() set setSelectedUserStore(selectedStore: IStore) {
    this.userSelectedStore = selectedStore;
    this.notifications = this.getNotifications;
  }

  @Input() set setUser(user: IUser) {
    this.user = user;
  }

  getAuthor(notification: INotification): string {
    if (
      notification.type === NotificationTypeEnum.AUTO_ORDERS ||
      notification.type === NotificationTypeEnum.STOCK_UPDATE
    ) {
      return 'System Generated';
    }
    return 'Gallix';
  }

  getHumanReadableNotificationType(notification: INotification): string {
    return NotificationTypeEnumHumanReadable[notification.type];
  }

  ngOnInit(): void {
    this.userStores$ = this.store.select(selectUserStores);
    this.userSelectedStore$ = this.store.select(selectSelectedUserStore);
  }

  onToggleClick(chip: NotificationChipTypes): void {
    switch (chip) {
      case 'AUTO_ORDERS':
        this.notificationChips.autoOrder = true;
        this.notificationChips.stockUpdate = false;
        break;
      case 'FAIL':
        this.notificationChips.fail = true;
        this.notificationChips.success = false;
        break;
      case 'READ':
        this.notificationChips.allMessages = false;
        this.notificationChips.readMessages = true;
        this.notificationChips.unreadMessages = false;
        break;
      case 'STOCK_UPDATE':
        this.notificationChips.autoOrder = false;
        this.notificationChips.stockUpdate = true;
        break;
      case 'SUCCESS':
        this.notificationChips.fail = false;
        this.notificationChips.success = true;
        break;
      case 'UNREAD':
        this.notificationChips.allMessages = false;
        this.notificationChips.readMessages = false;
        this.notificationChips.unreadMessages = true;
        break;
      case 'ALL':
      default:
        this.notificationChips.allMessages = true;
        this.notificationChips.autoOrder = false;
        this.notificationChips.readMessages = false;
        this.notificationChips.stockUpdate = false;
        this.notificationChips.unreadMessages = false;
        break;
    }
    this.onFilterNotifications();
  }

  onToggleClick2(chip: NotificationChipTypes): void {
    switch (chip) {
      case 'AUTO_ORDERS':
        if (this.notificationChips.autoOrder === false) {
          this.notificationChips.autoOrder = true;
          this.notificationChips.stockUpdate = false;
          this.notificationChips.allMessages = false;
        } else {
          this.notificationChips.stockUpdate = true;
        }
        break;
      case 'STOCK_UPDATE':
        if (this.notificationChips.stockUpdate === false) {
          this.notificationChips.stockUpdate = true;
          this.notificationChips.autoOrder = false;
          this.notificationChips.allMessages = false;
        } else {
          this.notificationChips.autoOrder = true;
        }
        break;
      case 'FAIL':
        if (this.notificationChips.fail === false) {
          this.notificationChips.fail = true;
          this.notificationChips.success = false;
          this.notificationChips.allMessages = false;
        } else {
          this.notificationChips.success = false;
        }
        break;
      case 'SUCCESS':
        if (this.notificationChips.success === false) {
          this.notificationChips.success = true;
          this.notificationChips.fail = false;
          this.notificationChips.allMessages = false;
        } else {
          this.notificationChips.fail = true;
        }
        break;
      case 'READ':
      case 'UNREAD':
        if (this.notificationChips.unreadMessages === false) {
          this.notificationChips.readMessages = true;
          this.notificationChips.unreadMessages = false;
          this.notificationChips.allMessages = false;
        } else {
          this.notificationChips.unreadMessages = true;
        }
        if (this.notificationChips.unreadMessages === false) {
          this.notificationChips.unreadMessages = true;
          this.notificationChips.readMessages = false;
          this.notificationChips.allMessages = false;
        } else {
          this.notificationChips.readMessages = true;
        }
        break;
      case 'ALL':
      default:
        if (this.notificationChips.allMessages === false) {
          this.notificationChips.allMessages = false;
          this.notificationChips.autoOrder = false;
          this.notificationChips.fail = false;
          this.notificationChips.readMessages = true;
          this.notificationChips.stockUpdate = false;
          this.notificationChips.unreadMessages = false;
        } else {
          this.notificationChips.allMessages = false;
        }
        break;
    }
    this.onFilterNotifications();
  }

  selectNotification(notification: INotification): void {
    const notify = getDeepCopyOfObject(notification);
    notify.read = notify.read?.filter(value => value !== null) ?? [];
    let hasUserReadNotifications = false;
    if (notify.read?.length > 0) {
      hasUserReadNotifications = notification
        .read.findIndex((user: INotificationRead) => (user.userId === notification.userId)) > -1;
    }
    if (!hasUserReadNotifications) {
      notify.read.push({
        userId: this.getUser.id,
        timestamp: dateToFirebaseTimestamp(new Date())
      });
      this.store.dispatch(updateNotification({notification: notify}));
    }
    if (notify.type === NotificationTypeEnum.AUTO_ORDERS) {
      this.store.dispatch(getNotificationAutoOrder({notification: notify}));
    }
    if (notify.type === NotificationTypeEnum.STOCK_UPDATE) {
      this.store.dispatch(getNotificationStockUpdate({notification: notify}));
    }
  }

  selectStore(store: IStore): void {
    this.store.dispatch(setUserSelectedStore({selectedStore: store}));
    this.onFilterNotifications();
  }

  trackByNotification(_: number, notification: INotification): string {
    return String(notification.notificationId);
  }

  private onFilterNotifications(): void {
    let notices: INotification[] = this.notifications.notifications;
    let returnNotices: INotification[] = [];

    if (this.notificationChips.autoOrder) {
      notices = notices.filter((notice: INotification) => notice.type === NotificationTypeEnum.AUTO_ORDERS);
    }
    if (this.notificationChips.stockUpdate) {
      notices = notices.filter((notice: INotification) => notice.type === NotificationTypeEnum.STOCK_UPDATE);
    }
    if (this.notificationChips.fail) {
      notices = notices.filter((notice: INotification) => !notice.success);
    }
    if (this.notificationChips.success) {
      notices = notices.filter((notice: INotification) => notice.success);
    }
    returnNotices = notices;
    if (this.notificationChips.readMessages) {
      returnNotices = notices.filter((notice: INotification) => notice.read?.length > 0);
    }
    if (this.notificationChips.unreadMessages) {
      returnNotices = notices.filter((notice: INotification) => !notice.read || notice.read?.length < 1);
    }
    if (this.notificationChips.allMessages) {
      returnNotices = notices;
    }
    this.notificationsDisplay = sortArrayByProperty(-1, false, 'end', getDeepCopyOfObject(returnNotices), 'timestamp', 'firebase-timestamp');
  }

}
