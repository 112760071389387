<div class="item-filter-container">

  <ion-item>
    <ion-select
      (ionChange)="onColumnChange($event)"
      [value]="filter.column"
      interface="popover"
      placeholder="Select A Column">
      <ion-select-option
        *ngFor="let col of colDefs; trackBy: trackByFunction"
        [value]="col.field">
        {{ col.headerName }}
      </ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item>
    <ion-select
      (ionChange)="onFilterTypeChange($event)"
      [disabled]="!filter.column"
      [value]="filter.filterType"
      interface="popover"
      placeholder="( Blank )">
      <ion-select-option
        *ngFor="let type of filterTypes; trackBy: trackByFunction"
        [value]="type.description">
        {{ type.description }}
      </ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item>
    <ng-container *ngIf="getFilterTypeInputCount === 'single'">
      <ion-input
        (ionBlur)="onValueChange()"
        *ngIf="filter.columnType !== 'boolean'"
        [(ngModel)]="filter.value"
        [disabled]="!filter.column || !filter.filterType"
        [type]="getFilterColumnType"
        placeholder="Enter a Value">
      </ion-input>
      <ion-checkbox *ngIf="filter.columnType === 'boolean'" [(ngModel)]="filter.value"></ion-checkbox>
    </ng-container>
    <ng-container *ngIf="getFilterTypeInputCount === 'multiple' && filter.columnType !== 'boolean' ">
      <!-- Todo: We have to look at Date inputs and Multi Inputs @Clayden -->
      <ion-input [type]="filter.columnType" placeholder="Enter Values"></ion-input>
    </ng-container>
  </ion-item>

  <ion-button (click)="onDeleteItemClick()" fill="clear">
    <app-icon-delete-outline
      [tooltip]="'Delete Filter'">
    </app-icon-delete-outline>
  </ion-button>

</div>

