<app-modal-header [close]="onCancelClick" title="Possible Errors"></app-modal-header>
<ion-content>
  <div class="table-div">
    <ag-grid-angular
      (cellValueChanged)="onCellValueChanged()"
      (gridReady)="onGridReady($event)"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [gridOptions]="gridOptions"
      [rowData]="rowData"
      class="ag-theme-custom">
    </ag-grid-angular>
    <ng-container>
      <button
        (click)="onCancelClick()"
        class="ignore-button"
        mat-raised-button
        type="button">
        Cancel
      </button>
      <button
        (click)="onChangeButtonClick()"
        class="submit-button"
        mat-raised-button
        type="button">
        Submit
      </button>
    </ng-container>
  </div>
</ion-content>
