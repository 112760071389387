import {IPriceBand, IPriceBandSnap} from '../../shared-models/price-banding/price-band';
import {getDeepCopyOfObject} from '../object/object.utils';
import {convertToDecimal, isValueBetweenRanges, splitValueAtN} from '../number/number.utils';
import {calculateGPFromPrice} from '../calculations/calculations-stock';
import {IStore} from '../../shared-models/store/store';
import {VatRates} from '../../../shared-utilities/models-old/datastructures';

/**
 * A constant list of columns that are affected by Price Banding for NGP Reports
 *
 * @returns {string[]} - A string array of column names that are affected by Price Banding.
 *
 * @example
 * // NGP Report Columns for Price Banding
 * const cols: string[] = [
 *   'diffGP',
 *   'nominalGP',
 *   'sellPriIncl1'
 * ];
 */
export const PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS: string[] = ['diffGP', 'nominalGP', 'sellPriIncl1'];

/**
 * Applies price banding to the provided object or array of objects based on specified price bands and store information.
 *
 * This function checks if the object is an array, and if so, it applies price banding to each object in the array.
 * For each object that is edited, it applies price banding rules using the provided price bands and columns.
 *
 * @template ObjectType - The type of the object to be updated.
 * @param {ObjectType} obj - The object or array of objects to apply price banding to.
 * @param {IPriceBand[]} priceBands - The list of price bands to apply.
 * @param {string[]} cols - The list of columns to check for changes.
 * @param {IStore} store - The store object containing information storeId and sequence .
 * @param {VatRates} vatRates - the vat Rate conversion pulled from firebase
 * @returns {ObjectType} - The object or array of objects with price banding applied.
 *
 * @example
 * const product = { sellPriIncl1: 100, dep: 'Electronics', isEdited: true, originalValue: { sellPriIncl1: { value: 95 } } };
 * const updatedProduct = applyPriceBanding(product, priceBands, ['sellPriIncl1'], store);
 * console.warn(updatedProduct);
 */
export function applyPriceBanding<ObjectType>(obj: ObjectType, priceBands: IPriceBand[], cols: string[], store: IStore, vatRates: VatRates): ObjectType {
  let copyObject: ObjectType = getDeepCopyOfObject<ObjectType>(obj);
  if (Array.isArray(copyObject)) {
    const bandedObjects = [];
    copyObject.forEach((item: ObjectType): void => {
      if (item['sellPriIncl1'] !== item['originalValue']['sellPriIncl1'].value || item['nominalGP'] !== item['originalValue']['nominalGP'].value) {
        item = applyPriceBandingToObject<ObjectType>(item, priceBands, cols, store, vatRates);
      }
      bandedObjects.push(item);
    });
    return bandedObjects as ObjectType;
  } else {
    return applyPriceBandingToObject<ObjectType>(copyObject, priceBands, cols, store, vatRates);
  }
}

/**
 * Applies price banding to a single object based on specified price bands and columns.
 *
 * This function checks for changes in the specified columns and applies price banding if differences are found between
 * the current and original values.
 *
 * @template ObjectType - The type of the object to be updated.
 * @param {ObjectType} obj - The object to apply price banding to.
 * @param {IPriceBand[]} priceBands - The list of price bands to apply.
 * @param {string[]} cols - The list of columns to check for changes.
 * @param {IStore} store - The store object containing information storeId and sequence .
 * @param {VatRates} vatRates - the vat Rate conversion pulled from firebase
 * @returns {ObjectType} - The object with price banding applied.
 */
export function applyPriceBandingToObject<ObjectType>(obj: ObjectType, priceBands: IPriceBand[], cols: string[], store: IStore, vatRates: VatRates): ObjectType {
  if (obj.hasOwnProperty('originalValue')) {
    let applyPriceBandToObject = false;
    Object.keys(obj).forEach((key: string): void => {
      if (cols.includes(key)) {
        if (obj['originalValue'][key]?.value !== obj[key]) {
          applyPriceBandToObject = true;
        }
      }
    });
    if (applyPriceBandToObject) {
      obj = applyPriceBandingValues<ObjectType>(obj, priceBands, store, vatRates);
    }
  }
  return obj;
}

/**
 * Applies price banding values to an object based on specified price bands.
 *
 * This function iterates through the price bands and applies matching price banding rules to the object if it falls within
 * the specified ranges or department/sub-department filters.
 *
 * @template ObjectType - The type of the object to be updated.
 * @param {ObjectType} obj - The object to apply price banding values to.
 * @param {IPriceBand[]} priceBands - The list of price bands to apply.
 * @param {IStore} store - The store object containing information storeId and sequence .
 * @param {VatRates} vatRates - the vat Rate conversion pulled from firebase
 * @returns {ObjectType} - The object with price banding values applied.
 */
export function applyPriceBandingValues<ObjectType>(obj: ObjectType, priceBands: IPriceBand[], store: IStore, vatRates: VatRates): ObjectType {
  priceBands.forEach((priceBand: IPriceBand): void => {
    let applyPriceBand = false;
    if (isValueBetweenRanges(+obj['sellPriIncl1'], priceBand.range)) {
      if (priceBand.departs?.length > 0) {
        if (priceBand.departs.includes(obj['dep'] as string)) {
          applyPriceBand = true;
        }
      }
      if (priceBand.subDeparts?.length > 0) {
        if (priceBand.subDeparts.includes(obj['subDep'] as string)) {
          applyPriceBand = true;
        }
      }
      if (!priceBand.departs || !priceBand.subDeparts) {
        applyPriceBand = true;
      }
    }
    if (applyPriceBand) {
      obj = applyPriceBandingValueSnap<ObjectType>(obj, priceBand, store, vatRates);
    }
  });
  return obj;
}

/**
 * Applies price banding snap rules to an object.
 *
 * This function applies specific snap rules to the object based on price bands and recalculates the nominal gross profit (GP).
 *
 * @template ObjectType - The type of the object to be updated.
 * @param {ObjectType} obj - The object to apply snap rules to.
 * @param {IPriceBand} priceBand - The price band with the snap rules.
 * @param {IStore} store - The store object containing information storeId and sequence .
 * @param {VatRates} vatRates - the vat Rate conversion pulled from firebase
 * @returns {ObjectType} - The object with price banding snaps and GP recalculated.
 */
export function applyPriceBandingValueSnap<ObjectType>(obj: ObjectType, priceBand: IPriceBand, store: IStore, vatRates: VatRates): ObjectType {
  if (priceBand.randSnap?.snaps.length > 0) {
    priceBand.randSnap.snaps.forEach((snap: IPriceBandSnap): void => {
      obj = applyPriceBandingRandSnap(obj, snap, priceBand.randSnap.digits);
    });
  }
  if (priceBand.centSnaps?.length > 0) {
    priceBand.centSnaps.forEach((cSnap: IPriceBandSnap): void => {
      obj = applyPriceBandingCentSnap(obj, cSnap);
    });
  }
  obj = calculateGPFromPrice<ObjectType>(obj, store, 2, vatRates);
  return obj;
}

/**
 * Applies a rand snap rule to the object's selling price.
 *
 * @template ObjectType - The type of the object to be updated.
 * @param {ObjectType} obj - The object to apply rand snap rules to.
 * @param {IPriceBandSnap} snap - The snap rule to apply.
 * @param {number} digits - The number of digits to snap.
 * @returns {ObjectType} - The object with the rand snap rule applied.
 */
export function applyPriceBandingRandSnap<ObjectType>(obj: ObjectType, snap: IPriceBandSnap, digits: number): ObjectType {
  let sellingPrice = +obj['sellPriIncl1'];
  const flooredValue = Math.floor(sellingPrice);
  const valueSplit = splitValueAtN(sellingPrice, digits);
  if (valueSplit.length > 1 && isValueBetweenRanges(valueSplit[1], snap.range)) {
    const dec = sellingPrice !== flooredValue ? convertToDecimal(sellingPrice - flooredValue, 0) : 0;
    sellingPrice = valueSplit[0] + snap.v + dec;
  }
  obj['sellPriIncl1'] = convertToDecimal(sellingPrice, 2);
  return obj;
}

/**
 * Applies a cent snap rule to the object's selling price.
 *
 * @template ObjectType - The type of the object to be updated.
 * @param {ObjectType} obj - The object to apply cent snap rules to.
 * @param {IPriceBandSnap} snap - The cent snap rule to apply.
 * @returns {ObjectType} - The object with the cent snap rule applied.
 */
export function applyPriceBandingCentSnap<ObjectType>(obj: ObjectType, snap: IPriceBandSnap): ObjectType {
  let sellingPrice = +obj['sellPriIncl1'];
  const flooredValue = Math.floor(sellingPrice);
  const diffCents = Math.round((sellingPrice - flooredValue) * 100);
  if (isValueBetweenRanges(diffCents, snap.range)) {
    const newCents = Math.round((flooredValue * 100) + snap.v);
    sellingPrice = newCents / 100;
  }
  obj['sellPriIncl1'] = sellingPrice.toFixed(2);
  return obj;
}

