import {ColDef} from 'ag-grid-community';

/**
 * The General Default Grid Properties
 * For a complete list of properties refer to:
 *  https://ag-grid.com/angular-data-grid/column-properties/
 * Fields are mapped according to NGPReport Model / Interface
 */
export const generalColDefDefaults: ColDef = {
  editable: false,
  resizable: true,
  sortable: true,
  filter: true,
  autoHeight: false,
  wrapText: false,
  width: 200,
  lockPosition: false,
  lockPinned: false,
  suppressAutoSize: false,
};
