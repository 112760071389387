import {IPageObj, ISubPageObj} from './page';
import {Icons} from '../../../shared-modules/shared-icons/icons';

/**
 * TODO TECHNICAL DEBT - Create PAGES Enum
 */
export const PAGES: IPageObj[] = [
  {page: 'home', text: 'Home', icon: Icons.home},
  {page: 'dashboard', text: 'Dashboard', icon: Icons.pieChart},
  {page: 'dashboard-reporting', text: 'Dashboard Reporting', icon: Icons.fileTrayStacked},
  {page: 'login', text: 'Sign In', icon: Icons.logIn},
  {page: 'stock-manager', text: 'Stock Manager', icon: Icons.create},
  {page: 'ngp-report', text: 'NGP Report', icon: Icons.clipboard},
  {page: 'auto-ordering', text: 'Auto Ordering', icon: Icons.cart, multiple: true, tab: 'orders'},
  {page: 'shelf-talkers', text: 'Shelf Talkers', icon: Icons.priceTags},
  {page: 'barcode-scanner', text: 'Barcode Scanner', icon: Icons.barcode},
  {page: 'user-access', text: 'Staff Access', icon: Icons.key},
  {page: 'settings', text: 'Settings', icon: Icons.settings, multiple: true, tab: 'user'},
];

export const TECHO_ADMIN_PAGES: IPageObj[] = [
  {page: 'admin-info', text: 'Admin Page', icon: Icons.fileTrayStacked},
];

export const PAGES_SUB: ISubPageObj = {
  ['auto-ordering']: [
    {page: 'auto-ordering', text: 'Orders ', icon: Icons.today, tab: 'orders'},
    {page: 'auto-ordering', text: 'Edit Schedule', icon: Icons.calendar, tab: 'schedule'},
    {page: 'auto-ordering', text: 'Unscheduled', icon: Icons.calendarClear, tab: 'unscheduled'},
    {page: 'auto-ordering', text: 'Order Settings', icon: Icons.settings, tab: 'order-settings'},
  ],
  ['settings']: [
    {page: 'settings', text: 'Store', icon: Icons.cart, tab: 'store'},
    {page: 'settings', text: 'User', icon: Icons.person, tab: 'user'},
    {page: 'settings', text: 'Email', icon: Icons.emailVariant, tab: 'email'},
  ],
};

export const MOBILE_PAGES: IPageObj[] = [
  {page: 'home', text: 'Home', icon: Icons.home},
  {page: 'dashboard', text: 'Dashboard', icon: Icons.pieChart},
  {page: 'barcode-scanner', text: 'Barcode Scanner', icon: Icons.barcode},
];
