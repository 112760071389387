import {Injectable} from '@angular/core';
import {ISortedMultiSelectItem} from '../../../models';

@Injectable({
  providedIn: 'root'
})
export class MultiSelectSortService {
  sort(values: { [key: number]: { name: string; selected: boolean; disabled: boolean } }): ISortedMultiSelectItem[] {
    if (!values) return [];

    const items = Object.entries(values).map(([key, value]) => ({
      key: parseInt(key, 10),
      name: value.name,
      selected: value.selected,
      disabled: value.disabled
    }));

    return items.sort((a, b) => {
      if (a.disabled !== b.disabled) {
        return a.disabled ? 1 : -1;
      }
      if (!a.disabled && !b.disabled && a.selected !== b.selected) {
        return a.selected ? -1 : 1;
      }
      return 0;
    });
  }
}
