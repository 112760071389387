<ng-container *ngIf="params?.displayName && (!params?.column?.getColId())">
  <span>{{ params.displayName }}</span>
</ng-container>

<ng-container
  *ngIf="params?.displayName && params?.column?.getColId() && params?.additionalData?.length > 0 ;
  else checkBox">
  <ion-button [id]="getButtonUniqueID" fill="clear">
    <div class="menu-item-container">
      <span [matTooltip]="'Open ' + params.displayName + ' Menu'">{{ params.displayName }}</span>
      <ng-container
        *ngIf="sortIcon?.[storeId]?.[params.column.getColDef().field]">
        <app-display-icon
          [border]="false"
          [colour]="'yellow'"
          [icon]="sortIcon[storeId][params.column.getColDef().field]">
        </app-display-icon>
      </ng-container>
      <ng-container *ngIf="editingColumns?.[storeId]?.[params.column.getColDef().field]">
        <app-display-icon
          [border]="false"
          [colour]="'red'"
          [icon]="icons.pencil">
        </app-display-icon>
      </ng-container>
    </div>
  </ion-button>

  <ion-popover *ngIf="isPopoverReady" [dismissOnSelect]="true" [trigger]="getButtonUniqueID">
    <ng-template>
      <ion-content>
        <ion-list>
          <ng-container *ngFor="let menuItem of params.additionalData; let index; trackBy: trackByMenuItem">
            <ng-container *ngIf="isButtonVisible(menuItem)">
              <ion-item
                (click)="menuItem.callback(menuItem.callbackParams)"
                [button]="true"
                [id]="menuItem.callbackParams.colId + menuItem.callbackParams.action">
                <div class="menu-item-container">
                  <app-display-icon
                    [border]="false"
                    [colour]="menuItem.colour"
                    [icon]="menuItem.icon"
                    [tooltip]="menuItem.displayName + ' ' + params.displayName">
                  </app-display-icon>
                  <span>{{ menuItem.displayName }}</span>
                </div>
              </ion-item>
            </ng-container>

            <ion-popover
              *ngIf="menuItem.options?.length > 0"
              [dismissOnSelect]="true"
              [trigger]="menuItem.callbackParams.colId + menuItem.callbackParams.action"
              alignment="start"
              side="end">
              <ng-template>
                <ion-content>
                  <ion-list>
                    <ng-container *ngFor="let opt of menuItem.options">
                      <ion-item
                        (click)="opt.callback(opt.callbackParams)"
                        [button]="true"
                        [id]="opt.callbackParams.colId + opt.callbackParams.action">
                        <div class="menu-item-container">
                          <app-display-icon
                            [border]="false"
                            [colour]="opt.colour"
                            [icon]="opt.icon"
                            [tooltip]="opt.displayName + ' ' + params.displayName">
                          </app-display-icon>
                          <span>{{ opt.displayName }}</span>
                        </div>
                      </ion-item>
                    </ng-container>
                  </ion-list>
                </ion-content>
              </ng-template>
            </ion-popover>
          </ng-container>
        </ion-list>
      </ion-content>
    </ng-template>
  </ion-popover>
</ng-container>

<ng-template #checkBox>
  <ion-checkbox
    (click)="onHeaderCheckboxClick($event)"
    *ngIf="params?.column?.getColId() === 'isSelected'"
    [checked]="isChecked"
    [indeterminate]="indeterminate">
  </ion-checkbox>
</ng-template>
