import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

// 1️⃣ Angular & Ionic Modules
import {IonicModule} from '@ionic/angular';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';

// 2️⃣ NgRx Modules
import {PushModule} from '@ngrx/component';

// 3️⃣ Shared Modules
import {SharedModule} from '../../shared-modules/shared-module/shared-module.module';
import {SharedIconsModule} from '../../shared-modules/shared-icons/shared-icons.module';
import {SharedGridModule} from '../../shared-modules/shared-grid/shared-grid.module';

// 4️⃣ Feature Components
import {STORE_SETTINGS_COMPONENTS} from './components/settings-store';

@NgModule({
  declarations: [
    ...STORE_SETTINGS_COMPONENTS,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    MatButtonModule,
    MatTooltipModule,
    PushModule,
    SharedModule,
    SharedIconsModule,
    SharedGridModule,
  ],
})
export class SettingsModule {
}
