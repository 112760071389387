<div [class.open]="isOpen" class="custom-accordion-item">

  <div (click)="toggle()" class="accordion-header" [ngClass]="class ? class : ''">
    <ng-content select=".header"></ng-content>
  </div>

  <div *ngIf="isOpen" class="accordion-body">
    <ng-content select=".body"></ng-content>
  </div>

</div>
