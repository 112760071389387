<div class="grid-container">
  <!-- ag-theme-quartz-auto-dark  -->
  <ag-grid-angular
    (columnMoved)="onColumnMoved($event)"
    (columnResized)="onColumnResized($event)"
    (gridReady)="onGridReady($event)"
    (gridSizeChanged)="onGridSizeChange()"
    (paginationChanged)="onPaginationChange($event)"
    [columnDefs]="colDefs"
    [columnTypes]="columnTypes"
    [defaultColDef]="colDefsDefaults"
    [gridOptions]="gridOptions"
    [overlayLoadingTemplate]="overlayTemplate"
    [overlayNoRowsTemplate]="overlayTemplate"
    [rowData]="rowData"
    class="ag-theme-custom">
  </ag-grid-angular>
  <div *ngIf="totalItems > 0 && currentPage.currentTab !== 'preview'">
    <div class="custom-pagination-bar">
      Page Size:
      <select (change)="onPageSizeChange($event)" [(ngModel)]="itemsPerPage">
        <option *ngFor="let size of pageSizeOptions; trackBy: trackByFunction" [value]="size">{{ size }}</option>
      </select>

      <span>{{ currentTablePage === 1 ? currentTablePage : (currentTablePage - 1) * itemsPerPage }}
        to <b>{{ currentTablePage * itemsPerPage > totalItems ? totalItems : currentTablePage * itemsPerPage }}</b>
        of <b>{{ totalItems }}</b>
      </span>

      <span>|</span>

      <app-icon-page-first
        (click)="!isFirstPageDisabled() && onGoToFirstPageClick()"
        [ngClass]="{ 'disabled': isFirstPageDisabled() }"
        [tooltip]="'First Page'"
        class="pagination-buttons">
      </app-icon-page-first>

      <app-icon-chevron-left
        (click)="!isFirstPageDisabled() && prevPage()"
        [ngClass]="{ 'disabled': isFirstPageDisabled() }"
        [tooltip]="'Previous Page'"
        class="pagination-buttons">
      </app-icon-chevron-left>

      <span>Page: {{ currentTablePage }} of <b>{{ totalPages }}</b></span>

      <app-icon-chevron-right
        (click)="!isLastPageDisabled() && nextPage()"
        [ngClass]="{ 'disabled': isLastPageDisabled() }"
        [tooltip]="'Next Page'"
        class="pagination-buttons">
      </app-icon-chevron-right>

      <app-icon-page-last
        (click)="!isLastPageDisabled() && onGoToLastPageClick()"
        [ngClass]="{ 'disabled': isLastPageDisabled() }"
        [tooltip]="'Last Page'"
        class="pagination-buttons">
      </app-icon-page-last>
    </div>
  </div>
</div>
