<ng-container *ngIf="optionType === 'switch'">
  <div
    class="option-group"
    role="group">
    <div
      (click)="onToggleCheckbox()"
      class="switch-content">
      <div class="option-title">
        <ng-content select="[switchTitle]"></ng-content>
        <app-icon-help-circle-outline
          *ngIf="optionTip"
          [attr.aria-label]="optionTip"
          [tooltip]="optionTip"
          class="option-tip"
          role="tooltip">
        </app-icon-help-circle-outline>
      </div>
      <app-switch-ui
        (checkedChange)="onCheckboxChange($event)"
        [ariaLabel]="'Toggle ' + slug"
        [checked]="checked"
        [disabled]="isSubmitting"
        [size]="0.6">
      </app-switch-ui>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="optionType === 'text'">
  <div
    class="option-group"
    role="group">
    <div class="text-content">
      <div class="option-title">
        <ng-content select="[textTitle]"></ng-content>
        <app-icon-help-circle-outline
          *ngIf="optionTip"
          [attr.aria-label]="optionTip"
          [tooltip]="optionTip"
          class="option-tip"
          role="tooltip">
        </app-icon-help-circle-outline>
      </div>
      <div class="text-input-wrapper">
        <input
          (blur)="onTextInputBlur()"
          (change)="onTextInputChange($event)"
          (focus)="onTextInputFocus()"
          (input)="onTextInputChange($event)"
          [attr.aria-label]="'Enter text for ' + slug"
          [placeholder]="placeholder"
          [required]="textInputRequired"
          [value]="textValue"
          type="text"/>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="optionType === 'select'">
  <div
    class="option-group"
    role="group">
    <div class="select-content">
      <div class="option-title">
        <ng-content select="[selectTitle]"></ng-content>
        <app-icon-help-circle-outline
          *ngIf="optionTip"
          [attr.aria-label]="optionTip"
          [tooltip]="optionTip"
          class="option-tip"
          role="tooltip">
        </app-icon-help-circle-outline>
      </div>
      <div
        (keydown)="onKeyDown($event)"
        [attr.aria-expanded]="isOpen"
        class="option-select-wrapper"
        role="combobox">
        <button
          (click)="onToggleSingleSelect()"
          [attr.aria-controls]="slug + '-listbox'"
          [attr.aria-haspopup]="true"
          [attr.aria-label]="selectedOption ? 'Currently selected: ' + selectedOption : placeholder"
          class="select-button"
          type="button">
          <span
            [class.placeholder]="selectedOption === null || selectedOption === undefined"
            class="selected-text">
            {{ selectedOption || placeholder }}
          </span>
          <app-icon-chevron-down
            [class.open]="isOpen"
            aria-hidden="true"
            class="chevron">
          </app-icon-chevron-down>
        </button>
        <div
          [attr.aria-activedescendant]="activeDescendantId"
          [attr.aria-label]="'Select options for ' + slug"
          [class.visible]="isOpen"
          [id]="slug + '-listbox'"
          class="options-container"
          role="listbox">
          <div
            (click)="selectOption(option.name)"
            (keydown.enter)="selectOption(option.name)"
            (keydown.space)="selectOption(option.name)"
            *ngFor="let option of selectOptions; trackBy: trackByOption; let i = index"
            [attr.aria-selected]="option.name === selectedOption"
            [attr.tabindex]="isOpen ? 0 : -1"
            [class.selected]="option.name === selectedOption"
            [id]="slug + '-option-' + i"
            [ngStyle]="{
              'color': option.colour ? gridUtils.getContrastColor(option.colour) : null,
              'background-color': option.colour
            }"
            class="option"
            role="option">
            {{ option.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="optionType === 'select-multiple'">
  <div
    class="option-group"
    role="group">
    <div class="select-content">
      <div class="option-title">
        <ng-content select="[selectMultipleTitle]"></ng-content>
        <app-icon-help-circle-outline
          *ngIf="optionTip"
          [attr.aria-label]="optionTip"
          [tooltip]="optionTip"
          class="option-tip"
          role="tooltip">
        </app-icon-help-circle-outline>
      </div>
      <div
        (keydown)="onKeyDown($event)"
        [attr.aria-expanded]="isMultiSelectOpen"
        class="option-select-wrapper"
        role="combobox">
        <button
          (click)="onToggleMultiSelect()"
          [attr.aria-controls]="slug + '-multi-listbox'"
          [attr.aria-haspopup]="true"
          [attr.aria-label]="getSelectedOptionsDisplay() || placeholder"
          class="select-button multi-select-display"
          type="button">
          <div
            aria-hidden="true"
            class="multi-select-badges">
            <div
              *ngFor="let key of getSelectedOptions(); trackBy: trackByNumber"
              [title]="getOptionName(key)"
              class="multi-select-badge">
              {{ getOptionName(key) }}
            </div>
          </div>
          <app-icon-chevron-down
            [class.open]="isMultiSelectOpen"
            aria-hidden="true"
            class="chevron">
          </app-icon-chevron-down>
        </button>
        <div
          [attr.aria-activedescendant]="activeDescendantId"
          [attr.aria-label]="'Multi-select options for ' + slug"
          [attr.aria-multiselectable]="true"
          [class.visible]="isMultiSelectOpen"
          [id]="slug + '-multi-listbox'"
          class="options-container multi-select-options"
          role="listbox">
          <div
            (click)="onToggleMultiSelectValue(item.key)"
            (keydown.enter)="$event.stopPropagation(); onToggleMultiSelectValue(item.key)"
            (keydown.space)="$event.preventDefault(); $event.stopPropagation(); onToggleMultiSelectValue(item.key)"
            *ngFor="let item of sortedMultiSelectValues; trackBy: trackByMultiSelectItem; let i = index"
            [attr.aria-disabled]="item.disabled"
            [attr.aria-selected]="item.selected"
            [attr.tabindex]="isMultiSelectOpen && !item.disabled ? 0 : -1"
            [class.disabled]="item.disabled"
            [class.multi-selected]="item.selected"
            [id]="slug + '-multi-option-' + i"
            class="option multi-select-option"
            role="option">
            <input
              [attr.aria-label]="'Select ' + item.name"
              [checked]="item.selected"
              [disabled]="item.disabled"
              class="visually-hidden"
              type="checkbox">
            <div
              aria-hidden="true"
              class="custom-indicator">
            </div>
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
