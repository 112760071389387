<ion-toolbar class="shared-page-header-segment-container multi-line-tabs">
  <ion-segment
    (ionChange)="onStoreChange($event)"
    [ngClass]="{'shared-page-header-segments-modal': isModal, 'shared-page-header-segments': !isModal}"
    [value]="selectedStore"
    color="dark">

    <ng-container *ngIf="stores.length > 0">
      <ng-container *ngFor="let store of stores; trackBy: trackByStoreId">
        <div
          [style.max-width]="isModal ? getTabMaxWidth(): ''"
          class="tab">
          <ion-segment-button
            [matTooltip]="store.name"
            [ngClass]="{'segment-button-modal': isModal, 'segment-button': !isModal}"
            [value]="store">
            <ion-label>
              {{ store.name }}
            </ion-label>
          </ion-segment-button>
          <app-shared-freshness-indicator-container
            [currentStore]="store"
            [dataType]="dataTimeStampKey[0]"
            class="freshness-button">
          </app-shared-freshness-indicator-container>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="stores.length < 1">
      <ion-segment-button disabled>
        <ion-label>No Stores Available</ion-label>
      </ion-segment-button>
    </ng-container>
  </ion-segment>

</ion-toolbar>