import {Component, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {SharedModalUtils} from 'src/app/shared/shared-utils/shared-modal/shared-modal.utils';
import {ISharedModalBasic} from 'src/app/shared/shared-models/modals/shared-modal-basic';
import {Icons} from 'src/app/shared-modules/shared-icons/icons';
import {deleteStockItemTags, setStockItemTags} from '../../../settings-old/store/settings.actions';

@Component({
  selector: 'app-settings-stock-tags',
  templateUrl: './setting-stock-tags.component.html',
  styleUrls: ['./setting-stock-tags.component.scss'],
})
export class SettingStockTagsComponent {
  //#region Properties
  protected newTag: string = '';
  protected isTagInvalid: boolean = false;
  protected tagErrorMessage: string = '';
  protected tags: { name: string; global: boolean }[] = [];
  //#endregion

  constructor(
    private readonly store: Store,
    private readonly modalUtils: SharedModalUtils
  ) {
  }

  //#region Input Setters
  @Input() set setTags(allTags: { name: string; global: boolean }[]) {
    this.tags = allTags;
  }
  //#endregion

  //#region Protected Methods
  protected formatTag(): void {
    this.newTag = this.newTag.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_]/g, '');

    if (this.newTag.length === 0) {
      this.tagErrorMessage = 'Tag cannot be empty.';
    } else if (this.newTag.length > 15) {
      this.tagErrorMessage = 'Tag cannot exceed 15 characters.';
    } else if (this.tags.some((tag: {
      name: string,
      global: boolean
    }) => tag.name.toLowerCase() === this.newTag.toLowerCase())) {
      this.tagErrorMessage = 'Tag already exists.';
    } else {
      this.tagErrorMessage = '';
    }

    this.isTagInvalid = !!this.tagErrorMessage;
  }

  protected addTag(): void {
    if (!this.newTag || this.isTagInvalid) return;

    this.store.dispatch(setStockItemTags({tag: this.newTag}));
    this.newTag = '';
    this.isTagInvalid = false;
    this.tagErrorMessage = '';
  }

  protected async deleteTag(tagName: string): Promise<void> {
    const shouldDelete = await this.showDeleteTagConfirmation(tagName);
    if (shouldDelete) {
      this.store.dispatch(deleteStockItemTags({tag: tagName}));
    }
  }

  protected trackByFunction(index: number): number {
    return index;
  }
  //#endregion

  //#region Private Methods
  private async showDeleteTagConfirmation(tagName: string): Promise<boolean> {
    const modalProps: ISharedModalBasic = {
      buttonAccept: true,
      buttonAcceptText: 'Delete Tag',
      buttonClose: true,
      buttonCloseCross: false,
      buttonCloseText: 'Cancel',
      modalTitle: `Delete Tag: ${tagName}`,
      modalTitleIcon: Icons.infoSlabCircleOutline,
      modalTitleIconColor: 'yellow',
      contextHeading: 'Are you sure you want to delete this tag?',
      contextDescription: ['This action cannot be undone.'],
      cssClass: ['shared-basic-modal-css']
    };
    const { data } = await this.modalUtils.createModal(modalProps);
    return data?.buttonPressed === 'button-accept';
  }
  //#endregion
}
