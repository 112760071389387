import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {DataAdditionalIcons} from '../../../../shared-utilities/models-old/ngp-report-grid/data-additional-icons';
import {Icons} from '../../../shared-icons/icons';
import {selectCurrentPage} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {Store} from '@ngrx/store';
import {keyToTitle, StockItem} from '../../../../shared-utilities/models-old/datastructures';
import {NGPReport} from '../../../../shared-utilities/models-old/ngp-reports/ngp-report';

/**
 * CellRendererIconsComponent renders custom HTML within an AG Grid Cell
 * The component displays icons according to additional data provided in the
 * column definitions, and whether it is set to be displayed within the object.
 */
@Component({
  selector: 'app-cell-renderer-icons',
  templateUrl: './cell-renderer-icons.component.html',
  styleUrls: ['./cell-renderer-icons.component.scss'],
})
export class CellRendererIconsComponent implements ICellRendererAngularComp {

  readonly icons: typeof Icons = Icons;
  params: ICellRendererParams;
  additionalData: DataAdditionalIcons
    & {
    costMiss: boolean;
    created: boolean;
    isEdited: boolean;
    error: boolean;
  };
  isStockManager: boolean;

  constructor(
    private readonly store: Store,
  ) {
  }

  agInit(
    params: ICellRendererParams &
      { dataAdditional: DataAdditionalIcons },
  ): void {
    this.assignComponentProperties(params);
    this.params = {...params};
    this.store.select(selectCurrentPage).subscribe((currentpage: string) => {
      this.isStockManager = currentpage === 'stock-manager';
    });
  }

  refresh(params: ICellRendererParams &
    { dataAdditional: DataAdditionalIcons }): boolean {
    this.assignComponentProperties(params);
    return true;
  }

  showEditedItems(data: StockItem | NGPReport): string {
    if (!data.originalValue) {
      return '';
    }

    const fieldsToIgnore = ['originalValue', 'isEdited', 'isError', 'new', 'isSelected'];
    const changedFields: string[] = [];

    function isEqualFlexible<AType>(a: AType, b: AType): boolean {
      if (a === b) return true;

      if (Array.isArray(a) && Array.isArray(b)) {
        if (a.length !== b.length) {
          return false;
        }

        for (let i = 0; i < a.length; i++) {
          if (!isEqualFlexible(a[i], b[i])) {
            return false;
          }

        }
        return true;
      }

      if (a && b && typeof a === 'object' && typeof b === 'object') {
        const aKeys = Object.keys(a);
        const bKeys = Object.keys(b);
        if (aKeys.length !== bKeys.length) return false;
        for (const key of aKeys) {
          if (!(key in (b))) return false;
          if (!isEqualFlexible((a)[key], (b)[key])) return false;
        }
        return true;
      }

      return a == b;
    }


    for (const field in data.originalValue) {
      if (!data.originalValue[field] || !('value' in data.originalValue[field])) {
        continue;
      }

      const currentValue = data[field];
      const originalVal = data.originalValue[field].value;

      if (
        !isEqualFlexible(currentValue, originalVal) &&
        !fieldsToIgnore.includes(field) &&
        keyToTitle[field]
      ) {
        changedFields.push(
          `${keyToTitle[field]} changed from ${originalVal} to ${currentValue}`,
        );
      }
    }
    return changedFields.join('\n');
  }


  /**
   * Assign the incoming data to component scope properties.
   *
   * @param params The parameters that are sent through from AG Grid, with dataAdditionalIcon properties
   */
  private assignComponentProperties(
    params: ICellRendererParams &
      { dataAdditional: DataAdditionalIcons }): void {
    this.additionalData = params?.dataAdditional ? {
      ...params.dataAdditional,
      costMiss: params?.data?.costMiss || false,
      created: params?.data?.created || false,
      isEdited: params?.data?.isEdited || false,
      error: params?.data?.error || false,
    } : null;
  }

}
