import {Component, Input, OnInit} from '@angular/core';
import {INotification} from '../../models/notification';
import {IStore} from '../../../../shared/shared-models/store/store';
import {IUser} from '../../../../shared/shared-models/user-access/user';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {
  selectAllNotificationAutoOrders, selectAllNotificationStockUpdates,
  selectIsGetNotificationAutoOrderLoading, selectIsGetNotificationStockUpdateLoading
} from '../../store/notification.selectors';
import {IAutoOrderOrder} from '../../../../shared/shared-models/auto-ordering/auto-order-order';
import {TempStockUpdate} from "../notification-stock-update/notification-stock-update.component";
import {getNotificationStockUpdate} from "../../store/notification.actions";

@Component({
  selector: 'app-notification-container',
  templateUrl: './notification-container.component.html',
  styleUrls: ['./notification-container.component.scss'],
})
export class NotificationContainerComponent implements OnInit {

  notification: INotification;
  user: IUser;
  userStores: IStore[] = [];

  isNotificationAutoOrderLoading$: Observable<{ [orderID: string]: boolean }>;
  isNotificationStockUpdateLoading$: Observable<{ [orderID: string]: boolean }>;
  notificationAutoOrders$: Observable<IAutoOrderOrder[]>;
  notificationStockUpdate$: Observable<TempStockUpdate>;

  constructor(
    private readonly store: Store
  ) {
  }

  @Input() set setNotification(notification: INotification) {
    this.notification = notification;
  }

  @Input() set setUser(user: IUser) {
    this.user = user;
  }

  @Input() set setUserStores(stores: IStore[]) {
    this.userStores = stores;
  }

  ngOnInit(): void {
    this.isNotificationAutoOrderLoading$ = this.store.select(selectIsGetNotificationAutoOrderLoading);
    this.isNotificationStockUpdateLoading$ = this.store.select(selectIsGetNotificationStockUpdateLoading);
    this.notificationAutoOrders$ = this.store.select(selectAllNotificationAutoOrders);
    this.notificationStockUpdate$ = this.store.select(selectAllNotificationStockUpdates);
  }

}
