import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {map} from 'rxjs/operators';

import {selectEmailSettingsView} from 'src/app/features/settings-old/store/settings.selectors';
import {IEmailSettings} from 'src/app/features/settings-old/models/settings-models';
import {IUser} from 'src/app/shared/shared-models/user-access/user';
import {SharedModalUtils} from 'src/app/shared/shared-utils/shared-modal/shared-modal.utils';
import {ISharedModalBasic} from 'src/app/shared/shared-models/modals/shared-modal-basic';
import {Icons} from 'src/app/shared-modules/shared-icons/icons';
import {
  CollectionUsersSettingsService
} from 'src/app/shared/shared-services/firebase/collection-users-settings.service';
import {
  path_users_userId_settings_stores_storeId_mailer_settings
} from 'src/app/shared/shared-services/database-paths';
import {testEmail} from 'src/app/features/settings-old/store/settings.actions';

import {IEmailSettingsFormState} from '../models';
import {EmailValidationManager} from './email-validation.manager';
import {EmailConfigurationManager} from './email-configuration.manager';
import {defaultEmailSettings, initialState} from '../constants';


@Injectable({
  providedIn: 'root'
})
export class EmailSettingsManager implements OnDestroy {
  #stateSubscription: Subscription;
  readonly #stateSubject = new BehaviorSubject<IEmailSettingsFormState>({...initialState});
  public readonly state$ = this.#stateSubject.asObservable();
  #isDirty = false;

  constructor(
    private readonly store: Store,
    private readonly validationManager: EmailValidationManager,
    private readonly configManager: EmailConfigurationManager,
    private readonly modalUtils: SharedModalUtils,
    private readonly collectionUsersSettingsService: CollectionUsersSettingsService
  ) {
  }

  //#region Public Accessors
  public get currentState(): IEmailSettingsFormState {
    return this.#stateSubject.getValue();
  }

  public get isDirty(): boolean {
    return this.#isDirty;
  }

  public get hasValidationErrors(): boolean {
    const settings = this.currentState.selectedColleagueEmailSettings;
    if (!settings || !this.hasRequiredFields(settings)) return true;
    const formErrors = this.currentState.formErrors;
    if (!formErrors) return false;
    const hasTopLevelErrors = ['email', 'user', 'pwd']
      .some((field: string) => !!formErrors[field]);
    if (hasTopLevelErrors) return true;
    if (formErrors.smtp &&
      Object.values(formErrors.smtp).some((error: string) => !!error)) {
      return true;
    }
    if (settings.enableImap && formErrors.imap &&
      Object.values(formErrors.imap).some((error: string) => !!error)) {
      return true;
    }
    return false;
  }

  //#region Public Methods
  public initialize(): Observable<IEmailSettingsFormState> {
    if (this.#stateSubscription) this.#stateSubscription.unsubscribe()
    this.#stateSubscription = this.store.select(selectEmailSettingsView).pipe(
      map(({colleagues, emailSettings, selectedStore, isSubmitting}) => {
        const storeChanged = selectedStore?.storeId !== this.currentState.selectedStore?.storeId
        const currentUser = colleagues[0]?.id;
        const selectedColleague = storeChanged ? currentUser : this.currentState.selectedColleague ?? currentUser;
        const selectedColleagueEmailSettings = storeChanged
          ? emailSettings?.[selectedColleague]
          : (this.currentState.selectedColleagueEmailSettings ??
            (selectedColleague ? emailSettings?.[selectedColleague] : null));
        return {
          colleagues,
          colleaguesEmailSettings: emailSettings,
          selectedColleague,
          selectedColleagueEmailSettings,
          selectedStore,
          filteredColleagues: colleagues,
          userQuery: this.currentState.userQuery ?? '',
          formErrors: this.currentState.formErrors ?? {},
          isSubmitting
        } as IEmailSettingsFormState;
      })
    ).subscribe((state: IEmailSettingsFormState) => {
      this.currentState ?
        this.#stateSubject.next({...this.currentState, ...state}) :
        this.#stateSubject.next(state);
    });
    this.#isDirty = false;
    return this.state$;
  }

  //#endregion

  public async onSelectColleague(id: string): Promise<void> {
    if (this.#isDirty) {
      const shouldDiscard = await this.showDiscardChangesConfirmation();
      if (!shouldDiscard) return
      this.#isDirty = false;
      this.updateState({formErrors: {}});
    }
    this.updateState({
      selectedColleagueEmailSettings: this.getSelectedColleagueEmailSettings(id),
      selectedColleague: id
    });
  }

  public onQueryChange(event: Event): void {
    const customEvent = event as unknown as { detail: { value: string } };
    const query = customEvent.detail?.value ?? '';
    const filteredColleagues = this.filterColleagues(query);
    this.updateState({
      userQuery: query,
      filteredColleagues
    });
  }

  public onEmailChange(event: Event): void {
    const email = (event.target as HTMLInputElement).value;
    const currentSettings = this.currentState.selectedColleagueEmailSettings;
    if (!currentSettings) return;
    const error = this.validationManager.isValidEmail(email);
    this.updateFormError('email', error);
    this.updateSelectedColleagueSettings({...currentSettings, email});
  }

  public onUserChange(event: Event): void {
    const user = (event.target as HTMLInputElement).value;
    const currentSettings = this.currentState.selectedColleagueEmailSettings;
    if (!currentSettings) return;
    const error = this.validationManager.isValidUser(user);
    this.updateFormError('user', error);
    this.updateSelectedColleagueSettings({...currentSettings, user});
  }

  public onPasswordChange(event: Event): void {
    const pwd = (event.target as HTMLInputElement).value;
    const currentSettings = this.currentState.selectedColleagueEmailSettings;
    if (!currentSettings) return;
    const error = this.validationManager.isValidPassword(pwd);
    this.updateFormError('pwd', error);
    this.updateSelectedColleagueSettings({...currentSettings, pwd, encryptedPwd: false});
  }

  public onSmtpServerChange(event: Event): void {
    const server = (event.target as HTMLInputElement).value;
    const currentSettings = this.currentState.selectedColleagueEmailSettings;
    if (!currentSettings) return;
    const error = this.validationManager.isValidSmtpServer(server);
    this.updateServerError('smtp', 'server', error);
    const updatedSettings = this.configManager.updateSmtpConfig(currentSettings, {server});
    this.updateSelectedColleagueSettings(updatedSettings);
  }

  public onSmtpPortChange(event: Event): void {
    const numPort = parseInt((event.target as HTMLInputElement).value, 10) || 0;
    const currentSettings = this.currentState.selectedColleagueEmailSettings;
    if (!currentSettings) return;
    const error = this.validationManager.isValidSmtpPort(numPort);
    this.updateServerError('smtp', 'port', error);
    const updatedSettings = this.configManager.updateSmtpConfig(currentSettings, {port: numPort});
    this.updateSelectedColleagueSettings(updatedSettings);
  }

  public onSmtpSslOnlyChange(checked: boolean): void {
    const currentSettings = this.currentState.selectedColleagueEmailSettings;
    if (!currentSettings) return;
    const updatedSettings = this.configManager.updateSmtpConfig(currentSettings, {
      sslOnly: checked
    });
    this.updateSelectedColleagueSettings(updatedSettings);
  }

  public onEnableImapChange(checked: boolean): void {
    const currentSettings = this.currentState.selectedColleagueEmailSettings;
    if (!currentSettings) return;
    const updatedSettings = this.configManager.updateImapEnabled(currentSettings, checked);
    if (!checked) {
      this.updateServerError('imap', 'server', null);
      this.updateServerError('imap', 'port', null);
    }
    this.updateSelectedColleagueSettings(updatedSettings);
  }

  public onImapServerChange(event: Event): void {
    const server = (event.target as HTMLInputElement).value;
    const currentSettings = this.currentState.selectedColleagueEmailSettings;
    if (!currentSettings) return;
    const error = this.validationManager.isValidImapServer(server);
    this.updateServerError('imap', 'server', error);
    const updatedSettings = this.configManager.updateImapConfig(currentSettings, {server});
    this.updateSelectedColleagueSettings(updatedSettings);
  }

  public onImapPortChange(event: Event): void {
    const numPort = parseInt((event.target as HTMLInputElement).value, 10) || 0;
    const currentSettings = this.currentState.selectedColleagueEmailSettings;
    if (!currentSettings) return;
    const error = this.validationManager.isValidImapPort(numPort);
    this.updateServerError('imap', 'port', error);
    const updatedSettings = this.configManager.updateImapConfig(currentSettings, {port: numPort});
    this.updateSelectedColleagueSettings(updatedSettings);
  }

  public onImapSslOnlyChange(checked: boolean): void {
    const currentSettings = this.currentState.selectedColleagueEmailSettings;
    if (!currentSettings) return;
    const updatedSettings = this.configManager.updateImapConfig(currentSettings, {
      sslOnly: checked
    });
    this.updateSelectedColleagueSettings(updatedSettings);
  }

  public async saveAndTestSettings(): Promise<void> {
    try {
      const currentState = this.currentState;
      if (!currentState.selectedColleagueEmailSettings) return;
      if (!currentState.selectedColleague || !currentState.selectedStore?.storeId) {
        throw new Error('No selected colleague or store');
      }
      const settings = currentState.selectedColleagueEmailSettings;
      const path = path_users_userId_settings_stores_storeId_mailer_settings(
        currentState.selectedColleague,
        currentState.selectedStore.storeId
      );
      const updatedSettings = {...settings, status: 'PENDING'};
      await this.collectionUsersSettingsService.setDocument(path, updatedSettings);
      this.store.dispatch(testEmail({
        storeId: currentState.selectedStore.storeId,
        userId: currentState.selectedColleague,
      }));
      this.#isDirty = false;
    } catch (error) {
      console.error('Failed to save or test email settings:', error);
    }
  }

  ngOnDestroy(): void {
    if (this.#stateSubscription) {
      this.#stateSubscription.unsubscribe();
    }
  }

  private hasRequiredFields(settings: IEmailSettings): boolean {
    if (!settings.email || !settings.user || !settings.pwd) {
      return false;
    }
    if (!settings.smtp?.server || !settings.smtp?.port) {
      return false;
    }
    return !(settings.enableImap &&
      (!settings.imap?.server || !settings.imap?.port));

  }

  private async showDiscardChangesConfirmation(): Promise<boolean> {
    const modalProps: ISharedModalBasic = {
      buttonAccept: true,
      buttonAcceptText: 'Discard Changes',
      buttonClose: true,
      buttonCloseCross: false,
      buttonCloseText: 'Cancel',
      modalTitle: 'Unsaved Changes',
      modalTitleIcon: Icons.infoSlabCircleOutline,
      modalTitleIconColor: 'yellow',
      contextHeading: 'You have unsaved changes that will be lost.',
      contextDescription: ['Do you want to discard your changes and select a different user?'],
      cssClass: ['shared-basic-modal-css']
    };
    const {data} = await this.modalUtils.createModal(modalProps);
    return data?.buttonPressed === 'button-accept';
  }

  //#endregion

  //#region Private Helper Methods
  private getSelectedColleagueEmailSettings(
    colleagueId: IUser['id']
  ): IEmailSettings | null {
    const state = this.currentState;
    if (!state.colleaguesEmailSettings || !colleagueId) return null;
    return state.colleaguesEmailSettings[colleagueId] || defaultEmailSettings;
  }

  private filterColleagues(query: string): IUser[] {
    const state = this.currentState;
    if (!query) return state.colleagues || [];
    const normalizedQuery = query.toLowerCase();
    return (state.colleagues || []).filter((user: IUser) => {
      const userId = user.id?.toLowerCase() || '';
      const userName = user.userName?.toLowerCase() || '';
      return userId.includes(normalizedQuery) || userName.includes(normalizedQuery);
    });
  }

  private updateState(updates: Partial<IEmailSettingsFormState>): void {
    this.#stateSubject.next({
      ...this.#stateSubject.getValue(),
      ...updates
    });
  }

  private updateFormError(
    field: 'email' | 'user' | 'pwd',
    error: string | null
  ): void {
    const formErrors = {...this.currentState.formErrors ?? {}};
    if (error) {
      formErrors[field] = error;
    } else {
      delete formErrors[field];
    }
    this.updateState({formErrors});
  }

  private updateServerError(
    type: 'smtp' | 'imap',
    field: 'server' | 'port',
    error: string | null
  ): void {
    const formErrors = {...this.currentState.formErrors ?? {}};
    if (!formErrors[type]) formErrors[type] = {};
    if (error) formErrors[type][field] = error;
    else {
      delete formErrors[type][field];
      if (Object.keys(formErrors[type]).length === 0) {
        delete formErrors[type];
      }
    }
    this.updateState({formErrors});
  }

  private updateSelectedColleagueSettings(settings: IEmailSettings): void {
    this.#isDirty = true;
    this.updateState({
      selectedColleagueEmailSettings: settings
    });
  }
  //#endregion
}
