import {IFilterType} from './advanced-filter-types';

/**
 * Enum representing different types of filters that can be applied.
 *
 * @readonly
 * @enum {string}
 */
export enum FilterTypesEnum {
  equalTo = 'equalTo',
  greaterThan = 'greaterThan',
  greaterThanOrEqualTo = 'greaterThanOrEqualTo',
  isNotAnyOf = 'isNotAnyOf',
  isOneOf = 'isOneOf',
  lessThan = 'lessThan',
  lessThanOrEqualTo = 'lessThanOrEqualTo',
  notEqualTo = 'notEqualTo',
  partiallyEqualTo = 'partiallyEqualTo',
  range = 'range',
  xDaysGreaterThan = 'xDaysGreaterThan',  // Custom Type for Dates
  xDaysLessThan = 'xDaysLessThan',        // Custom Type for Dates
}

/**
 * A predefined list of filter types with their metadata.
 * Each filter type includes an associated enum value, description, input type, and symbol representation.
 *
 * @constant
 * @type {IFilterType[]}
 */
export const FilterTypesString: IFilterType[] = [
  {enum: FilterTypesEnum.equalTo, description: 'equal to', inputs: 'single', symbol: '='},
  {enum: FilterTypesEnum.partiallyEqualTo, description: 'partially equal to', inputs: 'single', symbol: ':'},
  {enum: FilterTypesEnum.notEqualTo, description: 'not equal to', inputs: 'single', symbol: '!='},
  // { enum: FilterTypesEnum.isOneOf, description: 'is one of', inputs: 'multiple', symbol: '[]' },
  // { enum: FilterTypesEnum.isNotAnyOf, description: 'is not any of', inputs: 'multiple', symbol: '![]' },
];

/**
 * A predefined list of numerical filter types with their metadata.
 * Each filter type includes an associated enum value, description, input type, and symbol representation.
 *
 * @constant
 * @type {IFilterType[]}
 */
export const FilterTypesNumber: IFilterType[] = [
  {enum: FilterTypesEnum.equalTo, description: 'equal to', inputs: 'single', symbol: '='},
  {enum: FilterTypesEnum.notEqualTo, description: 'not equal to', inputs: 'single', symbol: '!='},
  {enum: FilterTypesEnum.greaterThan, description: 'greater than', inputs: 'single', symbol: '>'},
  {enum: FilterTypesEnum.lessThan, description: 'less than', inputs: 'single', symbol: '<'},
  {enum: FilterTypesEnum.lessThanOrEqualTo, description: 'less than or equal to', inputs: 'single', symbol: '<='},
  {enum: FilterTypesEnum.greaterThanOrEqualTo, description: 'greater than or equal to', inputs: 'single', symbol: '>='},
  // { enum: FilterTypesEnum.isOneOf, description: 'is one of', inputs: 'multiple', symbol: '[]' },
  // { enum: FilterTypesEnum.isNotAnyOf, description: 'is not any of', inputs: 'multiple', symbol: '![]' },
  // { enum: FilterTypesEnum.range, description: 'range', inputs: 'multiple', symbol: '[..]' },
];

/**
 * A predefined list of boolean filter types with their metadata.
 * Each filter type includes an associated enum value, description, input type, and symbol representation.
 *
 * @constant
 * @type {IFilterType[]}
 */
export const FilterTypesBoolean: IFilterType[] = [
  {enum: FilterTypesEnum.equalTo, description: 'equal to', inputs: 'single', symbol: '='},
  {enum: FilterTypesEnum.notEqualTo, description: 'not equal to', inputs: 'single', symbol: '!='},
  // { enum: FilterTypesEnum.isOneOf, description: 'is one of', inputs: 'multiple', symbol: '[]' },
  // { enum: FilterTypesEnum.isNotAnyOf, description: 'is not any of', inputs: 'multiple', symbol: '![]' },
];

/**
 * A predefined list of date filter types.
 * Reuses the number-based filter types as date comparisons follow similar logic.
 *
 * @constant
 * @type {IFilterType[]}
 */
export const FilterTypesDate: IFilterType[] = [
  ...FilterTypesNumber,
  // Custom Types For Dates as Numbers =============================================================================
  {enum: FilterTypesEnum.xDaysGreaterThan, description: 'greater than (x) days', inputs: 'single', symbol: '>'},
  {enum: FilterTypesEnum.xDaysLessThan, description: 'less than (x) days', inputs: 'single', symbol: '<'},
];

/**
 * Enum for filter types used in Typesense.
 * Represents various comparison operators and their corresponding descriptions for filtering.
 *
 * @readonly
 * @enum {string}
 */
export enum FilterTypeTypesense {
  EqualTo = 'equal to',
  PartiallyEqualTo = 'partially equal to',
  NotEqualTo = 'not equal to',
  GreaterThan = 'greater than',
  LessThan = 'less than',
  GreaterThanOrEqualTo = 'greater than or equal to',
  LessThanOrEqualTo = 'less than or equal to',
  DoesNotContain = 'does not contain',
  IsOneOf = 'is one of',
  IsNotAnyOf = 'is not any of',
  Range = 'range',
  xDaysGreaterThan = 'greater than (x) days',  // Custom Type for Dates
  xDaysLessThan = 'less than (x) days',        // Custom Type for Dates
}

/**
 * Mapping of filter types to their corresponding symbols.
 * Associates each filter type from the `FilterTypeTypesense` enum with its string symbol representation used for filtering.
 *
 * @constant
 * @type {{ [key in FilterTypeTypesense]: string }}
 */
export const filterTypeToSymbol: { [key in FilterTypeTypesense]: string } = {
  [FilterTypeTypesense.EqualTo]: '=',
  [FilterTypeTypesense.PartiallyEqualTo]: '',
  [FilterTypeTypesense.NotEqualTo]: '!=',
  [FilterTypeTypesense.GreaterThan]: '>',
  [FilterTypeTypesense.LessThan]: '<',
  [FilterTypeTypesense.GreaterThanOrEqualTo]: '>=',
  [FilterTypeTypesense.LessThanOrEqualTo]: '<=',
  [FilterTypeTypesense.DoesNotContain]: '!',
  [FilterTypeTypesense.IsOneOf]: '=',
  [FilterTypeTypesense.IsNotAnyOf]: '!=',
  [FilterTypeTypesense.Range]: '..',
  [FilterTypeTypesense.xDaysGreaterThan]: '>',
  [FilterTypeTypesense.xDaysLessThan]: '<',
};

/**
 * Enum for logical operators.
 * Represents logical conjunctions used in filtering expressions.
 *
 * @readonly
 * @enum {string}
 */
export enum LogicalOperator {
  AND = 'AND',
  OR = 'OR',
}

/**
 * Mapping of logical operators to their JavaScript equivalent symbols.
 * Associates each logical operator from the `LogicalOperator` enum with its corresponding symbol for logical operations.
 *
 * @constant
 * @type {{ [key in LogicalOperator]: string }}
 */
export const operatorMapping: { [key in LogicalOperator]: string } = {
  [LogicalOperator.AND]: '&&',
  [LogicalOperator.OR]: '||',
};

