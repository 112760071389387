<div class="shared-modal-advanced-filter-container">

  <ion-header>
    <ion-toolbar>
      <ion-title>
        <div>
          <div>{{ modalTitle }}</div>
          <span></span>
          <ion-button (click)="onButtonPress('button-close')" *ngIf="buttonCloseCross" fill="clear">
            <app-display-icon *ngIf="buttonCloseCross" [icon]="icons.close"></app-display-icon>
          </ion-button>
        </div>
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <div class="context-container">

    <div class="list-container">
      <div class="list-names">
        <ion-button
          *ngIf="advancedFilterGroups?.length < 1"
          [disabled]="true"
          fill="clear">
          <div>
            <span>Please Create Filter</span>
          </div>
        </ion-button>
        <ion-button
          (click)="onSelectedNameClick(group.name)"
          *ngFor="let group of advancedFilterGroups; trackBy: trackByFunction"
          [disabled]="getIsNewAdvancedFilterGroup || isAdvancedFiltersLoading"
          fill="clear">
          <div
            [ngClass]="{
              'selected': selectedAdvancedFilterGroup.name === group.name
            }">
            <app-icon-radiobox-blank
              *ngIf="group.id !== appliedAdvancedFilterGroup?.id">
            </app-icon-radiobox-blank>
            <app-icon-radiobox-marked
              *ngIf="group.id === appliedAdvancedFilterGroup?.id"
              [tooltip]="'Applied filter group to the grid.'">
            </app-icon-radiobox-marked>
            <app-icon-new-box
              *ngIf="!group.saved"
              [tooltip]="'New Unsaved Filter Group'">
            </app-icon-new-box>
            <span>{{ group.name }}</span>
            <app-icon-chevron-right></app-icon-chevron-right>
          </div>
        </ion-button>
      </div>
      <ion-button
        (click)="onNewItemClick()"
        [disabled]="getIsNewAdvancedFilterGroup"
        class="add-new-button">
        Add New
      </ion-button>
    </div>

    <div *ngIf="advancedFilterGroups?.length < 1 && !isAdvancedFiltersLoading && !selectedAdvancedFilterGroup"
         class="no-select-text">
      <div>Advanced Filter Groups</div>
      <div class="divider"></div>
      <div>There are no saved Advanced Filter Groups saved.</div>
      <div>Please create one to start.</div>
    </div>

    <div *ngIf="advancedFilterGroups?.length > 0 && !isAdvancedFiltersLoading && !selectedAdvancedFilterGroup"
         class="no-select-text">
      <div>Advanced Filter Groups</div>
      <div>There is no selection made for Advanced Filter Groups.</div>
      <div>Please make a selection to continue.</div>
    </div>

    <div *ngIf="!isAdvancedFiltersLoading && selectedAdvancedFilterGroup; else FormContainerElse"
         class="form-container">

      <div class="form-heading">
        <ion-item>
          <ion-label position="stacked">Filter Group Name</ion-label>
          <ion-input
            (ionChange)="onNameChange()"
            [(ngModel)]="selectedAdvancedFilterGroup.name"
            placeholder="Enter filter group name">
          </ion-input>
        </ion-item>
      </div>

      <div class="form-container-actions">
        <ion-button
          (click)="onApplyFilterClick()"
          *ngIf="selectedAdvancedFilterGroup?.id !== appliedAdvancedFilterGroup?.id"
          [disabled]="!selectedAdvancedFilterGroup.saved"
          fill="clear">
          <app-icon-filter-variant-plus
            [tooltip]="'Apply ' + selectedAdvancedFilterGroup.name + ' to grid'">
          </app-icon-filter-variant-plus>
          Apply to Grid
        </ion-button>
        <ion-button (click)="onRemoveFilterClick()"
                    *ngIf="selectedAdvancedFilterGroup?.id === appliedAdvancedFilterGroup?.id"
                    fill="clear">
          <app-icon-filter-variant-remove
            [tooltip]="'Remove ' + selectedAdvancedFilterGroup.name + ' on grid'">
          </app-icon-filter-variant-remove>
          Remove from Grid
        </ion-button>
        <span></span>
        <ion-button (click)="onSaveItemClick()" *ngIf="!selectedAdvancedFilterGroup.saved" fill="clear">
          <app-icon-content-save-outline
            [tooltip]="'Save - ' + selectedAdvancedFilterGroup.name + ' - Filter Group'">
          </app-icon-content-save-outline>
        </ion-button>
        <ion-button (click)="onCancelItemClick()" *ngIf="!selectedAdvancedFilterGroup.saved" fill="clear">
          <app-icon-cancel
            [tooltip]="'Discard changes on - ' + selectedAdvancedFilterGroup.name">
          </app-icon-cancel>
        </ion-button>
        <ion-button (click)="onDeleteAdvancedFilterGroupClick()" fill="clear">
          <app-icon-delete-outline
            [tooltip]="'Delete - ' + selectedAdvancedFilterGroup.name + ' - Filter Group'">
          </app-icon-delete-outline>
        </ion-button>
        <ion-button (click)="onCloneItemClick()" [disabled]="!selectedAdvancedFilterGroup.saved" fill="clear">
          <app-icon-content-copy
            [tooltip]="'Clone - ' + selectedAdvancedFilterGroup.name + ' - Filter Group'">
          </app-icon-content-copy>
        </ion-button>
      </div>

      <div class="divider"></div>

      <div class="form-list">
        <app-shared-modal-filters-advanced-group
          (applyFilterToGrid)="onApplyFilterClick()"
          (removeFilterFromGrid)="onRemoveFilterClick()"
          [predecessorID]="selectedAdvancedFilterGroup.id"
          [setColDef]="colDefsNames"
          [setFilterGroups]="(selectedGroupData$ | ngrxPush)">
        </app-shared-modal-filters-advanced-group>
      </div>

    </div>

  </div>

</div>

<ng-template #FormContainerElse>
  <ng-container *ngIf="isAdvancedFiltersLoading;">
    <div class="loading-indication-afg">
      <div>
        <app-shared-loading-indicator
          [animation]="animationLogo">
        </app-shared-loading-indicator>
      </div>
    </div>
  </ng-container>
</ng-template>

