import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import type {IMultiSelect, ISelect, ISortedMultiSelectItem, OptionType} from '../../models';
import {initialSettingState, type ISettingState} from './models';
import {DropdownService, MultiSelectSortService} from './services';
import {getDropdownOptions} from './utils';


@Component({
  selector: 'app-setting-section',
  templateUrl: './setting-section.component.html',
  styleUrls: ['./setting-section.component.scss']
})
export class SettingSectionComponent implements OnInit, OnDestroy {
  //#region Props
  @Input() slug!: string;
  @Input() optionType!: OptionType;
  @Input() optionTip? = '';
  @Input() isSubmitting = false;
  @Input() checked = false;
  @Input() selectOptions?: readonly ISelect[] = [];
  @Input() selectedOption = '';
  @Input() placeholder = 'Select an option';
  @Input() textValue = '';
  @Input() textInputRequired = false;
  @Input() multiSelectValues$: Observable<IMultiSelect>;

  @Output() readonly checkedChange = new EventEmitter<boolean>();
  @Output() readonly selectedOptionChange = new EventEmitter<string>();
  @Output() readonly textValueChange = new EventEmitter<string>();
  @Output() readonly multiSelectValuesChange = new EventEmitter<IMultiSelect>();
  @Output() readonly focusStateChange = new EventEmitter<boolean>();
  public sortedMultiSelectValues: ISortedMultiSelectItem[] = [];
  protected activeDescendantId: string | null = null;
  protected gridUtils = GridUtils;
  readonly #destroy$ = new Subject<void>();
  readonly #componentId: string = this.generateComponentId();
  #currentMultiSelectValues: IMultiSelect = {};
  #state: ISettingState = {...initialSettingState};
  #initialSortDone = false;

  //#endregion

  constructor(
    private elementRef: ElementRef,
    private dropdownService: DropdownService,
    private multiSelectSortService: MultiSelectSortService,
  ) {
  }

  //#region Getters & Setters
  public get isOpen(): boolean {
    return this.#state.isOpen;
  }

  public get isMultiSelectOpen(): boolean {
    return this.#state.isMultiSelectOpen;
  }

  public get showTooltip(): boolean {
    return this.#state.showTooltip;
  }

  //#endregion

  //#region Lifecycle
  ngOnInit(): void {
    this.setupDropdownListener();
    if (this.optionType === 'select-multiple') {
      this.multiSelectValues$.pipe(
        takeUntil(this.#destroy$)
      ).subscribe((values: IMultiSelect) => {
        this.#currentMultiSelectValues = values;
        if (values && Object.keys(values).length > 0 && !this.#initialSortDone) {
          this.sortedMultiSelectValues = this.multiSelectSortService.sort(values);
          this.#initialSortDone = true;
        } else if (values) {
          this.updateMultiSelectItemStates(values);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.cleanupDropdownPositioning();
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  //#endregion

  //#region Event Handlers
  @HostListener('window:resize')
  protected onResize(): void {
    if (this.#state.isOpen || this.#state.isMultiSelectOpen) {
      this.updateDropdownPosition();
    }
  }

  @HostListener('focusout', ['$event'])
  protected onFocusOut(event: FocusEvent): void {
    if (this.#state.isOpen || this.#state.isMultiSelectOpen) {
      const targetElement = event.relatedTarget as HTMLElement;
      if (!this.elementRef.nativeElement.contains(targetElement)) {
        this.closeAllDropdowns();
      }
    }
  }

  @HostListener('document:mousedown', ['$event'])
  protected onClickOutside(event: Event): void {
    const target = event.target as HTMLElement;
    const wrapper = this.elementRef.nativeElement.querySelector('.option-select-wrapper');
    const isOutsideClick = !wrapper?.contains(target);

    if ((this.#state.isOpen || this.#state.isMultiSelectOpen) && isOutsideClick) {
      this.closeAllDropdowns();
    }
  }

  protected onKeyDown(event: KeyboardEvent): void {
    if (!this.#state.isOpen && !this.#state.isMultiSelectOpen) {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        event.preventDefault();
        this.optionType === 'select' ? this.onToggleSingleSelect() : this.onToggleMultiSelect();
      }
      return;
    }

    const container = this.elementRef.nativeElement as HTMLElement;
    const selector = this.#state.isMultiSelectOpen
      ? '.multi-select-options'
      : '.options-container';
    const options = getDropdownOptions(container, selector);

    if (!options.length) return;

    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Home':
      case 'End':
        this.handleKeyboardNavigation(event, options);
        break;
      case 'Enter':
      case ' ':
        const focusedOption = document.activeElement as HTMLElement;
        if (focusedOption?.getAttribute('role') === 'option') {
          event.preventDefault();
          // Handle multi-select differently from single select
          if (this.#state.isMultiSelectOpen) {
            const key = parseInt(focusedOption.id.split('-').pop() || '', 10);
            if (!isNaN(key)) {
              this.onToggleMultiSelectValue(key);
            }
          } else {
            const optionText = focusedOption.textContent?.trim();
            if (optionText) {
              this.selectOption(optionText);
            }
          }
        }
        break;
      case 'Escape':
        event.preventDefault();
        this.closeAllDropdowns();
        break;
      case 'Tab':
        event.preventDefault();
        const focusedIndex = Array.from(options).indexOf(document.activeElement as HTMLElement);
        const nextIndex = event.shiftKey
          ? (focusedIndex <= 0 ? options.length - 1 : focusedIndex - 1)
          : (focusedIndex === options.length - 1 ? 0 : focusedIndex + 1);
        options[nextIndex].focus();
        this.updateActiveDescendant(options[nextIndex].id);
        break;
    }
  }

  //#endregion

  //#region Input Event Handlers
  protected onCheckboxChange(checked: boolean): void {
    this.checkedChange.emit(checked);
  }

  protected onTextInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.textValueChange.emit(input.value);
  }

  protected onTextInputFocus(): void {
    this.focusStateChange.emit(true);
  }

  protected onTextInputBlur(): void {
    this.focusStateChange.emit(false);
  }

  //#endregion

  //#region UI Action Methods
  protected onToggleCheckbox(): void {
    this.checkedChange.emit(!this.checked);
  }

  protected onToggleSingleSelect(): void {
    if (this.#state.isMultiSelectOpen) {
      this.closeMultiSelect();
    }
    const newState = !this.#state.isOpen;
    this.#state.isOpen = newState;
    if (newState) {
      this.dropdownService.notifyDropdownToggled(this.#componentId);
    }
    this.handleSelectToggle();
  }

  protected onToggleMultiSelect(): void {
    if (this.#state.isOpen) {
      this.closeDropdown();
    }

    const newState = !this.#state.isMultiSelectOpen;
    this.#state.isMultiSelectOpen = newState;

    if (newState) {
      this.dropdownService.notifyDropdownToggled(this.#componentId);
    }

    this.handleSelectToggle();
  }

  protected selectOption(option: string): void {
    this.selectedOptionChange.emit(option);
    this.closeDropdown();
  }

  protected onToggleMultiSelectValue(key: number): void {
    if (this.isMultiSelectValueDisabled(key)) return;
    const option = this.#currentMultiSelectValues[key];
    if (!option) return;
    const updatedValues = {
      ...this.#currentMultiSelectValues,
      [key]: {
        ...option,
        selected: !option.selected
      }
    };
    this.multiSelectValuesChange.emit(updatedValues);
  }

  //#endregion

  //#region Protected Methods - Display & State
  protected trackByOption(_: number, item: ISelect): string {
    return item.name + (item.colour || '');
  }

  protected trackByNumber(_: number, item: number): number {
    return item;
  }

  protected trackByMultiSelectItem(_: number, item: {
    key: number;
    name: string;
    selected: boolean;
    disabled: boolean;
  }): number {
    return item.key;
  }

  protected getOptionName(key: number): string {
    return this.#currentMultiSelectValues[key]?.name ?? '';
  }

  protected getSelectedOptions(): number[] {
    return Object.entries(this.#currentMultiSelectValues)
      .filter(([_, value]) => value.selected)
      .map(([key]) => parseInt(key, 10));
  }

  protected getSelectedOptionsDisplay(): string {
    return this.getSelectedOptions()
      .map((key: number) => this.getOptionName(key))
      .join(', ');
  }

  protected isMultiSelectValueSelected(key: number): boolean {
    return this.#currentMultiSelectValues[key]?.selected ?? false;
  }

  protected isMultiSelectValueDisabled(key: number): boolean {
    return this.#currentMultiSelectValues[key]?.disabled ?? false;
  }

  //#endregion

  //#region Private Methods
  private updateMultiSelectItemStates(values: IMultiSelect): void {
    this.sortedMultiSelectValues = this.sortedMultiSelectValues.map((item: ISortedMultiSelectItem) => {
      const newItem = values[item.key];
      if (newItem) {
        return {
          ...item,
          selected: newItem.selected,
          disabled: newItem.disabled
        };
      }
      return item;
    });
  }

  private handleKeyboardNavigation(event: KeyboardEvent, options: HTMLElement[]): void {
    const currentElement = document.activeElement as HTMLElement;
    const enabledOptions = Array.from(options).filter(
      option => !option.hasAttribute('aria-disabled') ||
        option.getAttribute('aria-disabled') === 'false'
    );
    if (!enabledOptions.length) return;
    const currentEnabledIndex = enabledOptions.indexOf(currentElement);
    let targetElement: HTMLElement | undefined;
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        targetElement = enabledOptions[(currentEnabledIndex + 1) % enabledOptions.length];
        break;
      case 'ArrowUp':
        event.preventDefault();
        targetElement = enabledOptions[
        (currentEnabledIndex - 1 + enabledOptions.length) % enabledOptions.length
          ];
        break;
      case 'Home':
        event.preventDefault();
        targetElement = enabledOptions[0];
        break;
      case 'End':
        event.preventDefault();
        targetElement = enabledOptions[enabledOptions.length - 1];
        break;
    }
    if (targetElement) {
      targetElement.focus();
      this.updateActiveDescendant(targetElement.id);
    }
  }

  private updateActiveDescendant(id: string | null): void {
    this.activeDescendantId = id;
  }

  private setupDropdownListener(): void {
    this.dropdownService.dropdownToggled$
      .pipe(takeUntil(this.#destroy$))
      .subscribe((id: string) => {
        if (id !== this.#componentId) this.closeAllDropdowns();
      });
  }

  private handleSelectToggle(): void {
    const isOpen = this.#state.isOpen || this.#state.isMultiSelectOpen;

    if (isOpen) {
      this.setupDropdownPositioning();
      requestAnimationFrame(() => {
        this.resetKeyboardNavigation();
      });
    } else {
      this.cleanupDropdownPositioning();
    }
  }

  private generateComponentId(): string {
    return 'setting-section-' + Math.random().toString(36).substring(2, 11);
  }

  private resetKeyboardNavigation(): void {
    this.activeDescendantId = null;
  }

  private updateDropdownPosition(): void {
    this.dropdownService.updateDropdownPosition(
      this.elementRef.nativeElement.querySelector('.select-button') as HTMLElement,
      this.elementRef.nativeElement.querySelector('.options-container') as HTMLElement
    );
  }

  private setupDropdownPositioning(): void {
    this.dropdownService.setupDropdownPositioning(this.elementRef);
  }

  private cleanupDropdownPositioning(): void {
    this.dropdownService.cleanupDropdownPositioning();
  }

  private closeAllDropdowns(): void {
    if (this.#state.isOpen || this.#state.isMultiSelectOpen) {
      this.#state.isOpen = false;
      this.#state.isMultiSelectOpen = false;
      this.cleanupDropdownPositioning();
      this.resetKeyboardNavigation();
    }
  }

  private closeDropdown(): void {
    this.#state.isOpen = false;
    this.cleanupDropdownPositioning();
    this.resetKeyboardNavigation();
  }

  private closeMultiSelect(): void {
    this.#state.isMultiSelectOpen = false;
  }

  //#endregion
}
