import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {SHARED_COMPONENTS} from './components';
import {IonicModule} from '@ionic/angular';
import {SharedIconsModule} from '../../shared-modules/shared-icons/shared-icons.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PushModule} from '@ngrx/component';
import {AgGridAngular} from 'ag-grid-angular';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [
    ...SHARED_COMPONENTS,
  ],
  imports: [
    AgGridAngular,
    CommonModule,
    FormsModule,
    IonicModule,
    MatTooltipModule,
    NgOptimizedImage,
    PushModule,
    ReactiveFormsModule,
    SharedIconsModule,
  ],
  exports: [
    ...SHARED_COMPONENTS,
  ],
})
export class SharedComponentsModule {
}
