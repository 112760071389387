<div
  [id]="animation.id"
  [ngStyle]="{
    'width': animation.fixedDimensions.width === 1 ? '100%' : animation.fixedDimensions.width + 'px',
    'height': animation.fixedDimensions.height === 1 ? '100%' : animation.fixedDimensions.height + 'px'
  }"
  class="logo-animation">

  <svg fill="none" viewBox="0 0 126 127" xmlns="http://www.w3.org/2000/svg">
    <path
      class="gallix-logo-black"
      d="M122.331 43.0094C123.354 45.9555 124.151 48.9755 124.716 52.043H98.9436C96.7313 52.043 94.7844 50.7307 93.7952 48.7149L93.7667 48.6847C93.2327 47.5848 92.6408 46.5141 91.9937 45.4771C85.9224 35.835 75.221 29.4196 63.0073 29.4196C56.3179 29.4185 49.7746 31.3822 44.1852 35.0679C38.5958 38.7536 34.2049 44.0002 31.5546 50.1599C28.9042 56.3197 28.1105 63.1229 29.2713 69.7298C30.4322 76.3368 33.4968 82.4582 38.0868 87.3385C20.377 78.2685 8.28023 59.7784 8.28023 38.4895C8.27756 36.3747 8.39416 34.2614 8.62946 32.1598C19.5332 13.3797 39.7729 0.780365 63.0073 0.780365C86.2132 0.780365 106.481 13.3797 117.357 32.1598C119.359 35.6077 121.024 39.2409 122.331 43.0094Z"
    />
    <path
      class="gallix-logo-blue"
      d="M125.819 79.3763V126.067H97.2622V116.59C85.8055 124.079 71.7318 127.934 56.7258 126.476C28.0254 123.675 4.50029 100.987 0.748784 72.3191C-0.611807 61.994 0.514908 52.0207 3.68488 43.0094C5.98255 73.8342 31.6552 98.1279 63.0073 98.1279C69.7323 98.1359 76.3091 96.1469 81.9086 92.4115H63.0073V63.7738H110.233C114.362 63.7725 118.323 65.415 121.245 68.3405C124.168 71.266 125.813 75.2352 125.819 79.3763Z"
    />
  </svg>

  <div class="mask-container">
    <div
      [ngStyle]="{
      '--animation-name': animation.animation,
      '--animation-duration': animation.duration + 's',
      '--animation-delay': animation.delay + 's',
      '--animation-direction': animation.direction,
      '--animation-fill-mode': animation.fill,
      '--animation-play-state': animation.playState,
      '--animation-iteration-count': animation.iterationCount,
      '--animation-timing-function': animation.timingFunction,
      '--animation-background': animation.backgroundColour ? animation.backgroundColour : 'var(--ion-color-light-v1)'
    }"
      class="mask mask-animation">
    </div>
  </div>

</div>

