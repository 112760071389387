// 1️⃣ Angular Imports
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

// 2️⃣ Third-Party Libraries
import {Action, ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';

// 3️⃣ Environment Configuration
import {environment} from '../../../environments/environment';

// 4️⃣ Core Modules
import {CoreRoutingModule} from './core-routing.module';

// 5️⃣ Feature Modules
import {NgpReportEffects} from '../ngp-report/store/ngp-report.effects';
import {ngpReportReducer} from '../ngp-report/store/ngp-report.reducer';
import {NotificationsModule} from '../notifications/notifications.module';
import {NotificationEffects} from '../notifications/store/notification.effects';
import {notificationReducer} from '../notifications/store/notification.reducer';
import {SettingsPageModule} from '../settings/settings-page.module';
import {ShelfTalkersEffects} from '../shelf-talkers/store/shelf-talkers.effects';
import {shelfTalkersReducer} from '../shelf-talkers/store/shelf-talkers.reducer';
import {SettingsReducer} from '../settings-old/store/settings.reducer';
import {SettingsEffects} from '../settings-old/store/settings.effects';
import {StockManagerEffects} from '../stock-manager/store/stock-manager.effects';
import {stockManagerReducer} from '../stock-manager/store/stock-manager.reducer';
import {UserAccessEffects} from '../user-access/store/user-access.effects';
import {userAccessReducer} from '../user-access/store/user-access.reducer';

// 6️⃣ Shared Modules & Store
import {sharedFilterReducer} from './store-shared-filter/shared-filter.reducer';
import {SharedFilterEffects} from './store-shared-filter/shared-filter.effects';
import {sharedGridReducer} from '../../shared-modules/shared-grid/store/shared-grid.reducer';
import {SharedGridEffects} from '../../shared-modules/shared-grid/store/shared-grid.effects';
import {sharedReducer} from './store-shared/shared.reducer';
import {SharedEffects} from './store-shared/shared.effects';

export const debug = (reducer: ActionReducer<never>): ActionReducer<never> =>
  (state, action: Action) => {
    if (!environment.production) {
      // console.warn('[State] ---> ', state);
      // console.warn(`[Action] --> ${action.type}`, action);
      // console.warn('\n');
    }
    return reducer(state, action);
  };

export const metaReducers: MetaReducer[] = !environment.production ? [debug] : [];

@NgModule({
  declarations: [],
  imports: [
    // 1️⃣ Angular & Core Modules
    CommonModule,
    CoreRoutingModule,

    // 2️⃣ Feature Modules
    NotificationsModule,
    SettingsPageModule,

    // 3️⃣ NgRx Store Modules
    StoreModule.forRoot(
      {
        ['core-shared']: sharedReducer,
        ['ngp-report']: ngpReportReducer,
        ['notification']: notificationReducer,
        ['settings']: SettingsReducer,
        ['shared-filter']: sharedFilterReducer,
        ['shared-grid']: sharedGridReducer,
        ['shelf-talkers']: shelfTalkersReducer,
        ['stock-manager']: stockManagerReducer,
        ['user-access']: userAccessReducer,
      },
      {
        metaReducers,
        runtimeChecks: {
          strictActionImmutability: false,
          strictActionSerializability: false,
          strictStateSerializability: false,
        },
      }
    ),

    // 4️⃣ NgRx Effects Modules
    EffectsModule.forRoot([
      NgpReportEffects,
      NotificationEffects,
      SharedEffects,
      SharedFilterEffects,
      SharedGridEffects,
      SettingsEffects,
      ShelfTalkersEffects,
      StockManagerEffects,
      UserAccessEffects,
    ]),

    // 5️⃣ NgRx DevTools
    StoreDevtoolsModule.instrument({
      maxAge: 70,
      logOnly: !environment.production,
      actionsBlocklist: ['[Core Shared][Time Stamps] Get Data Time Stamps Success'],
    }),
  ],

})
export class CoreModule {
}
