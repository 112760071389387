import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {ICellEditorParams} from 'ag-grid-community';
import {Icons} from '../../../shared-icons/icons';
import {IDepartment} from '../../../../shared/shared-models/stock/departments';
import {Store} from '@ngrx/store';
import {StockItem} from '../../../../shared-utilities/models-old/datastructures';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {NGPReport} from '../../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {selectTags} from '../../../../features/settings-old/store/settings.selectors';

@Component({
  selector: 'app-cell-editor-multi-select-array',
  templateUrl: './cell-editor-multi-select-array.component.html',
  styleUrls: ['./cell-editor-multi-select-array.component.scss'],
})
export class CellEditorMultiSelectArrayComponent implements ICellEditorAngularComp {

  @ViewChild('input', {static: true}) input: ElementRef;
  @ViewChild('selectElement', {static: true}) selectElement: ElementRef<HTMLSelectElement>;
  tags = ['in_ngp', 'ao_flag', 'onHold', 'test_tag'];
  value: string = '';
  params: ICellEditorParams;
  width: number;
  colDefField: string;
  icons: typeof Icons = Icons;
  departments: IDepartment[] = [];
  displayAbove: boolean = false;
  selectedValues: string[] = [];
  showOptions = false;
  protected readonly object = Object;

  constructor(
    private readonly store: Store,
  ) {
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    if (this.showOptions) {
      this.adjustPosition();
    }
  }

  agInit(params: ICellEditorParams): void {
    this.store.select(selectTags).subscribe((tags: { name: string, global: boolean }[]) => {
      this.tags = tags.map((tag: { name: string, global: boolean }) => tag.name);
    });
    this.params = {...params};
    this.params.data = {...params.data};
    this.params.colDef = {...params.colDef};
    this.colDefField = params.colDef.field;
    this.value = this.params.data[this.colDefField];
    this.selectedValues = params.value
      ? String(params.value)
        .split(',')
        .filter((val: string) => val.trim() !== '')
      : [];
    this.width = this.params.colDef.width;
    this.params.data.originalValue = {...params.data.originalValue};
    this.params.data.originalValue[this.colDefField] = {...params.data.originalValue[this.colDefField]};
  }

  getValue(): string {
    let newValues: string[] = [];

    if (Array.isArray(this.value)) {
      newValues = this.value.flat().filter((item: string) => typeof item === 'string' && item.trim() !== '');
    } else if (typeof this.value === 'string' && this.value.trim() !== '') {
      newValues = this.value
        .split(',')
        .map((item: string) => item.trim())
        .filter((item: string) => item !== '');
    }
    this.selectedValues = newValues;
    this.params.data[this.colDefField] = newValues;

    const originalValues = this.params?.data?.originalValue?.[this.colDefField]?.value as string[];
    this.params.data.isEdited = !this.arraysAreEqual(newValues, originalValues);

    GridUtils.updateIsEditedAndForceRefresh(this.params);
    GridUtils.updateSingleItem(
      this.params.data as NGPReport | StockItem,
      this.colDefField,
      newValues,
      this.store,
      'stock-manager',
    );

    return newValues.join(',');
  }


  arraysAreEqual(arr1: string[], arr2: string[]): boolean {
    if (!arr1 || !arr2) {
      return false;
    }
    if (arr1.length !== arr2.length) {
      return false;
    }
    return arr1.every((value, index) => value === arr2[index]);
  }


  refresh(params: ICellEditorParams<NGPReport | StockItem, string | number>): boolean {
    GridUtils.updateIsEditedAndForceRefresh(this.params);
    return false;
  }

  afterGuiAttached(): void {
    this.input.nativeElement.focus();
  }

  onUndoChangesClick(): void {
    this.value = this.params.data.originalValue[this.colDefField].value;
    this.selectedValues = this.params.data.originalValue[this.colDefField].value;
    this.params.data[this.colDefField] = this.params.data.originalValue[this.colDefField].value;
    GridUtils.removeIsEditingFlagAndDispatch(this.params.data as NGPReport | StockItem, this.colDefField, this.store, 'stock-manager');
    GridUtils.updateIsEditedAndForceRefresh(this.params);
  }

  toggleDropdown(): void {
    this.showOptions = !this.showOptions;
    if (this.showOptions) {
      this.adjustPosition();
    }
  }

  trackByFunction(index: number): number {
    return index;
  }

  adjustPosition(): void {
    const inputRect = this.input.nativeElement.getBoundingClientRect();
    const optionsHeight = 400;
    const spaceBelow = window.innerHeight - inputRect.bottom;
    const spaceAbove = inputRect.top;

    this.displayAbove = spaceBelow < optionsHeight && spaceAbove > optionsHeight;
  }

  selectText(): void {
    setTimeout((): void => {
      this.input.nativeElement.focus();
      this.input.nativeElement.setSelectionRange(0, this.value?.toString()?.length);
      this.input.nativeElement.select();
    }, 1);
  }

  toggleSelection(value: string): void {
    if (!Array.isArray(this.selectedValues)) {
      this.selectedValues = [];
    }

    const index = this.selectedValues.indexOf(value);

    if (index !== -1) {
      this.selectedValues = this.selectedValues?.filter((v: string) => v !== value);
    } else {
      this.selectedValues = [...this.selectedValues, value];
    }

    this.selectedValues = this.selectedValues?.filter((item: string) => item !== "");
  }

  onCancelButtonClick(): void {
    this.params.data[this.colDefField] = this.value;
    this.toggleDropdown();
    this.selectText();
  }

  onConfirmButtonClick(): void {
    this.value = this.selectedValues.join();
    this.params.data[this.colDefField] = this.selectedValues;
    this.params.value = this.selectedValues;
    this.toggleDropdown();
    this.selectText();

  }
}
