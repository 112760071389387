import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {ModalController} from '@ionic/angular';
import {ColDef, GridApi, GridOptions, GridReadyEvent, SelectionChangedEvent} from 'ag-grid-community';

import {
  gridColumnTypes,
} from '../../../../shared-utilities/models-old/ngp-report-grid/defaults/column-types/column-types';
import {
  generalColDefDefaults,
} from '../../../../shared-utilities/models-old/ngp-report-grid/defaults/general/general-col-def-defaults';

import {IAddStockItems} from '../../../shared-models/auto-ordering/auto-order-order';
import {IPaginationData} from '../../../shared-models/pagination/pagination-data';
import {
  getPaginationResultsForAddStockItem,
  getTypesenseStockItemsSearchResults,
  setStockSearchAppliedFilter,
} from '../../../../features-as-modules/feature-auto-ordering/store/auto-ordering.actions';

import {StockItem} from '../../../../shared-utilities/models-old/datastructures';
import {ISearchableFields} from '../../../shared-models/type-sense/default-searchable-fields';
import {
  getOtherItemsTypesenseShelfTalkersSearchResults,
  getOtherPaginationResultsForShelfTalkerStockItem,
  getPaginationResultsForShelfTalkerStockItem,
  setOtherPageSizeForShelfTalkers,
  setPageSizeForShelfTalkers,
} from '../../../../features/shelf-talkers/store/shelf-talkers.actions';

@Component({
  selector: 'app-shared-stock-search',
  templateUrl: './shared-stock-search.component.html',
  styleUrls: ['./shared-stock-search.component.scss'],
})
export class SharedStockSearchComponent {

  @Output() readonly stockItemsSelected = new EventEmitter<StockItem[]>();
  @Output() readonly removeStockItems = new EventEmitter<StockItem[]>();
  @Output() readonly addExceptionToStockItems = new EventEmitter<StockItem[]>();
  @Output() readonly shelfTalkerSettings = new EventEmitter<StockItem[]>();
  @Output() readonly printOtherItems = new EventEmitter<StockItem[]>();
  @Output() readonly redrawRows = new EventEmitter<GridApi>();

  @Input() gridColDefs: ColDef[];
  @Input() gridOptions: GridOptions;
  @Input() title: string = 'Add Stock Items';
  @Input() supplier?: string = '';
  @Input() currentNewItems?: number = 0;
  @Input() exceptions?: boolean = false;
  @Input() shelfTalkers?: boolean = false;
  @Input() showDeleteAllPrinted?: boolean = false;
  @Input() isShelfTalkersModal?: boolean = false;
  @Input() isModal?: boolean = true;
  @Input() showPaginationBar?: boolean = false;

  stockItems: StockItem[] = [];
  paginationData: IPaginationData;
  searchString: string = '';
  gridApi: GridApi;
  currentTablePage: number;
  itemsPerPage: number;
  totalItems: number;
  currentSearch: string;
  totalPages: number;
  selectedStockItemsMap: Map<string, StockItem> = new Map<string, StockItem>();
  preview: boolean = false;
  applyFilter: boolean = false;
  pageSizeOptions: number[] = [10, 15, 20, 50, 100];
  protected readonly columnTypes = gridColumnTypes;
  protected readonly generalColDefDefaults = generalColDefDefaults;
  protected readonly isNaN = isNaN;

  constructor(
    private readonly store: Store,
    private modalController: ModalController,
  ) {

  }

  get selectedStockItemCount(): number {
    return this.selectedStockItemsMap.size;
  }

  get getPreviewButtonData(): string {
    if (!this.shelfTalkers) {
      if (this.preview) {
        return `View All Stock Items`;
      } else {
        return `View Selected Items`;
      }
    } else {
      return `Delete Selected Items`;
    }

  }

  get overlayTemplate(): string {
    let template = '';
    if (isNaN(this.totalItems)) {
      template = '<span class="ag-overlay-no-rows-center"><ion-spinner name="dots"></ion-spinner></span>';
    } else {
      template = '<span class="ag-overlay-no-rows-center">No Items To Display</span>';
    }
    return template;
  }

  @Input() set setStockData(typesenseStockData: IAddStockItems | StockItem[]) {
    if (!typesenseStockData) {
      return;
    }
    if (!Array.isArray(typesenseStockData)) {
      // typesenseStockData is IAddStockItems
      this.stockItems = typesenseStockData.stockItems ?? [];
      this.paginationData = typesenseStockData.paginationData;
      this.currentTablePage = typesenseStockData.paginationData?.currentPage ?? 1;
      if (this.gridApi) {
        this.gridApi.setGridOption('paginationPageSize', typesenseStockData.paginationData?.pageSize);
      }
      this.itemsPerPage = typesenseStockData.paginationData?.pageSize;
      this.totalItems = typesenseStockData.paginationData?.totalItems;
      this.currentSearch = typesenseStockData.paginationData?.searchedValue;
    } else {
      const dataArray = typesenseStockData;
      this.stockItems = dataArray;
      this.itemsPerPage = dataArray.length;
      this.totalItems = dataArray.length;
      this.totalPages = 1;
    }
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
  }


  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.redrawRows.emit(this.gridApi);
    this.gridApi.setGridOption('onRowDataUpdated', () => {
      this.restoreSelectedRows();
    });
    this.gridApi.addEventListener('selectionChanged', (event: SelectionChangedEvent) => {
      this.updateSelectedStockItems(event);
    });
  }

  updateSelectedStockItems(event?: SelectionChangedEvent): void {
    if (this.gridApi !== undefined) {
      const selectedNodes = this.gridApi.getSelectedNodes();
      const newlySelectedItems: StockItem[] = selectedNodes.map((node): StockItem => node.data as StockItem);

      const displayedItems: StockItem[] = [];
      this.gridApi.forEachNode((node): void => {
        if (node.data) {
          displayedItems.push(node.data as StockItem);
        }
      });

      const mergedSelection = new Map<string, StockItem>(this.selectedStockItemsMap);
      displayedItems.forEach((item: StockItem) => {
        if (mergedSelection.has(item.code) && event.source === "rowClicked") {
          mergedSelection.delete(item.code);
        }
      });

      for (const item of newlySelectedItems) {
        mergedSelection.set(item.code, item);
      }

      this.selectedStockItemsMap = mergedSelection;
    }

  }

  restoreSelectedRows(): void {
    if (!this.gridApi) {
      return;
    }
    this.gridApi.forEachNode((node) => {
      if (this.selectedStockItemsMap.has((node.data as StockItem)?.code)) {
        node.setSelected(true);
      }
    });
  }

  searchOnKeyUp(): void {
    this.gridApi.removeEventListener('selectionChanged', this.updateSelectedStockItems);
    if (!this.shelfTalkers && !this.isShelfTalkersModal) {
      this.store.dispatch(
        getTypesenseStockItemsSearchResults({idField: this.searchString}),
      );
    } else if (this.isShelfTalkersModal) {
      this.store.dispatch(
        getOtherItemsTypesenseShelfTalkersSearchResults({idField: this.searchString}),
      );
    } else {
      if (this.gridApi) {
        this.gridApi.setGridOption(
          "quickFilterText",
          this.searchString,
        );
      }
    }

    this.gridApi.addEventListener('selectionChanged', (event: SelectionChangedEvent) => {
      this.updateSelectedStockItems(event);
    });
  }

  nextPage(): void {
    if (this.currentTablePage < this.totalPages) {
      this.currentTablePage++;
      this.loadPage();
    }
  }

  onGoToFirstPageClick(): void {
    this.currentTablePage = 1;
    this.loadPage();
    this.gridApi.paginationGoToFirstPage();
  }

  onGoToLastPageClick(): void {
    this.currentTablePage = this.totalPages;
    this.loadPage();
    this.gridApi.paginationGoToLastPage();
  }

  prevPage(): void {
    if (this.currentTablePage > 1) {
      this.currentTablePage--;
      this.loadPage();
      this.gridApi.paginationGoToPreviousPage();
    }
  }

  onPageSizeChange(event: Event): void {
    const newPageSize = (event.target as HTMLSelectElement).value;
    this.itemsPerPage = +newPageSize;
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.currentTablePage = 1;
    this.gridApi.setGridOption('paginationPageSize', this.itemsPerPage);
    if (this.isShelfTalkersModal && !this.shelfTalkers) {
      this.store.dispatch(setOtherPageSizeForShelfTalkers({pageSize: this.itemsPerPage}));
    } else if (this.shelfTalkers) {
      this.store.dispatch(setPageSizeForShelfTalkers({pageSize: this.itemsPerPage}));
    }
    this.loadPage();
  }

  loadPage(): void {
    this.gridApi.removeEventListener('selectionChanged', this.updateSelectedStockItems);
    if (!this.shelfTalkers && !this.isShelfTalkersModal) {
      this.store.dispatch(getPaginationResultsForAddStockItem({pageNumber: this.currentTablePage}));
    } else if (this.isShelfTalkersModal) {
      this.store.dispatch(getOtherPaginationResultsForShelfTalkerStockItem({pageNumber: this.currentTablePage}));
    } else {
      this.store.dispatch(getPaginationResultsForShelfTalkerStockItem({pageNumber: this.currentTablePage}));
    }
    this.gridApi.addEventListener('selectionChanged', (event: SelectionChangedEvent) => {
      this.updateSelectedStockItems(event);
    });
  }

  isFirstPageDisabled(): boolean {
    return this.currentTablePage === 1;
  }

  isLastPageDisabled(): boolean {
    return this.currentTablePage === this.totalPages;
  }

  trackByFunction(index: number): number {
    return index;
  }

  onAddExceptionClick(): void {
    const selectedItems = Array.from(this.selectedStockItemsMap.values());
    this.addExceptionToStockItems.emit(selectedItems);
    this.selectedStockItemsMap = new Map();
    this.gridApi.deselectAll();
    this.gridApi.refreshCells();
  }

  onViewSelectedClick(): void {
    if (!this.shelfTalkers) {
      this.gridApi.removeEventListener('selectionChanged', this.updateSelectedStockItems);
      this.preview = !this.preview;
      if (this.preview) {
        const selectedArray = Array.from(this.selectedStockItemsMap.values());
        this.gridApi.setGridOption('rowData', selectedArray);
        this.restoreSelectedRows();
      } else {
        this.gridApi.setGridOption('rowData', this.stockItems);
        this.restoreSelectedRows();
      }
      this.gridApi.addEventListener('selectionChanged', (event: SelectionChangedEvent) => {
        this.updateSelectedStockItems(event);
      });
    } else {
      const selectedItems = Array.from(this.selectedStockItemsMap.values());
      this.removeStockItems.emit(selectedItems);
      this.selectedStockItemsMap = new Map();
      this.gridApi.deselectAll();
      this.redrawRows.emit(this.gridApi);
    }

  }

  onGetSelectedStockItemsClick(): void {
    const selectedItems = Array.from(this.selectedStockItemsMap.values());
    this.stockItemsSelected.emit(selectedItems);
    this.selectedStockItemsMap = new Map();
    this.gridApi.deselectAll();
  }

  close = (): void => {
    void this.modalController.dismiss();
  };


  onToggleSupplierOnlyClick(): void {
    this.applyFilter = !this.applyFilter;
    if (this.applyFilter) {
      const appliedFilter: { [key: string]: ISearchableFields } = {
        ['40']: {
          [this.supplier]: {
            name: 'supplierId',
            value: true,
          },
        },
      };
      this.store.dispatch(setStockSearchAppliedFilter({filter: appliedFilter}));
    } else {
      this.store.dispatch(setStockSearchAppliedFilter({filter: {}}));
    }

    this.searchOnKeyUp();
  }

  deleteAllPrinted(): void {
    this.removeStockItems.emit(this.stockItems);
    this.selectedStockItemsMap = new Map();
    this.gridApi.deselectAll();
  }

  onPrintAllVisibleClick(): void {
    const itemsToPrint: StockItem[] = [];
    const startIndex = ((this.gridApi.paginationGetCurrentPage() + 1) * this.gridApi.paginationGetPageSize()) - this.gridApi.paginationGetPageSize();
    const stopIndex = ((this.gridApi.paginationGetCurrentPage() + 1) * this.gridApi.paginationGetPageSize());
    for (let i = startIndex; i < stopIndex; i++) {
      if (this.stockItems[i]) {
        itemsToPrint.push(this.stockItems[i]);
      }
    }
    this.stockItemsSelected.emit(itemsToPrint);
    this.selectedStockItemsMap = new Map();
    this.gridApi.deselectAll();
  }

  getDisplayedCount(): number {
    if (this.gridApi) {
      const startIndex = ((this.gridApi.paginationGetCurrentPage() + 1) * this.gridApi.paginationGetPageSize()) - this.gridApi.paginationGetPageSize();
      const stopIndex = ((this.gridApi.paginationGetCurrentPage() + 1) * this.gridApi.paginationGetPageSize());

      if (this.totalItems > this.gridApi.paginationGetPageSize()) {

        if (stopIndex < this.totalItems) {
          return this.gridApi.paginationGetPageSize();

        }
        if (stopIndex > this.totalItems) {
          return startIndex + (this.totalItems - stopIndex);
        }
      } else return this.totalItems;
    }
    return 0;
  }

  onPrintOtherItemsClick(): void {
    this.printOtherItems.emit();
  }

  onShelfTalkerSettingsClick(): void {
    this.shelfTalkerSettings.emit();
  }
}
