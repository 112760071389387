import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {IInitialState} from '../../../shared-utilities/models-old/initial-state/initial-state';
import * as NotificationActions from '../../notifications/store/notification.actions';
import {INotification, INotificationPerStore, INotificationTotals} from '../models/notification';
import {groupNotificationsPerUserStore} from './notification.reducer.utils';
import {IAutoOrderOrder} from '../../../shared/shared-models/auto-ordering/auto-order-order';
import {getDeepCopyOfObject} from '../../../shared/shared-utils/object/object.utils';

export interface NotificationState extends IInitialState {
  isGetNotificationAutoOrderLoading: {
    [orderId: string]: boolean;
  };
  isGetNotificationStockUpdateLoading: {
    [orderId: string]: boolean;
  };
  isUpdateNotificationLoading: boolean;
  isUserNotificationsLoading: boolean;
  notificationAutoOrders: IAutoOrderOrder[];
  notificationStockUpdates: string;
  notificationTotals: INotificationTotals;
  notifications: {
    [storeId: string]: INotificationPerStore
  }
  selected: {
    autoOrder: IAutoOrderOrder,
    notification: INotification,
    stockUpdate: string
  }
}

export const initialNotificationsState: NotificationState = {
  errors: [],
  notificationAutoOrders: [],
  isGetNotificationAutoOrderLoading: {},
  isGetNotificationStockUpdateLoading: {},
  isUpdateNotificationLoading: false,
  isUserNotificationsLoading: false,
  notificationStockUpdates: '',
  notificationTotals: {
    total: 0,
    unread: 0,
    read: 0
  },
  notifications: {},
  selected: {
    autoOrder: undefined,
    notification: undefined,
    stockUpdate: undefined,
  }
};

const createNotificationReducer: ActionReducer<NotificationState> = createReducer(
  getDeepCopyOfObject(initialNotificationsState),
  //#region GET NOTIFICATIONS PER STORE
  on(NotificationActions.getAllNotificationsForUserStores, (state: NotificationState) => {
    return {
      ...state,
      isUserNotificationsLoading: true,
    }
  }),
  on(NotificationActions.getAllNotificationsForUserStoresSuccess, (state: NotificationState, {
    storeIds,
    notifications
  }) => {
    const notificationsGrouped = groupNotificationsPerUserStore(storeIds, notifications);
    return {
      ...state,
      isUserNotificationsLoading: false,
      notificationTotals: notificationsGrouped.totals,
      notifications: notificationsGrouped.notificationsGrouped
    }
  }),
  on(NotificationActions.getAllNotificationsForUserStoresFailure, (state: NotificationState, {error}) => {
    return {
      ...state,
      isUserNotificationsLoading: false,
      errors: [...state.errors, error],
    }
  }),
  //#endregion
  //#region UPDATE NOTIFICATION
  on(NotificationActions.updateNotification, (state: NotificationState) => {
    return {
      ...state,
      isUpdateNotificationLoading: true,
    }
  }),
  on(NotificationActions.updateNotificationSuccess, (state: NotificationState) => {
    return {
      ...state,
      isUpdateNotificationLoading: false,
    }
  }),
  on(NotificationActions.updateNotificationFailure, (state: NotificationState, {error}) => {
    return {
      ...state,
      isUpdateNotificationLoading: false,
      errors: [...state.errors, error],
    }
  }),
  //#endregion
  //#region GET NOTIFICATION AUTO ORDER
  on(NotificationActions.getNotificationAutoOrder, (state: NotificationState, {notification}) => {
    return {
      ...state,
      isGetNotificationAutoOrderLoading: {
        ...state.isGetNotificationAutoOrderLoading,
        [notification.event]: true
      },
    }
  }),
  on(NotificationActions.getNotificationAutoOrderSuccess, (state: NotificationState, {autoOrder, notification}) => {
    const autoOrders = getDeepCopyOfObject(state.notificationAutoOrders);
    const index = autoOrders.findIndex((order: IAutoOrderOrder) => order.orderId === autoOrder.orderId)
    if (index > -1) {
      autoOrders[index] = autoOrder;
    } else {
      autoOrders.push(autoOrder);
    }
    return {
      ...state,
      notificationAutoOrders: autoOrders,
      isGetNotificationAutoOrderLoading: {
        ...state.isGetNotificationAutoOrderLoading,
        [notification.event]: false
      },
      selected: {
        ...state.selected,
        autoOrder: autoOrder,
        notification
      },
    }
  }),
  on(NotificationActions.getNotificationAutoOrderFailure, (state: NotificationState, {error, notification}) => {
    return {
      ...state,
      isGetNotificationAutoOrderLoading: {
        ...state.isGetNotificationAutoOrderLoading,
        [notification.event]: false
      },
      selected: {
        ...state.selected,
        notification,
      },
      errors: [...state.errors, error],
    }
  }),
  //#endregion
  //#region GET NOTIFICATION STOCK UPDATE
  on(NotificationActions.getNotificationStockUpdate, (state: NotificationState, {notification}) => {
    return {
      ...state,
      isGetNotificationStockUpdateLoading: {
        ...state.isGetNotificationAutoOrderLoading,
        [notification.event]: true
      },
    }
  }),
  on(NotificationActions.getNotificationStockUpdateSuccess, (state: NotificationState, {
    notification,
  }) => {
    return {
      ...state,
      isGetNotificationStockUpdateLoading: {
        ...state.isGetNotificationAutoOrderLoading,
        [notification.event]: false
      },
    }
  }),
  on(NotificationActions.getNotificationStockUpdateFailure, (state: NotificationState, {
    notification,
    error,
    stockUpdate
  }) => {
    return {
      ...state,
      isGetNotificationStockUpdateLoading: {
        ...state.isGetNotificationAutoOrderLoading,
        [notification.event]: false
      },
      errors: [...state.errors, error],
    }
  }),
  //#endregion
  //#region CLEAR NOTIFICATION
  on(NotificationActions.clearNotificationSection, (state: NotificationState, {clearErrors, clearNotifications}) => {
    return {
      ...state,
      errors: clearErrors ? [] : state.errors,
      notificationAutoOrders: [],
      isGetNotificationAutoOrderLoading: {},
      isGetNotificationStockUpdateLoading: {},
      isUpdateNotificationLoading: false,
      isUserNotificationsLoading: false,
      notificationStockUpdates: '',
      notificationTotals: clearNotifications ? {
        total: 0,
        unread: 0,
        read: 0
      } : state.notificationTotals,
      notifications: clearNotifications ? undefined : state.notifications,
      selected: {
        autoOrder: undefined,
        notification: undefined,
        stockUpdate: undefined,
      }
    }
  }),
  //#endregion

);

export const notificationReducer = (state: NotificationState, action: Action): NotificationState => createNotificationReducer(state, action);
