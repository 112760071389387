import {Injectable} from '@angular/core';
import {AngularFirestore, QueryDocumentSnapshot} from '@angular/fire/compat/firestore';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(
    protected angularFirestore: AngularFirestore,
  ) {
  }

  //#region COUNT DOCUMENTS
  /**
   * Retrieves the count of documents where a field matches any value in the provided array, as an observable.
   * @template DataType - The type of the document data.
   * @param path - The Firestore collection path.
   * @param fieldName - The field to filter by.
   * @param values - The array of values to match.
   * @returns An observable that emits the count of matching documents.
   */
  getCountDocumentsWhereFieldInValuesAsObservable<DataType>(
    path: string,
    fieldName: string,
    values: string[]
  ): Observable<number> {
    const collectionRef = this.angularFirestore.collection<DataType>(path, ref =>
      ref.where(fieldName, 'in', values)
    );

    return from(
      collectionRef.get().toPromise().then(querySnapshot => {
        return querySnapshot.size;
      })
    );
  }

  /**
   * Retrieves the count of documents where a field matches any value in the provided array,
   * and updates the count reactively as an observable.
   * @template DataType - The type of the document data.
   * @param path - The Firestore collection path.
   * @param fieldName - The field to filter by.
   * @param values - The array of values to match.
   * @returns An observable that emits the count of matching documents.
   */
  getCountDocumentsWhereFieldInValuesAsObservableValueChanges<DataType>(
    path: string,
    fieldName: string,
    values: string[]
  ): Observable<number> {
    const collectionRef = this.angularFirestore.collection<DataType>(path, ref =>
      ref.where(fieldName, 'in', values)
    );
    return collectionRef.snapshotChanges().pipe(
      map(actions => actions.length)
    );
  }

  /**
   * Retrieves the count of documents where a field matches any value in the provided array, as a promise.
   * @template DataType - The type of the document data.
   * @param path - The Firestore collection path.
   * @param fieldName - The field to filter by.
   * @param values - The array of values to match.
   * @returns A promise that resolves to the count of matching documents.
   */
  async countDocumentsWhereFieldInValuesAsPromise<DataType>(
    path: string,
    fieldName: string,
    values: string[]
  ): Promise<number> {
    const collectionRef = this.angularFirestore.collection<DataType>(path, ref =>
      ref.where(fieldName, 'in', values)
    );

    const querySnapshot = await collectionRef.get().toPromise();
    return querySnapshot.size;
  }

  //#endregion

  //#region GET COLLECTION
  /**
   * Retrieves all documents from a Firestore collection as an observable.
   * @template DataType - The type of the document data.
   * @param path - The Firestore collection path.
   * @returns An observable that emits an array of documents.
   */
  getCollectionAsObservable<DataType>(path: string): Observable<DataType[]> {
    const collectionRef = this.angularFirestore.collection<DataType>(path);
    return from(
      collectionRef
        .get()
        .toPromise()
        .then(querySnapshot => {
          return querySnapshot.docs.map((doc: QueryDocumentSnapshot<DataType>) => doc.data());
        })
    );
  }

  /**
   * Retrieves all documents from a Firestore collection as a promise.
   * @template DataType - The type of the document data.
   * @param path - The Firestore collection path.
   * @returns A promise that resolves to an array of documents.
   */
  async getCollectionAsPromise<DataType>(path: string): Promise<DataType[]> {
    const collectionRef = this.angularFirestore.collection<DataType>(path);
    return collectionRef
      .get()
      .toPromise()
      .then(querySnapshot => {
        return querySnapshot.docs.map((doc: QueryDocumentSnapshot<DataType>) => doc.data());
      });
  }

  /**
   * Retrieves documents where a field matches any value in the provided array, as an observable.
   * @template DataType - The type of the document data.
   * @param path - The Firestore collection path.
   * @param fieldName - The field to filter by.
   * @param values - The array of values to match.
   * @returns An observable that emits an array of matching documents.
   */
  getCollectionByFieldInAsValuesAsObservable<DataType>(
    path: string,
    fieldName: string,
    values: string[]
  ): Observable<DataType[]> {
    const collectionRef = this.angularFirestore.collection<DataType>(path, ref =>
      ref.where(fieldName, 'in', values)
    );

    return from(collectionRef.get().toPromise()).pipe(
      map(querySnapshot => querySnapshot.docs.map((doc: QueryDocumentSnapshot<DataType>) => doc.data()))
    );
  }

  /**
   * Retrieves documents where a field matches any value in the provided array,
   * and updates reactively as an observable.
   * @template DataType - The type of the document data.
   * @param path - The Firestore collection path.
   * @param fieldName - The field to filter by.
   * @param values - The array of values to match.
   * @returns An observable that emits an array of matching documents.
   */
  getCollectionByFieldInValuesAsObservableValueChanges<DataType>(
    path: string,
    fieldName: string,
    values: string[]
  ): Observable<DataType[]> {
    return this.angularFirestore.collection<DataType>(path, ref =>
      ref.where(fieldName, 'in', values)
    ).valueChanges();
  }

  /**
   * Retrieves documents where a field matches any value in the provided array, as a promise.
   * @template DataType - The type of the document data.
   * @param path - The Firestore collection path.
   * @param fieldName - The field to filter by.
   * @param values - The array of values to match.
   * @returns A promise that resolves to an array of matching documents.
   */
  async getCollectionByFieldInValuesAsPromise<DataType>(
    path: string,
    fieldName: string,
    values: string[]
  ): Promise<DataType[]> {
    const collectionRef = this.angularFirestore.collection<DataType>(path, ref =>
      ref.where(fieldName, 'in', values)
    );

    const querySnapshot = await collectionRef.get().toPromise();
    return querySnapshot.docs.map((doc: QueryDocumentSnapshot<DataType>) => doc.data());
  }

  //#endregion

  //#region GET DOCUMENT
  /**
   * Retrieves a Firestore document as an observable.
   * @template DataType - The type of the document data.
   * @param path - The Firestore document path.
   * @returns An observable that emits the document data or undefined if not found.
   */
  getDocumentAsObservable<DataType>(path: string): Observable<DataType | undefined> {
    const docRef = this.angularFirestore.doc<DataType>(path);
    return from(
      docRef
        .get()
        .toPromise()
        .then(docSnapshot => {
          if (docSnapshot?.exists) {
            return docSnapshot.data();
          } else {
            return undefined;
          }
        })
    );
  }

  /**
   * Retrieves a Firestore document as a promise.
   * @template DataType - The type of the document data.
   * @param path - The Firestore document path.
   * @returns A promise that resolves to the document data or undefined if not found.
   */
  async getDocumentAsPromise<DataType>(
    path: string,
  ): Promise<DataType> {
    try {
      const docRef = this.angularFirestore.doc<DataType>(path);
      const docSnapshot = await docRef.get().toPromise();
      if (docSnapshot?.exists) {
        return docSnapshot.data();
      } else {
        return undefined;
      }
    } catch (error) {
      console.error(error);
    }
  }

  //#endregion

  //#region UPDATE DOCUMENT
  /**
   * Updates a document in Firestore and returns an Observable that emits once the update is complete.
   *
   * @template DataType - The type of data stored in the document.
   * @param {string} path - The Firestore document path.
   * @param {Partial<DataType>} data - The partial data to update the document with.
   * @returns {Observable<void>} - An observable that emits when the update is successful.
   */
  updateDocumentAsObservable<DataType>(path: string, data: Partial<DataType>): Observable<void> {
    const docRef = this.angularFirestore.doc<DataType>(path);
    return from(docRef.update(data));
  }

  /**
   * Updates a document in Firestore and returns a Promise that resolves once the update is complete.
   *
   * @template DataType - The type of data stored in the document.
   * @param {string} path - The Firestore document path.
   * @param {Partial<DataType>} data - The partial data to update the document with.
   * @returns {Promise<void>} - A promise that resolves when the update is successful.
   */
  async updateDocumentAsPromise<DataType>(path: string, data: Partial<DataType>): Promise<void> {
    const docRef = this.angularFirestore.doc<DataType>(path);
    try {
      await docRef.update(data);
    } catch (error) {
      throw error;
    }
  }

  //#endregion

}
