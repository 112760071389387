import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {IDepartment, ISubDepartment} from '../../../../shared/shared-models/stock/departments';
import {
  selectDepAndSubDepForUserSelectedStore,
  selectStoreSettingsForSelectedUserStore,
} from '../../../../features/stock-manager/store/stock-manager.selectors';
import {NGPReport} from '../../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {StockItem} from '../../../../shared-utilities/models-old/datastructures';
import {ISharedStoreSettings} from '../../../../shared/shared-models/firebase/shared-store-settings';
import {getDeepCopyOfObject} from '../../../../shared/shared-utils/object/object.utils';
import {selectCurrentPage} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {setEditedItem} from '../../../../features/stock-manager/store/stock-manager.actions';

@Component({
  selector: 'app-cell-renderer-departments',
  templateUrl: './cell-renderer-departments.component.html',
  styleUrls: ['./cell-renderer-departments.component.scss'],
})
export class CellRendererDepartmentsComponent implements ICellRendererAngularComp, OnInit {

  value: string | number;
  params: ICellRendererParams;
  width: string;
  type: string;
  departments: IDepartment[];
  currentPage: string;
  storeSettings: ISharedStoreSettings;

  currentPage$: Observable<string>;
  departmentsAndSubDepartmentsForCurrentStore$: Observable<IDepartment[]>;
  storeSettings$: Observable<ISharedStoreSettings>;
  subDepartments: ISubDepartment[];

  constructor(
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef,
  ) {
  }

  agInit(params: ICellRendererParams<NGPReport | StockItem, string | number>): void {
    this.params = {...params};
    this.params.data = getDeepCopyOfObject({...params.data});
    this.value = {...params}.value;
    this.type = params.colDef.field;
  }

  ngOnInit(): void {
    this.departmentsAndSubDepartmentsForCurrentStore$ = this.store.select(selectDepAndSubDepForUserSelectedStore);
    this.store.select(selectCurrentPage).subscribe((page: string) => {
      this.currentPage = page;
    });
    this.storeSettings$ = this.store.select(selectStoreSettingsForSelectedUserStore);
    this.departmentsAndSubDepartmentsForCurrentStore$.subscribe((departments: IDepartment[]): void => {
      this.departments = departments;
      if (departments) {
        this.subDepartments = departments.flatMap((dep: IDepartment) =>
          Object.values(dep.subDepartments),
        );
      }
      this.cdr.markForCheck();
    });
    this.storeSettings$.subscribe((storeSettings: ISharedStoreSettings) => {
      this.storeSettings = storeSettings;
    });
    this.cdr.markForCheck();

  }

  checkSubDepCode(): boolean {
    if (this.params.colDef.field === 'subDep' && this.storeSettings?.linkDepartmentsEnabled) {
      if (this.params.data.dep !== '0' && this.departments) {
        const department = this.departments?.find((dep: IDepartment): boolean => dep.department === this.params.data.dep);
        const subDepartment = department?.subDepartments;
        if (subDepartment) {
          const filtered = subDepartment.filter((subDep: ISubDepartment): boolean => subDep.subDepartment === this.value);
          return filtered.length > 0;
        }
      }
      return false;
    } else {
      return true;
    }
  }

  refresh(): boolean {
    return false;
  }

  onUndoButtonClick(): void {
    this.value = this.params.data.originalValue[getDeepCopyOfObject(this.params.colDef.field)].value;
    this.params.value = this.params.data.originalValue[getDeepCopyOfObject(this.params.colDef.field)].value;
    this.params.data[getDeepCopyOfObject(this.params.colDef.field)] = this.params.data.originalValue[getDeepCopyOfObject(this.params.colDef.field)].value;
    this.checkSubDepCode();
    if (this.currentPage === 'stock-manager') {
      if (this.storeSettings?.linkDepartmentsEnabled) {
        this.params.data.isError = GridUtils.checkSubDepCode(this.params, this.departments);
      }
      GridUtils.removeIsEditingFlagAndDispatch(this.params.data as StockItem, getDeepCopyOfObject(this.params.colDef.field), this.store, this.currentPage);
      GridUtils.updateIsEditedAndForceRefresh(this.params);
      this.store.dispatch(setEditedItem({stockItem: this.params.data}));
    } else {
      if (this.storeSettings?.linkDepartmentsEnabled) {
        GridUtils.updateErrorAndForceRefresh(this.params, this.departments);
      }
      GridUtils.removeIsEditingFlagAndDispatch(this.params.data as NGPReport, getDeepCopyOfObject(this.params.colDef.field), this.store, this.currentPage);
      GridUtils.updateIsEditedAndForceRefresh(this.params);
    }

  }

  getDepartmentName(depCode: string): string {
    const department = this.departments?.find((dep: IDepartment) => dep?.department === depCode);
    return department?.name ?? depCode;
  }

  getSubDepartmentName(depCode: string): string {
    const subDepartments = this.subDepartments?.find((sub: ISubDepartment) => sub?.subDepartment === this.value);
    return subDepartments.name;
  }

}
