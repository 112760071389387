import {GridOptions, RowClassParams} from 'ag-grid-community';
import {GridUtils} from '../../../shared-utilities/utils-old/grid-utils-old/grid-utils';

/**
 * The Grid Options for the Stock Manger
 */
export const stockManagerGridOptions: GridOptions = {
  debug: false,
  editType: null,
  enterNavigatesVertically: true,
  enterNavigatesVerticallyAfterEdit: true,
  suppressAnimationFrame: true,
  enableCellTextSelection: true,
  multiSortKey: 'ctrl',
  rowBuffer: 100,
  pagination: true,
  rowHeight: 35,
  suppressScrollOnNewData: true,
  paginationAutoPageSize: false,
  paginationPageSize: 20,
  paginationPageSizeSelector: [10, 15, 20, 50, 100],
  suppressPaginationPanel: true,
  rowDragManaged: false,
  rowDragEntireRow: false,
  rowDragMultiRow: false,
  rowSelection: 'multiple',
  singleClickEdit: true,
  stopEditingWhenCellsLoseFocus: true,
  suppressRowClickSelection: true,
  undoRedoCellEditing: false,
  undoRedoCellEditingLimit: 25,
  getRowStyle: (params: RowClassParams): { [key: string]: string } => ({
    backgroundColor: `${params.data.lineColourValue}`,
    color: `${params.data.lineColourValue ? GridUtils.getContrastColor(params.data.lineColourValue as string) : 'initial'}`,
    fill: `${params.data.lineColourValue ? 'black' : 'var(--ion-color-white-contrast)'}`,
  }),
};

export const stockMangerVisibleFieldsDefault: string[] = [
  "icons",
  "code",
  "desc",
  "nominalGP",
  "onHand",
  "sellPriIncl1",
  "unitsYear"];
