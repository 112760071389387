import {createAction, props} from '@ngrx/store';
import {IUser} from '../../../shared/shared-models/user-access/user';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {StoreObject} from '../../../shared-utilities/models-old/store/store-object';
import {IStore} from '../../../shared/shared-models/store/store';
import {INavigationPage} from '../../../shared-utilities/models-old/page/page';

import {IUserAccess} from '../../../shared/shared-models/user-access/user-access';
import {INotification} from '../../../shared-utilities/models-old/datastructures';
import {IDataTimeStamps} from '../../../features/core/models/data-time-stamps';

// ====================================================================================================
// Get User
// ====================================================================================================
export const getUser = createAction(
  '[Core][User] Get User',
);

export const setPagesFinishedLoading = createAction(
  '[Core][User] Set Pages Finished Loading',
);

export const getUserSuccess = createAction(
  '[Core][User] Get User Success',
  props<{ user: IUser }>(),
);
export const userLoginSuccess = createAction(
  '[Core][User] User Login Success',
  props<{ isUserLoggedIn: boolean }>(),
);

export const getUserFailure = createAction(
  '[Core][User] Get User Failure',
  props<{ error: IError }>(),
);

export const setIsUserLoading = createAction(
  '[Core][User] Set Is User Loading',
  props<{ isLoading: boolean }>(),
);

// ====================================================================================================
// Get User Access
// ====================================================================================================
export const getUserAccess = createAction(
  '[Core][User] Get User Access',
);

export const getUserAccessSuccess = createAction(
  '[Core][User] Get User Access Success',
  props<{ userAccess: IUserAccess }>(),
);

export const getUserAccessFailure = createAction(
  '[Core][User] Get User Access Failure',
  props<{ error: IError }>(),
);

export const setIsUserAccessLoading = createAction(
  '[Core][User] Set Is User Access Loading',
  props<{ isLoading: boolean }>(),
);

// ====================================================================================================
// Set ITA (Is Techo Admin)
// ====================================================================================================
export const setITA = createAction(
  '[Core][Admin] Set ITA',
  props<{ ita: boolean }>(),
);

// ====================================================================================================
// Get User Stores
// ====================================================================================================
export const getUserStores = createAction(
  '[Core][User Stores] Get User Stores',
  props<{ pageStoreDocument: string }>(),
);

export const getUserStoresSuccess = createAction(
  '[Core][User Stores] Get User Stores Success',
  props<{ storeObject: StoreObject }>(),
);

export const getUserStoresFailure = createAction(
  '[Core][User Stores] Get User Stores Failure',
  props<{ error: IError }>(),
);

export const setIsUserStoresLoading = createAction(
  '[Core][User Stores] Set Is User Stores Loading',
  props<{ isLoading: boolean }>(),
);

// ====================================================================================================
// Set User Selected Store
// ====================================================================================================
export const setUserSelectedStore = createAction(
  '[Core][User Stores] Set User Selected Store',
  props<{ selectedStore: IStore }>(),
);

export const setUserSelectedStoreAndGetSettings = createAction(
  '[Core][User Stores] Set User Selected Store And Get Settings',
  props<{ selectedStore: IStore }>(),
)

// ====================================================================================================
// Set User Last Visited Page
// ====================================================================================================
export const setCurrentPageAndTab = createAction(
  '[Core][Navigation] Set User Last Visited Page',
  props<{ currentSelectedPage: INavigationPage }>(),
);

// ====================================================================================================
// Get Image URL
// ====================================================================================================
export const getImageURL = createAction(
  '[Core][Image] Get Image URL',
  props<{ path: string, image: string }>(),
);

export const getImageURLSuccess = createAction(
  '[Core][Image] Get Image URL Success',
  props<{ url: string, image: string }>(),
);

export const getImageURLFailure = createAction(
  '[Core][Image] Get Image URL Failure',
  props<{ error: IError, image: string }>(),
);

// ====================================================================================================
// User Messages
// ====================================================================================================
export const getUserMessages = createAction(
  '[Core][Messages] Get User Messages',
);

export const getUserMessagesSuccess = createAction(
  '[Core][Messages] Get User Messages Success',
  props<{ messages: INotification[] }>(),
);

export const getUserMessagesFailure = createAction(
  '[Core][Messages] Get User Messages Failure',
  props<{ error: IError }>(),
);

export const setIsUserMessageCountLoading = createAction(
  '[Core][Messages] Set Is User Message Count Loading',
  props<{ isLoading: boolean }>(),
);


export const getDataTimeStamps = createAction(
  '[Core Shared][Time Stamps] Get Data Time Stamps',
);

export const getDataTimeStampsSuccess = createAction(
  '[Core Shared][Time Stamps] Get Data Time Stamps Success',
  props<{ store: IStore, dataTimeStamps: IDataTimeStamps }>(),
);

export const getDataTimeStampsFailure = createAction(
  '[Core Shared][Time Stamps] Get Data Time Stamps Failure',
  props<{ error: IError }>(),
);


