import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {Store} from '@ngrx/store';
import {onHoldDecode} from '../../../../shared-utilities/models-old/datastructures';

@Component({
  selector: 'app-cell-renderer-supplier-search-code',
  templateUrl: './cell-renderer-supplier-search-code.component.html',
  styleUrls: ['./cell-renderer-supplier-search-code.component.scss'],
})
export class CellRendererSupplierSearchCodeComponent implements ICellRendererAngularComp {

  params: ICellRendererParams;
  width: string;
  value: boolean;
  type: string;
  departments: {} = {};
  protected readonly onHoldDecode = onHoldDecode;

  constructor(
    private readonly store: Store,
  ) {
  }

  agInit(params: ICellRendererParams): void {
    this.params = {...params};
    this.params.data = {...params.data};
    this.value = params.value;
    this.type = params.colDef.field;
  }

  getOnHoldCodeArray(code: number): string {
    return onHoldDecode(code, false, true).join(', ');
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
