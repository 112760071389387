import {Component} from '@angular/core';
import {IHeaderAngularComp} from 'ag-grid-angular';
import {IHeaderParams} from 'ag-grid-community';
import {Store} from '@ngrx/store';
import {sItemIntToKey, sItemKeyToInt} from '../../../../shared-utilities/models-old/datastructures';
import {setShelfTalkersSortMethod} from '../../../../features/shelf-talkers/store/shelf-talkers.actions';
import {
  selectShelfTalkersSortMethodForStoreId,
} from '../../../../features/shelf-talkers/store/shelf-talkers.selectors';

@Component({
  selector: 'app-shared-typesense-grid-header',
  templateUrl: './shared-typesense-grid-header.component.html',
  styleUrls: ['./shared-typesense-grid-header.component.scss'],
})
export class SharedTypesenseGridHeaderComponent implements IHeaderAngularComp {
  params: IHeaderParams;
  sort: string = '';

  constructor(
    private readonly store: Store,
  ) {
  }


  agInit(params: IHeaderParams): void {
    this.params = params;
    this.store.select(selectShelfTalkersSortMethodForStoreId).subscribe((sort: string) => {
      const sortType: string[] = sort.split(':');
      if (params.column.getColId() === sItemIntToKey[sortType[0]]) {
        this.sort = sortType[1];
      }
    });
  }

  refresh(params: IHeaderParams): boolean {
    return false;
  }

  onSortRequested(): void {
    if (this.sort === '') {
      this.sort = 'asc';
    } else if (this.sort === 'asc') {
      this.sort = 'desc';
    } else {
      this.sort = '';
    }
    this.store.dispatch(setShelfTalkersSortMethod({sortMethod: this.sort !== '' ? `${sItemKeyToInt[this.params.column.getColId()]}:${this.sort}` : ''}));
    this.params.api.refreshHeader();
  }

}
