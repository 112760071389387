import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest} from 'rxjs';
import {selectTags, selectUserStoreSettingsIsSubmitting} from 'src/app/features/settings-old/store/settings.selectors';

import type {ITag} from '../../models';

/**
 * TODO TECHNICAL DEBT export class StoreSettingsComponent implements OnInit {}
 * We need to unsubscribe from all subscriptions.
 */
@Component({
  selector: 'app-store-settings',
  templateUrl: './store-settings.component.html',
  styleUrls: [
    './store-settings.component.scss',
    '../../styles/shared-view-styles.scss'
  ]
})
export class StoreSettingsComponent implements OnInit {

  protected allTags: ITag[];
  protected isSubmittingOnHandForm: boolean;

  constructor(
    private readonly store: Store,
  ) {
  }

  ngOnInit(): void {
    combineLatest([
      this.store.select(selectTags),
      this.store.select(selectUserStoreSettingsIsSubmitting),
    ]).subscribe(([tags, isSubmittingOnHandForm]) => {
      this.allTags = tags;
      this.isSubmittingOnHandForm = isSubmittingOnHandForm;
    });
  }
}
