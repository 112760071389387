import {Component, OnInit} from '@angular/core';
import {SettingPriceThresholdsFormService} from './setting-price-thresholds-form.service';

@Component({
  selector: 'app-setting-price-thresholds',
  templateUrl: './setting-price-thresholds.component.html',
  styleUrls: [
    './setting-price-thresholds.component.scss',
    '../../styles/shared-component-styles.scss'
  ],
})
export class SettingPriceThresholdsComponent implements OnInit {
  protected maxIncPriceVal: number;
  protected maxDecPriceVal: number;

  constructor(
    protected readonly formService: SettingPriceThresholdsFormService
  ) {
  }

  ngOnInit(): void {
    this.formService.initialize().subscribe((state) => {
      this.maxIncPriceVal = state.percentage;
      this.maxDecPriceVal = state.negativePercentage;
    });
  }
}
