export function fuzzyScore(target: string, query: string): number {
  if (!query) return 1;
  if (!target) return 0;

  let score = 0;
  let targetIndex = 0;
  let queryIndex = 0;
  let lastMatch = -1;
  let consecutiveBonus = 1;

  const tLen = target.length;
  const qLen = query.length;

  while (queryIndex < qLen && targetIndex < tLen) {
    if (target[targetIndex].toLowerCase() === query[queryIndex].toLowerCase()) {
      score += 1;
      if (lastMatch === targetIndex - 1) {
        score += consecutiveBonus;
        consecutiveBonus += 1;
      } else {
        consecutiveBonus = 1;
      }
      lastMatch = targetIndex;
      queryIndex++;
    }
    targetIndex++;
  }
  if (queryIndex < qLen) {
    return 0;
  }
  const maxScore = qLen + (qLen * (qLen - 1)) / 2;
  return score / maxScore;
}

export function fuzzyFilterArrayOfObjects<DataType>(
  data: DataType[],
  query: string,
  keys: string[],
  threshold: number = 0.7
): DataType[] {
  return data.filter((item: DataType) =>
    keys.some((key: string) => {
      const fieldValue = String((item)[key] ?? '');
      const score = fuzzyScore(fieldValue, query);
      return score >= threshold;
    })
  );
}

export function fuzzyFilterObjectOfObjects<DataType>(
  data: { [key: string]: DataType },
  query: string,
  keys: string[],
  threshold: number = 0.7
): { [key: string]: DataType } {
  const result: { [key: string]: DataType } = {};
  for (const [key, value] of Object.entries(data)) {
    const passesThreshold = keys.some((filterKey: string) => {
      const fieldValue = String(value[filterKey] ?? '');
      const score = fuzzyScore(fieldValue, query);
      return score >= threshold;
    });

    if (passesThreshold) {
      result[key] = value;
    }
  }

  return result;
}
