<div id="notification-container">

  <div class="header">Notification</div>

  <div class="content main-header">
    <div class="content-container">Event</div>
    <div class="content-container size-limit-200">Store</div>
    <div class="content-container size-limit-150">User</div>
    <div class="content-container size-limit-150">Status</div>
  </div>

  <div class="content">
    <div class="content-container">{{ getNotification.event }}</div>
    <div class="content-container size-limit-200">{{ selectedStore.name }}</div>
    <div class="content-container size-limit-150">{{ getUserDetail }}</div>
    <div class="content-container size-limit-150">
      <div [ngClass]="getNotification.success ? 'dot-success' : 'dot-fail'">
        {{ getNotification.success ? 'SUCCESS' : 'FAILED' }}
      </div>
    </div>
  </div>


  <div class="mobile-content">

    <div class="mobile-content-container">
      <div class="main-header">Event</div>
      <span></span>
      <div>{{ getNotification.event }}</div>
    </div>

    <div class="mobile-content-container">
      <div class="main-header">Store</div>
      <span></span>
      <div>{{ selectedStore.name }}</div>
    </div>

    <div class="mobile-content-container">
      <div class="main-header">User</div>
      <span></span>
      <div>{{ getUserDetail }}</div>
    </div>

    <div class="mobile-content-container">
      <div class="main-header">Status</div>
      <span></span>
      <div>
        <div [ngClass]="getNotification.success ? 'dot-success' : 'dot-fail'">
          {{ getNotification.success ? 'SUCCESS' : 'FAILED' }}
        </div>
      </div>
    </div>

  </div>

</div>
