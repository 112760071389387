import {DisabledRules, StockItem} from '../../../shared-utilities/models-old/datastructures';
import {StockFunctions} from '../../../shared-utilities/functions-old/stock-functions';
import {firebaseTimeStampToDate} from "../firestore/firestore.utils";
import {Timestamp} from "@angular/fire/firestore";

/**
 * ### onSortArrayByProperty
 * Sorts an array of objects by a specified property, with additional functionality for sorting disabled items.
 * The function allows sorting based on various property types, including `NaN`, `null`, `boolean`, `number`, `string`, and more.
 * It also provides an option to sort items based on their "disabled" status, positioning disabled items at the start or end of the sorted list.
 *
 * @template ObjectType - The type of the objects in the array to be sorted.
 * @template SortPropertyType - The type of the property by which the array will be sorted. This can be a string or a custom type.
 *
 * @param {1 | -1} sortDirection - The direction to sort the array. `1` for ascending and `-1` for descending order.
 * @param {boolean} sortDisabledItems - Whether to sort disabled items (defaults to false).
 * @param {SortDisabledItemsLocation} sortDisabledItemsLocation - Where to place the disabled items in the sorted array. Can be either `'start'` or `'end'`.
 * @param {ObjectType[]} sortList - The array of objects to be sorted.
 * @param {keyof ObjectType} sortProperty - The property of the object by which to sort the array.
 * @param {SortPropertyType | CustomSortPropertyType} sortPropertyType - The type of the property being sorted. Can be a primitive type (e.g., 'number', 'string') or a custom type.
 * @param {boolean} [isStockItem=false] - Optional flag to specify if the object is a `StockItem`. This affects how disabled items are handled (default is `false`).
 * @param {DisabledRules} [stockDisableRules] - Optional rules for determining if a stock item is disabled.
 *
 * @returns {ObjectType[]} The sorted array of objects.
 *
 */
export function sortArrayByProperty<ObjectType, SortPropertyType>(
  sortDirection: 1 | -1,
  sortDisabledItems: boolean,
  sortDisabledItemsLocation: SortDisabledItemsLocation,
  sortList: ObjectType[],
  sortProperty: keyof ObjectType,
  sortPropertyType: SortPropertyType | CustomSortPropertyType,
  //isStockItem?: boolean - This will be removed later on because we need to make the StockFunctions.isDisabled generic aswell
  isStockItem?: boolean,
  stockDisableRules?: DisabledRules
): ObjectType[] {
  if (!sortList.length) return;

  switch (sortPropertyType) {
    //#region Non-Sortable Field Types
    /**
     * These are the non-sortable field types.
     */
    case 'NaN':
    case 'null':
    case 'object':
    case 'symbol':
    case 'undefined':
      // These are non-sortable no action was taken
      break;
    //#endregion Non-Sortable Field Types

    //#region Primitive Sortable Field Types
    case 'bigInt':
      sortList.sort((a: ObjectType, b: ObjectType) => {
        const valueA = BigInt(a[sortProperty] as string);
        const valueB = BigInt(b[sortProperty] as string);
        return (valueA > valueB ? 1 : valueA < valueB ? -1 : 0) * sortDirection;
      });
      break;

    case 'boolean':
      sortList.sort((a: ObjectType, b: ObjectType) => {
        const valueA = a[sortProperty] as boolean;
        const valueB = b[sortProperty] as boolean;
        return (valueA === valueB ? 0 : valueA ? 1 : -1) * sortDirection;
      });
      break;

    case 'date':
      sortList.sort((a: ObjectType, b: ObjectType) => {
        const valueA = new Date(a[sortProperty] as Date | string | number).getTime();
        const valueB = new Date(b[sortProperty] as Date | string | number).getTime();
        return (valueA - valueB) * sortDirection;
      });
      break;

    case 'firebase-timestamp':
      sortList.sort((a: ObjectType, b: ObjectType) => {
        const valueA = a[sortProperty] ? new Date(firebaseTimeStampToDate(a[sortProperty] as Timestamp)).getTime() : 0;
        const valueB = b[sortProperty] ? new Date(firebaseTimeStampToDate(b[sortProperty] as Timestamp)).getTime() : 0;
        return (valueA - valueB) * sortDirection;
      });
      break;

    case 'number':
      sortList.sort((a: ObjectType, b: ObjectType) => {
        const valueA = a[sortProperty] as number;
        const valueB = b[sortProperty] as number;
        return (valueA - valueB) * sortDirection;
      });
      break;

    case 'string':
      sortList.sort((a: ObjectType, b: ObjectType) => {
        const valueA = String(a[sortProperty]);
        const valueB = String(b[sortProperty]);
        return valueA.localeCompare(valueB) * sortDirection;
      });
      break
    //#endregion Primitive Sortable Field Types

    //#region Auto Order Custom Sortables
    case 'ao-custom-sort-scope':
      break;
    //#endregion Auto Order Custom Sortables
    default:
      break;
  }

  if (sortDisabledItems && stockDisableRules && isStockItem) {
    const enabledItems: ObjectType[] = [];
    const disabledItems: ObjectType[] = [];

    sortList.forEach((item) => {
      const isDisabled = StockFunctions.isDisabled(item as StockItem, stockDisableRules);
      if (isDisabled) {
        disabledItems.push(item);
      } else {
        enabledItems.push(item);
      }
    });

    if (sortDisabledItemsLocation === 'start') {
      return disabledItems.concat(enabledItems);
    } else {
      return enabledItems.concat(disabledItems);
    }
  }

  return sortList;

}

/**
 * Represents a custom type for sorting properties.
 * This is used to allow more flexibility for the types of properties that can be used for sorting,
 * enabling the use of strings or custom sort types.
 *
 * @typedef {string} CustomSortPropertyType
 */
type CustomSortPropertyType = string;

/**
 * Represents the possible locations for sorting disabled items.
 * This type is used to determine where to place disabled items in the sorted array.
 * The location can either be at the start or at the end of the array.
 *
 * @typedef {('start' | 'end')} SortDisabledItemsLocation
 */
type SortDisabledItemsLocation = 'start' | 'end';
