<div>

  <div>
    <app-notification
      [setNotification]="notification"
      [setUserStores]="userStores"
      [setUser]="user">
    </app-notification>
  </div>

  <div>
    <ng-container [ngSwitch]="notification.type">

      <ng-container *ngSwitchCase="'AUTO_ORDERS'">
        <app-notification-auto-order
          [setAutoOrders]="(notificationAutoOrders$ | ngrxPush)"
          [setIsAutoOrderLoading]="(isNotificationAutoOrderLoading$ | ngrxPush)"
          [setNotification]="notification">
        </app-notification-auto-order>
      </ng-container>

      <ng-container *ngSwitchCase="'STOCK_UPDATE'">
        <app-notification-stock-update
          [setIsStockUpdateLoading]="(isNotificationStockUpdateLoading$ | ngrxPush)"
          [setNotification]="(notification)"
          [setStockUpdates]="(notificationStockUpdate$ | ngrxPush)">
        </app-notification-stock-update>
      </ng-container>

    </ng-container>

  </div>

</div>
