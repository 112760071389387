import {keyToTitle, StockItem} from '../datastructures';
import {AutoOrderItem} from './ao-datastructures';
import {getStockValueType} from '../stock-stuff/stock-item-value-type';
import {StockFunctions} from '../../functions-old/stock-functions';
import {IAutoOrderOrderItemFirestore} from '../../../shared/shared-models/auto-ordering/auto-order-order';

export const AO_STOCK_INFO_COLUMNS = [
  'stockId',
  'desc',
  'purchaseOrder',
  'onHand',
  'lastSold',
  'latestCost',
  'onHoldCode',
  'packSize',
  'lineColourCode',
  'ordLvl',
  'unitsYear',
  'regularSuppCode',
  'noDecimal',
  '_tags',
] as const;

export interface AOStockInfo extends Pick<StockItem, typeof AO_STOCK_INFO_COLUMNS[number]> {
  flagged?: boolean;
  poOnHold?: boolean;
}

export type FormattedStockInfoStrings = {
  [k in (
    (keyof Omit<AOStockInfo, 'flagged' | 'poOnHold' | '_tags' | 'noDecimal' | 'stockId'>) |
    (keyof Omit<AutoOrderItem, 'new'>)
    )]: string;
};

export const getDefaultVisibleColumns = (): (keyof FormattedStockInfoStrings)[] => [
  'code', 'desc', 'zeroDays', 'maxSold', 'netSold', 'purchaseOrder', 'onHand', 'qtyDiff',
];

export const aoKeyTextAlign = (key: keyof FormattedStockInfoStrings): 'left' | 'right' | 'center' => {
  const align = StockFunctions.itemFieldTextAlign(key as keyof StockItem);
  return align ? align : 'right';
};

export const AO_KEY_2_TITLE: { [key in keyof FormattedStockInfoStrings]: string } = {
  code: keyToTitle.code,
  desc: keyToTitle.desc,
  purchaseOrder: keyToTitle.purchaseOrder,
  onHand: keyToTitle.onHand,
  lastSold: keyToTitle.lastSold,
  latestCost: keyToTitle.latestCost,
  onHoldCode: keyToTitle.onHoldCode,
  packSize: keyToTitle.packSize,
  lineColourCode: keyToTitle.lineColourCode,
  ordLvl: keyToTitle.ordLvl,
  unitsYear: keyToTitle.unitsYear,
  regularSuppCode: keyToTitle.regularSuppCode,
  mainSupplier: 'Main Supplier',
  outOfStock: 'Times Out Stock',
  maxSold: 'Max Sold',
  netSold: 'Nett Sold',
  qtyDiff: 'Suggested Order',
  maxReturned: 'Max Returned',
  negativeDays: 'Days Negative',
  zeroDays: 'Days Zero',
} as const;

export const formatAOStockInfoValues = (stockItem: AOStockInfo, orderItem: IAutoOrderOrderItemFirestore): FormattedStockInfoStrings => {
  const formatted = {} as FormattedStockInfoStrings;
  // All values of AOStockInfo that are keys of StockItem can be formatted. If they are not they don't need to be.

  Object.keys(stockItem).forEach((key: keyof AOStockInfo) => {
    const valueType = getStockValueType(key as keyof StockItem);
    if (!valueType) {
      return;
    }
    switch (valueType) {
      case 'string':
        formatted[key] = stockItem[key];
        break;
      case 'number':
        if (typeof stockItem[key] === 'number') {
          formatted[key] = stockItem[key] ? (stockItem[key] as number) : 0;
        } else if (typeof stockItem[key] === 'boolean') {
          formatted[key] = stockItem[key] ? 1 : 0;
        } else {
          formatted[key] = 'NaN';
        }
        break;
      case 'date':
        formatted[key] = stockItem[key] ? (new Date(stockItem[key] as string)).toLocaleDateString() : null;
        break;
      case 'boolean':
        formatted[key] = stockItem[key] ? 'T' : 'F';
        break;
      case 'string[]':
        break;
    }
  });
  Object.keys(orderItem).forEach((key: keyof AutoOrderItem) => {
    if (key === 'new') {
      return;
    }
    if (orderItem.new === 'ADDED') {
      formatted[key] = 'NaN';
      return;
    } else { // PERIOD-2
      const num = +orderItem[key];

      if (num - Math.floor(num) === 0) {
        formatted[key] = '' + Math.floor(num);
        return;
      }
      formatted[key] = num.toFixed(2);
    }
  });
  return formatted;

};
