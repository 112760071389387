import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-shared-chip',
  templateUrl: './shared-chip.component.html',
  styleUrls: ['./shared-chip.component.scss'],
})
export class SharedChipComponent {

  @Input() chipId: string;

  @Input() selected: boolean = false;

}
