import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree} from '@angular/router';
import {validateAccess} from './guard-utils';

/**
 * Guards navigation by checking if the user has access to the requested page.
 *
 * This function is used in routing to determine whether a user is allowed to
 * activate the route based on their access permissions. It validates access
 * by passing the page URL to the `validateAccess` function and returns the result.
 *
 * @param {ActivatedRouteSnapshot} route - The route snapshot containing the
 *        information about the activated route.
 * @param {RouterStateSnapshot} state - The router state snapshot containing
 *        the state of the router, including the URL.
 * @returns {Promise<boolean | UrlTree>} - A promise that resolves to either
 *         `true` if the user has access to the page, or a `UrlTree` if redirection
 *         is needed due to insufficient access.
 */
export const canActivateGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<boolean | UrlTree> => {
  const page: string = state.url.split('?')[0].slice(1);
  return validateAccess(page);
};
