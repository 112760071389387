<ion-header *ngIf="isModal">
  <ion-toolbar>
    <div>
      <ion-title>{{ title }}</ion-title>
      <ion-title>
        <ion-icon (click)="close()" name="close-outline" size="large"></ion-icon>
      </ion-title>
    </div>
  </ion-toolbar>
</ion-header>

<div class="search-or-preview">
  <ion-input
    (ionChange)="searchOnKeyUp()"
    *ngIf="!preview"
    [(ngModel)]="searchString"
    [placeholder]="shelfTalkers ? 'Search ShelfTalkers': 'Search Stock Items'"
    class="search-bar" type="search">
    <ion-icon name="search-circle-outline"></ion-icon>
  </ion-input>
  <div (click)="totalItems > 0 ? onPrintAllVisibleClick() : null" *ngIf="shelfTalkers" class="print-all-button-div">
    <app-icon-price-tags></app-icon-price-tags>
    <span> Print {{ getDisplayedCount() }}</span>
  </div>
  <div (click)="onPrintOtherItemsClick()" *ngIf="shelfTalkers" class="print-all-button-div">
    <app-icon-printer-outline></app-icon-printer-outline>
    <span> Print Other Items </span>
  </div>
  <div (click)="deleteAllPrinted()" *ngIf="shelfTalkers" class="delete-all-button-div">
    <app-icon-delete-outline></app-icon-delete-outline>
    <span> Delete All {{ showDeleteAllPrinted ? 'Printed' : '' }} </span>
  </div>

  <div (click)="onShelfTalkerSettingsClick()" *ngIf="shelfTalkers" class="show-settings-buttons">
    <app-icon-settings></app-icon-settings>
  </div>
  <div *ngIf="supplier !== '' && supplier !== undefined" class="supplier-toggle-div">
    <span>Only Show Items From Supplier {{ supplier }}</span>
    <ion-toggle
      (ionChange)="onToggleSupplierOnlyClick()">
    </ion-toggle>
  </div>

  <h1 *ngIf="preview">
    Selected Items
  </h1>
</div>


<ag-grid-angular
  (gridReady)="onGridReady($event)"
  [columnDefs]="gridColDefs"
  [columnTypes]="columnTypes"
  [defaultColDef]="generalColDefDefaults"
  [gridOptions]="gridOptions"
  [overlayLoadingTemplate]="overlayTemplate"
  [overlayNoRowsTemplate]="overlayTemplate"
  [rowData]="stockItems"
  class="ag-theme-custom">
</ag-grid-angular>
<div>
  <div *ngIf="!preview && !showPaginationBar && !isNaN(totalItems)" class="custom-pagination-bar">

    Page Size:
    <select (change)="onPageSizeChange($event)" [(ngModel)]="itemsPerPage">
      <option *ngFor="let size of pageSizeOptions; trackBy: trackByFunction" [value]="size">{{ size }}</option>
    </select>

    <span class="spacing-span"></span>

    <span>{{ currentTablePage === 1 ? currentTablePage : (currentTablePage - 1) * itemsPerPage }}
      to <b>{{ currentTablePage * itemsPerPage > totalItems ? totalItems : currentTablePage * itemsPerPage }}</b>
        of <b>{{ totalItems }}</b>
    </span>

    <span class="spacing-span"></span>

    <app-icon-page-first
      (click)="!isFirstPageDisabled() && onGoToFirstPageClick()"
      [ngClass]="{ 'disabled': isFirstPageDisabled() }"
      [tooltip]="'First Page'"
      class="pagination-buttons">
    </app-icon-page-first>

    <app-icon-chevron-left
      (click)="!isFirstPageDisabled() && prevPage()"
      [ngClass]="{ 'disabled': isFirstPageDisabled() }"
      [tooltip]="'Previous Page'"
      class="pagination-buttons">
    </app-icon-chevron-left>

    <span>Page: {{ currentTablePage }} of <b>{{ totalPages }}</b></span>

    <app-icon-chevron-right
      (click)="!isLastPageDisabled() && nextPage()"
      [ngClass]="{ 'disabled': isLastPageDisabled() }"
      [tooltip]="'Next Page'"
      class="pagination-buttons">
    </app-icon-chevron-right>

    <app-icon-page-last
      (click)="!isLastPageDisabled() && onGoToLastPageClick()"
      [ngClass]="{ 'disabled': isLastPageDisabled() }"
      [tooltip]="'Last Page'"
      class="pagination-buttons">
    </app-icon-page-last>
  </div>

</div>

<div class="buttons-div">
  <ion-button
    (click)="onAddExceptionClick()"
    *ngIf="shelfTalkers && exceptions"
    [disabled]="selectedStockItemCount < 1"
    class="add-exception-selected-button"
    fill="outline">
    <app-icon-link-variant></app-icon-link-variant>
    Add Exception
  </ion-button>
  <ion-button
    (click)="onViewSelectedClick()"
    *ngIf="shelfTalkers"
    [disabled]="selectedStockItemCount < 1"
    class="delete-selected-button"
    fill="outline">
    <app-icon-delete-outline></app-icon-delete-outline>
    Delete Selected
  </ion-button>
  <ion-button (click)="onViewSelectedClick()" *ngIf="!shelfTalkers && !isShelfTalkersModal"
              [disabled]="selectedStockItemCount < 1 && !preview">
    {{ getPreviewButtonData }}
  </ion-button>
  <ion-button
    (click)="onGetSelectedStockItemsClick()"
    *ngIf="!shelfTalkers && !isShelfTalkersModal"
    [disabled]="(selectedStockItemCount < 1 || selectedStockItemCount > (30 - currentNewItems))"
    fill="outline">
    <span *ngIf="selectedStockItemCount > (30 - currentNewItems)">
      Item Limit of {{ (30 - currentNewItems) }} Exceeded
    </span>
    <span *ngIf="selectedStockItemCount < (31 - currentNewItems)">
      <ng-container>
         Add {{ selectedStockItemCount !== 1 ? selectedStockItemCount + ' items' : selectedStockItemCount + ' item' }}
      </ng-container>
       </span>
  </ion-button>
  <ion-button
    (click)="onGetSelectedStockItemsClick()"
    *ngIf="shelfTalkers || isShelfTalkersModal"
    [disabled]="selectedStockItemCount < 1"
    fill="outline">
    <span>
        Print {{ selectedStockItemCount !== 1 ? selectedStockItemCount + ' ShelfTalkers' : selectedStockItemCount + ' ShelfTalker' }}
       </span>
  </ion-button>
</div>

