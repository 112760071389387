import {
  ngpReportGridColDefs
} from 'src/app/shared-utilities/models-old/ngp-report-grid/defaults/ngp-report/ngp-report-grid-col-defs';
import {stockManagerGridColDefs} from 'src/app/features/stock-manager/models/stock-manager-grid-col-def';

import {EnterEndOptionsType, OptionType, TabEndOptionsType} from '../../models';

const SHARED_KEY_BUTTON_OPTIONS = {
  restart_page: 'Jump back to first field on current page',
  restart_column: 'Restart at the top of the column',
  next_page: 'Go to the first field on the next page',
} as const

const TAB_END_OPTIONS: { [k in TabEndOptionsType]: string } = {
  ...SHARED_KEY_BUTTON_OPTIONS
} as const;

const ENTER_END_OPTIONS: { [k in EnterEndOptionsType]: string } = {
  ...SHARED_KEY_BUTTON_OPTIONS,
  next_column: 'Start the next column',
  next_page_same_column: 'Continue the column on the next page',
} as const;

export const USER_SETTINGS = {
  tableSettings: {
    fitToTable: {
      title: 'Fit to Table',
      optionType: 'switch' as OptionType,
      optionTip: 'When enabled, all table columns are resized to fit within the screen, making all columns visible but potentially squashed. When disabled, columns maintain their fixed width, and you can scroll horizontally to view those that dont fit on the screen.',
    },
    tabEnd: {
      optionType: 'select' as OptionType,
      options: TAB_END_OPTIONS,
    },
    enterEnd: {
      optionType: 'select' as OptionType,
      options: ENTER_END_OPTIONS,
    },
    ngpEditsPreview: {
      title: 'Set NGP Preview Columns',
      optionType: 'select-multiple' as OptionType,
      optionTip: 'Choose which columns will display by default when previewing NGP Report.',
      options: ngpReportGridColDefs,
    },
    stockEditsPreview: {
      title: 'Set Stock Manager Preview Columns',
      optionType: 'select-multiple' as OptionType,
      optionTip: 'Choose which columns will display by default when previewing Stock Manager.',
      options: stockManagerGridColDefs,
    }
  },
} as const;
