import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CoreActions from './core.actions';
import * as SettingsActions from '../../../features/settings-old/store/settings.actions';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { from, merge, of } from 'rxjs';
import { FirebaseService } from '../../../shared-utilities/services-old/firebase.service';
import { IUser } from '../../../shared/shared-models/user-access/user';
import { StoreObject } from '../../../shared-utilities/models-old/store/store-object';
import { FireAuthService } from '../../../shared-utilities/services-old/fire-auth.service';
import { IError } from '../../../shared-utilities/models-old/error/error';
import { IUserAccess } from '../../../shared/shared-models/user-access/user-access';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  CollectionUserAccessColleaguesService,
} from '../../../shared/shared-services/firebase/collection-user-access-colleagues.service';
import {hasUserLoggedIn, selectUser, selectUserStores} from './core.selectors';
import {AngularFirestore, CollectionReference} from '@angular/fire/compat/firestore';
import {IStore} from '../../../shared/shared-models/store/store';
import {INotification} from '../../../shared-utilities/models-old/datastructures';
import {IFirebaseDataTimeStamps} from '../../../features/core/models/data-time-stamps';
import {convertIDataTimeStampsToDates} from '../utils/core.effects.utils';
import {
  path_shared_stores_data_storeId_data_singular_documents_timestamps,
} from '../../../shared/shared-services/database-paths';

@Injectable()
export class CoreEffects {

  // ====================================================================================================
  // Get User and User Settings
  // ====================================================================================================

  // '[Core][User] Get User'
  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.getUser),
      withLatestFrom(
        this.store.pipe(select(selectUser)),
        this.store.pipe(select(hasUserLoggedIn)),
      ),
      mergeMap(([_, currentUser, hasUserLoggedIn]) =>
        from(this.firebaseService.userObj).pipe(
          mergeMap((user: IUser | null) => {
            if (user === null) {
              return of(
                CoreActions.setCurrentPageAndTab({currentSelectedPage: {currentPage: 'login', currentTab: null}}),
                CoreActions.getUserSuccess({user}));
            } else if (hasUserLoggedIn) {
              return of(
                CoreActions.getUserSuccess({ user }),
                SettingsActions.getAllUserSettings(),
              );
            } else {
              return of(
                CoreActions.setCurrentPageAndTab({
                  currentSelectedPage: {
                    currentPage: 'home',
                    currentTab: null,
                  },
                }),
                CoreActions.getUserSuccess({ user }),
                SettingsActions.getAllUserSettings(),
                CoreActions.userLoginSuccess({isUserLoggedIn: true}),
              );
            }
          }),
          catchError((error: IError) => of(CoreActions.getUserFailure({ error }))),
        ),
      ),
    ),
  );

  // ====================================================================================================
  // Get | Set User Stores
  // ====================================================================================================

  // '[Core][User Stores] Get User Stores'
  getUserStores$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.getUserStores),
      mergeMap(({ pageStoreDocument }) =>
        this.firebaseService.pageStores(pageStoreDocument).pipe(
          mergeMap((storeObject: StoreObject) => {
            if (pageStoreDocument === 'messages-modal') {
              return of(
                CoreActions.getUserStoresSuccess({ storeObject }),
                CoreActions.getUserMessages(),
                CoreActions.getDataTimeStamps(),
              );
            }
            return of(CoreActions.getUserStoresSuccess({ storeObject }));
          }),
          catchError((error: IError) =>
            of(CoreActions.getUserStoresFailure({ error })),
          ),
        ),
      ),
    ),
  );

  getUserStoresSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActions.getUserStoresSuccess),
    map(() => SettingsActions.getAllUserStoreSettings()),
  ));

  setUserSelectedStoreAndGetSettings$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActions.setUserSelectedStoreAndGetSettings),
    map(() => SettingsActions.getAllUserStoreSettings()),
  ));

  // ====================================================================================================
  // Get Image URL
  // ====================================================================================================
  // '[Core][Image] Get Image URL'
  getImageURL = createEffect(() => this.actions$.pipe(
    ofType(CoreActions.getImageURL),
    mergeMap(({ path, image }) =>
      this.firebaseService.getImageUrl(path)
        .pipe(
          map((url: string) => CoreActions.getImageURLSuccess({ url, image })),
          catchError((error: IError) => of(CoreActions.getImageURLFailure({ error, image }))),
        ),
    ),
  ));

  // ====================================================================================================
  // Get User Access
  // ====================================================================================================

  // '[Core][User] Get User Access'
  getUserAccess$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActions.getUserAccess),
    mergeMap(() =>
      this.fireAuthService.userAccess
        .pipe(
          map((userAccess: IUserAccess) => CoreActions.getUserAccessSuccess({ userAccess })),
          catchError((error: IError) => of(CoreActions.getUserAccessFailure({ error }))),
        ),
    ),
  ));

  // '[Core][User] Get User Access'
  getUserAccessSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActions.getUserAccessSuccess),
    map(() => CoreActions.getUserStores({ pageStoreDocument: 'messages-modal' })),
  ),
  );

  // ====================================================================================================
  // User Messages
  // ====================================================================================================

  getUserMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.getUserMessages),
      withLatestFrom(this.store.pipe(select(selectUserStores))),
      mergeMap(([_, stores]) => {
        const storeIds = stores.map((store: IStore) => store.storeId);
        if (storeIds.length > 0) {
          return this.angularFirestore
            .collection('/notifications/', (reference: CollectionReference) =>
              reference.where('storeId', 'in', storeIds),
            )
            .valueChanges({ idField: 'id' })
            .pipe(
              map((notifications: INotification[]) =>
                CoreActions.getUserMessagesSuccess({ messages: notifications }),
              ),
              catchError((error) => {
                return of(CoreActions.getUserMessagesFailure({ error }));
              }),
            );
        } else {
          return of(CoreActions.getUserMessagesSuccess({ messages: [] }));
        }
      }),
    ),
  );


  loadDataTimeStamps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.getDataTimeStamps),
      withLatestFrom(this.store.pipe(select(selectUserStores))),
      switchMap(([action, userStores]) => {
        return merge(
          ...userStores.map((store: IStore) =>
            this.angularFirestore
              .doc<IFirebaseDataTimeStamps>(
                path_shared_stores_data_storeId_data_singular_documents_timestamps(store.storeId),
              )
              .valueChanges()
              .pipe(
                map((dataTimeStamps) => {
                    const convertedTimeStamps = convertIDataTimeStampsToDates(dataTimeStamps);
                    return CoreActions.getDataTimeStampsSuccess({
                      store,
                      dataTimeStamps: convertedTimeStamps,
                    });
                  },
                ),
                catchError((error: IError) => {
                    console.error(error);
                    return of(CoreActions.getDataTimeStampsFailure({error}));
                  },
                ),
              ),
          ),
        );
      }),
    ),
  );


  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
    private fireAuthService: FireAuthService,
    private angularFirestore: AngularFirestore,
    private router: Router,
    private collectionUserAccessColleaguesService: CollectionUserAccessColleaguesService,
    private readonly store: Store,
  ) {
  }


}
