import {Component, Input, OnInit} from '@angular/core';

/**
 * A component that renders an SVG of the full Gallix Logo
 */
@Component({
  selector: 'app-logo-full',
  templateUrl: './logo-full.component.html',
  styleUrls: ['./logo-full.component.scss'],
})
export class LogoFullComponent implements OnInit {
  @Input() contrast: boolean = false;
  mainColour: string;

  ngOnInit(): void {
    this.mainColour = this.contrast ? 'var(--ion-color-white-contrast)' : 'var(--ion-color-constant-white)';
  }
}
