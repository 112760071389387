<div class="table-container">
  <table class="table">
    <thead>
    <tr>
      <th>Description</th>
      <th>Value</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>Maximum Increase in Price</td>
      <td>
        <div class="input-group">
          <input
            (input)="formService.onPctChange($event)"
            [value]="maxIncPriceVal"
            class="form-control"
            max="100"
            min="0"
            type="number">
          <span class="input-group-text">%</span>
        </div>
      </td>
    </tr>
    <tr>
      <td>Maximum Decrease in Price</td>
      <td>
        <div class="input-group">
          <input
            (input)="formService.onNegPctChange($event)"
            [value]="maxDecPriceVal"
            class="form-control"
            max="100"
            min="0"
            type="number">
          <span class="input-group-text">%</span>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div class="save-button-container">
  <button (click)="formService.onSubmit($event)" type="button">Save</button>
</div>
