import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {FirebaseService} from '../../../shared-utilities/services-old/firebase.service';
import * as StockManagerActions from '../store/stock-manager.actions';
import {catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {
  DisabledRules,
  DisabledRulesSupp,
  LineColour,
  sItemIntToKey,
  sItemKeyToInt,
  StockItem,
  Supplier,
} from '../../../shared-utilities/models-old/datastructures';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {concat, EMPTY, from, Observable, of} from 'rxjs';
import {Action, select, Store} from '@ngrx/store';
import {TypeSenseService} from '../../../shared/shared-services/type-sense/type-sense.service';
import {IPaginationData} from '../../../shared/shared-models/pagination/pagination-data';
import {
  selectAllStockItemsForCurrentSelectedStore,
  selectDontDisplayModalAgain,
  selectEditedItems,
  selectEnablePriceBandingForStockManager,
  selectItemDisablingRules,
  selectPaginationDataForUserSelectedStore,
  selectTypesenseQueryData,
} from './stock-manager.selectors';
import {
  IFacetCounts,
  ITypeSenseApiData,
  TypeSenseSearchResponse,
} from '../../../shared/shared-models/type-sense/type-sense-types';
import {ISearchableFields} from '../../../shared/shared-models/type-sense/default-searchable-fields';
import {IDepartment} from '../../../shared/shared-models/stock/departments';
import {
  createFacetCountsMap,
  mapTypeSenseObjectToGeneric,
} from '../../../shared/shared-utils/typesense-utils/typesense.utils';
import {createMapFromObjectsObject, getDeepCopyOfObject} from '../../../shared/shared-utils/object/object.utils';
import {StockFunctions} from '../../../shared-utilities/functions-old/stock-functions';
import {
  calculateGPFromPrice,
  calculatePriceFromGpValue,
} from '../../../shared/shared-utils/calculations/calculations-stock';
import {
  selectCurrentPage,
  selectCurrentPageAndTab,
  selectSelectedUserStore,
  selectUser,
  selectUserStores,
} from '../../../features-as-modules/feature-core/store/core.selectors';
import {
  applyPriceBanding,
  PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS,
} from '../../../shared/shared-utils/price/price-banding.utils';
import {
  selectPriceBandingForUserSelectedStore,
  selectSharedGridVatRateBySelectedStore,
} from '../../../shared-modules/shared-grid/store/shared-grid.selectors';
import {
  path_shared_stores_data_storeId_data_singular_documents_store_settings,
  path_stores_storeId_settings_disable_stock_item_rules,
  path_users_userId_settings_stock_edits_preview,
} from '../../../shared/shared-services/database-paths';
import {
  CollectionUsersSettingsService,
} from '../../../shared/shared-services/firebase/collection-users-settings.service';
import {CollectionSharedStockService} from '../../../shared/shared-services/firebase/collection-shared-stock.service';
import {SharedModalUtils} from '../../../shared/shared-utils/shared-modal/shared-modal.utils';
import {ISharedModalBasic, ISharedModalBasicResponse} from '../../../shared/shared-models/modals/shared-modal-basic';
import {Icons} from '../../../shared-modules/shared-icons/icons';
import {OverlayEventDetail} from '@ionic/core';
import {CollectionStoresDocsService} from '../../../shared/shared-services/firebase/collection-stores-docs.service';
import {
  CollectionStoresSettingsService,
} from '../../../shared/shared-services/firebase/collection-stores-settings.service';
import * as AutoOrderActions from '../../../features-as-modules/feature-auto-ordering/store/auto-ordering.actions';
import {clearEditedItemsInLocalStorage} from '../../ngp-report/store/ngp-report.effects.utils';
import {
  CollectionSharedSingularDocumentsService,
} from '../../../shared/shared-services/firebase/collection-shared-singular-documents.service';
import {ISharedStoreSettings} from '../../../shared/shared-models/firebase/shared-store-settings';
import {editableFields} from '../models/stock-manager-grid-col-def';
import {AO_KEY_2_TITLE} from '../../../shared-utilities/models-old/auto-ordering/auto-order-types';
import {convertToDecimal} from '../../../shared/shared-utils/number/number.utils';

@Injectable()
export class StockManagerEffects {

  // Call on Init for each store
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>
  //'[Stock Manager][TypeSense] Get All Api Keys'
  getAllTypesenseApiKeysForStores$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getAllTypesenseApiKeysForStores),
      withLatestFrom(
        this.store.pipe(select(selectUserStores)),
        this.store.pipe(select(selectCurrentPage)),
      ),
      mergeMap(([_, stores, page]) =>
        from(this.collectionStoresDocsService.getAllTypesenseApiKeysForStores(stores)).pipe(
          mergeMap((apiDocs: { [storeId: string]: ITypeSenseApiData }) => {
            if (page === 'auto-ordering') {
              return concat(
                of(StockManagerActions.getAllTypesenseApiKeysForStoresSuccess({apiDocs})),
                of(AutoOrderActions.getTypesenseFacetCounts()),
              );
            }
            return of(StockManagerActions.getAllTypesenseApiKeysForStoresSuccess({apiDocs}));
          }),
          catchError((error) =>
            of(StockManagerActions.getAllTypesenseApiKeysForStoresFailure({error})),
          ),
        ),
      ),
    ),
  );


  // '[Stock Manager][Line Colour] Get Line Colours',
  getLineColours$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getLineColours),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([{pageStoreDocument}, selectedStore, {paginationData}]) =>
        from(this.firebaseService.getStoreDataDoc(pageStoreDocument, selectedStore.storeId)).pipe(
          mergeMap((lineColours: LineColour) => {
            return [
              StockManagerActions.getLineColoursSuccess({lineColours, selectedStore}),
              StockManagerActions.getAllStockItems({
                idField: paginationData?.searchedValue == null ? '' : paginationData.searchedValue,
                currentPage: 1,
                pageSize: paginationData?.pageSize == null ? 20 : paginationData?.pageSize,
              }),
            ];
          }),
          catchError((error: IError) => {
            return of(StockManagerActions.getLineColoursFailure({error, store: selectedStore}));
          }),
        ),
      ),
    ),
  );

  // '[Stock Manager][Item Disabling] Get Stock Items Disabling Rules',
  getStockItemDisablingRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getStockItemDisablingRules),
      withLatestFrom(
        this.store.pipe(select(selectTypesenseQueryData)),
        this.store.pipe(select(selectSelectedUserStore)),
      ),
      mergeMap(([_, queryData, selectedStore]) => {
        const {store} = queryData;
        return from(this.collectionStoresSettingsService.getDocument<DisabledRulesSupp>(path_stores_storeId_settings_disable_stock_item_rules(selectedStore.storeId))).pipe(
          map((rulesDoc: DisabledRules) => {
            return StockManagerActions.getStockItemDisablingRulesSuccess({store, rulesDoc});
          }),
          catchError((errors: IError) =>
            of(StockManagerActions.getStockItemDisablingRulesFailure({errors})),
          ),
        );
      }),
    ),
  );

  // =======================================================================
  // Stock Item
  // =======================================================================

  // '[Stock Manager][Stock Item] Get All Stock Items',
  getAllStockItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getAllStockItems),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([{idField, currentPage, pageSize}, queryData]) => {
        const {
          store,
          searchableFields,
          sortType,
          filters,
          advancedFilterGroup,
          paginationData,
          suppliers,
        } = queryData;
        if (suppliers && Object.keys(suppliers)?.length > 0) {
          return of(
            StockManagerActions.getEditedItemsWithValueFromLocalStorage(),
            StockManagerActions.getAllStockItemsWithExistingSuppliers({
              idField,
              currentPage,
              pageSize,
              store,
              selectedFields: searchableFields,
              selectedStoreSuppliers: suppliers,
            }),
            StockManagerActions.getNextPageForStockItems(),
          );
        } else {
          return of(
            StockManagerActions.getEditedItemsWithValueFromLocalStorage(),
            StockManagerActions.getAllStockItemsWithoutExistingSuppliers({
              idField,
              currentPage,
              pageSize,
              store,
              selectedFields: searchableFields,
            }),
            StockManagerActions.getNextPageForStockItems(),
          );
        }
      }),
    ),
  );

  //'[Stock Manager][Stock Item] Get All Stock Items When Suppliers Exist ',
  getAllStockItemsWithExistingSuppliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getAllStockItemsWithExistingSuppliers),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([{idField, currentPage, pageSize, store, selectedFields}, queryData]) => {
        const {sortType, filters, advancedFilterGroup} = queryData;

        return this.typeSenseService
          .searchStockItems(
            idField,
            currentPage,
            pageSize,
            store,
            selectedFields,
            filters,
            sortType,
            advancedFilterGroup,
          )
          .pipe(
            mergeMap((stockItemsRaw: TypeSenseSearchResponse<StockItem>) => {
              const facetCounts: IFacetCounts = createFacetCountsMap(stockItemsRaw.facet_counts);
              const paginationData: IPaginationData = {
                totalItems: stockItemsRaw.found,
                pageSize,
                currentPage,
                searchedValue: idField,
              };
              const mappedStockItems = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
              let stockItems: StockItem[] = mappedStockItems.map((item: StockItem) => ({
                ...item,
                storeId: store.storeId,
                store: store.name,
                disabled: false,
                isEdited: false,
                isError: false,
                multipleSuppliers: item.multipleSuppliers ?? [],
              }));
              stockItems = stockItems.map((item: StockItem) => {
                const updatedStockItem = calculateGPFromPrice(item, store, 2);
                if (isNaN(updatedStockItem.nominalGP) || updatedStockItem.nominalGP === null) {
                  updatedStockItem.nominalGP = 100;
                }
                return updatedStockItem;
              });
              return [
                StockManagerActions.getAllStockItemsSuccess({
                  stockItems,
                  store,
                  paginationData,
                  facetCounts,
                }),
                StockManagerActions.getNextPageForStockItems(),
              ];
            }),
            catchError((error: IError) =>
              of(StockManagerActions.getAllStockItemsFailure({error, store})),
            ),
          );
      }),
    ),
  );

  //'[Stock Manager][Stock Item] Get All Stock Items When Suppliers Do Not Exist ',
  getAllStockItemsWithoutExistingSuppliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getAllStockItemsWithoutExistingSuppliers),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([{idField, currentPage, pageSize, store, selectedFields}, queryData]) => {
        const {sortType, filters, advancedFilterGroup} = queryData;

        return this.typeSenseService
          .searchStockItems(idField, currentPage, pageSize, store, selectedFields, filters, sortType, advancedFilterGroup)
          .pipe(
            mergeMap((stockItemsRaw: TypeSenseSearchResponse<StockItem>) =>
              from(this.firebaseService.getSuppliers(store.storeId)).pipe(
                map((rawSuppliers: { [key: string]: Supplier }) => {
                  const suppliers = createMapFromObjectsObject<Supplier>(rawSuppliers, 'name');
                  const facetCounts: IFacetCounts = createFacetCountsMap(stockItemsRaw.facet_counts);
                  const manipulatedSuppliers = Object.keys(suppliers).reduce((acc, key) => {
                    acc[key] = {
                      name: rawSuppliers[key].name,
                      value: false,
                    };
                    return acc;
                  }, {} as ISearchableFields);

                  const paginationData: IPaginationData = {
                    totalItems: stockItemsRaw.found,
                    pageSize,
                    currentPage,
                    searchedValue: idField,
                  };

                  const mappedStockItems = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
                  let stockItems = mappedStockItems.map((item: StockItem): StockItem => ({
                    ...item,
                    storeId: store.storeId,
                    store: store.name,
                    isEdited: false,
                    isError: false,
                    multipleSuppliers: item.multipleSuppliers ?? [],
                  }));

                  stockItems = stockItems.map((item: StockItem) => {
                    const updatedStockItem = calculateGPFromPrice(item, store, 2);
                    if (isNaN(updatedStockItem.nominalGP)) {
                      updatedStockItem.nominalGP = 100;
                    }
                    return {...updatedStockItem, ...item};
                  });

                  return [
                    StockManagerActions.getSuppliersForStockManagerSuccess({
                      suppliers: manipulatedSuppliers,
                      store,
                    }),
                    StockManagerActions.getAllStockItemsSuccess({
                      stockItems,
                      store,
                      paginationData,
                      facetCounts,
                    }),
                    StockManagerActions.getNextPageForStockItems(),
                  ];
                }),
              ),
            ),
            mergeMap((actions) => from(actions)),
            catchError((error: IError) =>
              from([
                StockManagerActions.getAllStockItemsFailure({error, store}),
                StockManagerActions.getSuppliersForStockManagerFailure({error, store}),
              ]),
            ),
          );
      }),
    ),
  );

  // =======================================================================
  // Departments and SubDepartments
  // =======================================================================

  //'[Stock Manager][Departments] Get Store Departments For Stock Manager',
  fetchStoreDepartments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getStoreDepartmentsForStockManager),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([_, queryData]) => {
        const {store} = queryData;
        return this.firebaseService.getDepartmentsByStore(store).pipe(
          switchMap((departments: IDepartment[]) =>
            from(
              this.collectionSharedSingularDocumentsService.getDocument(
                path_shared_stores_data_storeId_data_singular_documents_store_settings(store.storeId),
              ),
            ).pipe(
              map((storeSettings: ISharedStoreSettings) =>
                StockManagerActions.getStoreDepartmentsForStockManagerSuccess({
                  store,
                  departments,
                  storeSettings,
                }),
              ),
              catchError((error: IError) =>
                of(StockManagerActions.getStoreDepartmentsForStockManagerFailure({error, store})),
              ),
            ),
          ),
          catchError((error: IError) =>
            of(StockManagerActions.getStoreDepartmentsForStockManagerFailure({error, store})),
          ),
        );
      }),
    ),
  );


  // =======================================================================
  // Suppliers
  // =======================================================================

  //'[Stock Manager][Suppliers] Set Suppliers For Stock Manager',
  setSuppliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.setSuppliersForStockManager),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([{suppliers}, queryData]) => {
        const {
          store,
          paginationData: {pageSize, searchedValue, currentPage},
          searchableFields,
          sortType,
          filters,
          advancedFilterGroup,
        } = queryData;

        return this.typeSenseService
          .searchStockItems(
            searchedValue,
            currentPage,
            pageSize,
            store,
            searchableFields,
            filters,
            sortType,
            advancedFilterGroup,
          )
          .pipe(
            map((stockItemsRaw: TypeSenseSearchResponse<StockItem>) => {
              const paginationData: IPaginationData = {
                totalItems: stockItemsRaw.found,
                pageSize,
                currentPage,
                searchedValue,
              };
              const facetCounts: IFacetCounts = createFacetCountsMap(stockItemsRaw.facet_counts);
              const mappedStockItems = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
              let stockItems: StockItem[] = mappedStockItems.map((item: StockItem) => ({
                ...item,
                storeId: store.storeId,
                store: store.name,
                isEdited: false,
                isError: false,
                multipleSuppliers: item.multipleSuppliers ?? [],
              }));
              stockItems = stockItems.map((item: StockItem) => {
                const updatedStockItem = calculateGPFromPrice(item, store, 2);
                if (isNaN(updatedStockItem.nominalGP)) {
                  updatedStockItem.nominalGP = 100;
                }
                return {...updatedStockItem, ...item};
              });
              return StockManagerActions.setSuppliersForStockManagerSuccess({
                stockItems,
                store,
                paginationData,
                facetCounts,
                suppliers,
              });
            }),
            catchError((error: IError) =>
              of(StockManagerActions.getPaginationResultsForStockItemsFailure({error, store})),
            ),
          );
      }),
    ),
  );
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>


  // Filters and Menu Actions
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>

  // ====================================================================================================
  // Set Grid Menu Action
  // ====================================================================================================

  //[Stock Manager][Menu Actions] Set Stock Manager Menu Actions per Column',
  setStockManagerMenuActionsWithStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.setStockManagerMenuActions),
      withLatestFrom(
        this.store.pipe(select(selectTypesenseQueryData)),
        this.store.pipe(select(selectEnablePriceBandingForStockManager)),
      ),
      switchMap(([action, queryData, currentlyPriceBanding]) => {
        const {store} = queryData;
        let sortType = '';

        if (action.gridHeaderMenu.typeOfChange === 'isSorting') {
          switch (action.gridHeaderMenu.sort) {
            case 'sort-asc':
              sortType = `${sItemKeyToInt[action.gridHeaderMenu.colId]}:asc`;
              break;
            case 'sort-desc':
              sortType = `${sItemKeyToInt[action.gridHeaderMenu.colId]}:desc`;
              break;
            default:
              sortType = ``;
              break;
          }
        }

        if (action.gridHeaderMenu.typeOfChange === 'isSorting') {
          const actions = [
            StockManagerActions.setStockManagerMenuActionsWithStore({
              ...action,
              store,
            }),
            StockManagerActions.setStockManagerSortTye({sortType}),
          ];
          return from(actions);
        } else if (action.gridHeaderMenu.typeOfChange === "isPriceBanding") {
          let actions = [];
          if (action.gridHeaderMenu.value === currentlyPriceBanding) {
            actions = [
              StockManagerActions.setStockManagerMenuActionsWithStore({
                ...action,
                store: store,
              }),
            ];
          } else {
            actions = [
              StockManagerActions.setStockManagerMenuActionsWithStore({
                ...action,
                store: store,
              }),
              StockManagerActions.togglePriceBanding(),
            ];
          }

          return from(actions);
        } else {
          return of(
            StockManagerActions.setStockManagerMenuActionsWithStore({
              ...action,
              store,
            }),
          );
        }
      }),
    ),
  );

  //'[Stock Manager][Menu Actions] Set Stock Manager Sort Type',
  getAllStockItemsSorted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.setStockManagerSortTye),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([{sortType}, queryData]) => {
        const {
          store,
          paginationData: {searchedValue, pageSize},
          searchableFields,
          filters,
          advancedFilterGroup,
        } = queryData;

        return this.typeSenseService
          .searchStockItems(
            searchedValue,
            1,
            pageSize,
            store,
            searchableFields,
            filters,
            sortType,
            advancedFilterGroup,
          )
          .pipe(
            map((stockItemsRaw: TypeSenseSearchResponse<StockItem>) => {
              const facetCounts: IFacetCounts = createFacetCountsMap(stockItemsRaw.facet_counts);
              const paginationData: IPaginationData = {
                totalItems: stockItemsRaw.found,
                pageSize,
                currentPage: 1,
                searchedValue,
              };
              const mappedStockItems = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
              let stockItems: StockItem[] = mappedStockItems.map((item: StockItem) => ({
                ...item,
                storeId: store.storeId,
                store: store.name,
                isEdited: false,
                isError: false,
                multipleSuppliers: item.multipleSuppliers ?? [],
              }));
              stockItems = stockItems.map((item: StockItem) => {
                const updatedStockItem = calculateGPFromPrice(item, store, 2);
                if (isNaN(updatedStockItem.nominalGP)) {
                  updatedStockItem.nominalGP = 100;
                }
                return {...updatedStockItem, ...item};
              });
              return StockManagerActions.getAllStockItemsSuccess({
                stockItems,
                store,
                paginationData,
                facetCounts,
              });
            }),
            catchError((error: IError) =>
              of(StockManagerActions.getAllStockItemsFailure({error, store})),
            ),
          );
      }),
    ),
  );

  // =======================================================================
  // Filters
  // =======================================================================

// '[Stock Manager][Filters] Set Stock Item Filters',
  setFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.setStockItemFilters),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([{filters}, queryData]) => {
        const {
          store,
          paginationData: {pageSize, searchedValue, currentPage},
          searchableFields,
          sortType,
          advancedFilterGroup,
        } = queryData;
        return this.typeSenseService
          .searchStockItems(
            searchedValue,
            1,
            pageSize,
            store,
            searchableFields,
            filters,
            sortType,
            advancedFilterGroup,
          )
          .pipe(
            map((stockItemsRaw: TypeSenseSearchResponse<StockItem>) => {
              const paginationData: IPaginationData = {
                totalItems: stockItemsRaw.found,
                pageSize,
                currentPage: 1,
                searchedValue,
              };
              const facetCounts: IFacetCounts = createFacetCountsMap(stockItemsRaw.facet_counts);
              const mappedStockItems = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
              let stockItems: StockItem[] = mappedStockItems.map((item: StockItem) => ({
                ...item,
                storeId: store.storeId,
                store: store.name,
                isEdited: false,
                isError: false,
                multipleSuppliers: item.multipleSuppliers ?? [],
              }));
              stockItems = stockItems.map((item: StockItem) => {
                const updatedStockItem = calculateGPFromPrice(item, store, 2);
                if (isNaN(updatedStockItem.nominalGP)) {
                  updatedStockItem.nominalGP = 100;
                }
                return {...updatedStockItem, ...item};
              });
              return StockManagerActions.setStockItemFiltersSuccess({
                stockItems,
                store,
                paginationData,
                facetCounts,
                filters,
              });
            }),
            catchError((error: IError) =>
              of(StockManagerActions.setStockItemFiltersFailure({error, store})),
            ),
          );
      }),
    ),
  );
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>


  // Editing Items Functions
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>

  //'[Stock Manager] set Edited Item',
  setEditedItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        StockManagerActions.setEditedItem,
      ),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectEditedItems)),
        this.store.pipe(select(selectEnablePriceBandingForStockManager)),
        this.store.pipe(select(selectPriceBandingForUserSelectedStore)),
        this.store.pipe(select(selectSharedGridVatRateBySelectedStore)),
      ),
      switchMap(([{stockItem}, currentSelectedStore, editedItems, isPriceBanding, priceBands, vatRate]) => {
        let updatedStockItem = {...stockItem};
        const originalValues = stockItem.originalValue || {};
        Object.entries(updatedStockItem).forEach(([key, value]) => {
          if (
            (value === '' && key !== '_tags') ||
            value === null ||
            value === undefined
          ) {
            updatedStockItem[key] = originalValues[key]?.value;
          }
        });

        const excludedKeys = new Set(['originalValue', 'isEdited', 'isError', 'diffGP', 'prevCostPrice', 'prevGP']);
        const changedValues: {
          key: string;
          original: string | number | boolean | Date;
          new: string | number | boolean | Date;
        }[] = [];

        const anyValueDiffers = Object.keys(updatedStockItem).some((key: string) => {
          if (excludedKeys.has(key)) {
            return false;
          }

          const updatedValue = updatedStockItem[key];
          const originalValue = originalValues[key]?.value;

          let changed = false;

          if (Array.isArray(updatedValue) && Array.isArray(originalValue)) {
            if (updatedValue.length !== originalValue.length) {
              changed = true;
            } else {
              changed = !updatedValue.every(
                (val: string, index: number) => val === originalValue[index],
              );
            }
          } else {
            changed = updatedValue !== originalValue;
          }

          if (changed) {
            changedValues.push({
              key,
              original: originalValue,
              new: updatedValue,
            });
          }

          return changed;
        });

        if (anyValueDiffers) {

          if (isPriceBanding) {
            updatedStockItem = applyPriceBanding(
              updatedStockItem,
              priceBands,
              PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS,
              currentSelectedStore,
              vatRate,
            );
          }

          return [
            StockManagerActions.setEditedItemAdd({
              stockItem: {...updatedStockItem},
              store: currentSelectedStore,
            }), StockManagerActions.setEditedItemToLocalStorage({
              stockItem: {...updatedStockItem},
              store: currentSelectedStore,
            }),
          ];
        } else if (editedItems?.[stockItem.code]) {
          return [
            StockManagerActions.setEditedItemRemove({
              stockItem: {...updatedStockItem},
              store: currentSelectedStore,
            }),
            StockManagerActions.setEditedItemToLocalStorage({
              stockItem: {...updatedStockItem},
              store: currentSelectedStore,
            }),
          ];
        } else {
          return from([]);
        }
      }),
      filter(action => !!action),
    ),
  );

  setEditedItemToLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StockManagerActions.setEditedItemToLocalStorage),
        withLatestFrom(
          this.store.pipe(select(selectSelectedUserStore)),
          this.store.pipe(select(selectEditedItems)),
        ),
        tap(([action, store, editedItems]) => {
          const {stockItem} = action;
          let data = {};
          const storageKey = 'savedStockManagerEdits';
          const serializedData = localStorage.getItem(storageKey);
          try {
            if (serializedData !== null) {
              data = JSON.parse(serializedData);
            } else {
              data[store.storeId] = {};
            }
            data[store.storeId] = editedItems;

            localStorage.setItem(storageKey, JSON.stringify(data));
          } catch (error: unknown) {
            console.error('Failed to save' + storageKey + ' to localStorage:', error);
          }

        }),
        catchError((error: IError) => {
          console.error('Error in setEditedItemToLocalStorage$ effect:', error);
          return of();
        }),
      ),
    {dispatch: false},
  );

  getEditedItemsWithValueFromLocalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getEditedItemsWithValueFromLocalStorage),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectEditedItems)),
      ),
      mergeMap(([_, store, editedItems]) => {
        let data: { [storeId: string]: { [code: string]: StockItem } } = {};
        const storageKey = 'savedStockManagerEdits';
        const serializedData = localStorage.getItem(storageKey);

        if (serializedData !== null) {
          data = JSON.parse(serializedData);
          return of(StockManagerActions.setEditedItemsWithValueFromLocalStorage({editedItems: data, store}));
        }

        return EMPTY;
      }),
      catchError((error: IError) => {
        console.error('Error in setEditedItemsWithValueFromLocalStorage$ effect:', error);
        return EMPTY;
      }),
    ),
  );


  //[NGPReport] Clear Saved Edits For Store
  clearSavedNgpReportEditsForStore$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StockManagerActions.removeEditedItemFromLocalStorage),
        withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
        tap(([_, store]) => {
          clearEditedItemsInLocalStorage(store, 'savedStockManagerEdits');
        }),
      ),
    {dispatch: false},
  );


  // this happens on multiple actions
  updateStockItemsWithEdited$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        StockManagerActions.getAllStockItemsSuccess,
        StockManagerActions.getPaginationResultsForStockItemsSuccess,
        StockManagerActions.getSearchResultsForStockItemsSuccess,
        StockManagerActions.setNextPageForSharedGridAtStoreId,
        StockManagerActions.setEditedItemsToStockPage,
        StockManagerActions.setEditedItemAdd,
        StockManagerActions.setEditedItemRemove,
      ),
      withLatestFrom(
        this.store.pipe(select(selectEditedItems)),
        this.store.pipe(select(selectTypesenseQueryData)),
        this.store.pipe(select(selectAllStockItemsForCurrentSelectedStore)),
      ),
      mergeMap(([_, editedItems, queryData, stockItems]) => {
        const {store} = queryData;
        let updatedStockItems: StockItem[] = [];

        if (editedItems && Object.keys(editedItems).length > 0) {
          const editedItemCodes = Object.keys(editedItems);
          updatedStockItems = stockItems.map((stockItem: StockItem) => {
            if (editedItemCodes.includes(stockItem.code)) {
              const editedItem = editedItems[stockItem.code];
              return {
                ...editedItem,
              };
            }
            return stockItem;
          });
        } else {
          updatedStockItems = stockItems;
        }

        return of(
          StockManagerActions.updateStockItemsWithEditedSuccess({
            stockItems: updatedStockItems,
            store,
            editedItems: editedItems ? Object.values(editedItems) : [],
          }),
        );
      }),
    ),
  );

  // =========================================
  // Item Disabling Rules
  // =========================================
  //'[Stock Manager][Price] Set The Price, NGP for Stock Manager',
  updatePriceGpForNgpReport$ = createEffect(() => this.actions$.pipe(
    ofType(StockManagerActions.setPriceGpForStockManager),
    withLatestFrom(
      this.store.pipe(select(selectSelectedUserStore))),
    switchMap(([action, currentSelectedStore]) => {
      const storeId = currentSelectedStore.storeId;
      let copiedStockItem: StockItem = {...action.stockItem};

      if (action.field === 'sellPriIncl1') {
        const obj = calculateGPFromPrice(copiedStockItem, currentSelectedStore, 2);
        copiedStockItem.nominalGP = obj.nominalGP;
      } else if (action.field === 'nominalGP') {
        if (copiedStockItem.nominalGP >= 100) {
          copiedStockItem.nominalGP = 99.99;
        }

        copiedStockItem.sellPriIncl1 = convertToDecimal(calculatePriceFromGpValue(currentSelectedStore, copiedStockItem), 2);
      }
      return [
        StockManagerActions.setPriceGpForStockMangerWithStoreID({
          stockItem: copiedStockItem,
          field: action.field,
          storeId: currentSelectedStore,
        }),
        StockManagerActions.setEditedItem({stockItem: copiedStockItem}),
      ];
    }),
  ));

  // ====================================================================================================
  // Set Price And NGP For Stock Manager
  // ====================================================================================================
  //'[Stock Manager][Preview Columns] Get Stock Manager Preview Columns'
  getStockManagerPreviewColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getStockManagerPreviewColumns),
      withLatestFrom(this.store.pipe(select(selectUser))),
      switchMap(([_, {id}]) => {
        return from(this.collectionUsersSettingsService.getDocument<{
          [p: number]: string
        }>(path_users_userId_settings_stock_edits_preview(id))).pipe(
          map((columnData: {
            [key: number]: string
          }) => StockManagerActions.setStockManagerPreviewColumnsSuccess({columnData: columnData ? columnData : {}})),
          catchError((error: IError) => of(StockManagerActions.setStockManagerPreviewColumnsFailure({error}))),
        );
      }),
    ),
  );
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>


  // Everything for Preview And Submit
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>
  //'[Stock Manager][Update Firebase] Set Stock Updates On Firebase For Stock Manager'
  setStockUpdatesForStockManger$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.setStockUpdatesForStockManger),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      switchMap(([{itemsToUpdate, taggedItems, multipleSuppliers}, store]) => {
        if (Object.keys(taggedItems).length > 0) {
          void this.collectionSharedStockService.updateStockTags(taggedItems, store);
        }
        if (Object.keys(multipleSuppliers).length > 0) {
          void this.collectionSharedStockService.updateMultipleSuppliers(multipleSuppliers, store);
        }

        if (Object.keys(itemsToUpdate).length) {
          return from(this.firebaseService.stockUpdate(store.storeId, itemsToUpdate)).pipe(
            mergeMap(() =>
              [
                StockManagerActions.removeEditedItemsAfterStockUpdate({store}),
                StockManagerActions.removeEditedItemFromLocalStorage(),
              ]),
            catchError((error: IError) =>
              of(StockManagerActions.stockUpdateFailure({error})),
            ),
          );
        } else if (Object.keys(taggedItems).length > 0) {
          return of(
            StockManagerActions.removeEditedItemsAfterStockUpdate({store}),
            StockManagerActions.removeEditedItemFromLocalStorage(),
          );
        } else {
          return EMPTY;
        }
      }),
    ),
  );


  removeEditedItemsAfterStockUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.removeEditedItemsAfterStockUpdate),
      map(() => StockManagerActions.getSearchResultsForStockItems({idField: ''})),
    ),
  );
  undoAllEditsToStockManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.undoAllEditsToStockManager),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      switchMap(([_, store]) => {
        return from([StockManagerActions.removeEditedItemsAfterStockUpdate({store}), StockManagerActions.removeEditedItemFromLocalStorage()]);
      }),
    ),
  );
  //'[Stock Manager][Pagination] Set Page Size For Shared Grid',
  setPageSizeForSharedGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.setPageSizeForSharedGrid),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectPaginationDataForUserSelectedStore)),
      ),
      map(([{pageSize}, store, {currentPage}]) => {
        // Dispatch success action with pageSize and store ID
        return StockManagerActions.setPageSizeForSharedGridAtStoreId({
          pageSize: pageSize,
          store: store,
          pageNumber: currentPage,
        });
      }),
    ),
  );
  //'[Stock Manager][Pagination] Set Next Page For Shared Grid',
  setNextPageForSharedGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.setNextPageForSharedGrid),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectPaginationDataForUserSelectedStore)),
        this.store.pipe(select(selectCurrentPageAndTab)),
      ),
      mergeMap(([_, store, paginationData, {currentTab}]) => {
        if (currentTab !== 'preview') {
          const newPaginationData = getDeepCopyOfObject(paginationData);
          newPaginationData.currentPage++;
          return of(StockManagerActions.setNextPageForSharedGridAtStoreId({paginationData: newPaginationData, store}));
        } else {
          return EMPTY;
        }
      }),
    ));
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>


  // Pagination and Search functions
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>
  //'[Stock Manager][Pagination] Get Pagination Results For Stock Item [Grid]',
  getPaginationResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getPaginationResultsForStockItems),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([{pageNumber}, queryData]) => {
        const {
          store,
          paginationData: {pageSize, searchedValue},
          searchableFields,
          sortType,
          filters,
          advancedFilterGroup,
        } = queryData;

        return this.typeSenseService
          .searchStockItems(
            searchedValue,
            pageNumber,
            pageSize,
            store,
            searchableFields,
            filters,
            sortType,
            advancedFilterGroup,
          )
          .pipe(
            mergeMap((stockItemsRaw: TypeSenseSearchResponse<StockItem>) => {
              const paginationData: IPaginationData = {
                totalItems: stockItemsRaw.found,
                pageSize,
                currentPage: pageNumber,
                searchedValue,
              };
              const facetCounts: IFacetCounts = createFacetCountsMap(stockItemsRaw.facet_counts);
              const mappedStockItems = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
              let stockItems: StockItem[] = mappedStockItems.map((item: StockItem) => ({
                ...item,
                storeId: store.storeId,
                store: store.name,
                isEdited: false,
                isError: false,
                multipleSuppliers: item.multipleSuppliers ?? [],
              }));
              stockItems = stockItems.map((item: StockItem) => {
                const updatedStockItem = calculateGPFromPrice(item, store, 2);
                if (isNaN(updatedStockItem.nominalGP)) {
                  updatedStockItem.nominalGP = 100;
                }
                return {...updatedStockItem, ...item};
              });

              return [
                StockManagerActions.getPaginationResultsForStockItemsSuccess({
                  stockItems,
                  store,
                  paginationData,
                  facetCounts,
                }),
                StockManagerActions.getNextPageForStockItems(),
              ];
            }),
            catchError((error: IError) =>
              of(StockManagerActions.getPaginationResultsForStockItemsFailure({error, store})),
            ),
          );
      }),
    ),
  );
  //'[Stock Manager][Pagination] Get Next Page For Stock Items',
  getNextPageForStockItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getNextPageForStockItems),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      filter(([_, queryData]) => !!queryData.store && !!queryData.paginationData && !!queryData.searchableFields),
      mergeMap(([_, queryData]) => {
        const {
          store,
          paginationData: {currentPage, pageSize, searchedValue, totalItems},
          searchableFields,
          sortType,
          filters,
          advancedFilterGroup,
        } = queryData;

        const nextPageNumber = currentPage + 1 < totalItems / pageSize ? currentPage + 1 : 1;

        return this.typeSenseService
          .searchStockItems(
            searchedValue,
            nextPageNumber,
            pageSize,
            store,
            searchableFields,
            filters,
            sortType,
            advancedFilterGroup,
          )
          .pipe(
            map((nextPageItems: TypeSenseSearchResponse<StockItem>) => {
              const mappedNextPageItems = mapTypeSenseObjectToGeneric<StockItem>(nextPageItems).map((item) => ({
                ...item,
                storeId: store.storeId,
                store: store.name,
              }));
              mappedNextPageItems.forEach((item: StockItem) => {
                const stockItem = calculateGPFromPrice(item, store, 2);
                item.disabled = false;
                item.nominalGP = +stockItem.nominalGP;
              });
              const nextPaginationData: IPaginationData = {
                totalItems: nextPageItems.found,
                pageSize,
                currentPage: currentPage,
                searchedValue,
              };
              return StockManagerActions.getNextPageForStockItemsSuccess({
                stockItems: mappedNextPageItems,
                store,
                nextPaginationData,
              });
            }),
            catchError((error: IError) =>
              of(StockManagerActions.getNextPageForStockItemsFailure({error, store})),
            ),
          );
      }),
    ),
  );
  //'[Stock Manager][Search Results] Get Search Results For Stock Items',
  getSearchResultsForStockItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getSearchResultsForStockItems),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData)),
        this.store.pipe(select(selectUserStores))),
      mergeMap(([{idField}, queryData, stores]) => {
        const {
          store,
          paginationData: {pageSize},
          searchableFields,
          sortType,
          filters,
          advancedFilterGroup,
        } = queryData;

        return this.typeSenseService
          .searchStockItems(
            idField,
            1,
            pageSize,
            store,
            searchableFields,
            filters,
            sortType,
            advancedFilterGroup,
          )
          .pipe(
            map((stockItemsRaw: TypeSenseSearchResponse<StockItem>) => {
              const paginationData: IPaginationData = {
                totalItems: stockItemsRaw.found,
                pageSize,
                currentPage: 1,
                searchedValue: idField,
              };
              const mappedStockItems = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
              let stockItems: StockItem[] = mappedStockItems.map((item: StockItem) => ({
                ...item,
                storeId: store.storeId,
                store: store.name,
                isEdited: false,
                isError: false,
                multipleSuppliers: item.multipleSuppliers ?? [],
              }));
              stockItems = stockItems.map((item: StockItem) => {
                const updatedStockItem = calculateGPFromPrice(item, store, 2);
                if (isNaN(updatedStockItem.nominalGP)) {
                  updatedStockItem.nominalGP = 100;
                }
                return {...updatedStockItem, ...item} as StockItem;
              });
              return [
                StockManagerActions.getSearchResultsForStockItemsSuccess({
                  stockItems,
                  store,
                  paginationData,
                  facetCounts: createFacetCountsMap(stockItemsRaw.facet_counts),
                  stores,
                }),
                StockManagerActions.getNextPageForStockItems(),
              ];
            }),
            mergeMap((actions) => from(actions)),
            catchError((error: IError) =>
              of(StockManagerActions.getSearchResultsForStockItemsFailure({error, store})),
            ),
          );
      }),
    ),
  );
  // =======================================================================
  private readonly icons = Icons;

  // =======================================================================
  // Search Results
  //'[Stock Manager][Edited Items] Get Edited Items from Typesense'
  getEditedItemsFromTypesense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getEditedItemsFromTypesense),
      withLatestFrom(
        this.store.pipe(select(selectTypesenseQueryData)),
        this.store.pipe(select(selectEditedItems)),
        this.store.pipe(select(selectPriceBandingForUserSelectedStore)),
        this.store.pipe(select(selectSharedGridVatRateBySelectedStore)),
        this.store.pipe(select(selectEnablePriceBandingForStockManager)),
      ),
      mergeMap(([_, queryData, editedItems, priceBanding, vatRates, isPriceBanding]) => {
        const {store, paginationData: {searchedValue}, searchableFields} = queryData;
        const fetchAllPages = (currentPage = 1, combinedHits: StockItem[] = []): Observable<{
          hits: StockItem[];
          facetCounts: IFacetCounts | null
        }> => {
          return this.typeSenseService.searchEditedStockItems(searchedValue, currentPage, store, searchableFields, editedItems).pipe(
            mergeMap((stockItemsRaw: TypeSenseSearchResponse<StockItem>) => {
              const newHits = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
              const allHits = [...combinedHits, ...newHits];
              const facetCounts = createFacetCountsMap(stockItemsRaw.facet_counts || []);

              if (allHits.length < stockItemsRaw.found) {
                return fetchAllPages(currentPage + 1, allHits);
              } else {
                return of({hits: allHits, facetCounts});
              }
            }),
          );
        };

        return fetchAllPages().pipe(
          mergeMap(({hits: allStockItems, facetCounts}) => {
            const mappedEditedStockItems = allStockItems.map((item: StockItem) => ({
              ...item,
              storeId: store.storeId,
              store: store.name,
              isEdited: true,
              isError: false,
            }));
            const discrepancies: {
              code: string;
              field: string;
              original: string | number | boolean | Date;
              current: string | number | boolean | Date
            }[] = [];
            const editable = editableFields;
            const omittedKeys = ['originalValue', 'isSelected', 'isEdited', '_tags', 'multipleSuppliers', 'supplierDetails'];
            mappedEditedStockItems.forEach(item => {
              const editedItem = editedItems[item.code];
              if (editedItem) {
                Object.keys(item).forEach(key => {
                  if (!omittedKeys.includes(key) && item[key] !== editedItem.originalValue[key]?.value && sItemKeyToInt[key] && editable.includes(key)) {
                    discrepancies.push({
                      code: item.code,
                      field: sItemKeyToInt[key],
                      original: editedItem.originalValue[key]?.value,
                      current: item[key],
                    });
                  }
                });
              }
            });

            if (discrepancies.length > 0) {
              const discrepancyMessages = discrepancies.map(d =>
                `Item Code: ${d.code} |   Field: ${AO_KEY_2_TITLE[sItemIntToKey[d.field]]}   |   Local: ${d.original}   |   Remote: ${d.current}`,
              );
              const ModalProps: ISharedModalBasic = {
                buttonAccept: false,
                buttonAcceptText: '',
                buttonClose: true,
                buttonCloseCross: false,
                buttonCloseText: 'Continue',
                modalTitle: 'Discrepancies Found in Stock Items',
                modalTitleIcon: this.icons.infoSlabCircleOutline,
                modalTitleIconColor: 'red',
                contextHeading: 'Some stock items were updated by another source while you were editing them.',
                contextDescription: [
                  'Please review the discrepancies below:',
                  ...discrepancyMessages,
                ],
                cssClass: ['shared-basic-modal-css-larger'],
              };
              void this.sharedModalUtils.createModal(ModalProps);
            }

            const filterEditedItems = (editedItems: { [p: string]: StockItem }): { [p: string]: StockItem } => {
              const filteredItems: { [p: string]: StockItem } = {};

              for (const [key, stockItem] of Object.entries(editedItems)) {
                const differences: {
                  field: string;
                  original: string | number | boolean | Date;
                  current: string | number | boolean | Date
                }[] = [];

                const hasDifferences = Object.keys(stockItem).some((field: string) => {
                  const originalValue = stockItem.originalValue?.[field]?.value;
                  const currentValue = stockItem[field];
                  if (originalValue !== currentValue) {
                    differences.push({field, original: originalValue, current: currentValue});
                    return true;
                  }
                  return false;
                });

                if (hasDifferences) {
                  filteredItems[key] = stockItem;
                }
              }

              return filteredItems;
            };

            return [
              StockManagerActions.getEditedItemsFromTypesenseSuccess({
                editedItemsFromTypesense: mappedEditedStockItems,
                store,
                facetCounts: facetCounts || {},
                priceBandedItems: isPriceBanding ? applyPriceBanding(
                  Object.values(filterEditedItems(editedItems)),
                  priceBanding,
                  PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS,
                  store,
                  vatRates,
                ) : Object.values(filterEditedItems(editedItems)),
              }),
            ];
          }),
          catchError((error: IError) =>
            of(StockManagerActions.getEditedItemsFromTypesenseFailure({error, store})),
          ),
        );
      }),
    ),
  );
  //'[Stock Manager][Item Disabling] Set Item to be Disabled',
  setItemDisabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.setItemDisabledStatus),
      withLatestFrom(
        this.store.pipe(select(selectTypesenseQueryData)),
        this.store.pipe(select(selectItemDisablingRules)),
        this.store.pipe(select(selectDontDisplayModalAgain)),
      ),
      switchMap(([action, queryData, disabledRules, dontDisplayModalAgain]) => {
        const {store} = queryData;
        const actions: Action[] = [];
        const stockItem = {...action.stockItem};
        if (disabledRules) {
          StockFunctions.enableItem(stockItem, disabledRules, action.enable,
            {
              onHoldCode: stockItem.originalValue['onHoldCode'].value as number,
              lineColourCode: stockItem.originalValue['lineColourCode'].value as number,
            });
        } else if (!dontDisplayModalAgain) {
          const ModalProps: ISharedModalBasic = {
            buttonAccept: false,
            buttonAcceptText: '',
            buttonClose: true,
            buttonCloseCross: false,
            buttonCloseText: 'Continue',
            modalTitle: `No Item Disabling Rules Found`,
            modalTitleIcon: this.icons.infoSlabCircleOutline,
            modalTitleIconColor: 'red',
            contextHeading: `This Item Will Only Be ${!action.enable ? 'Disabled' : 'Enabled'} On Gallix`,
            contextDescription: [
              'No changes will take place on IQ.',
              'If you wish for changes to be made on IQ please complete the Stock Item Disabling settings.',
            ],
            cssClass: ['shared-basic-modal-css-medium'],
          };
          return from(this.sharedModalUtils.createModal(ModalProps)).pipe(
            mergeMap((result: OverlayEventDetail<ISharedModalBasicResponse>) => {
              return [StockManagerActions.setItemDisabledWithStoreID({
                stockItem,
                store,
              }), StockManagerActions.setDontDisplayModalAgain()];
            }),
          );
        }
        return [StockManagerActions.setEditedItem({stockItem})];
      }),
    ),
  );

// <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>
  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
    private typeSenseService: TypeSenseService,
    private collectionUsersSettingsService: CollectionUsersSettingsService,
    private collectionSharedStockService: CollectionSharedStockService,
    private collectionSharedSingularDocumentsService: CollectionSharedSingularDocumentsService,
    private collectionStoresSettingsService: CollectionStoresSettingsService,
    private collectionStoresDocsService: CollectionStoresDocsService,
    private readonly store: Store,
    private sharedModalUtils: SharedModalUtils,
  ) {
  }
}
