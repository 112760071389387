import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import * as NGPReportActions from './ngp-report.actions';
import {IInitialState} from '../../../shared-utilities/models-old/initial-state/initial-state';
import {
  FiltersAndTools,
  filtersAndToolsDefault,
} from '../../../shared-utilities/models-old/ngp-reports/filters-tools-ngp';
import {NGPReport} from '../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {FilterAndToolUtils} from '../../../shared-utilities/models-old/utils-old/filterAndToolUtils';
import {
  DisabledRules,
  LineColour,
  StockItem,
  Supplier,
  VatRates,
} from '../../../shared-utilities/models-old/datastructures';
import {ReportUtils} from '../../../shared-utilities/utils-old/shared-utils-old/report-utils';
import {GridUtils} from '../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {ColDef} from 'ag-grid-community';
import {StoreHeaderMenuData} from '../../../shared-utilities/models-old/ngp-report-grid/header-menu-data';
import {IStore} from '../../../shared/shared-models/store/store';
import {PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS} from '../../../shared/shared-utils/price/price-banding.utils';
import * as NGPReportUtils from './ngp-report.reducer.utils';
import {checkForDisabledItems, mergeNgpReportsFromLocalStorage} from './ngp-report.reducer.utils';
import {IDepartment} from '../../../shared/shared-models/stock/departments';
import {getDeepCopyOfObject} from '../../../shared/shared-utils/object/object.utils';
import {selectGetSelectNGPReportsFiltered} from './ngp-report.selectors.utils';
import {ISharedStoreSettings} from '../../../shared/shared-models/firebase/shared-store-settings';

export interface NGPReportState extends IInitialState {
  // NGP Report------------------------------
  isNGPReportsLoading: boolean;
  // Converted to Maps for predictable insertion order:
  ngpReports: Map<string, Map<string, NGPReport>>;
  editedNgpReports: { [storeId: string]: NGPReport[] };
  ngpReportsNewlyAdded: Map<string, Map<string, NGPReport>>;
  ngpReportsNewlyRemoved: Map<string, Map<string, NGPReport>>;
  // Sub Header -----------------------------
  filtersAndTools: FiltersAndTools;
  // Line Colours ---------------------------
  isLineColourLoading: boolean;
  lineColours: { [storeId: string]: LineColour } | null;
  storeSettings: { [storeId: string]: ISharedStoreSettings } | null;
  // Stock Items ----------------------------
  isStockItemsLoading: boolean;
  // Grid Function --------------------------
  ngpReportGridColDefs: { [storeId: string]: ColDef[] };
  visibleFields: { [key: string]: boolean };
  previewColumnData: { [key: number]: boolean };
  // Edited and sorting State for column ----
  headerMenuData: StoreHeaderMenuData;
  // Store Departments ----------------------
  isDepartmentsLoading: boolean;
  departments: { [storeId: string]: IDepartment[] };
  // Suppliers ------------------------------
  isStoreSuppliersLoading: boolean;
  storeSuppliers: { [storeId: string]: { [key: string]: Supplier } };
  // Vat Rates ------------------------------
  vatRates: { [storeId: string]: VatRates };
  // Disabled Rules
  disabledRules: { [storeId: string]: DisabledRules };
}

export const initialNGPReportState: NGPReportState = {
  // Initial --------------------------------
  errors: [],
  // NGP Report------------------------------
  isNGPReportsLoading: false,
  ngpReports: new Map(),
  editedNgpReports: {},
  ngpReportsNewlyAdded: new Map(),
  ngpReportsNewlyRemoved: new Map(),
  // Sub Header -----------------------------
  filtersAndTools: filtersAndToolsDefault,
  // Line Colours ---------------------------
  isLineColourLoading: false,
  lineColours: null,
  storeSettings: {},
  // Stock Items ----------------------------
  isStockItemsLoading: false,
  // Grid Function --------------------------
  ngpReportGridColDefs: {},
  visibleFields: {},
  previewColumnData: {},
  // Edited and sorting State for column ----
  headerMenuData: {},
  // Store Departments ----------------------
  isDepartmentsLoading: false,
  departments: {},
  // Suppliers ------------------------------
  isStoreSuppliersLoading: false,
  storeSuppliers: {},
  // Vat Rates ------------------------------
  vatRates: {},
  // Disabled Rules
  disabledRules: {},
};

const createCoreReducer: ActionReducer<NGPReportState> = createReducer(
  initialNGPReportState,

  // ====================================================================================================
  // Get Line Colours
  // ====================================================================================================
  on(NGPReportActions.getLineColours, (state: NGPReportState, action) => ({
    ...state,
    isLineColourLoading: true,
  })),
  on(NGPReportActions.getLineColoursSuccess, (state: NGPReportState, {lineColours, selectedStore, storeSettings}) => {
    const storeId = selectedStore.storeId;
    const currentStoreReports: Map<string, NGPReport> = state.ngpReports.get(storeId) || new Map();
    const reportsArray = Array.from(currentStoreReports.values());
    let updatedReportsArray = getDeepCopyOfObject(reportsArray);
    if (storeSettings.lineColoursEnabled) {
      updatedReportsArray = GridUtils.applyLineColoursToNgpReports(lineColours, reportsArray);
    }
    const updatedStoreReports = new Map<string, NGPReport>();
    updatedReportsArray.forEach(report => updatedStoreReports.set(report.stockId, report));
    const newNgpReports = new Map(state.ngpReports);
    newNgpReports.set(storeId, updatedStoreReports);
    return {
      ...state,
      isLineColourLoading: false,
      lineColours: {
        ...state.lineColours,
        [storeId]: lineColours,
      },
      storeSettings: {
        ...state.storeSettings,
        [storeId]: storeSettings,
      },
      ngpReports: newNgpReports,
    };
  }),
  on(NGPReportActions.getLineColoursFailure, (state: NGPReportState, {error}) => ({
    ...state,
    isLineColourLoading: false,
    lineColours: null,
    errors: [...state.errors, error],
  })),
  on(NGPReportActions.setIsLineColoursLoading, (state: NGPReportState, {isLoading}) => ({
    ...state,
    isLineColourLoading: isLoading,
  })),

  // ====================================================================================================
  // Get Stock Items
  // ====================================================================================================
  on(NGPReportActions.getStockItem, (state: NGPReportState) => ({
    ...state,
    isStockItemsLoading: true,
    stockItems: null,
  })),
  on(NGPReportActions.getStockItemSuccess, (state: NGPReportState, {stockItems, store}) => {
    const storeId = store.storeId;
    const existingReports: Map<string, NGPReport> = state.ngpReports.get(storeId) || new Map();

    if (existingReports.size < 1) {
      stockItems = stockItems.map((item: StockItem) => ({
        ...item,
        newlyAdded: false,
      }));
    }

    let data = getStockItemSuccessCalculations(state, store, stockItems, true);

    // Determine newly added items: those that are in the fetched data but not in existingReports.
    const newReports = new Map<string, NGPReport>();
    for (const report of Object.values(data.ngpReports)) {
      if (!existingReports.has(report.stockId)) {
        newReports.set(report.stockId, report);
      }
    }

    data = getStockItemSuccessCalculations(state, store, stockItems, true, Array.from(newReports.values()));
    const disabledCheckedArray = checkForDisabledItems(
      stockItems,
      Object.values(data.ngpReports),
      state.disabledRules[storeId],
      true,
    );
    const updatedReportsMap = new Map<string, NGPReport>();
    disabledCheckedArray.forEach(report => updatedReportsMap.set(report.stockId, report));

    if (existingReports.size > 0) {
      data.filtersAndTools.newItemCount = newReports.size;
    }

    const mergedReportsObj = mergeNgpReportsFromLocalStorage(
      storeId,
      Object.fromEntries(updatedReportsMap),
      'savedNgpReportEdits',
    );
    const finalReportsMap = new Map<string, NGPReport>(Object.entries(mergedReportsObj));
    const finalStoreReports = existingReports.size < 1 ? finalReportsMap : existingReports;
    let sortedFinalStoreReports: Map<string, NGPReport>;
    if (state.headerMenuData?.[storeId] && Object.keys(state.headerMenuData?.[storeId])?.length < 1) {
      sortedFinalStoreReports = new Map(
        Array.from(finalStoreReports.entries()).sort(([, a], [, b]) => a.diffGP - b.diffGP),
      );
    } else {
      sortedFinalStoreReports = finalStoreReports;
    }

    const removedReports = new Map<string, NGPReport>();
    for (const [stockId, report] of existingReports.entries()) {
      if (!(stockId in data.ngpReports)) {
        removedReports.set(stockId, report);
      }
    }

    const newNgpReports = new Map(state.ngpReports);
    newNgpReports.set(storeId, sortedFinalStoreReports);
    const newNgpReportsNewlyAdded = new Map(state.ngpReportsNewlyAdded);
    newNgpReportsNewlyAdded.set(storeId, newReports);
    const newNgpReportsNewlyRemoved = new Map(state.ngpReportsNewlyRemoved);
    newNgpReportsNewlyRemoved.set(storeId, removedReports);

    return {
      ...state,
      isStockItemsLoading: false,
      ngpReports: newNgpReports,
      ngpReportsNewlyAdded: newNgpReportsNewlyAdded,
      ngpReportsNewlyRemoved: newNgpReportsNewlyRemoved,
      filtersAndTools: data.filtersAndTools,
    };
  }),
  on(NGPReportActions.getStockItemFailure, (state: NGPReportState, {error}) => ({
    ...state,
    isStockItemsLoading: false,
    stockItems: {},
    ngpReports: new Map(),
    errors: [...state.errors, error],
  })),
  on(NGPReportActions.setIsStockItemLoading, (state: NGPReportState, {isLoading}) => ({
    ...state,
    isStockItemsLoading: isLoading,
  })),

  // ====================================================================================================
  // Get Items Disabling Rules
  // ====================================================================================================
  on(NGPReportActions.getStockItemDisablingRulesSuccess, (state: NGPReportState, {store, rulesDoc}) => ({
    ...state,
    disabledRules: {
      ...state.disabledRules,
      [store.storeId]: rulesDoc,
    },
  })),
  on(NGPReportActions.getStockItemDisablingRulesFailure, (state: NGPReportState, {errors}) => ({
    ...state,
    errors: [...state.errors, errors],
  })),
  on(NGPReportActions.setItemDisabledWithStoreID, (state: NGPReportState, {ngpReport, store}) => {
    const storeId = store.storeId;
    const storeReports = new Map(state.ngpReports.get(storeId) || []);
    const currentReport = storeReports.get(ngpReport.stockId);
    if (!currentReport) return state;
    const updatedReport = {...currentReport, ...ngpReport};
    storeReports.set(ngpReport.stockId, updatedReport);
    const newNgpReports = new Map(state.ngpReports);
    newNgpReports.set(storeId, storeReports);
    return {
      ...state,
      ngpReports: newNgpReports,
    };
  }),

  // ====================================================================================================
  // Update NGP Report
  // ====================================================================================================
  on(NGPReportActions.updateSingleNGPReportWithStoreId, (state: NGPReportState, {ngpReport, storeId}) => {
    const storeReports = new Map(state.ngpReports.get(storeId) || []);
    const currentReport = storeReports.get(ngpReport.stockId) || ({} as NGPReport);
    const updatedReport = {...currentReport, ...ngpReport};
    storeReports.set(ngpReport.stockId, updatedReport);
    const newNgpReports = new Map(state.ngpReports);
    newNgpReports.set(storeId, storeReports);
    const amountEdited = Array.from(storeReports.values()).filter((report: NGPReport) => report['isEdited']).length;
    return {
      ...state,
      filtersAndTools: {
        ...state.filtersAndTools,
        editedCount: amountEdited,
      },
      ngpReports: newNgpReports,
    };
  }),
  on(NGPReportActions.updateAllNGPReportsWithStoreID, (state: NGPReportState, {ngpReports, storeId}) => {
    const reportsToUpdate = new Map<string, NGPReport>();
    ngpReports.forEach((report: NGPReport) => {
      reportsToUpdate.set(report.stockId, report);
    });
    const storeReports = new Map(state.ngpReports.get(storeId) || []);
    reportsToUpdate.forEach((report, stockId) => {
      storeReports.set(stockId, report);
    });
    const amountEdited = Array.from(storeReports.values()).filter((report: NGPReport) => report['isEdited']).length;
    const newNgpReports = new Map(state.ngpReports);
    newNgpReports.set(storeId, storeReports);
    return {
      ...state,
      filtersAndTools: {
        ...state.filtersAndTools,
        editedCount: amountEdited,
      },
      ngpReports: newNgpReports,
    };
  }),
  on(NGPReportActions.updateIsSelectedForSingleNGPReportWithStoreId, (state, {ngpReport, storeId}) => {
    const storeReports = state.ngpReports.get(storeId);
    if (!storeReports) return state;

    const currentReport = storeReports.get(ngpReport.stockId);
    if (!currentReport) return state;
    const updatedReport = {...currentReport, ...ngpReport};
    const updatedStoreReports = new Map(storeReports);
    updatedStoreReports.set(ngpReport.stockId, updatedReport);
    const newNgpReports = new Map(state.ngpReports);
    newNgpReports.set(storeId, updatedStoreReports);
    const newEditedCount = updatedStoreReports.size;

    return {
      ...state,
      filtersAndTools: {
        ...state.filtersAndTools,
        editedCount: newEditedCount,
      },
      ngpReports: newNgpReports,
    };
  }),


  // ====================================================================================================
  // Set Filters and Tools
  // ====================================================================================================
  on(NGPReportActions.setNGPReportFiltersAndToolsWithStore, (state: NGPReportState, {filtersAndTools, store}) => {
    const filtersAndToolsUpdated = FilterAndToolUtils.changeFilterAndToolsCounts(filtersAndTools);
    const storeHeaderMenuData = NGPReportUtils.setNGPReportStoreHeaderMenuDataFromFiltersAndToolsForPriceBanding(
      filtersAndToolsUpdated,
      PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS,
      state.headerMenuData,
      store,
    );
    return {
      ...state,
      filtersAndTools: filtersAndToolsUpdated,
      headerMenuData: storeHeaderMenuData,
    };
  }),
  on(NGPReportActions.setNewlyAddedItemsVisibilityWithStoreID, (state: NGPReportState, {storeId}) => {
    const storeReports = (state.ngpReports.get(storeId) || new Map()) as Map<string, NGPReport>;
    const newlyAdded = (state.ngpReportsNewlyAdded.get(storeId) || new Map()) as Map<string, NGPReport>;
    const updatedStoreReports = addNewlyAddedItemsToNgpReports(storeReports, newlyAdded);
    let filtersAndTools: FiltersAndTools = state.filtersAndTools;
    if (updatedStoreReports.size > 0) {
      filtersAndTools = FilterAndToolUtils.determineFiltersAndTools(Array.from(updatedStoreReports.values()), filtersAndTools);
      filtersAndTools = FilterAndToolUtils.changeFilterAndToolsCounts(filtersAndTools);
    }
    let copiedMenuData = getDeepCopyOfObject(state.headerMenuData);
    Object.keys(state.headerMenuData).forEach((sId: string) => {
      Object.keys(state.headerMenuData[sId]).forEach((colId: string) => {
        delete copiedMenuData[sId][colId].sortType;
      });
    });
    filtersAndTools.total =
      (state.ngpReportsNewlyAdded.get(storeId)?.size || 0) + (state.ngpReports.get(storeId)?.size || 0);
    const newNgpReportsNewlyAdded = new Map(state.ngpReportsNewlyAdded);
    newNgpReportsNewlyAdded.set(storeId, new Map());
    const newNgpReports = new Map(state.ngpReports);
    newNgpReports.set(storeId, updatedStoreReports);
    return {
      ...state,
      filtersAndTools,
      ngpReportsNewlyAdded: newNgpReportsNewlyAdded,
      ngpReports: newNgpReports,
      headerMenuData: copiedMenuData,
    };
  }),

  // ====================================================================================================
  // Set Grid Column Definitions
  // ====================================================================================================
  on(NGPReportActions.setNewlyRemovedItemsVisibilitySuccess, (state: NGPReportState, {
    filtersAndTools,
    headerMenuData,
    ngpReports,
    ngpReportsNewlyRemoved,
  }) => ({
    ...state,
    filtersAndTools,
    headerMenuData,
    ngpReports,
    ngpReportsNewlyRemoved,
  })),
  on(NGPReportActions.setNGPReportGridColDefsWithStoreID, (state: NGPReportState, {colDefs, storeId}) => ({
    ...state,
    ngpReportGridColDefs: {
      ...state.ngpReportGridColDefs,
      [storeId]: colDefs,
    },
  })),

  // ====================================================================================================
  // Set Editing and Sorting for Column
  // ====================================================================================================
  on(NGPReportActions.setNGPReportMenuActionsWithStore, (state: NGPReportState, {store, gridHeaderMenu}) => {
    const headerMenuData: StoreHeaderMenuData = NGPReportUtils.setNGPReportStoreHeaderMenuActions(
      store,
      state.headerMenuData,
      PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS,
      gridHeaderMenu,
    );
    const filtersAndTools = NGPReportUtils.setFiltersAndToolsPropertyByHeaderMenuActionTypeChange<boolean>(
      state.filtersAndTools,
      'isApplyPriceBandingOn',
      gridHeaderMenu.typeOfChange,
      gridHeaderMenu.value,
    );
    const storeId = store.storeId;
    const storeReports = (state.ngpReports.get(storeId) || new Map()) as Map<string, NGPReport>;
    const sortedNgp = selectGetSelectNGPReportsFiltered(Array.from(storeReports.values()), headerMenuData[storeId]);
    const sortedReducedNgp = new Map<string, NGPReport>();
    sortedNgp.forEach((item: NGPReport) => sortedReducedNgp.set(item.stockId, item));
    const newNgpReports = new Map(state.ngpReports);
    newNgpReports.set(storeId, sortedReducedNgp);
    return {
      ...state,
      ngpReports: newNgpReports,
      filtersAndTools,
      headerMenuData,
    };
  }),

  // ====================================================================================================
  // Set Visible Fields
  // ====================================================================================================
  on(NGPReportActions.setNGPReportVisibility, (state: NGPReportState, {colDef, menuData}) => {
    const updatedVisibleFields = {...state.visibleFields};
    colDef?.forEach((item) => {
      const fieldIndex = menuData?.findIndex((index: string) => index === item.field);
      if (
        item.field === 'icons' ||
        item.field === 'stockId' ||
        item.field === 'desc' ||
        item.field === 'isSelected' ||
        item.field === 'diffGP'
      ) {
        updatedVisibleFields[item.field] = true;
      } else {
        updatedVisibleFields[item.field] = !!menuData?.[fieldIndex];
      }
    });
    return {
      ...state,
      visibleFields: updatedVisibleFields,
    };
  }),
  on(NGPReportActions.updateSingleVisibleField, (state: NGPReportState, {colId, value}) => ({
    ...state,
    visibleFields: {
      ...state.visibleFields,
      [colId]: value,
    },
  })),

  //===============================================================================================================
  // Set the NGP Preview Column Data
  //===============================================================================================================
  on(NGPReportActions.setNgpPreviewColumnsSuccess, (state, {columnData}) => {
    const newVisibleFields = {...state.visibleFields};
    const columnFields = Object.values(columnData);
    Object.keys(newVisibleFields).forEach((field: string) => {
      newVisibleFields[field] = columnFields.includes(field);
    });
    return {
      ...state,
      previewColumnData: newVisibleFields,
    };
  }),
  on(NGPReportActions.setNgpPreviewColumnsFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
  })),

  // ====================================================================================================
  // Get Store Departments
  // ====================================================================================================
  on(NGPReportActions.getStoreDepartments, (state) => ({
    ...state,
    isDepartmentsLoading: true,
  })),
  on(NGPReportActions.getStoreDepartmentsSuccess, (state, {store, departments}) => ({
    ...state,
    isDepartmentsLoading: false,
    departments: {
      ...state.departments,
      [store.storeId]: departments,
    },
    error: null,
  })),
  on(NGPReportActions.getStoreDepartmentsFailure, (state, {error}) => ({
    ...state,
    isDepartmentsLoading: false,
    errors: [...state.errors, error],
  })),
  on(NGPReportActions.setIsStoreDepartmentsLoading, (state, {isLoading}) => ({
    ...state,
    isDepartmentsLoading: isLoading,
  })),

  // ====================================================================================================
  // Get Store Suppliers
  // ====================================================================================================
  on(NGPReportActions.getStoreSuppliersByStoreId, (state) => ({
    ...state,
    isSuppliersLoading: true,
  })),
  on(NGPReportActions.getStoreSuppliersByStoreIdSuccess, (state, {suppliers, storeId}) => ({
    ...state,
    isSuppliersLoading: false,
    storeSuppliers: {
      ...state.storeSuppliers,
      [storeId]: suppliers,
    },
  })),
  on(NGPReportActions.getStoreSuppliersByStoreIdFailure, (state, {error}) => ({
    ...state,
    isSuppliersLoading: false,
    errors: [...state.errors, error],
  })),
  on(NGPReportActions.setIsStoreSuppliersByStoreIdLoading, (state, {isLoading}) => ({
    ...state,
    isSuppliersLoading: isLoading,
  })),

  // ====================================================================================================
  // Remove Selected Items From NGP Report
  // ====================================================================================================
  on(NGPReportActions.removeSelectedNgpReports, (state: NGPReportState) => ({
    ...state,
    isNGPReportsLoading: true,
  })),
  on(NGPReportActions.removeSelectedNgpReportsWithStoreID, (state: NGPReportState, {selectedReports, storeId}) => {
    const storeReports = new Map(state.ngpReports.get(storeId) || []);
    selectedReports.forEach((report: NGPReport) => {
      storeReports.delete(report.stockId);
    });
    const newNgpReports = new Map(state.ngpReports);
    newNgpReports.set(storeId, storeReports);
    return {
      ...state,
      isNGPReportsLoading: false,
      ngpReports: newNgpReports,
    };
  }),

  // ====================================================================================================
  // Update Price, Nom Gp And GD Diff By StoreID
  // ====================================================================================================
  on(NGPReportActions.updatePriceGpForNgpReportWithStoreIDSuccess, (state: NGPReportState, {
    ngpReport,
    field,
    storeId,
  }) => {
    const storeReports = new Map(state.ngpReports.get(storeId) || []);
    storeReports.set(ngpReport.stockId, {
      ...storeReports.get(ngpReport.stockId),
      ...ngpReport,
    });
    const newNgpReports = new Map(state.ngpReports);
    newNgpReports.set(storeId, storeReports);
    const amountEdited = Array.from(storeReports.values()).filter((report: NGPReport) => report['isEdited']).length;
    return {
      ...state,
      filtersAndTools: {
        ...state.filtersAndTools,
        editedCount: amountEdited,
      },
      ngpReports: newNgpReports,
    };
  }),
  on(NGPReportActions.getVatRateConversionSuccess, (state, {vatRates}) => ({
    ...state,
    vatRates,
  })),
  on(NGPReportActions.getVatRateConversionFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
  })),
  on(NGPReportActions.setApplyPriceBandingToNGPReportItemsAtStoreId, (state, {ngpReports, store}) => {
    const storeId = store.storeId;
    const storeReports = new Map(state.ngpReports.get(storeId) || []);
    Object.entries(ngpReports).forEach(([stockId, report]) => {
      storeReports.set(stockId, report);
    });
    const newNgpReports = new Map(state.ngpReports);
    newNgpReports.set(storeId, storeReports);
    return {
      ...state,
      ngpReports: newNgpReports,
    };
  }),
);

export const ngpReportReducer = (state: NGPReportState, action: Action): NGPReportState =>
  createCoreReducer(state, action);

// ----------------------------------------------------------------------
// Utility functions (update these as needed to work with Maps)
// ----------------------------------------------------------------------

export const addNewlyAddedItemsToNgpReports = (
  ngpReports: Map<string, NGPReport>,
  newlyAddedItems: Map<string, NGPReport>,
): Map<string, NGPReport> => {
  const combined = new Map(ngpReports);
  newlyAddedItems.forEach((report, stockId) => {
    combined.set(stockId, {...report, newlyAdded: false});
  });
  return combined;
};

export const getStockItemSuccessCalculations = (
  state: NGPReportState,
  store: IStore,
  stockItems: StockItem[],
  addOGValueField: boolean,
  existingReports?: NGPReport[],
): { filtersAndTools: FiltersAndTools; ngpReports: { [stockId: string]: NGPReport } } => {
  let ngpReportsArray: NGPReport[] = ReportUtils.convertStockItemsToNGPReports(stockItems || [], store.storeId);
  if (state.lineColours && ngpReportsArray.length > 0 && state?.storeSettings?.[store.storeId]?.lineColoursEnabled) {
    ngpReportsArray = GridUtils.applyLineColoursToNgpReports(state.lineColours[store.storeId], ngpReportsArray);
  }
  let filtersAndTools: FiltersAndTools = state.filtersAndTools;
  if (ngpReportsArray.length > 0) {
    filtersAndTools = FilterAndToolUtils.determineFiltersAndTools(ngpReportsArray, filtersAndTools);
    filtersAndTools = FilterAndToolUtils.changeFilterAndToolsCounts(filtersAndTools, existingReports);
  }
  if (addOGValueField) {
    ngpReportsArray = addOriginalValueField(ngpReportsArray);
  }
  const ngpReports = ngpReportsArray.reduce((acc, ngpReport) => {
    acc[ngpReport.stockId] = ngpReport;
    return acc;
  }, {});


  return {ngpReports, filtersAndTools};
};

export const addOriginalValueField = (ngpReports: NGPReport[]): NGPReport[] => {
  ngpReports.forEach((ngpReport: NGPReport): void => {
    ngpReport.originalValue = {};
    Object.keys(ngpReport).forEach((property: string): void => {
      if (property !== 'originalValue') {
        ngpReport.originalValue[property] = {value: ngpReport[property]};
      }
    });
    ngpReport.originalValue['newlyAdded'].value = false;
    if (ngpReport.originalValue['sellPriIncl1'].value === '0' || ngpReport.originalValue['sellPriIncl1'].value === 0) {
      ngpReport.originalValue['sellPriIncl1'].value = 0;
      ngpReport.originalValue['nominalGP'].value = 100;
      ngpReport.originalValue['diffGP'].value = 100;
      ngpReport['sellPriIncl1'] = 0;
      ngpReport['nominalGP'] = 100;
      ngpReport['diffGP'] = 100;
    }

  });
  return ngpReports;
};
