import {Injectable} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  DocumentData,
} from '@angular/fire/compat/firestore';
import {path_shared_store_data_last_updated_stock_tags, path_shared_store_data_stock} from '../database-paths';
import {generateFirebaseCollectionReference} from '../../shared-utils/firestore/firestore.utils';
import {IFirebaseQuery} from '../../shared-models/firebase/firebase-queries';
import {IStore} from '../../shared-models/store/store';
import {Observable} from 'rxjs';
import {StockItem} from '../../../shared-utilities/models-old/datastructures';
import {map} from 'rxjs/operators';
import {castObjectToType} from '../../shared-utils/object/object.utils';

@Injectable({
  providedIn: 'root',
})
export class CollectionSharedStockService {

  constructor(
    private angularFirestore: AngularFirestore,
  ) {
  }

  // ====================================================================================================
  // Stock
  // ====================================================================================================

  getStockItemsCast(
    store: IStore,
    firebaseQueries: IFirebaseQuery[],
    idField: string,
  ): Observable<StockItem[]> {
    return this.getStockItemsCollection(store, firebaseQueries)
      .valueChanges({idField})
      .pipe(
        map((documents: DocumentData[]) => {
          return documents.map((doc: DocumentData) => castObjectToType<StockItem, DocumentData>(doc));
        }),
      );
  }

  getStockItemsCollection(store: IStore, firebaseQueries: IFirebaseQuery[]): AngularFirestoreCollection {
    return this.angularFirestore.collection(
      path_shared_store_data_stock(store.storeId),
      (reference: CollectionReference) => {
        return generateFirebaseCollectionReference(firebaseQueries, reference);
      },
    );
  }

  async updateStockTags(tagedItems: { [stockId: string]: string[] }, store: IStore): Promise<void> {
    const BATCH_SIZE = 500;
    const stockIds = Object.keys(tagedItems);

    const batches = [];
    const batchesCache = [];
    for (let i = 0; i < stockIds.length; i += BATCH_SIZE) {
      const batch = this.angularFirestore.firestore.batch();
      const batchCache = this.angularFirestore.firestore.batch();
      const chunk = stockIds.slice(i, i + BATCH_SIZE);

      chunk.forEach((stockId: string) => {
        const stockRef = this.angularFirestore.firestore
          .collection(path_shared_store_data_stock(store.storeId))
          .doc(stockId);
        const stockCacheRef = this.angularFirestore.firestore
          .collection(path_shared_store_data_last_updated_stock_tags(store.storeId))
          .doc(stockId);
        const tags = tagedItems[stockId];

        batch.update(stockRef, {['_tags']: tags});
        batchCache.set(stockCacheRef, {['_tags']: tags}, {merge: true});
      });
      batches.push(batch);
      batchesCache.push(batchCache);
    }

    // Commit the main batch updates
    for (const [index, batch] of batches.entries()) {
      try {
        await batch.commit();
      } catch (error) {
        console.error(`Error committing batch ${index + 1}:`, error);
      }
    }

    // Commit the cache batch updates
    for (const [index, batch] of batchesCache.entries()) {
      try {
        await batch.commit();
      } catch (error) {
        console.error(`Error committing cache batch ${index + 1}:`, error);
      }
    }
  }


  async updateMultipleSuppliers(multipleSuppliers: { [stockId: string]: string[] }, store: IStore): Promise<void> {
    const BATCH_SIZE = 500;
    const stockIds = Object.keys(multipleSuppliers);

    const batches = [];
    for (let i = 0; i < stockIds.length; i += BATCH_SIZE) {
      const batch = this.angularFirestore.firestore.batch();
      const chunk = stockIds.slice(i, i + BATCH_SIZE);

      chunk.forEach((stockId: string) => {
        const stockRef = this.angularFirestore.firestore
          .collection(path_shared_store_data_stock(store.storeId))
          .doc(stockId);
        const suppliers = multipleSuppliers[stockId];

        batch.update(stockRef, {['multipleSuppliers']: suppliers});
      });
      batches.push(batch);
    }

    for (const [index, batch] of batches.entries()) {
      try {
        await batch.commit();
      } catch (error) {
        console.error(`Error committing batch ${index + 1}:`, error);
      }
    }
  }

}
