import {ColDef} from 'ag-grid-community';

//#region convertToSpacedWords
/**
 * ### Converts a camelCase or PascalCase string into space-separated words with capitalized first letters
 *
 * This function takes a string in camelCase or PascalCase format and converts it into a more readable format with spaces between words and the first letter of each word capitalized.
 *
 * @param {string} text - The text to convert from camelCase or PascalCase to a spaced format.
 * @returns {string} The converted string with spaces and capitalized words.
 *
 * @example
 * convertToSpacedWords('camelCase') // returns 'Camel Case'
 * convertToSpacedWords('PascalCase') // returns 'Pascal Case'
 */
const convertToSpacedWords = (text: string): string => {
  return text
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .split(/\s+/)
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

//#endregion

//#region getColumnNames
/**
 * ### Gets the column display name based on the provided header name or field name
 *
 * This function returns the display name for a column. If a `headerName` is provided, it is used directly. Otherwise, the function uses the `field` name, converting it to a more readable format.
 *
 * @param {string} headerName - The optional header name for the column.
 * @param {string} field - The field name to use if header name is not provided.
 * @returns {string} The column display name, either from `headerName` or converted from `field`.
 *
 * @example
 * getColumnName('', 'firstName') // returns 'First Name'
 * getColumnName('Custom Header', 'firstName') // returns 'Custom Header'
 */
export const getColumnName = (headerName: string, field: string): string => {
  return headerName ? headerName : convertToSpacedWords(field);
};
//#endregion

//#region isColumnSelected
/**
 * ### Checks if a column is selected based on field selection values
 *
 * This function checks whether a column is selected by matching its field name with the values in the provided field selection mapping.
 * If the `lockPosition` property of the column is `true`, it will always be considered selected.
 *
 * @param {ColDef} option - The column definition object from ag-grid, which includes the column `field` and `lockPosition`.
 * @param {Object.<string, string>} fsValues - A mapping of field selection values where the values are column field names.
 * @returns {boolean} `true` if the column is selected, `false` otherwise.
 *
 * @example
 * isColumnSelected({ field: 'name', lockPosition: true }, {}) // returns true
 * isColumnSelected({ field: 'age' }, { col1: 'age' }) // returns true
 */
export const isColumnSelected = (option: ColDef, fsValues: { [key: string]: string }): boolean => {
  if (option.lockPosition && option.field !== 'disabled') return true;
  if (Object.values(fsValues).length > 0) {
    return Object.values(fsValues).includes(option.field) || (!!(option.lockPosition) && option.field !== 'disabled');
  } else return false;
};
//#endregion

//#region isColumnDisabled
/**
 * ### Checks if a column is disabled based on its lock position
 *
 * This function checks whether a column is disabled based on its `lockPosition` property. If the `lockPosition` is `true`, the column is considered disabled.
 *
 * @param {ColDef} option - The column definition object from ag-grid, which includes the column `field` and `lockPosition`.
 * @returns {boolean} `true` if the column is disabled, `false` otherwise.
 *
 * @example
 * isColumnDisabled({ field: 'id', lockPosition: true }) // returns true
 * isColumnDisabled({ field: 'name' }) // returns false
 */
export const isColumnDisabled = (option: ColDef): boolean =>
  !!(option.lockPosition) && option.field !== 'disabled';
//#endregion
