import {CellEditorSelectDropdownComponent} from './cell-editor-select-dropdown/cell-editor-select-dropdown.component';
import {CellEditorSupplierComponent} from './cell-editor-supplier/cell-editor-supplier.component';
import {CellEditorPriceNgpComponent} from './cell-editor-price-ngp/cell-editor-price-ngp.component';
import {CellEditorGeneralComponent} from './cell-editor-general/cell-editor-general.component';
import {
  CellEditorMultiSelectOnHoldComponent,
} from './cell-editor-multi-select-on-hold/cell-editor-multi-select-on-hold.component';
import {
  CellEditorMultiSelectArrayComponent,
} from './cell-editor-multi-select-array/cell-editor-multi-select-array.component';
import {
  CellEditorSupplierMultiSelectComponent,
} from './cell-editor-supplier-multi-select/cell-editor-supplier-multi-select.component';

export const CELL_EDITOR_GENERAL = CellEditorGeneralComponent;
export const CELL_EDITOR_PRICE_NGP = CellEditorPriceNgpComponent;
export const CELL_EDITOR_SELECT_DROPDOWN = CellEditorSelectDropdownComponent;
export const CELL_EDITOR_MULTI_SELECT_ARRAY = CellEditorMultiSelectArrayComponent;
export const CELL_EDITOR_MULTI_SELECT_ON_HOLD = CellEditorMultiSelectOnHoldComponent;
export const CELL_EDITOR_SUPPLIER = CellEditorSupplierComponent;
export const CELL_EDITOR_SUPPLIER_MULTI_SELECT = CellEditorSupplierMultiSelectComponent;

export const CELL_EDITOR_COMPONENTS = [
  CELL_EDITOR_GENERAL,
  CELL_EDITOR_MULTI_SELECT_ARRAY,
  CELL_EDITOR_MULTI_SELECT_ON_HOLD,
  CELL_EDITOR_PRICE_NGP,
  CELL_EDITOR_SELECT_DROPDOWN,
  CELL_EDITOR_SUPPLIER,
  CELL_EDITOR_SUPPLIER_MULTI_SELECT,
];

