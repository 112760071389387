<ion-header *ngIf="title" id="pop-title">
  <ion-toolbar>
    <ion-title>{{ title }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content id="pop-select-content">
  <div class="container">

    <div *ngIf="search" class="search-options">
      <ion-input
        (ionChange)="searchOptions($event)" class="search-bar" clear-input="true" clearInput="true"
        debounce="500"
        placeholder="Search" type="search">
        <ion-icon name="search-circle-outline"></ion-icon>
      </ion-input>
    </div>

    <div class="variable-list-div">
      <!-- Select all won't work on the SINGLE SELECT option -->
      <div *ngIf="selectAll" [class]="'select-option select-all' + (allSelected ? ' selected' : '')">
        <button (click)="toggleAll()" type="button">
          <ion-icon name="checkmark-done-outline"></ion-icon>
          Select All
        </button>
      </div>

      <!-- --------------------------------------------- MULTI SELECT ---------------------------------------------- -->
      <div
        *ngIf="multiple; else single"
        [class]="'options' + (!selectionClasses && !selectionStyles ? ' default-even-grey' : '')">
        <div
          (click)="selectSKey(sKey)"
          *ngFor="let sKey of order; trackBy: trackByFunction"
          [ngClass]="classes[sKey] + (disabled.includes(sKey) ? ' disabled' : '')"
          [style]="selectionStyles ? selectionStyles[sKey] : null"
          class="item-for-selection">
          <ion-checkbox
            (click)="selectSKey(sKey)" [(ngModel)]="checks[sKey]"
            [disabled]="disabled && disabled.includes(sKey)">
          </ion-checkbox>
          <span>{{ selection[sKey] }}</span>

          <ng-container *ngIf="isAdditionalIconVisible && selectionToPlaceIcons[sKey]">
            <app-display-icon
              [icon]="additionalIcon">
            </app-display-icon>
          </ng-container>

          <ng-container *ngIf="isDeleteVisible && !selectionToPlaceIcons[sKey]">
            <app-display-icon
              (click)="onDeleteClick(sKey)"
              [colour]="'red'"
              [icon]="icons.deleteOutline">
            </app-display-icon>
          </ng-container>

        </div>
      </div>

      <!-- --------------------------------------------- SINGLE SELECT --------------------------------------------- -->
      <ng-template #single>
        <div [class]="'options single' + (!selectionClasses && !selectionStyles ? ' default-even-grey' : '')">
          <div
            (click)="selectSKey(sKey)" *ngFor="let sKey of order; trackBy: trackByFunction"
            [class]="classes[sKey] + (disabled.includes(sKey) ? ' disabled' : '')"
            [style]="selectionStyles ? selectionStyles[sKey] : null">
            <app-display-icon
              [icon]="checked === sKey ? icons.radioBoxMarked : icons.radioBoxBlank">
            </app-display-icon>
            <span>{{ selection[sKey] }}</span>
          </div>
        </div>
      </ng-template>
    </div>

    <div *ngIf="selectModButtons" [class]="'select-mod-buttons' + (selectModButtons.length === 1 ? ' single' : '')">
      <button
        (click)="selectionModHandle(butt.handler)"
        *ngFor="let butt of selectModButtons; trackBy: trackByFunction"
        [class]="butt.cssClass"
        type="button">
        {{ butt.text }}
      </button>
    </div>

    <div class="main-buttons">
      <span class="custom">
        <button
          (click)="additionalHandle(additionalMainButton.handler)"
          *ngIf="additionalMainButton"
          [class]="additionalMainButton.cssClass"
          type="button">
          {{ additionalMainButton.text }}
        </button>
      </span>
      <span class="complete">
        <button (click)="onCancel()" class="cancel-button" type="button">
          {{ cancelText }}
        </button>
        <button (click)="onSubmit()" class="ok-button" type="button">
          {{ okText }}
        </button>
      </span>
    </div>
  </div>
</ion-content>
