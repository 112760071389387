import {IAdvancedFilterGroup, IFilter, IFilterGroup} from './advanced-filter-groups';
import {partialMatchNumberValues, partialMatchStringValues} from '../../../../shared-utils/primitives/value.utils';

/**
 * Applies an advanced filter to a dataset.
 *
 * @template DataType - The type of the data elements.
 * @param {DataType[]} data - The dataset to filter.
 * @param {IFilter} filter - The filter criteria to apply.
 * @returns {DataType[]} - The filtered dataset.
 */
export function applyAdvancedFilterGroupFilterToData<DataType>(data: DataType[], filter: IFilter): DataType[] {
  return data.filter((item: DataType): boolean => {
    switch (filter.filterType) {

      case 'equal to':
        switch (filter.columnType) {
          case 'number':
            return Number(item[filter.column]) === Number(filter.value);
          case 'string':
            return String(item[filter.column]).toUpperCase() === String(filter.value).toUpperCase();
          case 'date':
          default:
            break;
        }
        break;

      case 'partially equal to':
        switch (filter.columnType) {
          case 'number':
            return partialMatchNumberValues(Number(item[filter.column]), Number(filter.value), 5);
          case 'string':
            return partialMatchStringValues(String(item[filter.column]).toUpperCase(), String(filter.value).toUpperCase());
          case 'date':
          default:
            break;
        }
        break;

      case 'greater than':
        switch (filter.columnType) {
          case 'number':
            return Number(item[filter.column]) > Number(filter.value);
          case 'date':
          case 'string':
          default:
            break
        }
        break;

      case 'greater than or equal to':
        switch (filter.columnType) {
          case 'number':
            return Number(item[filter.column]) >= Number(filter.value);
          case 'date':
          case 'string':
          default:
            break
        }
        break;

      case 'less than':
        switch (filter.columnType) {
          case 'number':
            return Number(item[filter.column]) < Number(filter.value);
          case 'date':
          case 'string':
          default:
            break
        }
        break;

      case 'less than or equal to':
        switch (filter.columnType) {
          case 'number':
            return Number(item[filter.column]) <= Number(filter.value);
          case 'date':
          case 'string':
          default:
            break
        }
        break;

      case 'not equal to':
        switch (filter.columnType) {
          case 'number':
            return Number(item[filter.column]) !== Number(filter.value);
          case 'string':
            return String(item[filter.column]).toUpperCase() !== String(filter.value).toUpperCase();
          case 'date':
          default:
            break
        }
        break;

      default:
        break


    }
  });
}

/**
 * Applies an advanced filter group to a dataset.
 *
 * @template DataType - The type of the data elements.
 * @param {DataType[]} data - The dataset to filter.
 * @param {IFilterGroup} filterGroup - The group of filters to apply.
 * @returns {DataType[]} - The filtered dataset.
 */
export function applyAdvancedFilterGroupFilterGroupToData<DataType>(data: DataType[], filterGroup: IFilterGroup): DataType[] {
  const {filtersAndGroups, operator} = filterGroup;
  return filtersAndGroups.reduce((filteredData: DataType[], filterOrGroup: IFilterGroup | IFilter): (DataType)[] => {
    if ('filtersAndGroups' in filterOrGroup) {
      const groupResult = applyAdvancedFilterGroupFilterGroupToData(data, filterOrGroup);
      if (operator === 'AND') {
        return filteredData.filter((item: DataType) => groupResult.includes(item));
      } else {
        return [...filteredData, ...groupResult];
      }
    } else {
      const filterResult = applyAdvancedFilterGroupFilterToData(data, filterOrGroup);
      if (operator === 'AND') {
        return filteredData.filter((item: DataType) => filterResult.includes(item));
      } else {
        return [...filteredData, ...filterResult];
      }
    }
  }, operator === 'AND' ? data : []);
}

/**
 * Applies an advanced filter group to a dataset.
 *
 * @template DataType - The type of the data elements.
 * @param {DataType[]} data - The dataset to filter.
 * @param {IAdvancedFilterGroup} advancedFilterGroup - The advanced filter group to apply.
 * @returns {DataType[]} - The filtered dataset.
 */
export function applyAdvancedFilterGroupToData<DataType>(data: DataType[], advancedFilterGroup: IAdvancedFilterGroup): DataType[] {
  return applyAdvancedFilterGroupFilterGroupToData<DataType>(data, advancedFilterGroup?.filterGroup);
}
