import {
  SettingItemDisablingRulesComponent
} from './setting-item-disabling-rules/setting-item-disabling-rules.component';
import {SettingPriceThresholdsComponent} from './setting-price-thresholds/setting-price-thresholds.component';
import {SettingSectionComponent} from './setting-section/setting-section.component';
import {SettingStockTagsComponent} from './setting-stock-tags/setting-stock-tags.component';

export {UiComponentsModule} from './ui/ui-components.module';

export const SETTINGS_COMPONENTS = [
  SettingSectionComponent,
  SettingStockTagsComponent,
  SettingItemDisablingRulesComponent,
  SettingPriceThresholdsComponent,
];
