import {INotification, INotificationPerStore, INotificationTotals} from '../models/notification';

//region groupNotificationsPerUserStore
/**
 * Groups notifications per user store and calculates the total number of notifications.
 *
 * This function organizes notifications based on `storeId`, grouping them under each store
 * while also computing the total number of notifications across all stores.
 *
 * @param {string[]} storeIds - An array of store IDs for which notifications need to be grouped.
 * @param {INotification[]} notifications - The array of notifications to be grouped by store ID.
 *
 * @returns {Object} An object containing:
 * - `totalNotifications` (number): The total count of notifications across all stores.
 * - `notificationsGrouped` (Object): An object where each key is a store ID, and
 *   the value is an `INotificationPerStore` containing the count and the list of notifications for that store.
 *
 * @example
 * const storeIds = ['store1', 'store2'];
 * const notifications = [
 *   { storeId: 'store1', event: 'order', response: 'success', ... },
 *   { storeId: 'store2', event: 'stock', response: 'low', ... },
 *   { storeId: 'store1', event: 'refund', response: 'processed', ... }
 * ];
 * const groupedNotifications = groupNotificationsPerUserStore(storeIds, notifications);
 * console.info(groupedNotifications.totalNotifications); // 3
 * console.info(groupedNotifications.notificationsGrouped);
 */
export function groupNotificationsPerUserStore(
  storeIds: string[],
  notifications: INotification[]
): {
  totals: INotificationTotals,
  notificationsGrouped: {
    [storeId: string]: INotificationPerStore
  }
} {
  const storeNotifications: { [storeId: string]: INotificationPerStore } = {};
  const totals: INotificationTotals = {
    total: 0,
    read: 0,
    unread: 0
  }
  storeIds.forEach((storeId: string) => {
    storeNotifications[storeId] = {notifications: [], total: 0, read: 0, unread: 0};
    storeNotifications[storeId].notifications = notifications.filter((notification: INotification) => notification.storeId === storeId);
    storeNotifications[storeId].total = storeNotifications[storeId].notifications.length;
    storeNotifications[storeId].read = storeNotifications[storeId].notifications
      .filter((notification: INotification) => notification?.read?.length > 0).length;
    storeNotifications[storeId].unread = storeNotifications[storeId].total - storeNotifications[storeId].read;
    totals.total += storeNotifications[storeId].total;
    totals.read += storeNotifications[storeId].read;
    totals.unread += storeNotifications[storeId].unread;
  });
  return {
    totals: totals,
    notificationsGrouped: storeNotifications
  };
}

//endregion
