import {Component, EventEmitter, HostBinding, Output} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'th[resizable]',
  templateUrl: './resizable.component.html',
  styleUrls: ['./resizable.component.scss'],
})
export class ResizableComponent {
  @Output() readonly widthChange: EventEmitter<number> = new EventEmitter<number>();

  @HostBinding('style.width.px')
  width: number | null = null;

  onResize(width: number): void {
    this.width = width;
    this.widthChange.emit(width);
  }

}
