import {ElementRef, Injectable, OnDestroy} from '@angular/core';
import {IPosition} from '../models';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DropdownService implements OnDestroy {
  #initialDropdownPosition: 'above' | 'below' | null = null;
  #scrollHandler: ((this: Window, ev: Event) => void) | null = null;

  private dropdownToggled = new Subject<string>();
  dropdownToggled$ = this.dropdownToggled.asObservable();

  updateDropdownPosition(button: HTMLElement, dropdown: HTMLElement): void {
    if (!button || !dropdown) return;

    const buttonRect = button.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const spaceBelow = viewportHeight - buttonRect.bottom;
    const spaceAbove = buttonRect.top;

    const position: IPosition = {
      left: `${buttonRect.left}px`,
      top: 'auto'
    };

    const minSpace = 200;


    if (this.#initialDropdownPosition === null) {
      this.#initialDropdownPosition = spaceBelow >= minSpace || spaceBelow >= spaceAbove ? 'below' : 'above';
    }

    if (this.#initialDropdownPosition === 'below') {
      position.top = `${buttonRect.bottom + 4}px`;
      dropdown.style.bottom = 'auto';
    } else {
      dropdown.style.bottom = `${viewportHeight - buttonRect.top + 4}px`;
      position.top = 'auto';
    }

    Object.assign(dropdown.style, position);
  }

  setupDropdownPositioning(elementRef: ElementRef): void {
    this.#initialDropdownPosition = null;
    const button = elementRef.nativeElement.querySelector('.select-button') as HTMLElement;
    const dropdown = elementRef.nativeElement.querySelector('.options-container') as HTMLElement;
    this.updateDropdownPosition(button, dropdown);
    this.setupScrollHandler(elementRef);
  }

  cleanupDropdownPositioning(): void {
    this.removeScrollHandler();
    this.#initialDropdownPosition = null;
  }

  notifyDropdownToggled(componentId: string): void {
    this.dropdownToggled.next(componentId);
  }

  ngOnDestroy(): void {
    this.cleanupDropdownPositioning();
    this.dropdownToggled.complete();
  }

  private setupScrollHandler(elementRef: ElementRef): void {
    this.#scrollHandler = (): void => {
      const button = elementRef.nativeElement.querySelector('.select-button') as HTMLElement;
      const dropdown = elementRef.nativeElement.querySelector('.options-container') as HTMLElement;
      this.updateDropdownPosition(button, dropdown);
    };
    window.addEventListener('scroll', this.#scrollHandler, true);
  }

  private removeScrollHandler(): void {
    if (this.#scrollHandler) {
      window.removeEventListener('scroll', this.#scrollHandler, true);
      this.#scrollHandler = null;
    }
  }
}
