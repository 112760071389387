import {Component, Inject, Injector, OnInit} from '@angular/core';
import {AlertController, ModalController, PopoverController} from '@ionic/angular';
import {Observable} from 'rxjs';
import {MsgApiResponseComponent} from '../msg-api-response/msg-api-response.component';
import {
  MsgUserSignupNotificationComponent,
} from '../msg-user-signup-notification/msg-user-signup-notification.component';
import {INCOMING_MSG_TYPES, INotification, StoreInfo} from '../../../../shared-utilities/models-old/datastructures';
import {Store} from '@ngrx/store';
import {DateRangeSelectorComponent} from '../date-range-selector/date-range-selector.component';
import {SelectPopoverComponent} from '../select-popover/select-popover.component';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {getUserStores, setUserSelectedStore} from '../../../../features-as-modules/feature-core/store/core.actions';
import {
  selectSelectedUserStore,
  selectUserMessages,
  selectUserStores
} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {objLen} from '../../../../shared-utilities/functions-old/object-functions';
import {
  firstDayOMonth,
  firstDayOWeek,
  lastDayOfWeek,
  lastDayOMonth,
} from '../../../../shared-utilities/functions-old/date-functions';
import {IStore} from '../../../../shared/shared-models/store/store';
import {deleteOldMessages} from '../../../../features/core/store-shared/shared.actions';
import {getDeepCopyOfObject} from '../../../../shared/shared-utils/object/object.utils';
import {take} from 'rxjs/operators';

const RECOMMENDED_MAX = 20 as const;

type MsgComponent =
  typeof MsgApiResponseComponent |
  typeof MsgUserSignupNotificationComponent |
  typeof DefaultBodyComponent;

interface GBA {
  currentWeek: INotification[];
  currentMonth: INotification[];
  olderMessages: INotification[];
}

const RANKED_GBA_KEY = ['currentWeek', 'currentMonth', 'olderMessages'] as const;

@Component({
  selector: 'app-messages-modal',
  templateUrl: './messages-modal.page.html',
  styleUrls: ['./messages-modal.page.scss'],
})
export class MessagesModalPage implements OnInit {
  selectedStore: IStore;
  uniqueMsgTypes: { [t in typeof INCOMING_MSG_TYPES[number]]?: string } = {};
  filteredMessageTypes: { [t in typeof INCOMING_MSG_TYPES[number]]?: true };
  groupedByAge: GBA = {currentWeek: [], currentMonth: [], olderMessages: []};
  initialOpenGroup: keyof GBA;
  messagesByStore: { [storeId: string]: INotification[] } = {};
  selectedMessages: INotification[] = [];
  filtered: INotification[];
  sentButIMAPFailed: { [msgID: string]: boolean } = {};
  otherTabs: { value: string; name?: string }[] = [];
  otherSenders: string[] = [];
  selectedDates: { start: Date; end: Date };
  expandedMessage: string;
  hide: { [what: string]: boolean } = {};
  changing: boolean;
  previews: { [msgID: string]: string } = {};
  injectors = {};

  userNotifications$: Observable<INotification[]>;
  userStores$: Observable<IStore[]>;
  userSelectedStore$: Observable<IStore>;

  private userStores: IStore[] = [];
  private msgComponents = {};

  constructor(
    private firebase: FirebaseService,
    private alertControl: AlertController,
    private injector: Injector,
    private popControl: PopoverController,
    private readonly store: Store,
    private modalController: ModalController,
  ) {
  }

  get getUserStores(): IStore[] {
    return this.userStores;
  }

  private static msgComponentLookUp(type: string): MsgComponent {
    switch (type) {
      case 'STOCK_UPDATE':
        return MsgApiResponseComponent;
      case 'SIGN_UP':
        return MsgUserSignupNotificationComponent;
      default:
        return DefaultBodyComponent;
    }
  }

  private static setPreview(notification: INotification): string {
    switch (notification.type) {
      case 'STOCK_UPDATE':
        return 'Stock Update ' + (notification.success ? 'Success' : 'Fail');
      case 'AUTO_ORDER_RESULT':
        return 'Auto Order ' + (notification.success ? 'Success' : 'Fail');
      case 'SIGN_UP':
        return 'User signed up using your inviter link';
      case 'ACCESS_CHANGE':
        return 'ACCESS CHANGE';
      default:
        return notification.response || '* no message body *';
    }
  }

  ngOnInit(): void {
    this.store.dispatch(getUserStores({pageStoreDocument: 'messages-modal'}));
    this.userNotifications$ = this.store.select(selectUserMessages);
    this.userStores$ = this.store.select(selectUserStores);
    this.userSelectedStore$ = this.store.select(selectSelectedUserStore);

    this.userStores$.subscribe((stores: IStore[]) => {
      this.userStores = stores;
    });

    this.userSelectedStore$.pipe(take(1)).subscribe((store: IStore) => {
      this.selectedStore = this.getUserStores.find((userStore: IStore) => userStore.storeId === store.storeId)
      this.store.dispatch(setUserSelectedStore({selectedStore: this.selectedStore}));
    });

    this.userNotifications$.subscribe((notificationArray: INotification[]) => {
      const notifications = getDeepCopyOfObject(notificationArray);
      notifications.sort((a: INotification, b: INotification) => {
        const dateA = new Date(a.timestamp.seconds * 1000 + a.timestamp.nanoseconds / 1000000);
        const dateB = new Date(b.timestamp.seconds * 1000 + b.timestamp.nanoseconds / 1000000);
        return dateB.getTime() - dateA.getTime();
      });


      const messagesByStore: { [storeId: string]: INotification[] } = {};
      notifications.forEach((notification: INotification) => {
        if (!messagesByStore[notification.storeId]) {
          messagesByStore[notification.storeId] = [];
        }
        messagesByStore[notification.storeId].push(notification);
        this.messagesByStore = messagesByStore;

        if (notification['event'] && !this.previews[notification['event']]) {
          this.previews[notification['event']] = MessagesModalPage.setPreview(notification);
        }

        const storeExistsIndex = this.userStores.findIndex((userStore: IStore) => userStore.storeId === notification.storeId);
        if (
          this.userStores.length > 0 &&
          storeExistsIndex < 0 &&
          !this.otherSenders.includes(notification.storeId)
        ) {
          this.otherTabs.push({value: notification.storeId, name: `Store ${notification.storeId.slice(1, 3)}...`});
          this.otherSenders.push(notification.storeId);
        }

      });

      if (!this.selectedStore?.storeId) {
        const storeIds = Object.keys(this.messagesByStore);
        if (storeIds.length > 0) {
          this.selectStore(storeIds[0] as unknown as IStore);
        }
      } else {
        this.selectedMessages = this.messagesByStore[this.selectedStore.storeId] || [];
        this.groupMessages();
      }
    });

    if (this.groupedByAge.olderMessages.length > RECOMMENDED_MAX) {
      const ids = this.groupedByAge.olderMessages
        .slice(RECOMMENDED_MAX)
        .map((message: INotification) => message['event']);
      void this.deleteOldMessages(true, ids, this.groupedByAge.olderMessages.map((messages: INotification) => messages['id'])).then();
    }
  }

  async filterMsgTypes(event: Event): Promise<void> {
    const value = this.filteredMessageTypes ? Object.keys(this.filteredMessageTypes) : null;
    const pc = await this.popControl.create({
      component: SelectPopoverComponent,
      event,
      componentProps: {
        title: 'Filter Notifications',
        selection: this.uniqueMsgTypes,
        value,
        multiple: true,
        selectAll: true,
      },
    });
    await pc.present();
    const {data} = await pc.onDidDismiss();

    if (data?.length && data.length !== objLen(this.uniqueMsgTypes)) {
      this.filteredMessageTypes = {};
      data.forEach((key: string | number): boolean => (this.filteredMessageTypes[key] = true));
    } else {
      this.filteredMessageTypes = null;
    }
    this.filterMsgs();
  }

  async filterDates(event: Event): Promise<void> {
    const mc = await this.popControl.create({
      component: DateRangeSelectorComponent,
      event,
      componentProps: {
        selectedDates: this.selectedDates ? {
          start: this.selectedDates.start,
          end: this.selectedDates.end,
        } : null,
        minDate: this.selectedMessages.length ? this.selectedMessages[this.selectedMessages.length - 1].timestamp : null,
        maxDate: this.selectedMessages.length ? this.selectedMessages[0].timestamp : null,
        isPopOver: true,
      },
    });
    await mc.present();
    const {data} = await mc.onDidDismiss();

    if (data) {
      this.selectedDates = data;
      if (this.selectedDates.start) {
        this.selectedDates.start.setHours(0, 0, 0, 1);
      }
      if (this.selectedDates.end) {
        this.selectedDates.end.setHours(23, 59, 59, 999);
      }
    } else {
      this.selectedDates = null;
    }
    this.filterMsgs();
  }

  getStoreInfo = (storeId: string, isStore = true): StoreInfo | { value: string; name?: string } => {
    if (isStore) {
      return this.userStores.find((userStore: IStore) => userStore.storeId === storeId);
    } else {
      return this.otherTabs.find((tabKeyValue) => tabKeyValue.value === storeId);
    }
  };

  selectStore(store: IStore): void {
    if (this.selectedStore?.storeId === store.storeId) {
      return;
    }
    this.selectedStore = this.userStores.find((userStore: IStore) => userStore.storeId === store.storeId);
    this.selectedDates = null;
    this.selectedMessages = this.messagesByStore[store.storeId] || [];
    this.store.dispatch(setUserSelectedStore({selectedStore: store}));
    this.groupMessages();
  }

  close(): void {
    void this.modalController.dismiss();
  }

  getDate(timeStamp: { seconds: number, nanoseconds: number }): Date {
    return new Date(timeStamp.seconds * 1000 + timeStamp.nanoseconds / 1000000);
  }

  expand(id: string): void {
    const message = this.selectedMessages.find((message: INotification) => message['event'] === id);
    if (!message) {
      return;
    }
    this.expandedMessage = this.expandedMessage === id ? null : id;

    if (this.expandedMessage && !this.msgComponents[id]) {
      this.msgComponents[id] = MessagesModalPage.msgComponentLookUp(message.type);

      if (this.msgComponents[id]) {
        this.injectors[id] = Injector.create({
          providers: [{
            provide: 'INPUT', useValue: {
              msgID: id,
              message,
              getStoreInfo: this.getStoreInfo,
              timeStamp: new Date(message.timestamp.seconds * 1000 + message.timestamp.nanoseconds / 1000000).toLocaleString(),
            },
          }],
          parent: this.injector,
        });
      }
    }
  }

  getExpansion(id: string): MsgComponent {
    return this.msgComponents[id] as MsgComponent;
  }

  async deleteOldMessages(
    recommendClean: boolean = false,
    splicedMessageIds?: string[],
    allMessageIds?: string[],
  ): Promise<void> {
    const deleteOld = async (allMessageIds?: string[]): Promise<void> => {
      const ac2 = await this.alertControl.create({
        header: 'Delete Old Message',
        subHeader: 'Are you sure you want to delete all messages older than one Month? This action cannot be undone',
        cssClass: 'custom-alert',
        buttons: ['Cancel', {text: 'Delete All', role: 'y'}],
      });
      await ac2.present();
      const response = await ac2.onDidDismiss();

      if (response.role === 'y') {
        this.store.dispatch(deleteOldMessages({messageIds: allMessageIds ?? this.groupedByAge.olderMessages.map((message: INotification) => message['event'])}));
      }
    };

    this.changing = true;

    if (recommendClean) {
      const ac1 = await this.alertControl.create({
        header: 'Clear Old Message',
        subHeader: `You have more than ${RECOMMENDED_MAX} messages older than one month.`,
        message: 'We recommend clearing out the oldest ones to keep the inbox running smoothly.',
        cssClass: 'custom-alert',
        buttons: ['Cancel', {text: `Keep ${RECOMMENDED_MAX}`, role: 'keep'}, {text: 'Delete All', role: 'a'}],
      });
      await ac1.present();
      const {role} = await ac1.onDidDismiss();
      if (role === 'a') {
        await deleteOld(allMessageIds);
      } else if (role === 'keep') {
        this.store.dispatch(deleteOldMessages({messageIds: splicedMessageIds}));
      }
    } else {
      await deleteOld();
    }
    this.changing = false;
  }

  async deleteMessage(msgID: string): Promise<void> {
    this.changing = true;
    this.selectedMessages = this.selectedMessages.filter((message: INotification) => message['id'] !== msgID);
    if (this.messagesByStore[this.selectedStore.storeId]) {
      this.messagesByStore[this.selectedStore.storeId] = this.messagesByStore[this.selectedStore.storeId].filter((message: INotification) => message['id'] !== msgID);
    }
    this.groupMessages();
    await this.firebase.deleteMessage(msgID);
    this.changing = false;
  }

  trackByFunction(index: number): number {
    return index;
  }

  private groupMessages(): void {
    this.groupedByAge = {currentWeek: [], currentMonth: [], olderMessages: []};

    const now = new Date();
    const startOfWeek = firstDayOWeek(now, 'monday').getTime();
    const endOfWeek = lastDayOfWeek(now, 'sunday').getTime();
    const startOfMonth = firstDayOMonth(now).getTime();
    const endOfMonth = lastDayOMonth(now).getTime();

    this.selectedMessages.forEach((notification: INotification) => {
      const date = new Date(notification.timestamp.seconds * 1000 + notification.timestamp.nanoseconds / 1000000);
      const ts = date.getTime();
      if (ts >= startOfWeek && ts <= endOfWeek) {
        this.groupedByAge.currentWeek.push(notification);
      } else if (ts >= startOfMonth && ts <= endOfMonth) {
        this.groupedByAge.currentMonth.push(notification);
      } else {
        this.groupedByAge.olderMessages.push(notification);
      }
    });

    for (const key of RANKED_GBA_KEY) {
      if (this.groupedByAge[key].length) {
        this.initialOpenGroup = key as keyof GBA;
        break;
      }
    }
  }

  private filterMsgs(): void {
    let messages = this.selectedMessages;
    if (this.filteredMessageTypes) {
      messages = messages.filter((message: INotification) => this.filteredMessageTypes[message.type]);
    }
    if (this.selectedDates) {
      const min = this.selectedDates.start ? this.selectedDates.start.getTime() : 0;
      const max = this.selectedDates.end ? this.selectedDates.end.getTime() : Number.MAX_VALUE;
      messages = messages.filter((message: INotification) => {
        const date = new Date(message.timestamp.seconds * 1000 + message.timestamp.nanoseconds / 1000000);
        const t = date.getTime();
        return t >= min && t <= max;
      });
    }
    this.filtered = messages;
  }


}

// TODO move this to its own component
// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  template: `
    <ion-grid>
      <ion-row>
        <ion-col><b>{{ title }}</b></ion-col>
      </ion-row>
      <ion-row>
        <ion-col>{{ body }}</ion-col>
      </ion-row>
    </ion-grid>
  `,
})
export class DefaultBodyComponent {
  body: string;
  title: string;

  constructor(
    @Inject('INPUT') private useValue: {
      id: string;
      message: INotification;
      getStoreInfo(storeId: string): StoreInfo;
      timeStamp: string;
    },
  ) {
    switch (useValue.message.type) {
      case 'ACCESS_CHANGE':
        this.title = 'Important:';
        this.body = useValue.message.response ? useValue.message.response : 'Your application access has been updated.';
        break;
      default:
        this.title = `From ${useValue.getStoreInfo(useValue.message.storeId).name || useValue.message.storeId}`;
        this.body = useValue.message.response ? useValue.message.response : '* no message body *';
    }
  }
}
