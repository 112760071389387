import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
  CollectionUsersSettingsService,
} from '../../../../shared/shared-services/firebase/collection-users-settings.service';
import {Store} from '@ngrx/store';
import {
  path_users_userId_settings_stores_storeId_mailer_settings,
} from '../../../../shared/shared-services/database-paths';
import {selectSelectedUserStore, selectUser} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {IUser} from '../../../../shared/shared-models/user-access/user';
import {IEmailSettings} from '../../../../features/settings-old/models/settings-models';
import {take} from 'rxjs/operators';
import {getAllUserEmailSettings, testEmail} from '../../../../features/settings-old/store/settings.actions';
import {
  selectAllUsersEmailSettingForCurrentSelectedStore,
  selectIsSelectedUserEmailSettingsLoading,
  selectIsUserEmailsLoading,
} from '../../../../features/settings-old/store/settings.selectors';
import {
  AnimationDefault,
  AnimationDirection,
  Animations,
  IAnimation,
} from '../../../../shared/shared-models/animations/animation';
import {getDeepCopyOfObject} from '../../../../shared/shared-utils/object/object.utils';
import {IStore} from '../../../../shared/shared-models/store/store';

@Component({
  selector: 'app-automation-settings-email',
  templateUrl: './automation-settings-email.component.html',
  styleUrls: ['./automation-settings-email.component.scss'],
})
export class AutomationSettingsEmailComponent implements OnInit {
  @Input() storeId: string;

  settings: { [storeId: string]: { [userId: string]: IEmailSettings } } = {};

  storeName: string;
  saving: boolean;
  testing: string;

  hide: { [something: string]: boolean } = {mailer: true, iqLogin: true};

  settingsForm: FormGroup;
  formCache: { [storeId: string]: { [userId: string]: IEmailSettings } } = {};
  changed: { [storeId: string]: { [userId: string]: { [path: string]: boolean } } } = {};

  gmail: { [storeId: string]: { [userId: string]: boolean } } = {};
  defaultStatus: { [storeId: string]: { [userId: string]: boolean } } = {};
  copiedValue: IEmailSettings;
  default: { [storeId: string]: FormGroup } = {};

  readonly help = {
    mailer: {
      email: null,
      user: null,
      pwd: null,
      smtp: {port: null, server: null, sslOnly: null},
      imap: {port: null, server: null, sentBox: null, sslOnly: null},
    },
  };

  editableUsers: { [userId: string]: IEmailSettings } = {};
  user: IUser;
  userId: string;


  isUserEmailSettingsLoading: boolean;
  isSelectedUserEmailSettingsLoading: boolean;
  selectedStore: IStore;

  readonly animationLogo: IAnimation = {
    ...AnimationDefault,
    animation: Animations.CIRCLE_DISAPPEAR_AND_REAPPEAR,
    direction: AnimationDirection.REVERSE,
    fixedDimensions: {width: 1, height: 1},
    backgroundColour: 'var(--ion-color-white)',
    id: 'app-automation-settings-email-component-animation-logo',
  };
  hasChanges: boolean;
  private savedPassword: { [userId: string]: string } = {};

  constructor(
    private modalController: ModalController,
    private collectionUsersSettingsService: CollectionUsersSettingsService,
    private readonly store: Store,
  ) {
  }

  private static astrixArise(length: number): string {
    return '*'.repeat(length);
  }

  async ngOnInit(): Promise<void> {
    this.store.dispatch(getAllUserEmailSettings());

    this.user = await this.store.select(selectUser).pipe(take(1)).toPromise();
    this.store.select(selectIsUserEmailsLoading)
      .subscribe((isLoading: boolean) => {
        this.isUserEmailSettingsLoading = isLoading;
      });
    this.store.select(selectIsSelectedUserEmailSettingsLoading)
      .subscribe((isLoading: boolean) => {
        this.isSelectedUserEmailSettingsLoading = isLoading;
      });
    this.store.select(selectSelectedUserStore)
      .subscribe((store: IStore): void => {
        this.selectedStore = store;
      });

    this.defaultStatus[this.storeId] = {};
    this.settings[this.storeId] = {};
    this.gmail[this.storeId] = {};


    this.initializeEmailSettings();
    this.settingsForm?.get('enableImap').valueChanges.subscribe((isEnabled: boolean) => {
      const imapGroup = this.settingsForm.get('imap');
      if (isEnabled) {
        imapGroup.enable();
      } else {
        imapGroup.disable();
      }
    });

  }

  initializeEmailSettings(): void {
    this.store.select(selectAllUsersEmailSettingForCurrentSelectedStore).subscribe((settings: {
      [p: string]: IEmailSettings
    }) => {
      this.settingsForm = null;
      this.settings[this.storeId] = {};
      this.defaultStatus[this.storeId] = {};
      this.changed[this.storeId] = {};
      this.formCache[this.storeId] = {};

      this.editableUsers = getDeepCopyOfObject({...settings});

      for (const userId of Object.keys(this.editableUsers)) {
        this.changed[this.storeId][userId] = {};
        if (settings[userId]) {
          this.settings[this.storeId][userId] = settings[userId];

          if (settings[userId].email) {
            this.defaultStatus[this.storeId][userId] = false;
          } else {
            this.settings[this.storeId][userId] = {} as IEmailSettings;
          }
        } else {
          this.settings[this.storeId][userId] = {} as IEmailSettings;
          this.defaultStatus[this.storeId][userId] = true;
        }
        this.gmail[this.storeId][userId] = this.isGmail(
          this.settings[this.storeId][userId],
        );
      }
    });
  }

  close = (): void => {
    void this.modalController.dismiss().then();
  };

  accordionChange(event: Event): void {
    const customEvent = event as CustomEvent<{ value: string }>;
    if (this.settingsForm) {
      this.formCache[this.storeId][this.userId] = this.settingsForm.value;
    }
    this.userId = customEvent.detail.value;

    if (!this.userId) {
      return;
    }
    const s = this.editableUsers[this.userId];
    if (s.enableImap === undefined) {
      s.enableImap = true;
    }

    const req = Validators.required;
    this.settingsForm = new FormGroup({
      email: new FormControl(s.email, [req]),
      user: new FormControl(
        s.user,
        Validators.pattern(/^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),
      ),
      pwd: new FormControl(
        AutomationSettingsEmailComponent.astrixArise(this.savedPassword?.[this.userId]?.length ?? (s.pwd?.length || 0)),
      ),
      smtp: new FormGroup({
        port: new FormControl(s.smtp?.port),
        server: new FormControl(s.smtp?.server),
        sslOnly: new FormControl(s.smtp?.sslOnly),
      }),
      enableImap: new FormControl(s.enableImap),
      imap: new FormGroup({
        server: new FormControl({value: s.imap?.server, disabled: false}),
        port: new FormControl({value: s.imap?.port, disabled: false}),
        sentBox: new FormControl({value: s.imap?.sentBox, disabled: false}),
        sslOnly: new FormControl({value: s.imap?.sslOnly, disabled: false}),
      }),
    });
    if (this.settingsForm.get('enableImap').value) {
      this.settingsForm.get('imap').enable();
    } else {
      this.settingsForm.get('imap').disable();
    }

    this.gmail[this.storeId][this.userId] = this.isGmail(s);

    if (this.gmail[this.storeId][this.userId]) {
      this.settingsForm.get('imap.server').setValue('');
      this.settingsForm.get('imap.port').setValue('');
      this.settingsForm.get('imap.sentBox').setValue('');
    }

    this.settingsForm.get('enableImap').valueChanges.subscribe((isEnabled: boolean) => {
      const imapGroup = this.settingsForm.get('imap');
      if (isEnabled) {
        imapGroup.enable();
      } else {
        imapGroup.disable();
      }
    });
    if (this.savedPassword[this.userId]) {
      this.settingsForm.get('pwd').setValue(this.savedPassword[this.userId]);
    }
    this.settingsForm.get('imap.sentBox').setValue('INBOX.Gallix');
  }

  copy(): void {
    this.copiedValue = this.settingsForm.value as IEmailSettings;
    this.copiedValue.pwd = null;
  }

  paste(): void {
    if (this.copiedValue !== undefined) {
      this.settingsForm.setValue(this.copiedValue);
    }
  }

  trackByFunction(index: number): number {
    return index;
  }

  savingCB(saving: boolean = true): void {
    this.saving = saving;
    const elements = document.getElementsByClassName('saving-disable');
    const opacity = saving ? '50%' : '100%';

    for (let i = 0; i < elements.length; i++) {
      (elements.item(i) as HTMLElement).style.opacity = opacity;
    }
  }

  changeEvt(
    event: Event,
    path: string,
    userId: string = this.userId,
    storeId: string = this.storeId,
  ): void {
    event.stopPropagation();
    const customEvent = event as CustomEvent<{ value: string }>;
    this.hasChanges = true;

    if (path === 'pwd') {
      this.savedPassword[userId] = customEvent.detail.value;
      this.settingsForm.get(path).setValue(this.savedPassword[userId]);

    }


    if (path.endsWith('server')) {
      this.gmail[storeId][userId] = this.isGmail(
        this.settingsForm.value as IEmailSettings,
      );

      if (this.gmail[storeId][userId]) {
        this.settingsForm.get('imap.server').setValue('');
        this.settingsForm.get('imap.port').setValue('');
        this.settingsForm.get('imap.sentBox').setValue('');
      }
    }

    const newValue = customEvent.detail.value;
    let v: IEmailSettings = this.settings[storeId][userId];
    if (!this.changed[storeId][userId]) {
      this.changed[storeId][userId] = {};
    }

    for (const p of path.split('.')) {
      if (v?.[p]) {
        v = v[p];
      } else {
        this.changed[storeId][userId][path] = true;
        return;
      }
    }
    this.changed[storeId][userId][path] = newValue !== v[path];
  }

  save(storeId: string = this.storeId, userId: string = this.userId): void {
    if (this.settingsForm.invalid) {
      return;
    }
    const updates = this.settingsForm.value as IEmailSettings;
    updates.status = 'PENDING';
    if (!(updates.imap?.server && updates.imap.port)) {
      delete updates.imap;
    }

    if (updates.pwd) {
      let valid = false;
      for (const ch of updates.pwd) {
        if (ch !== '*') {
          valid = true;
          updates.encryptedPwd = false;
          break;
        }
      }

      if (!valid) {
        delete updates.pwd;
      }
    }

    if (Object.keys(updates).length) {
      this.savingCB();
      this.saving = true;
      void this.collectionUsersSettingsService
        .setDocument<IEmailSettings>(
          path_users_userId_settings_stores_storeId_mailer_settings(userId, storeId),
          updates,
        )
        .then(() => {
          this.savingCB(false);
          this.saving = false;
          this.hasChanges = false;
          void this.testEmail();
        });
    }
  }

  async testEmail(): Promise<void> {
    this.store.dispatch(testEmail({
      storeId: this.storeId,
      userId: this.userId,
    }));
  }


  invalid(): void {
    const dig = (fg: FormGroup): void => {
      for (const key of Object.keys(fg.controls)) {
        const control = fg.controls[key];

        if (control instanceof FormGroup) {
          dig(control);
        }
      }
    };
    dig(this.settingsForm);
  }

  private isGmail(emailInfo: IEmailSettings): boolean {
    return !!emailInfo && (
      (typeof emailInfo.imap?.server === 'string' && emailInfo.imap.server.toLowerCase().includes('gmail.com')) ||
      (typeof emailInfo.smtp?.server === 'string' && emailInfo.smtp.server.toLowerCase().includes('gmail.com'))
    );
  }

}
