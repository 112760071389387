<ng-container *ngIf="tabs.includes(currentTab)">
  <app-shared-store-tabs
    (emitStoreChange)="onStoreSelect($event)"
    [setStores]="viewTabStores[currentTab].stores"
    [setUserSelectedStore]="selectedStore">
  </app-shared-store-tabs>
</ng-container>

<main>
  <section class="settings-form-section">
    <ng-container *ngIf="currentTab === 'user'">
      <div class="form">
        <app-user-settings></app-user-settings>
      </div>
    </ng-container>
    <ng-container *ngIf="currentTab === 'store'">
      <ng-container *ngIf="isAdminOfCurrentStore; else noAccess">
        <div class="form">
          <app-store-settings></app-store-settings>
        </div>
      </ng-container>
      <ng-template #noAccess>
        <app-no-access-settings></app-no-access-settings>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="currentTab === 'email'">
      <app-email-settings>
      </app-email-settings>
    </ng-container>
  </section>
</main>
