import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {NotificationState} from './notification.reducer';
import {INotification, INotificationPerStore} from '../models/notification';
import {TempStockUpdate} from "../components/notification-stock-update/notification-stock-update.component";
import {dateToFirebaseTimestamp} from "../../../shared/shared-utils/firestore/firestore.utils";

/**
 * Selector to get the notification state from the store.
 * @returns The NotificationState for the feature.
 */
export const selectNotificationState = createFeatureSelector<NotificationState>('notification');

//region SELECT LOADING
/**
 * Selector to check if user notifications are currently loading.
 * @param state - The NotificationState from the store.
 * @returns A boolean indicating whether user notifications are loading.
 */
export const selectIsUserNotificationsLoading = createSelector(
  selectNotificationState,
  (state: NotificationState) => state.isUserNotificationsLoading,
);

/**
 * Selector to determine if the notification update process is currently loading.
 *
 * @returns {boolean} - `true` if the update is in progress, otherwise `false`.
 */
export const selectIsUpdateNotificationsLoading = createSelector(
  selectNotificationState,
  (state: NotificationState) => state.isUpdateNotificationLoading,
);

/**
 * ### Selector for retrieving the loading state of fetching auto-order notifications.
 *
 * This selector accesses the `isGetNotificationAutoOrderLoading` property
 * from the notification state to determine if the auto-order notification request is in progress.
 *
 * @constant
 * @returns {boolean} `true` if the auto-order notification request is loading, otherwise `false`.
 *
 * @example
 * ```ts
 * const isLoading = store.select(selectIsGetNotificationAutoOrderLoading);
 * console.info(isLoading); // true or false
 * ```
 */
export const selectIsGetNotificationAutoOrderLoading = createSelector(
  selectNotificationState,
  (state: NotificationState) => state.isGetNotificationAutoOrderLoading,
);

/**
 * ### Selector for retrieving the loading state of stock update notifications.
 *
 * This selector checks whether the application is currently fetching stock update notifications.
 *
 * @constant
 * @returns {boolean} `true` if the stock update notification request is in progress, otherwise `false`.
 *
 * @example
 * ```ts
 * const isLoading = store.select(selectIsGetNotificationStockUpdateLoading);
 * console.info(isLoading); // true or false
 * ```
 */
export const selectIsGetNotificationStockUpdateLoading = createSelector(
  selectNotificationState,
  (state: NotificationState) => state.isGetNotificationStockUpdateLoading,
);
//endregion

//region SELECT COUTERS
/**
 * Selector to get the notification totals from the state.
 * @param state - The NotificationState from the store.
 * @returns The notification totals object containing the total, read, and unread notifications.
 */
export const selectNotificationTotals = createSelector(
  selectNotificationState,
  (state: NotificationState) => state.notificationTotals,
);

/**
 * Selector to get the total number of notifications from the state.
 * @param state - The NotificationState from the store.
 * @returns The total number of notifications.
 */
export const selectNotificationTotalCount = createSelector(
  selectNotificationState,
  (state: NotificationState) => state.notificationTotals.total,
);

/**
 * Selector to get the total number of read notifications from the state.
 * @param state - The NotificationState from the store.
 * @returns The total number of read notifications.
 */
export const selectNotificationTotalRead = createSelector(
  selectNotificationState,
  (state: NotificationState) => state.notificationTotals.read,
);

/**
 * Selector to get the total number of unread notifications from the state.
 * @param state - The NotificationState from the store.
 * @returns The total number of unread notifications.
 */
export const selectNotificationTotalUnread = createSelector(
  selectNotificationState,
  (state: NotificationState) => state.notificationTotals.unread,
);

//endregion

//region SELECT NOTIFICATIONS
/**
 * Selector to get all notifications from the state.
 * @param state - The NotificationState from the store.
 * @returns An array of all notifications.
 */
export const selectAllNotifications = createSelector(
  selectNotificationState,
  (state: NotificationState) => state.notifications,
);

/**
 * Selector to get the notifications per store for a specific storeId.
 * @param storeId - The store ID for which notifications are fetched.
 * @returns The notifications for the specified store.
 */
export const selectNotificationsPerStoreByStoreId =
  (storeId: string): MemoizedSelector<object, INotificationPerStore> => createSelector(
    selectNotificationState,
    (state: NotificationState) => state.notifications[storeId] || {} as INotificationPerStore
  );

/**
 * Selector to get the list of notifications for a specific store.
 * @param storeId - The store ID for which notifications are fetched.
 * @returns An array of notifications for the specified store.
 */
export const selectNotificationsByStoreId =
  (storeId: string): MemoizedSelector<object, INotification[]> => createSelector(
    selectNotificationState,
    (state: NotificationState) => state.notifications[storeId].notifications || [] as INotification[]
  );

//endregion


//region SELECT AUTO ORDERS
/**
 * ### Selector for retrieving all auto-order notifications.
 *
 * This selector accesses the `notificationAutoOrders` property from the notification state.
 *
 * @constant
 * @returns {IAutoOrderOrder[]} An array of auto-order notifications.
 *
 * @example
 * ```ts
 * const autoOrders = store.select(selectAllNotificationAutoOrders);
 * console.info(autoOrders); // Array of auto-order notifications
 * ```
 */
export const selectAllNotificationAutoOrders = createSelector(
  selectNotificationState,
  (state: NotificationState) => state.notificationAutoOrders,
);


/**
 * ### Selector for retrieving a temporary stock update notification.
 *
 * This selector returns a temporary stock update object with default values,
 * including execution and generation timestamps set to the current date.
 *
 * @constant
 * @returns {TempStockUpdate} A temporary stock update object.
 *
 * @example
 * ```ts
 * const stockUpdate = store.select(selectAllNotificationStockUpdates);
 * console.info(stockUpdate);
 * ```
 */
export const selectAllNotificationStockUpdates = createSelector(
  selectNotificationState,
  (state: NotificationState) => {
    const temp: TempStockUpdate = {
      executed: dateToFirebaseTimestamp(new Date),
      generated: dateToFirebaseTimestamp(new Date),
      orderId: 'Np order ID',
    }
    return temp;
  }
);


//endregion

//region SELECT SELECTED
/**
 * ### Selector for retrieving the auto-order associated with the selected notification.
 *
 * This selector accesses the `selected` property from the notification state.
 * @returns {IAutoOrderOrder | null} The selected auto-order notification or `null` if none is selected.
 *
 * @example
 * ```ts
 * const selectedAutoOrder = store.select(selectAutoOrderBySelectedNotification);
 * console.info(selectedAutoOrder); // Auto-order notification or null
 * ```
 */
export const selectAutoOrderBySelectedNotification = createSelector(
  selectNotificationState,
  (state: NotificationState) => state.selected
);

//endregion

