import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import * as CoreActions from './core.actions';
import {IInitialState} from '../../../shared-utilities/models-old/initial-state/initial-state';
import {IUser} from '../../../shared/shared-models/user-access/user';
import {IStore} from '../../../shared/shared-models/store/store';
import {INavigationPage} from '../../../shared-utilities/models-old/page/page';
import {GridUtils} from '../../../shared-utilities/utils-old/grid-utils-old/grid-utils';

import {IUserAccess} from '../../../shared/shared-models/user-access/user-access';
import {INotification} from '../../../shared-utilities/models-old/datastructures';
import {IDataTimeStamps} from '../../../features/core/models/data-time-stamps';

export interface CoreState extends IInitialState {
  // User -----------------------------------
  isUserLoading: boolean;
  isUserLoggedIn: boolean;
  isPagesFinishedLoading: boolean;
  user: IUser;
  ita: boolean;
  isUserAccessLoading: boolean;
  userAccess: IUserAccess;
  // User Stores ----------------------------
  isUserStoresLoading: boolean;
  userStores: IStore[];
  userSelectedStore: IStore;
  userLastSelectedStore: IStore;
  currentSelectedPage: INavigationPage;
  // Images ---------------------------------
  images: { [image: string]: string };
  // Messages -------------------------------
  isUserMessageCountLoading: boolean;
  userMessagesCount: number;
  userMessages: INotification[];
  dataTimeStamps: { [storeId: string]: IDataTimeStamps };

}

export const initialCoreState: CoreState = {
  // Initial --------------------------------
  errors: [],
  // User -----------------------------------
  isUserLoading: false,
  isUserLoggedIn: false,
  isPagesFinishedLoading: false,
  user: null,
  ita: false,
  isUserAccessLoading: false,
  userAccess: null,
  // User Stores ----------------------------
  isUserStoresLoading: false,
  userStores: null,
  userSelectedStore: null,
  userLastSelectedStore: null,
  currentSelectedPage: {currentPage: 'home', currentTab: null},
  // Images ---------------------------------
  images: {},
  // Messages -------------------------------
  isUserMessageCountLoading: false,
  userMessagesCount: 0,
  userMessages: [],
  dataTimeStamps: {},
};

const createCoreReducer: ActionReducer<CoreState> = createReducer(
  initialCoreState,
  // ====================================================================================================
  // Get User
  // ====================================================================================================
  on(CoreActions.getUser, (state: CoreState) => ({
    ...state,
    isUserLoading: true,
    user: null,
  })),
  on(CoreActions.getUserSuccess, (state: CoreState, {user}) => ({
    ...state,
    isUserLoading: false,
    user,
  })),
  on(CoreActions.userLoginSuccess, (state: CoreState, {isUserLoggedIn}) => ({
    ...state,
    isUserLoggedIn: isUserLoggedIn,
  })),
  on(CoreActions.getUserFailure, (state: CoreState, {error}) => ({
    ...state,
    isUserLoading: false,
    user: null,
    errors: [...state.errors, error],
  })),
  on(CoreActions.setIsUserLoading, (state: CoreState, {isLoading}) => ({
    ...state,
    isUserLoading: isLoading,
  })),
  // ====================================================================================================
  // Get User Access
  // ====================================================================================================
  on(CoreActions.getUserAccess, (state: CoreState) => ({
    ...state,
    isUserAccessLoading: true,
    userAccess: null,
  })),
  on(CoreActions.setPagesFinishedLoading, (state: CoreState) => ({
    ...state,
    isPagesFinishedLoading: true,
  })),
  on(CoreActions.getUserAccessSuccess, (state: CoreState, {userAccess}) => ({
    ...state,
    isUserAccessLoading: false,
    userAccess,
  })),
  on(CoreActions.getUserAccessFailure, (state: CoreState, {error}) => ({
    ...state,
    isUserAccessLoading: false,
    userAccess: null,
    errors: [...state.errors, error],
  })),
  on(CoreActions.setIsUserAccessLoading, (state: CoreState, {isLoading}) => ({
    ...state,
    isUserAccessLoading: isLoading,
  })),
  // ====================================================================================================
  // Set ITA (Is Techo Admin)
  // ====================================================================================================
  on(CoreActions.setITA, (state: CoreState, {ita}) => ({
    ...state,
    ita,
  })),
  // ====================================================================================================
  // Get User Stores
  // ====================================================================================================
  on(CoreActions.getUserStores, (state: CoreState) => ({
    ...state,
    isUserStoresLoading: true,
    userStores: null,
  })),
  on(CoreActions.getUserStoresSuccess, (state: CoreState, {storeObject}) => {
    const userStores = GridUtils.mapStoreObjectToStore(storeObject);
    const stateLastSelectedStore = state.userLastSelectedStore;
    const findIndex = stateLastSelectedStore
      ? userStores.findIndex(store => store.storeId === stateLastSelectedStore.storeId)
      : -1;
    const userSelectedStore = findIndex !== -1 ? stateLastSelectedStore : userStores[0] || null;
    return {
      ...state,
      isUserStoresLoading: false,
      userStores,
      userSelectedStore,
      userLastSelectedStore: userSelectedStore,
    };
  }),
  on(CoreActions.getUserStoresFailure, (state: CoreState, {error}) => ({
    ...state,
    isUserStoresLoading: false,
    userStores: null,
    errors: [...state.errors, error],
  })),
  on(CoreActions.setIsUserStoresLoading, (state: CoreState, {isLoading}) => ({
    ...state,
    isUserStoresLoading: isLoading,
  })),
  // ====================================================================================================
  // Set User Selected Store
  // ====================================================================================================
  on(CoreActions.setUserSelectedStore, (state: CoreState, {selectedStore}) => ({
    ...state,
    userSelectedStore: selectedStore,
    userLastSelectedStore: selectedStore,
  })),
  on(CoreActions.setUserSelectedStoreAndGetSettings, (state: CoreState, {selectedStore}) => ({
    ...state,
    userSelectedStore: selectedStore,
    userLastSelectedStore: selectedStore
  })),
  // ====================================================================================================
  // Set Use Last Visited Page
  // ====================================================================================================
  on(CoreActions.setCurrentPageAndTab, (state: CoreState, {currentSelectedPage}) => ({
    ...state,
    currentSelectedPage,
  })),
  // ====================================================================================================
  // Get Image URL
  // ====================================================================================================
  on(CoreActions.getImageURL, (state: CoreState, {path, image}) => {
    const images = {...state.images};
    if (images[image]) {
      delete images[image];
    }
    return {
      ...state,
      images,
    };
  }),
  on(CoreActions.getImageURLSuccess, (state: CoreState, {url, image}) => {
    const images = {...state.images};
    images[image] = url;
    return {
      ...state,
      images,
    };
  }),
  on(CoreActions.getImageURLFailure, (state: CoreState, {error}) => {
    return {
      ...state,
      errors: [...state.errors, error],
    };
  }),
  // ====================================================================================================
  // User Messages
  // ====================================================================================================

  on(CoreActions.setIsUserMessageCountLoading, (state: CoreState, {isLoading}) => ({
    ...state,
    isUserMessageCountLoading: isLoading,
  })),
  on(CoreActions.getUserMessagesSuccess, (state: CoreState, {messages}) => ({
    ...state,
    isUserMessageCountLoading: false,
    userMessages: messages,
    userMessagesCount: messages.length,
  })),
  on(CoreActions.getUserMessagesFailure, (state: CoreState, {error}) => ({
    ...state,
    isUserMessageCountLoading: false,
    errors: [...state.errors, error],
  })),
  on(CoreActions.getDataTimeStampsSuccess, (state, {store, dataTimeStamps}) => ({
    ...state,
    dataTimeStamps: {
      ...state.dataTimeStamps,
      [store.storeId]: dataTimeStamps,
    },
  })),
  on(CoreActions.getDataTimeStampsFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
  })),
);

export const coreReducer = (state: CoreState, action: Action): CoreState => createCoreReducer(state, action);
