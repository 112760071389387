import {Component, Input} from '@angular/core';
import {IFacetCounts, IFacetFieldCounts} from '../../../../shared/shared-models/type-sense/type-sense-types';

@Component({
  selector: 'app-cell-renderer-supplier-facet-counts',
  templateUrl: './cell-renderer-supplier-facet-counts.component.html',
  styleUrls: ['./cell-renderer-supplier-facet-counts.component.scss'],
})
export class CellRendererSupplierFacetCountsComponent {
  @Input() code: string;
  supplierFacets: IFacetFieldCounts;

  get supplierFacetCount(): number {
    return this.supplierFacets?.[decodeURIComponent(this.code)] ?? 0;
  }

  @Input() set facetCounts(facetCounts: IFacetCounts) {
    this.supplierFacets = facetCounts?.['regularSuppCode'] ?? {};
  };
}
