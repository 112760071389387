import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {DELETE_FILED, FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {AlertController, ModalController} from '@ionic/angular';
import {OverlayEventDetail} from '@ionic/core';
import {filters2Filter, objReKey} from '../../../../shared-utilities/functions-old/object-functions';
import {ColDef} from 'ag-grid-community';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';

@Component({
  selector: 'app-save-user-settings',
  templateUrl: './save-user-settings.page.html',
  styleUrls: ['./save-user-settings.page.scss'],
})
export class SaveUserSettingsPage implements OnInit {

  @Input() getSaveObj?: () => never;
  @Input() document: string;
  @Input() desc?: string;
  @Input() what: string;
  @Input() confirmLoad = true;
  @Input() entryPrefix: string;
  @Input() entrySuffix: string;
  @Input() colDefs?: ColDef[];
  @Input() order?: string[];

  isChangeOccurring: boolean;

  savedEntries: { [save: string]: any };
  savedEntriesOrder: string[];

  saveForm: UntypedFormControl;

  selectedSavedEntry: string;

  constructor(
    private firebaseService: FirebaseService,
    private modalController: ModalController,
    private alertControl: AlertController,
  ) {
  }

  get getNumberOfSavedDocuments(): number {
    return this.savedEntriesOrder.length;
  }

  ngOnInit(): void {
    this.getSavedEntries();
    this.saveForm = new UntypedFormControl(null, [Validators.required, Validators.pattern(/^([\w-]+)+$/)]);
    if (this.entryPrefix && !this.entryPrefix.endsWith('-')) {
      this.entryPrefix += '-';
    }
    if (this.entrySuffix && !this.entrySuffix.startsWith('-')) {
      this.entrySuffix = '-' + this.entrySuffix;
    }
  }

  async onSaveEntry(): Promise<void> {
    const userEntryText: string = this.saveForm.value as string;
    let saveEntry: string = userEntryText;
    if (saveEntry) {
      // Overrides an existing entry
      if (this.savedEntries.hasOwnProperty(saveEntry)) {
        let alertElement: HTMLIonAlertElement = await this.alertControl.create({
          header: `Overwrite '${userEntryText}'`,
          message: `Are you sure you want to save over '${userEntryText}'?`,
          cssClass: ['custom-alert', 'warn'],
          buttons: ['cancel', {text: 'save', role: 'y'}],
        });
        await alertElement.present();
        let alertResponse: OverlayEventDetail = await alertElement.onDidDismiss();
        if (alertResponse.role !== 'y') {
          return;
        }
        alertElement = await this.alertControl.create({
          header: `Overwriting '${userEntryText}'`, message: `This can not be undone.`,
          cssClass: ['custom-alert', 'warn'], buttons: ['cancel', {text: 'save', role: 'y'}],
        });
        await alertElement.present();
        alertResponse = await alertElement.onDidDismiss();
        if (alertResponse.role !== 'y') {
          return;
        }
      }

      if (this.entryPrefix && !saveEntry.startsWith(this.entryPrefix)) {
        saveEntry = this.entryPrefix + saveEntry;
      }
      if (this.entrySuffix && !saveEntry.endsWith(this.entrySuffix)) {
        saveEntry += this.entrySuffix;
      }

      this.isChangeOccurring = true;
      const updateEntry = {};
      updateEntry[userEntryText] = {
        data: this.getSaveObj(),
        timestamp: new Date(),
        colDefs:
          Object.values(this.colDefs)
            .map((colDef: ColDef) => colDef.field)
            .filter((field: string) => field),
      };

      void this.firebaseService.updateUserPreferences(this.document, updateEntry)
        .then((): void => {
          this.getSavedEntries();
          this.isChangeOccurring = false;
          void this.alertControl.create({
            header: 'Save Complete',
            message: `Settings saved as '${userEntryText}'`,
            // on saveEntry remove prefix suffix
            cssClass: 'custom-alert',
            buttons: ['ok'],
          }).then((alertElement: HTMLIonAlertElement): void => {
            void alertElement.present().then();
            localStorage.setItem(`${this.entrySuffix}-visible-fields-preferences`,
              JSON.stringify({
                document: this.document,
                prefix: this.entryPrefix,
                suffix: this.entrySuffix,
                entry: saveEntry,
                data: this.getSaveObj(),
                timestamp: new Date(),
              }));
          });
        });
    }
  }

  async onLoadEntry(): Promise<void> {
    if (this.confirmLoad) {
      const alertElement: HTMLIonAlertElement = await this.alertControl.create({
        header: 'Load Save',
        message: `Load saved data '${this.selectedSavedEntry}'?`,
        cssClass: 'custom-alert',
        buttons: ['cancel', {text: 'load', role: 'y'}],
      });
      await alertElement.present();
      const response: OverlayEventDetail = await alertElement.onDidDismiss();

      if (response.role !== 'y') {
        return;
      }
    }
    void this.modalController.dismiss(this.savedEntries[this.selectedSavedEntry]).then();
  }

  getDisplayDate(savedEntry: any): string {
    if (!savedEntry?.timestamp) {
      return '(No Date Found)';
    }
    const date = savedEntry.timestamp;
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ` +
      `${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`;
  }

  onSelectChange(savedEntry: string): void {
    if (this.selectedSavedEntry !== savedEntry) {
      this.selectedSavedEntry = savedEntry;
      if (this.saveForm) {
        this.saveForm.setValue(savedEntry);
      }
    } else {
      this.selectedSavedEntry = null;
      if (this.saveForm && this.saveForm.value === savedEntry) {
        this.saveForm.setValue(null);
      }
    }
  }

  onSelectAndLoadChange(savedEntry: string): void {
    this.onSelectChange(savedEntry);
    void this.onLoadEntry().then();
  }

  onInputChange(): void {
    const formSavedEntryValue = this.saveForm.value;
    if (formSavedEntryValue) {
      if (this.selectedSavedEntry && formSavedEntryValue !== this.selectedSavedEntry) {
        this.selectedSavedEntry = null;
      }
      if (this.savedEntries.hasOwnProperty(formSavedEntryValue)) {
        this.selectedSavedEntry = formSavedEntryValue;
      }
    }
  }

  onClose(): void {
    void this.modalController.dismiss().then();
  }

  onDelete(userEntry: string): void {
    let savedEntry: string = userEntry;
    if (this.entryPrefix && !savedEntry.startsWith(this.entryPrefix)) {
      savedEntry = this.entryPrefix + savedEntry;
    }
    if (this.entrySuffix && !savedEntry.endsWith(this.entrySuffix)) {
      savedEntry += this.entrySuffix;
    }
    void this.alertControl.create({
      header: 'Confirm Deletion',
      message: `Are you sure you want to delete save '${userEntry}'?`,
      cssClass: 'custom-css',
      buttons: ['cancel', {text: 'delete', role: 'y'}],
    }).then((alert: HTMLIonAlertElement) => alert.present()
      .then(() => alert.onDidDismiss()
        .then((response: OverlayEventDetail): void => {
          if (response.role === 'y') {
            this.isChangeOccurring = true;
            const deletedField = {};
            deletedField[userEntry] = DELETE_FILED();
            void this.firebaseService.updateUserPreferences(this.document, deletedField)
              .then((): void => {
                this.isChangeOccurring = false;
                this.savedEntriesOrder.splice(this.savedEntriesOrder.indexOf(userEntry), 1);
                delete this.savedEntries[userEntry];
              });
          }
        }),
      ),
    );
  }

  private getSavedEntries(): void {
    this.firebaseService
      .getUserPreferences(this.document)
      .then((userSavedDocs): void => {
        this.savedEntries = {};
        this.savedEntriesOrder = [];
        if (userSavedDocs) {
          this.assignTimestampToDocuments(userSavedDocs);
          this.savedEntries = userSavedDocs;
          // this.filterPrefixesAndSuffixes(true);
          this.savedEntriesOrder = Object.keys(userSavedDocs)
            .sort((a: string, b: string): number => userSavedDocs[a].timestamp < userSavedDocs[b].timestamp ? -1 : 1);
        }
      })
      .catch((error): void => {
        console.error(`An error has occurred while loading user settings/preferences, please contact support.`, error);
        this.savedEntries = {};
        this.savedEntriesOrder = [];
      });
  }

  private assignTimestampToDocuments(userSavedDocs: any): void {
    Object.keys(userSavedDocs).forEach((savedDocName: string): void => {
      if (userSavedDocs[savedDocName]?.timestamp) {
        userSavedDocs[savedDocName].timestamp = userSavedDocs[savedDocName].timestamp.toDate();
      } else {
        console.error(`No timestamp found for document user-preference => (${savedDocName}), please contact support`);
      }
    });
  }

  private filterPrefixesAndSuffixes(remove: boolean): void {
    // A list of filters to apply to the saves according to the prefixes and suffixes
    const filters: ((save: string) => boolean)[] = [];
    // A list of trim functions to apply to the saves to remove the prefixes and suffixes
    const trimFunctions: ((save: string) => string)[] = [];

    if (this.entryPrefix) {
      filters.push((save: string) => save.startsWith(this.entryPrefix));

      if (remove) {
        trimFunctions.push((save: string) => save.substring(this.entryPrefix.length));
      }
    }

    if (this.entrySuffix) {
      filters.push((save: string) => save.endsWith(this.entrySuffix));

      if (remove) {
        trimFunctions.push((save: string) => save.substring(0, save.length - this.entrySuffix.length));
      }
    }
    const allSaves = Object.keys(this.savedEntries);

    if (!filters.length) {
      return;
    }

    const savesFiltered = allSaves.filter(filters2Filter(filters));
    allSaves.forEach((save) => {
      if (!savesFiltered.includes(save)) {
        delete this.savedEntries[save];
      }
    });

    if (trimFunctions.length > 0) {
      trimFunctions.forEach((f) => {
        objReKey(this.savedEntries, f);
      });
    }
    this.savedEntriesOrder = Object.keys(this.savedEntries);
  }

}
