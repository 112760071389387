import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SharedFilterState} from './shared-filter.reducer';
import {IAdvancedFilterGroup} from '../../../shared/shared-components/components/shared-advanced-filters/utils/advanced-filter-groups';

export const selectSharedState = createFeatureSelector<SharedFilterState>('shared-filter');

// ====================================================================================================
// Advanced Filter Group
// ====================================================================================================
export const selectAdvancedFilterGroups = createSelector(
  selectSharedState,
  (state: SharedFilterState) => state.advancedFilterGroups
);

export const selectAppliedAdvancedFilterGroup = createSelector(
  selectSharedState,
  (state: SharedFilterState) => state.appliedAdvancedFilterGroup !== null ? state.appliedAdvancedFilterGroup : null
);

export const selectAdvancedFilterGroupGrid = createSelector(
  selectSharedState,
  (state: SharedFilterState) => state.selectedAdvancedFilterGroupGrid
);

export const selectAdvancedFilterGroupsBySelectedGrid = createSelector(
  selectSharedState,
  selectAdvancedFilterGroupGrid,
  (state: SharedFilterState, grid: string) => state.advancedFilterGroups.filter((afg: IAdvancedFilterGroup): boolean => afg.grid === grid)
);

export const selectIsAdvancedFilterGroupsLoading = createSelector(
  selectSharedState,
  (state: SharedFilterState) => state.isAdvancedFilterGroupLoading
);

export const selectSelectedAdvancedFilterGroup = createSelector(
  selectSharedState,
  (state: SharedFilterState) => state.selectedAdvancedFilterGroup
);

export const selectSelectedAdvancedFilterGroupFilterGroups = createSelector(
  selectSelectedAdvancedFilterGroup,
  (filterGroup: IAdvancedFilterGroup) => filterGroup?.filterGroup || undefined
);
