import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {ICellEditorParams} from 'ag-grid-community';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {StockItem, Supplier} from '../../../../shared-utilities/models-old/datastructures';
import {ISearchableFields} from '../../../../shared/shared-models/type-sense/default-searchable-fields';
import {Icons} from '../../../shared-icons/icons';
import {Store} from '@ngrx/store';
import {selectCurrentPage} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {selectSuppliersByUserSelectedStore} from '../../../../features/ngp-report/store/ngp-report.selectors';
import {selectSuppliersForUserSelectedStore} from '../../../../features/stock-manager/store/stock-manager.selectors';
import {debounceTime} from 'rxjs/operators';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {NGPReport} from '../../../../shared-utilities/models-old/ngp-reports/ngp-report';

@Component({
  selector: 'app-cell-editor-supplier-multi-select',
  templateUrl: './cell-editor-supplier-multi-select.component.html',
  styleUrls: ['./cell-editor-supplier-multi-select.component.scss'],
})
export class CellEditorSupplierMultiSelectComponent implements ICellEditorAngularComp {

  @ViewChild('input') input: ElementRef;
  value: string[];
  params: ICellEditorParams;
  width: number;
  colDefField: string;
  departments = {};
  unSortedSuppliers: { key: string; description: string; }[];
  currentPage: string;
  searchControl = new FormControl('');
  filteredOptions: { key: string; description: string; }[] = [];
  showOptions = false;
  displayAbove: boolean = false;
  selectedValues: string[] = [];
  currentPage$: Observable<string>;
  unSortedSuppliers$: Observable<{ [key: string]: Supplier } | ISearchableFields>;
  protected readonly icons: typeof Icons = Icons;
  private searchedValue: string[];

  constructor(
    private readonly store: Store,
  ) {
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    if (this.showOptions) {
      this.adjustPosition();
    }
  }

  agInit(params: ICellEditorParams): void {
    this.currentPage$ = this.store.select(selectCurrentPage);
    this.currentPage$.subscribe((page: string) => {
      this.currentPage = page;
    });
    if (this.currentPage === 'ngp-report') {
      this.unSortedSuppliers$ = this.store.select(selectSuppliersByUserSelectedStore);
    } else {
      this.unSortedSuppliers$ = this.store.select(selectSuppliersForUserSelectedStore);
    }
    this.unSortedSuppliers$.subscribe((suppliers: ISearchableFields | { [key: string]: Supplier }): void => {
      this.unSortedSuppliers = this.objectToArray(suppliers);
      this.filteredOptions = this.unSortedSuppliers;
    });

    this.params = {...params};
    this.params.data = {...params.data};
    this.params.colDef = {...params.colDef};
    this.colDefField = params.colDef.field;
    this.value = this.params.data[this.colDefField];
    this.value.forEach((supplier: string) => {
      this.selectedValues.push(supplier);
    });
    this.width = this.params.colDef.width;
    this.params.data.originalValue = {...params.data.originalValue};
    this.params.data.originalValue[this.colDefField] = {...params.data.originalValue[this.colDefField]};
    this.searchControl.valueChanges.pipe(
      debounceTime(200),
    ).subscribe((searchTerm: string): void => {
      this.filterOptions(searchTerm);
    });
  }

  objectToArray(obj: ISearchableFields | { [key: string]: Supplier }): { key: string; description: string; }[] {
    if (obj) {
      return Object.keys(obj).map((key: string) => ({key, description: obj[key].name}));
    }
  }

  filterOptions(searchTerm?: string): void {
    if (searchTerm) {
      this.searchedValue = searchTerm.split(',');
    }
    let options = this.unSortedSuppliers.slice();
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      options = options.filter((option: { key: string; description: string }) => {
        const keyMatches = option.key.toLowerCase().includes(lowerSearchTerm);
        const descriptionMatches = option.description?.toLowerCase().includes(lowerSearchTerm);
        return keyMatches || descriptionMatches;
      });
    }
    options.sort((a: { key: string, description: string }, b: { key: string, description: string }) => {
      const aSelected = this.selectedValues.includes(a.key);
      const bSelected = this.selectedValues.includes(b.key);
      if (aSelected && !bSelected) {
        return -1;
      } else if (!aSelected && bSelected) {
        return 1;
      } else {
        return a.key.localeCompare(b.key);
      }
    });

    this.filteredOptions = options;
  }


  onUndoChangesClick(): void {
    this.value = this.params.data.originalValue[this.colDefField].value;
    this.params.data[this.colDefField] = this.value;
    GridUtils.removeIsEditingFlagAndDispatch(this.params.data as StockItem | NGPReport, this.colDefField, this.store, this.currentPage);
  }


  getValue(): string[] {
    if (this.selectedValues?.length < 1 && this.searchedValue?.length > 0) {
      this.searchedValue.forEach((value: string) => {
        if (this.unSortedSuppliers.some(supplier => supplier.key === value)) {
          this.selectedValues.push(value);
        }
      });
      this.onConfirmButtonClick();
    }
    GridUtils.updateIsEditedAndForceRefresh(this.params);
    GridUtils.updateSingleItem(this.params.data as StockItem | NGPReport, this.colDefField, this.value, this.store, this.currentPage);
    return this.selectedValues;
  }

  refresh(params: ICellEditorParams): boolean {
    GridUtils.updateIsEditedAndForceRefresh(this.params);
    return false;
  }

  afterGuiAttached(): void {
    if (!this.params?.data?._tags?.includes('disabled')) {
      this.input.nativeElement.focus();
    }
  }

  trackByFunction(index: number): number {
    return index;
  }

  toggleDropdown(): void {
    this.showOptions = !this.showOptions;
    if (this.showOptions) {
      this.filterOptions();
      this.adjustPosition();
    }
  }

  toggleSelection(value: string): void {
    if (this.selectedValues.includes(value as unknown as string)) {
      this.selectedValues = this.selectedValues?.filter((v: string) => v !== value as unknown as string);
    } else {
      this.selectedValues.push(value as unknown as string);
    }
  }


  adjustPosition(): void {
    const inputRect = this.input.nativeElement.getBoundingClientRect();
    const optionsHeight = 400;
    const spaceBelow = window.innerHeight - inputRect.bottom;
    const spaceAbove = inputRect.top;

    this.displayAbove = spaceBelow < optionsHeight && spaceAbove > optionsHeight;
  }

  onCancelButtonClick(): void {
    this.params.data[this.colDefField] = this.value;
    this.toggleDropdown();
  }

  onConfirmButtonClick(): void {
    this.value = this.selectedValues;
    this.params.data[this.colDefField] = this.selectedValues;
    this.params.value = this.selectedValues;
    this.toggleDropdown();
    this.getValue();
    this.params.api.stopEditing();

  }
}
