import {Injectable} from '@angular/core';
import {IAutoOrderOrder} from '../../../shared/shared-models/auto-ordering/auto-order-order';
import {Icons} from '../../../shared-modules/shared-icons/icons';
import {Store} from '@ngrx/store';
import {getAutoOrderSupplierEmailsByUserSelectedStore} from "../store/auto-ordering.actions";

@Injectable({
  providedIn: 'root'
})
export class AutoOrderStatusService {

  constructor(
    private readonly store: Store
  ) {
  }

  getStatusColour(
    autoOrder: IAutoOrderOrder,
    statusProperty: keyof Pick<IAutoOrderOrder, 'emailStatus' | 'iqStatus' | 'pdfStatus' | 'status'>
  ): 'green' | 'green-stroke' | 'red' | 'red-stroke' | 'yellow' | 'yellow-stroke' | 'white' | 'white-stroke' |
    'primary' | 'secondary' | 'primary-stroke' | 'secondary-stroke' | 'black' | 'black-stroke' {
    switch (autoOrder[statusProperty]) {
      case 'READY':
        return 'primary';
      case 'PENDING':
      case 'PREPARED':
        return 'yellow';
      case 'EMPTY':
      case 'SKIP':
      case 'SENT':
      case 'SUCCESS':
        return 'green';
      case 'DELETING':
      case 'FAILED':
        return 'red';
      default:
        return 'white';
    }
  }

  getStatusIcon(
    autoOrder: IAutoOrderOrder,
    statusProperty: keyof Pick<IAutoOrderOrder, 'emailStatus' | 'iqStatus' | 'pdfStatus' | 'status'>
  ): Icons {
    let icon = Icons.googleDinosaur;
    switch (autoOrder[statusProperty]) {
      case 'READY':
        if (statusProperty === 'emailStatus') icon = Icons.emailCheckOutline;
        if (statusProperty === 'pdfStatus') icon = Icons.pdfBox;
        if (statusProperty === 'iqStatus') icon = Icons.logoIQ;
        if (statusProperty === 'status') icon = Icons.receiptTextOutline;
        break;
      case 'PENDING':
        if (statusProperty === 'emailStatus') icon = Icons.emailSyncOutline;
        break;
      case 'SKIP':
        if (statusProperty === 'emailStatus') icon = Icons.closeCircle;
        break;
      case 'SENT':
        if (statusProperty === 'emailStatus') icon = Icons.emailFastOutline;
        break;
      case 'FAILED':
        if (statusProperty === 'emailStatus') icon = Icons.emailAlertOutline;
        if (statusProperty === 'pdfStatus') icon = Icons.pdfBox;
        if (statusProperty === 'iqStatus') icon = Icons.logoIQ;
        if (statusProperty === 'status') icon = Icons.receiptTextRemoveOutline;
        break;
      case 'SUCCESS':
        if (statusProperty === 'pdfStatus') icon = Icons.download;
        if (statusProperty === 'iqStatus') icon = Icons.logoIQ;
        if (statusProperty === 'status') icon = Icons.receiptTextCheckOutline;
        break;
      case 'EMPTY':
        if (statusProperty === 'status') icon = Icons.receiptOutline;
        break;
      case 'PREPARED':
        if (statusProperty === 'status') icon = Icons.receiptTextEditOutline;
        break;
      case 'DELETING':
        if (statusProperty === 'status') icon = Icons.deleteClockOutline;
        break;
      case 'CREATED':
      case 'SUBMITTED':
        break;
    }
    return icon;
  }

  triggerAction(
    autoOrder: IAutoOrderOrder,
    statusProperty: keyof Pick<IAutoOrderOrder, 'emailStatus' | 'iqStatus' | 'pdfStatus' | 'status'>
  ): void {
    if (statusProperty === 'emailStatus') {
      switch (autoOrder[statusProperty]) {
        case 'PENDING':
        case 'SKIP':
        case 'SENT':
        case 'FAILED':
          this.store.dispatch(getAutoOrderSupplierEmailsByUserSelectedStore({
            supplierIds: [autoOrder.supplierId]
          }));
          break;
        default:
        case 'READY':
          break;
      }
    }
    if (statusProperty === 'iqStatus') {
      switch (autoOrder[statusProperty]) {
        case 'READY':
        case 'FAILED':
        case 'SUCCESS':
          break;
      }
    }
    if (statusProperty === 'pdfStatus') {
      switch (autoOrder[statusProperty]) {
        case 'READY':
        case 'FAILED':
        case 'SUCCESS':
          break;
      }
    }
    if (statusProperty === 'status') {
      switch (autoOrder[statusProperty]) {
        case 'READY':
        case 'FAILED':
        case 'SUCCESS':
        case 'CREATED':
        case 'EMPTY':
        case 'PREPARED':
        case 'SUBMITTED':
        case 'DELETING':
          break;
      }
    }
  }
}

