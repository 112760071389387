import {CellClassParams, ColDef, ValueFormatterParams} from 'ag-grid-community';
import * as CellRenderers from '../../../shared-modules/shared-grid/components-cell-renderers';
import {DataAdditionalNumbers} from '../../../shared-utilities/models-old/ngp-report-grid/data-additional-numbers';
import {DateUtils} from '../../../shared-utilities/utils-old/shared-utils-old/date-utils';
import {DataAdditionalIcons} from '../../../shared-utilities/models-old/ngp-report-grid/data-additional-icons';
import * as CellEditors from '../../../shared-modules/shared-grid/components-cell-editors';
import {StockItem} from '../../../shared-utilities/models-old/datastructures';

const costMissTooltip = 'Latest cost is not the same as last GRV price.';
const itemRecentlyCreatedTooltip = 'Item was recently created.';
const itemEditedTooltip = 'Item was marked as edited.';
const itemErrorTooltip = `Item has an error and can't be submitted`;

export const stockManagerGridColDefs: ColDef[] = [
  // Icons ------------------------------
  {
    field: 'icons',
    headerName: '',
    hide: false,
    pinned: 'left',
    lockVisible: true,
    resizable: true,
    lockPinned: true,
    lockPosition: true,
    cellRenderer: CellRenderers.CELL_RENDERER_ICONS,
    cellRendererParams: {
      dataAdditional: {
        isCostMissIconVisible: true,
        costMissIconTooltip: costMissTooltip,
        isItemRecentlyCreatedIconVisible: true,
        itemRecentlyCreatedIconTooltip: itemRecentlyCreatedTooltip,
        isItemEditedIconVisible: true,
        itemEditedIconTooltip: itemEditedTooltip,
        isErrorIconVisible: true,
        itemErrorIconTooltip: itemErrorTooltip,
      } as DataAdditionalIcons,
    },
    width: 70,
  },
  // General ----------------------------
  {
    field: 'code',
    headerName: 'Code',
    hide: false,
    pinned: 'left',
    resizable: true,
    lockVisible: true,
    lockPinned: true,
    lockPosition: true,
    type: ['alignRight'],
    editable: false,
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_DISABLE_ITEM,
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
    },
    width: 120,
  },
  {
    field: 'desc',
    headerName: 'Description',
    pinned: 'left',
    hide: false,
    lockPinned: true,
    resizable: true,
    editable: false,
    lockVisible: true,
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 300,
  },
  {
    field: 'barcode',
    headerName: 'Barcode',
    hide: true,
    lockPinned: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 160,
  },
  {
    field: 'binL',
    headerName: 'Bin Location',
    hide: true,
    lockPinned: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 120,
  },
  {
    field: 'lastPurchase',
    headerName: 'Last Purchased',
    hide: true,
    lockPinned: true,
    cellEditor: false,
    valueFormatter: (params: ValueFormatterParams): string => {
      if (+params.value < 1 || isNaN(+params.value)) {
        return 'No Date Found';
      } else {
        return DateUtils.dateToString(params.value as Date);
      }
    },
    width: 150,
  },
  {
    field: 'lastSold',
    headerName: 'Last Sold',
    hide: true,
    lockPinned: true,
    cellEditor: false,
    valueFormatter: (params: ValueFormatterParams): string => {
      if (+params.value < 1 || isNaN(+params.value)) {
        return 'No Date Found';
      } else {
        return DateUtils.dateToString(params.value as Date);
      }
    },
    width: 150,
  },
  {
    field: 'dlvColl',
    headerName: 'DelCol',
    hide: true,
    lockPinned: true,
    resizable: true,
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    width: 100,
  },
  {
    field: 'dep',
    headerName: 'Department',
    hide: true,
    lockPinned: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_SELECT_DROPDOWN,
    cellRenderer: CellRenderers.CELL_RENDERER_DEPARTMENTS,
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 175,
  },
  {
    field: 'subDep',
    headerName: 'Sub Department',
    hide: true,
    lockPinned: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_SELECT_DROPDOWN,
    cellRenderer: CellRenderers.CELL_RENDERER_DEPARTMENTS,
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 175,
  },
  {
    field: 'gp1',
    headerName: 'GP % (Avg Cost)',
    hide: true,
    lockPinned: true,
    type: ['alignRight'],
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellRendererParams: {dataAdditional: {decimalPlaces: 2} as DataAdditionalNumbers},
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 150,
  },
  {
    field: 'suppUsedLastDate1',
    headerName: 'GRV Date',
    hide: true,
    lockPinned: true,
    cellEditor: false,
    valueFormatter: (params: ValueFormatterParams): string => {
      if (+params.value < 1 || isNaN(+params.value)) {
        return 'No Date Found';
      } else {
        return DateUtils.dateToString(params.value as Date);
      }
    },
    width: 175,
  },
  {
    field: 'genCode',
    headerName: 'General Code',
    hide: true,
    resizable: true,
    lockPinned: true,
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 140,
  },
  {
    field: 'latestCost',
    headerName: 'Latest Cost',
    hide: false,
    lockPinned: true,
    type: ['alignRight'],
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellRendererParams: {dataAdditional: {decimalPlaces: 2} as DataAdditionalNumbers},
    width: 120,
  },
  {
    field: 'onHand',
    headerName: 'On Hand',
    hide: false,
    lockPinned: true,
    type: ['alignRight'],
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    width: 100,
  },
  {
    field: 'nominalGP',
    headerName: 'Current GP %',
    hide: false,
    lockPinned: true,
    lockVisible: true,
    resizable: true,
    type: ['alignRight'],
    cellEditor: CellEditors.CELL_EDITOR_PRICE_NGP,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellRendererParams: {dataAdditional: {decimalPlaces: 2} as DataAdditionalNumbers},
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 160,
  },
  {
    field: 'onHoldCode',
    headerName: 'On Hold Code',
    hide: true,
    lockPinned: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_MULTI_SELECT_ON_HOLD,
    cellRenderer: CellRenderers.CELL_RENDERER_MULTI_SELECT,
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 140,
  },
  {
    field: '_tags',
    headerName: 'Tags',
    hide: true,
    lockPinned: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_MULTI_SELECT_ARRAY,
    cellRenderer: CellRenderers.CELL_RENDERER_MULTI_SELECT_ARRAY,
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        const arraysAreEqual = (arr1: string[], arr2: string[]): boolean => {
          if (!arr1 || !arr2) {
            return false;
          }
          if (arr1.length !== arr2?.length) {
            return false;
          }
          return arr1.every((value, index) => {
            return value === arr2[index];
          });
        };
        if (typeof params.data[pos] === 'string') {
          params.data[pos] = params.data[pos].split(',');
        }
        return !arraysAreEqual(
          params?.data?.[pos]?.filter((item: string) => item !== '') as string[],
          params?.data?.originalValue?.[pos]?.value as unknown as string[],
        );
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 100,
  },
  {
    field: 'ordLvl',
    headerName: 'Order Level',
    hide: true,
    lockPinned: true,
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 120,
  },
  {
    field: 'maxOrderLevel',
    headerName: 'Max Order Level',
    hide: true,
    lockPinned: true,
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 100,
  },
  {
    field: 'minOrderLevel',
    headerName: 'Min Order Level',
    hide: true,
    lockPinned: true,
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 100,
  },
  {
    field: 'packSize',
    headerName: 'Pack Size',
    hide: true,
    lockPinned: true,
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 100,
  },
  {
    field: 'prvSellingPri',
    headerName: 'Prev Retail Price',
    hide: true,
    lockPinned: true,
    type: ['alignRight'],
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellRendererParams: {dataAdditional: {decimalPlaces: 2} as DataAdditionalNumbers},
    width: 160,
  },
  {
    field: 'sellPriIncl1',
    headerName: 'Retail Price',
    hide: false,
    resizable: true,
    lockVisible: true,
    type: ['alignRight'],
    cellEditor: CellEditors.CELL_EDITOR_PRICE_NGP,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellRendererParams: {dataAdditional: {decimalPlaces: 2} as DataAdditionalNumbers},
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return +params.data.originalValue?.[pos]?.value !== +params.data[pos];
      },
    },
    width: 130,
  },
  {
    field: 'purchaseOrder',
    headerName: 'Purchase Order',
    hide: true,
    lockPinned: true,
    resizable: true,
    cellEditor: false,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    width: 150,
  },
  {
    field: 'recommendedGP',
    headerName: 'Target GP %',
    hide: true,
    lockPinned: true,
    type: ['alignRight'],
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_CURRENCY,
    cellRendererParams: {dataAdditional: {decimalPlaces: 2} as DataAdditionalNumbers},
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 100,
  },
  {
    field: 'suppCode',
    headerName: 'Supplier Code',
    hide: true,
    lockPinned: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_GENERAL,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 130,
  },
  {
    field: 'regularSuppCode',
    headerName: 'Supplier',
    hide: true,
    lockPinned: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_SUPPLIER,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 100,
  },
  {
    field: 'multipleSuppliers',
    headerName: 'Other Suppliers',
    hide: true,
    lockPinned: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_SUPPLIER_MULTI_SELECT,
    cellRenderer: CellRenderers.CELL_RENDERER_TEXT,
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        const arraysAreEqual = (arr1: string[], arr2: string[]): boolean => {
          if (!arr1 || !arr2) {
            return false;
          }
          if (arr1.length !== arr2.length) {
            return false;
          }
          return arr1.every((value, index) => {
            return value === arr2[index];
          });
        };
        if (typeof params.data[pos] === 'string') {
          params.data[pos] = params.data[pos].split(',');
        }
        return !arraysAreEqual(
          params?.data?.[pos]?.filter((item: string) => item !== '') as string[],
          params?.data?.originalValue?.[pos]?.value as unknown as string[],
        );
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 140,
  },
  {
    field: 'lineColourCode',
    headerName: 'Line Colour',
    hide: true,
    lockPinned: true,
    resizable: true,
    cellEditor: CellEditors.CELL_EDITOR_SELECT_DROPDOWN,
    cellRenderer: CellRenderers.CELL_RENDERER_LINE_COLOUR,
    cellClassRules: {
      ['cell-changed']: (params: CellClassParams<StockItem>): boolean => {
        const pos = params.column.getColDef().field;
        return params.data.originalValue?.[pos]?.value !== params.data[pos];
      },
      ['cell-disabled']: (params: CellClassParams<StockItem>): boolean => {
        return params.data.disabled;
      },
    },
    width: 140,
  },
  {
    field: 'unitsYear',
    headerName: 'Units 12 Months',
    hide: false,
    lockVisible: true,
    lockPinned: true,
    type: ['alignRight'],
    cellEditor: false,
    valueFormatter: (params: ValueFormatterParams<StockItem>): string => {
      if (isNaN(params.data.unitsYear)) {
        return '0';
      }
      return `${params.data.unitsYear}`;
    },
    resizable: true,
    width: 150,
  },
];

export const editableFields: string[] = [
  'desc',
  'barcode',
  'binL',
  'dep',
  'subDep',
  'genCode',
  'nominalGP',
  'onHoldCode',
  '_tags',
  'ordLvl',
  'maxOrderLevel',
  'minOrderLevel',
  'packSize',
  'sellPriIncl1',
  'recommendedGP',
  'suppCode',
  'multipleSuppliers',
  'lineColourCode',
];
