import {Injectable} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {FirebaseError} from '@angular/fire/app';
import {Message} from '../models-old/datastructures';
import {AccessFlags, RuleHumanID, RuleInfo, ruleStructure} from '../models-old/utils-old/rule-structure';
import {AngularFirestore, DocumentSnapshot} from '@angular/fire/compat/firestore';
import {StandardAlertsService} from './standard-alerts.service';
import {IUserAccess} from '../../shared/shared-models/user-access/user-access';
import {ISharedModalBasic, ISharedModalBasicResponse} from '../../shared/shared-models/modals/shared-modal-basic';
import {Icons} from '../../shared-modules/shared-icons/icons';
import {
  SharedModalBasicComponent,
} from '../../shared/shared-components/components/shared-modal-basic/shared-modal-basic.component';
import {getUser, getUserAccess, setCurrentPageAndTab} from '../../features-as-modules/feature-core/store/core.actions';
import {Store} from '@ngrx/store';
import {OverlayEventDetail} from
    "@ionic/core";

@Injectable({
  providedIn: 'root',
})
export class FireAuthService {

  private authStateSub: Subscription;
  private userAccessSubject: BehaviorSubject<IUserAccess> = new BehaviorSubject<IUserAccess>(null);
  private readonly userIdSubject: BehaviorSubject<string>;
  private readonly preLogoutCallbacks: { [name: string]: { call(): void | Promise<void>; promise?: boolean } } = {};
  private readonly ruleFlags = new BehaviorSubject<{ [storeId: string]: boolean[] }>({});

  private authTime: Date;

  constructor(
    private fireAuth: AngularFireAuth,
    private angularFirestore: AngularFirestore,
    private stdAlert: StandardAlertsService,
    private alertControl: AlertController,
    private modalController: ModalController,
    private readonly store: Store,
  ) {
    this.userIdSubject = new BehaviorSubject<string>(null);
    this.fireAuth.setPersistence('session').then(() => {
      this.subscribe();
    }).catch((error) => {
      console.error('Failed to set persistence', error);
      this.subscribe();
    });
  }

  get userIdSub(): Observable<string> {
    return this.userIdSubject.asObservable();
  }

  get userAccess(): Observable<IUserAccess> {
    return this.userAccessSubject.asObservable();
  }

  hasAccess(storeId: string, ruleIdentity: {
    ruleIndex?: number;
    ruleID?: RuleHumanID | AccessFlags
  }): true | RuleInfo {
    const hasIndex = ruleIdentity.hasOwnProperty('ruleIndex');
    const hasID = ruleIdentity.hasOwnProperty('ruleID');
    let ruleIndex: number = null;

    if (hasID) {
      if (AccessFlags[ruleIdentity.ruleID]) {
        if (this.userAccessSubject.value[ruleIdentity.ruleID]) {
          return true;
        } else if (this.userAccessSubject.value.hasOwnProperty(ruleIdentity.ruleID)) {
          console.warn(`User should not have an empty access flag ${AccessFlags[ruleIdentity.ruleID]}`);
        }
        return AccessFlags[ruleIdentity.ruleID] as RuleInfo;
      }
      if (ruleStructure.dictionary.hasOwnProperty(ruleIdentity.ruleID)) {
        ruleIndex = ruleStructure.index.find((rule, index) => rule.humanID === ruleIdentity.ruleID).index;
      }
    }

    if (ruleIndex !== null) {
      if (hasIndex && ruleIndex !== ruleIdentity.ruleIndex) {
        throw Error('Provided ruleIndex and ruleID do not point to the same rule');
      }
    } else if (hasIndex) {
      ruleIndex = ruleIdentity.ruleIndex;

      if (ruleIndex < 0 || ruleIndex >= ruleStructure.index.length) {
        throw Error(`Invalid ruleIndex ${ruleIndex}. No such rule index exists.`);
      }
    } else {
      throw Error('WTF');
    }

    if (this.ruleFlags.value[storeId]?.[ruleIndex]) {
      return true;
    } else {
      return ruleStructure.index[ruleIndex];
    }
  }

  async pause(): Promise<void> {
    const uID = await this.userIdSub.pipe(take(1)).toPromise();

    if (!uID) {
      this.authStateSub.unsubscribe();
      this.authStateSub = null;
    }
  }

  async unPause(): Promise<void> {
    const uID = await this.userIdSub.pipe(take(1)).toPromise();

    if (!uID && !this.authStateSub) {
      this.subscribe();
    }
  }

  setLogoutCallback(name: string, callback: () => void | Promise<void>, promise?: boolean): boolean {
    if (this.preLogoutCallbacks.hasOwnProperty(name)) {
      return false;
    }
    this.preLogoutCallbacks[name] = {call: callback};
    if (promise) {
      this.preLogoutCallbacks[name].promise = promise;
    }
    return true;
  }

  async login(email: string, password: string): Promise<void> {
    if (email && password) {
      const loadingModal = await this.modalController.create({
        component: SharedModalBasicComponent,
        componentProps: {
          modalTitle: 'Verifying Credentials',
          contextHeading: 'Logging In',
          modalTitleIcon: Icons.logIn,
          modalTitleIconColor: 'green-stroke',
          buttonClose: false,
          buttonAccept: false,
          contextDescription: [
            'Please wait as we verify your login credentials.',
          ],
        },
        cssClass: 'shared-basic-modal-css-smaller',
      });
      await loadingModal.present();

      try {
        const response = await this.fireAuth.signInWithEmailAndPassword(email, password);
        await this.unPause();
        await loadingModal.dismiss();
        this.store.dispatch(getUser());
        this.store.dispatch(getUserAccess());
        if (response.user) {
          this.store.dispatch(setCurrentPageAndTab({currentSelectedPage: {currentPage: 'home', currentTab: null}}));
        }
      } catch (e) {
        await loadingModal.dismiss();

        let modalProps = {
          buttonClose: true,
          buttonAccept: false,
          buttonCloseText: 'Close',
          modalTitleIcon: Icons.bug,
          modalTitleIconColor: 'red',
          modalTitle: '',
          contextHeading: '',
          contextDescription: [],
        };

        switch (e.code) {
          case 'auth/user-not-found':
            modalProps = {
              ...modalProps,
              modalTitle: 'User Not Found',
              contextHeading: 'No user found with the provided email address.',
              contextDescription: [
                'Please check you have entered the correct address or that your account has not been deleted.',
              ],
            };
            break;
          case 'auth/invalid-email':
            modalProps = {
              ...modalProps,
              modalTitle: 'Invalid Email Address',
              contextHeading: 'The address provided is not a valid email address.',
            };
            break;
          case 'auth/wrong-password':
            modalProps = {
              ...modalProps,
              modalTitle: 'Password Incorrect',
              contextHeading: 'The password you entered is incorrect.',
            };
            break;
          default:
            modalProps = {
              ...modalProps,
              modalTitle: 'Unknown Error Occurred',
              contextHeading: 'An unexpected error occurred.',
              contextDescription: [`Error code: ${e.code}`],
            };
            break;
        }

        const errorModal = await this.modalController.create({
          component: SharedModalBasicComponent,
          componentProps: modalProps,
          cssClass: ['shared-basic-modal-css-small'],
        });
        await errorModal.present();
      }
    }
  }

  async onSignOutRequest(
    confirmSignOut: boolean = true,
    navigateToLogIn: boolean = true,
  ): Promise<void> {
    const userId = await this.userIdSub.pipe(take(1)).toPromise();

    if (!userId || userId === '') {
      return;
    }

    if (confirmSignOut) {
      const componentProps: ISharedModalBasic = {
        buttonAccept: true,
        buttonAcceptText: 'Sign Out',
        buttonClose: true,
        buttonCloseCross: false,
        buttonCloseText: `Cancel`,
        modalTitle: 'Do You Wish To Sign out?',
        modalTitleIcon: Icons.logIn,
        modalTitleIconColor: 'yellow-stroke',
        contextHeading: 'NOTE: You are about to Sign out of Gallix.',
        contextDescription: [
          'Please make sure all edits are submitted before signing out.',
          'You will need to log in and possibly re-enter your credentials to access your account again.',
        ],
      };
      const modalController: HTMLIonModalElement = await this.modalController.create({
        component: SharedModalBasicComponent,
        componentProps,
        cssClass: ['shared-basic-modal-css-small'],
      });
      await modalController.present();
      const response: OverlayEventDetail<ISharedModalBasicResponse> = await modalController.onDidDismiss();
      if (response?.data?.buttonPressed !== 'button-accept') {
        return;
      }
    }

    this.setUserAccessFlags(null);
    this.userIdSubject.next(null);

    const callBackNames: string[] = Object.keys(this.preLogoutCallbacks);

    if (callBackNames.length > 0) {
      callBackNames.forEach((callBackName: string): void => {

        const callBackPromise: {
          call(): (void | Promise<void>);
          promise?: boolean
        } = this.preLogoutCallbacks[callBackName];

        if (callBackPromise.promise) {
          void (callBackPromise.call as () => Promise<void>)()
            .then((): void => {
              delete this.preLogoutCallbacks[callBackName];
            });
        } else {
          void callBackPromise.call();
          delete this.preLogoutCallbacks[callBackName];
        }
      });
    }

    void this.fireAuth.signOut().then((): void => {
      if (navigateToLogIn) {
        this.store.dispatch(setCurrentPageAndTab({currentSelectedPage: {currentPage: 'login', currentTab: null}}));
        window.location.reload();
      }
    });
  }

  async sendPasswordResetEmail(email: string, storeId?: string): Promise<void> {
    const userId = this.userIdSubject.value;

    const success = async (): Promise<void> => {
      const ac = await this.alertControl.create({
        header: 'Email Sent', subHeader: 'A password reset email has been ' +
          `sent to ${email}.`, cssClass: 'custom-alert', buttons: ['ok'],
      });
      await ac.present();
      await ac.onDidDismiss();
    };

    const uncertainEmail = async (): Promise<void> => {
      try {
        await this.fireAuth.sendPasswordResetEmail(email);
        await success();
      } catch (error) {
        if ((error as FirebaseError).code === 'auth/user-not-found') {
          await this.stdAlert.errorAlert({type: 'USER-NOT-FOUND'});
        } else {
          await this.stdAlert.errorAlert({
            subHeader: `Error requesting password reset on ${error}`, message: `"${error.message}"`,
          });
        }
      }
    };

    if (userId) {
      if (email !== userId) {
        if (storeId) {
          if (this.hasAccess(storeId, {ruleID: 'a.'}) === true) {
            await uncertainEmail();
          } else {
            await this.stdAlert.errorAlert({
              subHeader: 'You do not have permission to request an email reset ' +
                `for ${email}`, type: 'PERMISSION',
            });
          }
        }
      } else {
        await this.fireAuth.sendPasswordResetEmail(email);
        await success();
      }
    } else {
      await uncertainEmail();
    }
  }

  async accessChange(msg: Message): Promise<void> {

    if (msg.type !== 'ACCESS_CHANGE' || (this.authTime && msg.timestamp.getTime() < this.authTime.getTime())) {
      return;
    }
    const userId = await this.userIdSub.pipe(take(1)).toPromise();
    void this.angularFirestore.doc(`/user_access/${userId}`).get().toPromise()
      .then((uaD: DocumentSnapshot<unknown>) => {
        this.setUserAccessFlags(uaD.data() as IUserAccess);
      });
    const ac = await this.alertControl.create({
      header: 'Your Access Permissions Have Been Updated', message: 'If you think this is a mistake please contact ' +
        'your admin.', cssClass: 'custom-alert', buttons: ['ok'],
    });
    await ac.present();
    await ac.onDidDismiss();
  }

  private subscribe(): void {
    this.authStateSub = this.fireAuth.authState.subscribe(user => {
      if (user) {
        this.userIdSubject.next(user.email);
        void this.angularFirestore.doc(`/user_access/${user.email}`).get().toPromise()
          .then((uaD: DocumentSnapshot<IUserAccess>) => {
            this.setUserAccessFlags(uaD.data() );
          });
      } else {
        this.userIdSubject.next(null);
      }
    }, error => {
      console.error(error);
    });
  }

  private setUserAccessFlags(access: IUserAccess | null): void {
    const storeFlags: { [storeId: string]: boolean[] } = {};
    if (access?.storeList && access.storeList.length > 0) {
      access.storeList.forEach((storeId: string): void => {
        storeFlags[storeId] = ruleStructure.index.map((rule: RuleInfo, index: number): boolean =>
          rule.index === index && Math.floor((access.stores[storeId] / Math.pow(2, index)) % 2) === 1);
      });
    }
    this.ruleFlags.next(Object.keys(storeFlags).length > 0 ? storeFlags : null);
    this.userAccessSubject.next(access);
  }
}
