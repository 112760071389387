import {Component, Input} from '@angular/core';
import {ColDef} from 'ag-grid-community';
import {togglePriceBanding} from '../../../../features/stock-manager/store/stock-manager.actions';
import {ModalController, PopoverController} from '@ionic/angular';
import {Store} from '@ngrx/store';
import {stockManagerGridColDefs} from '../../../../features/stock-manager/models/stock-manager-grid-col-def';
import {getDeepCopyOfObject} from '../../../../shared/shared-utils/object/object.utils';
import {ISharedModalBasic} from '../../../../shared/shared-models/modals/shared-modal-basic';
import {Icons} from '../../../shared-icons/icons';
import {
  SharedModalBasicComponent,
} from '../../../../shared/shared-components/components/shared-modal-basic/shared-modal-basic.component';
import {
  ngpReportGridColDefs,
} from '../../../../shared-utilities/models-old/ngp-report-grid/defaults/ngp-report/ngp-report-grid-col-defs';
import {setNGPReportFiltersAndTools} from '../../../../features/ngp-report/store/ngp-report.actions';
import {first} from 'rxjs/operators';
import {selectFiltersAndToolsWithAppliedFilters} from '../../../../features/ngp-report/store/ngp-report.selectors';
import {Observable} from 'rxjs';
import {FiltersAndTools} from '../../../../shared-utilities/models-old/ngp-reports/filters-tools-ngp';
import {setFitToGrid} from 'src/app/features/settings-old/store/settings.actions';
import {setCurrentPageAndTab} from '../../../../features-as-modules/feature-core/store/core.actions';

@Component({
  selector: 'app-stock-manager-input-container',
  templateUrl: './shared-grid-table-settings.component.html',
  styleUrls: ['./shared-grid-table-settings.component.scss'],
})
export class SharedGridTableSettingsComponent {
  @Input() visibleFields: { [p: string]: boolean };
  @Input() showAllColDef: boolean;
  @Input() isCurrentlyPriceBanding: boolean;
  stockManagerColDefs: ColDef[] = [...stockManagerGridColDefs];
  stockManagerColDefsDefault: ColDef[] = getDeepCopyOfObject(stockManagerGridColDefs);
  ngpReportColDefs: ColDef[] = [...ngpReportGridColDefs];
  ngpReportColDefsDefault: ColDef[] = getDeepCopyOfObject(ngpReportGridColDefs);
  colDefs: ColDef[] = [];
  colDefsDefault: ColDef[] = [];
  selectPopover: {
    order: string[]; selection: { [key: string]: string }; disabledKeys: string[]; value: string[];
  } = {
    order: [], selection: {}, disabledKeys: [], value: [],
  };
  currentSelectedPage: string;

  filtersAndTools$: Observable<FiltersAndTools>;

  protected readonly icons: typeof Icons = Icons;

  constructor(
    private modalController: ModalController,
    private readonly store: Store,
    private popoverController: PopoverController,
  ) {
  }

  @Input() set currentPage(currentPage: string) {
    this.currentSelectedPage = currentPage;
    switch (currentPage) {
      case'stock-manager':
        this.colDefs = [...this.stockManagerColDefs];
        this.colDefsDefault = getDeepCopyOfObject(this.stockManagerColDefsDefault);
        break;
      case'ngp-report':
        this.colDefs = [...this.ngpReportColDefs];
        this.filtersAndTools$ = this.store.select(selectFiltersAndToolsWithAppliedFilters);
        this.colDefsDefault = getDeepCopyOfObject(this.ngpReportColDefsDefault);
        break;
    }
  }


  onToggleFitToTableClick(): void {
    this.store.dispatch(setFitToGrid({fitToGrid: !this.showAllColDef}));
  }

  navigateToSettings(): void {
    void this.popoverController.dismiss();
    this.store.dispatch(setCurrentPageAndTab({currentSelectedPage: {currentPage: 'settings', currentTab: 'user'}}));
  }

  async onTogglePriceBandingClick(event: Event): Promise<void> {
    const isPriceBanding = (event.target as HTMLInputElement).checked;
    if (this.isCurrentlyPriceBanding !== isPriceBanding) {
      if (this.isCurrentlyPriceBanding) {
        void this.onTriggerPriceBanding().then();
      }
      switch (this.currentSelectedPage) {
        case'stock-manager':
          this.store.dispatch(togglePriceBanding());
          break;
        case'ngp-report':
          const filtersAndTools = await this.filtersAndTools$.pipe(first()).toPromise();
          filtersAndTools.isApplyPriceBandingOn = !isPriceBanding;
          this.store.dispatch(setNGPReportFiltersAndTools({filtersAndTools: filtersAndTools}));
          break;
      }
    } else {
      switch (this.currentSelectedPage) {
        case'stock-manager':
          this.store.dispatch(togglePriceBanding());
          break;
        case'ngp-report':
          const filtersAndTools = await this.filtersAndTools$.pipe(first()).toPromise();
          filtersAndTools.isApplyPriceBandingOn = !isPriceBanding;
          this.store.dispatch(setNGPReportFiltersAndTools({filtersAndTools: filtersAndTools}));
          break;
      }
    }
  }

  private async onTriggerPriceBanding(): Promise<void> {
    const componentProps: ISharedModalBasic = {
      buttonAccept: true,
      buttonAcceptText: 'Accept',
      buttonClose: false,
      buttonCloseCross: false,
      buttonCloseText: ``,
      modalTitle: 'Important Notice on Edited Items',
      modalTitleIcon: Icons.infoSlabCircleOutline,
      modalTitleIconColor: 'yellow',
      contextHeading: 'NOTE: You are about to enable price banding.',
      contextDescription: [
        'You are enabling Price Banding to ensure price consistency.',
        '',
        'Price banding will be applied to all currently edited items, all edited values will be affected.',
        'This can be undone, while changes are not saved, by disabling price banding.',
      ],
    };
    const modalController: HTMLIonModalElement = await this.modalController.create({
      component: SharedModalBasicComponent, componentProps, cssClass: ['shared-basic-modal-css'],
    });
    await modalController.present();
  }


}
