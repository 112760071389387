// 1️⃣ Angular Imports
import {ErrorHandler, isDevMode, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouteReuseStrategy} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';

// 2️⃣ Third-Party Libraries
import {AgGridModule} from 'ag-grid-angular';
import {AngularFireModule} from '@angular/fire/compat';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgxPaginationModule} from 'ngx-pagination';
import {PushModule} from '@ngrx/component';

// 3️⃣ Environment Configurations
import {environment} from '../environments/environment';

// 4️⃣ Application Modules & Components
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

// 5️⃣ Features
import {CoreModule} from './features/core/core.module';
import {FeatureCoreModule} from './features-as-modules/feature-core/feature-core.module';

// 6️⃣ Shared Modules
import {SharedComponentsModule} from './shared/shared-components/shared-components.module';
import {SharedIconsModule} from './shared-modules/shared-icons/shared-icons.module';
import {SharedModule} from './shared-modules/shared-module/shared-module.module';

// 7️⃣ Global Services or Handlers
import {GlobalErrorHandler} from './shared/shared-models/error/global-error-handler';

@NgModule({
  bootstrap: [
    AppComponent
  ],
  declarations: [
    AppComponent
  ],
  imports: [
    AgGridModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    FeatureCoreModule,
    IonicModule.forRoot(),
    MatTooltipModule,
    NgxPaginationModule,
    PushModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    SharedComponentsModule,
    SharedIconsModule,
    SharedModule,
  ],
  providers: [
    BarcodeScanner,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
  ],
})
export class AppModule {
}
