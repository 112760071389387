<div
  [ngClass]="params.data.originalValue?.[params.colDef.field]?.value !== value ? 'edited' : ''"
  class="cell-renderer-text">
  <div>{{ value }}</div>
  <app-icon-backspace-outline
    (click)="onUndoChangesClick()"
    *ngIf="params.data.originalValue?.[params.colDef.field]?.value !== value && !params.data.disabled && areArraysEqual()"
    [tooltip]="'Previous Value = ' +params.data.originalValue[params.colDef.field].value">
  </app-icon-backspace-outline>
</div>

