import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {IDataTimeStamps} from '../../../../../features/core/models/data-time-stamps';
import {IStore} from '../../../../shared-models/store/store';

@Component({
  selector: 'app-shared-freshness-indicator-component',
  templateUrl: './shared-freshness-indicator-component.component.html',
  styleUrls: ['./shared-freshness-indicator-component.component.scss'],
})
export class SharedFreshnessIndicatorComponentComponent {
  @Input() dataType: string;
  @Input() currentStore: IStore;
  private dataTimeStamp: Date;

  constructor(private cdr: ChangeDetectorRef) {
  }

  @Input() set timeStamps(timeStamps: { [p: string]: IDataTimeStamps }) {
    this.setTimeStampToDataType(timeStamps);
  }

  setTimeStampToDataType(timeStamps: { [p: string]: IDataTimeStamps }): void {
    this.dataTimeStamp = timeStamps?.[this.currentStore.storeId]?.[this.dataType];
  }


  getColor(): string {
    this.cdr.markForCheck();
    if (!this.dataTimeStamp) {
      return 'grey';
    }

    const timeStampDate = new Date(this.dataTimeStamp);
    const now = new Date();
    const diffMs = now.getTime() - timeStampDate.getTime();
    const diffMinutes = diffMs / 60000;

    if (diffMinutes < 2) {
      return 'green';
    } else if (diffMinutes < 10) {
      return 'lightgreen';
    } else if (diffMinutes < 45) {
      return 'orange';
    }
    return 'red';
  }

  getTimeSinceUpdate(): string {
    this.cdr.markForCheck();
    if (!this.dataTimeStamp) {
      return 'No update information available.';
    }

    const timeStampDate = new Date(this.dataTimeStamp);
    const now = new Date();
    const diffMs = now.getTime() - timeStampDate.getTime();
    const diffMinutes = Math.floor(diffMs / 60000);

    if (diffMinutes < 1) {
      return 'Data Is Live';
    } else if (diffMinutes < 60) {
      return `Updated ${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`;
    } else if (diffMinutes < 1440) { // less than 24 hours
      const diffHours = Math.floor(diffMinutes / 60);
      return `Updated ${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
    } else if (diffMinutes < 10080) { // less than 7 days
      const diffDays = Math.floor(diffMinutes / 1440);
      const remainderMinutes = diffMinutes % 1440;
      const diffHours = Math.floor(remainderMinutes / 60);
      const dayText = diffDays > 1 ? 'days' : 'day';
      const hourText = diffHours > 1 ? 'hours' : 'hour';
      return `Updated ${diffDays} ${dayText}${diffHours > 0 ? ' and ' + diffHours + ' ' + hourText : ''} ago`;
    } else {
      const diffWeeks = Math.floor(diffMinutes / 10080);
      const remainderMinutes = diffMinutes % 10080;
      const diffDays = Math.floor(remainderMinutes / 1440);
      const weekText = diffWeeks > 1 ? 'weeks' : 'week';
      const dayText = diffDays > 1 ? 'days' : 'day';
      return `Updated ${diffWeeks} ${weekText}${diffDays > 0 ? ' and ' + diffDays + ' ' + dayText : ''} ago`;
    }
  }


}
