import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class CollectionSharedSingularDocumentsService {
  constructor(
    private angularFirestore: AngularFirestore,
  ) {
  }

  async getDocument<DataType>(
    path: string,
  ): Promise<DataType> {
    try {
      const docRef = this.angularFirestore.doc<DataType>(path);
      const docSnapshot = await docRef.get().toPromise();
      if (docSnapshot?.data()) {
        return docSnapshot.data();
      } else {
        return {} as DataType;
      }
    } catch (error) {
      console.error(error);
    }
  }
}
