<app-modal-header [close]="close" title="Store Settings"></app-modal-header>

<ion-content>
  <app-shared-store-tabs
    (emitStoreChange)="onStoreChange($event)"
    [isModal]="true"
    [setStores]="(userStores$ | ngrxPush)"
    [setUserSelectedStore]="(userSelectedStore$ | ngrxPush)"
    [showFreshnessIndicator]="false">
  </app-shared-store-tabs>
  <ng-container>
    <div *ngFor="let mb of buttons" [class]="'section'">
      <div>
        <span>{{ mb.text }}</span>
        <button (click)="open(mb.component)" [disabled]="disabled[selectedStoreBS.value]" mat-raised-button>edit
        </button>
      </div>
    </div>
    <!--    <div [class]="'section' + (disabled[selectedStoreBS.value] ? ' disabled' : '')">-->
    <!--      <div>-->
    <!--        <span>Define suppliers disabling rules</span>-->
    <!--        <button mat-raised-button [disabled]="disabled[selectedStoreBS.value]" (click)="open('disable-suppliers')">edit</button>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div [class]="'section' + (disabled[selectedStoreBS.value] ? ' disabled' : '')">-->
    <!--      <div>-->
    <!--        <span>Configure general NGP Report settings</span>-->
    <!--        <button mat-raised-button [disabled]="disabled[selectedStoreBS.value]" (click)="open('ngp-rep-sett')">edit</button>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div [class]="'section' + (disabled[selectedStoreBS.value] ? ' disabled' : '')">-->
    <!--      <div>-->
    <!--        <span>Edit Auto Ordering Email Settings</span>-->
    <!--        <button mat-raised-button [disabled]="tempDisableButtons.aoEmail || disabled[selectedStoreBS.value]"-->
    <!--                (click)="open('app-automation-settings-email')"-->
    <!--        >-->
    <!--          edit-->
    <!--        </button>-->
    <!--      </div>-->
    <!--    </div>-->
  </ng-container>

  <ion-fab horizontal="end" slot="fixed" vertical="top">
    <ion-fab-button *ngIf="changes">
      <ion-icon name="save"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
