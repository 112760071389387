import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';

import {
  AnimationDefault,
  AnimationDirection,
  Animations,
  IAnimation
} from 'src/app/shared/shared-models/animations/animation';
import {IUser} from 'src/app/shared/shared-models/user-access/user';

import {IEmailSettingsFormState} from './models';
import {EmailSettingsManager} from './managers';


@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss']
})
export class EmailSettingsComponent implements OnInit {
  protected formState$: Observable<IEmailSettingsFormState>;
  protected readonly emailSettingsAnimation: IAnimation = {
    ...AnimationDefault,
    animation: Animations.CIRCLE_DISAPPEAR_AND_REAPPEAR,
    direction: AnimationDirection.REVERSE,
    fixedDimensions: {width: 1, height: 1},
    backgroundColour: 'var(--ion-color-light-shade-v1)',
    id: 'app-automation-settings-email-component-animation-logo',
  };

  constructor(protected readonly emailSettingsManager: EmailSettingsManager) {
  }

  ngOnInit(): void {
    this.formState$ = this.emailSettingsManager.initialize();
  }

  protected trackByColleagueId(_: number, colleague: IUser): string {
    return colleague.id;
  }

  protected getUserNameInitials(userName: string | null): string {
    return userName
      ? userName.charAt(0).toUpperCase()
      : '';
  }
}
