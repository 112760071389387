<ion-header>
  <ion-toolbar>
    <div>
      <ion-title>{{ title }}</ion-title>
      <ion-title *ngIf="!hideClose">
        <ion-icon (click)="close()" name="close-outline" size="large"></ion-icon>
      </ion-title>
    </div>
  </ion-toolbar>
</ion-header>
