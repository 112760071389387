import {Component, OnInit} from "@angular/core";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

import {TableNavSettings} from "src/app/shared-utilities/models-old/datastructures";

import type {EnterEndOptionsType, IMultiSelect, ISelect, IUserSettingsFormState, TabEndOptionsType} from "../../models";
import {USER_SETTINGS} from "./user-settings.constants";
import {UserSettingsFormService} from "./user-settings-form.service";


@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: [
    './user-settings.component.scss',
    '../../styles/shared-view-styles.scss'
  ]
})
export class UserSettingsComponent implements OnInit {
  //#region Table Setting Constants
  protected readonly fitToTableConstants = USER_SETTINGS.tableSettings.fitToTable;
  protected readonly tabEndOptionsConstants = USER_SETTINGS.tableSettings.tabEnd;
  protected readonly enterEndOptionsConstants = USER_SETTINGS.tableSettings.enterEnd;
  protected readonly ngpPreviewColumnsConstants = USER_SETTINGS.tableSettings.ngpEditsPreview;
  protected readonly stockManagerPreviewColumnsConstants = USER_SETTINGS.tableSettings.stockEditsPreview;
  //#endregion

  //#region Observable State Properties
  protected isSubmitting: boolean;
  protected fitToTable: boolean;
  protected tabEndSelectedOption: string;
  protected enterEndSelectedOption: string;
  protected ngpPreviewSelectedColumns$: Observable<IMultiSelect>;
  protected stockPreviewSelectedColumns$: Observable<IMultiSelect>;
  //#endregion

  //#region Value Mappers
  private readonly tabEndDisplayToInternal = this.createDisplayToInternalMap<TabEndOptionsType>(
    USER_SETTINGS.tableSettings.tabEnd.options
  );

  private readonly enterEndDisplayToInternal = this.createDisplayToInternalMap<EnterEndOptionsType>(
    USER_SETTINGS.tableSettings.enterEnd.options
  );

  //#endregion

  constructor(
    private readonly userSettingsForm: UserSettingsFormService
  ) {
  }

  //#region Template Option Getters
  protected get tabEndOptions(): ISelect[] {
    return Array.from(this.tabEndDisplayToInternal.keys()).map((name: string) => ({name}));
  }

  protected get enterEndOptions(): ISelect[] {
    return Array.from(this.enterEndDisplayToInternal.keys()).map((name: string) => ({name}));
  }

  //#endregion

  ngOnInit(): void {
    this.initializeForm();
  }

  //#region Event Handlers
  protected onSubmit(event: Event): void {
    event.preventDefault();
    this.userSettingsForm.submitForm();
  }

  //#endregion

  protected onFitToTableChange(checked: boolean): void {
    this.userSettingsForm.updateFitToTable(checked);
  }

  protected onTabEndOptionChange(value: string): void {
    this.userSettingsForm.updateTabEnd(this.tabEndDisplayToInternal.get(value));
  }

  //#endregion

  protected onEnterEndOptionChange(value: string): void {
    this.userSettingsForm.updateEnterEnd(this.enterEndDisplayToInternal.get(value));
  }

  protected onNgpPreviewChange(values: IMultiSelect): void {
    this.userSettingsForm.updateNgpPreview(values);
  }

  //#endregion

  protected onStockPreviewChange(values: IMultiSelect): void {
    this.userSettingsForm.updateStockPreview(values);
  }

  //#region Form Initialization
  private initializeForm(): void {
    const formState$ = this.userSettingsForm.initialize()
    formState$.subscribe((state: IUserSettingsFormState) => {
      this.isSubmitting = state.isSubmitting ?? false;
      this.fitToTable = state.fitToTable;
      this.tabEndSelectedOption = this.convertTabEndOptions(state.tabEnd);
      this.enterEndSelectedOption = this.convertEnterEndOptions(state.enterEnd);
    })
    this.ngpPreviewSelectedColumns$ = formState$.pipe(map(state => state.ngpPreview));
    this.stockPreviewSelectedColumns$ = formState$.pipe(map(state => state.stockPreview));
  }

  //#region Helper Methods
  private createDisplayToInternalMap<T extends string>(
    options: { [key in T]: string }
  ): Map<string, T> {
    return new Map(
      Object.entries(options)
        .map(([key, value]) => [value, key as T])
    ) as Map<string, T>;
  }

  private convertSelectionOptions<T extends string>(
    firestoreValue: TableNavSettings['tabEnd'] | TableNavSettings['enterEnd'],
    options: { [key in T]: string }
  ): string {
    return firestoreValue ? options[firestoreValue as T] : '';
  }

  //#region Option Conversion Methods
  private convertTabEndOptions(firestoreValue: TableNavSettings['tabEnd']): string {
    return this.convertSelectionOptions<TabEndOptionsType>(
      firestoreValue,
      this.tabEndOptionsConstants.options
    );
  }

  private convertEnterEndOptions(firestoreValue: TableNavSettings['enterEnd']): string {
    return this.convertSelectionOptions<EnterEndOptionsType>(
      firestoreValue,
      this.enterEndOptionsConstants.options
    );
  }

  //#endregion
}
