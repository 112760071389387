import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ModalController} from '@ionic/angular';

export type PartialProgressOptions = Omit<ProgressOptions<number>, 'updates'>;

export interface ProgressOptions<UpdateType> {
  updates: Observable<UpdateType>;
  autoClose?: boolean;
  completion?: string;
  header?: string;
  indeterminate?: boolean;
  message?: string;
  okButton?: string;
  retry?: boolean;
  showPercentage?: boolean;
  subHeader?: string;
}

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent implements OnInit {

  @Input() updates: Observable<number>;
  @Input() header = 'Loading';
  @Input() subHeader = 'Please wait.';
  @Input() message?: string;

  @Input() autoClose = true;
  @Input() completion = 'Complete';
  @Input() okButton = 'ok';

  @Input() indeterminate = false;
  @Input() showPercentage = false;

  @Input() retry = false;

  showCompletion: 'success' | 'failure';
  progress = 0;

  constructor(
    private modalController: ModalController,
  ) {
  }

  ngOnInit(): void {
    this.updates.subscribe(
      (next: number): void => {
        this.progress = next;
      },
      (error): void => {
        if (this.autoClose) {
          this.onClose();
        } else {
          this.completion = '' + error;
          this.showCompletion = 'failure';
          console.error(error);
        }
      }, () => {
        if (this.autoClose) {
          this.onClose();
        } else {
          this.showCompletion = 'success';
        }
      });
  }

  onClose(): void {
    void this.modalController.dismiss().then();
  }
}
