import {Injectable} from '@angular/core';
import Typesense, {Client} from 'typesense';
import {from, Observable} from 'rxjs';
import {IStore} from '../../shared-models/store/store';
import {ISearchableFields} from '../../shared-models/type-sense/default-searchable-fields';
import {StockItem} from '../../../shared-utilities/models-old/datastructures';
import {
  convertFilterObjectToTypesenseFilter,
  convertSearchableFields,
} from '../../shared-utils/typesense-utils/typesense.utils';
import {TypeSenseSearchResponse} from '../../shared-models/type-sense/type-sense-types';
import {searchStockItemsCreateFilterString} from './type-sense.service.utils';
import {ITypeSenseQuery} from '../../shared-models/type-sense/type-sense.queries';
import {IAdvancedFilterGroup} from '../../shared-components/components/shared-advanced-filters/utils/advanced-filter-groups';
import {Store} from '@ngrx/store';
import {take} from 'rxjs/operators';
import {selectApiKeyForSelectedStore} from '../../../features/stock-manager/store/stock-manager.selectors';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
/**
 * @class TypeSenseService
 * A service class for interacting with the TypeSense API.
 * Initializes the TypeSense client and provides methods to perform searches.
 */
export class TypeSenseService {

  /**
   * The TypeSense client instance used to interact with the TypeSense API.
   *
   * @member {Client} client
   */
  private client: Client;

  /**
   * Constructs the TypeSenseService and initializes the TypeSense client.
   *
   * @constructor
   */
  constructor(
    private readonly store: Store,
  ) {
  }

  /**
   * Searches stock items with pagination.
   *
   * @param {string} query - The search query string.
   * @param {number} page - The page number for pagination.
   * @param {number} perPage - The number of items per page.
   * @param {IStore} store - The currently selected store.
   * @param {ISearchableFields} query_by - The codes of the fields that you want the search to query.
   * @param {Object<string, ISearchableFields>} [filters] - The list of suppliers that you want to add to filters; value must be true to add to filters.
   * @param sort
   * @param advancedFilters
   * @returns {Observable<TypeSenseSearchResponse<StockItem>>} An observable emitting the search results.
   */
  searchStockItems(
    query: string,
    page: number,
    perPage: number,
    store: IStore,
    query_by: ISearchableFields,
    filters?: {
      [key: string]: ISearchableFields
    },
    sort?: string,
    advancedFilters?: IAdvancedFilterGroup
  ): Observable<TypeSenseSearchResponse<StockItem>> {
    const fieldArray = convertSearchableFields(query_by);
    let filterString = '';
    if (filters && Object.keys(filters).length > 0) {
      filterString = searchStockItemsCreateFilterString(filters);
    }

    if (advancedFilters && filterString.length > 0) {
      filterString = `${filterString} && ${convertFilterObjectToTypesenseFilter(advancedFilters)}`;
    } else if (advancedFilters && filterString.length === 0) {
      filterString = `${convertFilterObjectToTypesenseFilter(advancedFilters)}`;
    }

    if (!sort) {
      sort = '1:asc';
    }

    const searchParams: ITypeSenseQuery = {
      q: query,
      query_by: fieldArray,
      page: page,
      filter_by: filterString,
      facet_by: '3,4,40,46,_tags,multipleSuppliers',
      max_facet_values: 500,
      sort_by: sort,
      per_page: perPage,
    };
    this.initializeClient();
    if (this.client) {
      const typeSenseData: Observable<TypeSenseSearchResponse<StockItem>> =  from(this.client
        .collections(store.storeId)
        .documents()
        .search(searchParams) as unknown as Observable<TypeSenseSearchResponse<StockItem>>);
      if (typeSenseData) {
        // This takes care of null | undefined values returned from TypeSense
        return typeSenseData;
      } else {
        from([] as unknown as Observable<TypeSenseSearchResponse<StockItem>>);
      }
    } else return from([] as unknown as Observable<TypeSenseSearchResponse<StockItem>>);
  }

  searchEditedStockItems(query: string, page: number, store: IStore, query_by: ISearchableFields, editedItems?: {
    [key: string]: StockItem
  }): Observable<TypeSenseSearchResponse<StockItem>> {
    const fieldArray = convertSearchableFields(query_by);
    const searchParams: ITypeSenseQuery = {
      q: query,
      query_by: fieldArray,
      page: page,
      filter_by: `0:=[${Object.keys(editedItems)}]`,
      facet_by: '3,4,40,46,_tags,multipleSuppliers',
      max_facet_values: 500,
      sort_by: '1:asc',
      per_page: 250,
    };
    this.initializeClient();
    if (this.client) {
      const typeSenseData: Observable<TypeSenseSearchResponse<StockItem>> =  from(this.client
        .collections(store.storeId)
        .documents()
        .search(searchParams) as unknown as Observable<TypeSenseSearchResponse<StockItem>>);
      if (typeSenseData) {
        // This takes care of null | undefined values returned from TypeSense
        return typeSenseData;
      } else {
        from([] as unknown as Observable<TypeSenseSearchResponse<StockItem>>);
      }
    } else return from([] as unknown as Observable<TypeSenseSearchResponse<StockItem>>);
  }

  private initializeClient(): void {
    let typesenseApiKey = '';
    this.store.select(selectApiKeyForSelectedStore).pipe(take(1)).subscribe((apiKey: string) => {
      typesenseApiKey = apiKey;
      if (typesenseApiKey) {
        this.client = new Typesense.Client({
          nodes: [{host: environment.typeSenseKey + '-1.a1.typesense.net', port: 443, protocol: 'https'}],
          apiKey: typesenseApiKey,
          connectionTimeoutSeconds: 5,
        });
      }
    });
  }

}

