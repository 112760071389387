import {validateAccess} from './guard-utils';
import {ActivatedRouteSnapshot, CanActivateChildFn, RouterStateSnapshot, UrlTree} from '@angular/router';

/**
 * Guards navigation to child routes by checking if the user has access to the requested page.
 *
 * This function is used in routing to determine whether a user is allowed to activate a
 * child route based on their access permissions. It validates access by passing the page
 * URL from the router state to the `validateAccess` function and returns the result.
 *
 * @param {ActivatedRouteSnapshot} childRoute - The activated child route snapshot,
 *        providing information about the specific child route being accessed.
 * @param {RouterStateSnapshot} state - The router state snapshot containing the
 *        state of the router, including the URL of the requested route.
 * @returns {Promise<boolean | UrlTree>} - A promise that resolves to either `true`
 *         if the user has access to the page, or a `UrlTree` if redirection is needed
 *         due to insufficient access.
 */
export const canActivateChildGuard: CanActivateChildFn = (
  childRoute: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<boolean | UrlTree> => {
  const page: string = state.url.split('?')[0].slice(1);
  return validateAccess(page);
};
