<div (keydown)="onKeyDown($event)" class="custom-select" tabindex="0">

  <div class="chips-select-container">

    <div (click)="toggleDropdown()" class="select-display">
      <span *ngIf="selectedCount === 0">{{ placeholder }}</span>
      <span *ngIf="selectedCount > 0">{{ selectedCount }} Selected</span>
      <i [class.open]="isDropdownOpen" class="arrow"></i>
    </div>

    <div class="chips-container">
      <ng-container *ngFor="let key of optionKeys; trackBy: trackByFunction">
        <ion-chip
          (click)="!options[key].disabled && removeChip(key, $event)"
          *ngIf="options[key].selected"
          [ngClass]="{'disabled-chip': options[key].disabled, 'active-chip': !options[key].disabled}">
          <ion-label>{{ options[key].name }}</ion-label>
          <ion-icon *ngIf="!options[key].disabled" name="close-circle"></ion-icon>
        </ion-chip>
      </ng-container>
    </div>

  </div>

  <div (click)="$event.stopPropagation()" *ngIf="isDropdownOpen" [@dropdownAnimation] class="dropdown-panel">
    <div class="header">
      <input (input)="onSearchChange()" [(ngModel)]="searchText" placeholder="Search..." type="text"/>
    </div>

    <div class="options-container">
      <ul>
        <li (click)="toggleOption(key, $event)"
            *ngFor="let key of filteredOptionKeys; let i = index; trackBy: trackByFunction"
            [class.disabled]="currentOptions[key].disabled"
            [class.highlighted]="i === highlightedIndex">
          <input (click)="$event.stopPropagation()" [checked]="currentOptions[key].selected"
                 [disabled]="currentOptions[key].disabled"
                 type="checkbox"/>
          <span>{{ currentOptions[key].name }}</span>
        </li>
      </ul>
    </div>

    <div class="action-buttons">
      <button (click)="cancelSelection(); $event.stopPropagation()" class="cancel-btn" type="button">Cancel</button>
      <button (click)="confirmSelection(); $event.stopPropagation()" class="confirm-btn" type="button">Confirm</button>
    </div>
  </div>
</div>
