import {IEmailSettings} from 'src/app/features/settings-old/models/settings-models';
import {IEmailSettingsFormState} from '../models';

export const initialState: IEmailSettingsFormState = {
  colleagues: [],
  colleaguesEmailSettings: {},
  selectedColleague: null,
  selectedColleagueEmailSettings: null,
  selectedStore: null,
  filteredColleagues: [],
  userQuery: '',
  formErrors: {
    email: '',
    user: '',
    pwd: '',
    smtp: {
      server: '',
      port: '',
    },
    imap: {
      server: '',
      port: '',
    },
  },
  isSubmitting: false,
};

export const defaultEmailSettings: IEmailSettings = {
  email: '',
  user: '',
  pwd: '',
  smtp: {
    server: '',
    port: 0,
    sslOnly: false
  },
  imap: {
    server: '',
    port: 0,
    sslOnly: false,
    sentBox: '',
  },
  enableImap: false
} as const;
