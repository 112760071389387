import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SettingsState} from './settings.reducer';
import {selectSelectedUserStore, selectUser} from '../../../features-as-modules/feature-core/store/core.selectors';
import {IStore} from '../../../shared/shared-models/store/store';
import {selectColleagues} from '../../core/store-shared/shared.selectors';
import {IUser} from 'src/app/shared/shared-models/user-access/user';
import {IColleagues, IColleagueUser} from 'src/app/shared/shared-models/user-access/user-access';
import {IEmailSettings} from '../models/settings-models';
import {DisabledRules, LineColour, TableNavSettings} from 'src/app/shared-utilities/models-old/datastructures';

const selectSettingsState = createFeatureSelector<SettingsState>('settings');

// Select NGP Report User Settings
export const selectNGPReportColPreviewSettings = createSelector(
  selectSettingsState,
  (state: SettingsState) =>
    state.userSettings?.ngpReportColPreviewSettings,
);

// Select Stock Manager Column Preview Settings
/**
 * TODO TECHNICAL DEBT - export const selectStockManagerColPreviewSettings = createSelector()
 * We need to make a default setting so as to not return an undefined here
 *
 */
export const selectStockManagerColPreviewSettings = createSelector(
  selectSettingsState,
  selectSelectedUserStore,
  (state: SettingsState, store: IStore): { [key: string]: string } | undefined => {
    const settings = state?.userSettings?.stockManagerColPreviewSettings;
    return settings?.[store.storeId] as { [key: number]: string } ?? settings;
  },
);


// Select Disabled Rules
export const selectRulesDoc = createSelector(
  selectSettingsState,
  selectSelectedUserStore,
  (state: SettingsState, selectedStore: IStore) =>
    state.storeSettings[selectedStore.storeId]?.disabledRules,
);

// Select Line Colour
export const selectLineColour = createSelector(
  selectSettingsState,
  selectSelectedUserStore,
  (state: SettingsState, selectedStore: IStore) =>
    state.storeSettings[selectedStore.storeId]?.lineColour,
);

// Select Table Navigation Settings
export const selectNavSettings = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.userSettings.tableNavigationSettings,
);

export const selectFitToGrid = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.userSettings.fitToGrid,
);

export const selectPriceThresholds = createSelector(
  selectSettingsState,
  selectSelectedUserStore,
  (state: SettingsState, selectedStore: IStore) =>
    state.storeSettings[selectedStore.storeId]?.priceThresholds
);

export const selectPriceBands = createSelector(
  selectSettingsState,
  selectSelectedUserStore,
  (state: SettingsState, selectedStore: IStore) =>
    state.storeSettings[selectedStore.storeId]?.priceBands
)

export const selectAllUsersEmailSettingForCurrentSelectedStore = createSelector(
  selectSettingsState,
  selectSelectedUserStore,
  (state: SettingsState, store: IStore) => state.usersEmailSettings[store.storeId],
);

export const selectIsUserEmailsLoading = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.isUserEmailSettingsLoading,
);

export const selectTags = createSelector(
  selectSettingsState,
  selectSelectedUserStore,
  (state: SettingsState, store: IStore) => state.allTags[store.storeId],
);

export const selectIsSelectedUserEmailSettingsLoading = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.isSelectedUserEmailSettingsLoading,
);

export const selectSelectedUserEmailSettings = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.selectedUserEmailSettings,
);

export const selectUserSettingsIsSubmitting = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.userSettings.isSubmitting,
);

export const selectAllUserSettings = createSelector(
  selectNavSettings,
  selectFitToGrid,
  selectNGPReportColPreviewSettings,
  selectStockManagerColPreviewSettings,
  (
    navSettings: TableNavSettings,
    fitToGrid: boolean,
    ngpSettings: { [key: number]: string },
    stockSettings: { [key: string]: string }
  ) => ({
    navSettings,
    fitToGrid,
    ngpSettings,
    stockSettings,
  })
);

export const selectItemDisablingSettings = createSelector(
  selectRulesDoc,
  selectLineColour,
  (rulesDoc: DisabledRules, lineColours: LineColour) => ({
    rulesDoc,
    lineColours,
  })
);

export const selectUserStoreSettingsIsSubmitting = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.isSubmittingStoreSettings,
);

export const selectUserEmailSettingsIsSubmitting = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.isSubmittingEmailSettings,
)

export const selectEmailSettingsView = createSelector(
  selectUser,
  selectColleagues,
  selectSelectedUserStore,
  selectAllUsersEmailSettingForCurrentSelectedStore,
  selectUserEmailSettingsIsSubmitting,
  (
    currentUser: IUser,
    colleagues: IColleagues,
    selectedStore: IStore,
    emailSettings: { [userId: string]: IEmailSettings },
    isSubmitting: boolean
  ) => {
    const eligibleUsers = [
      currentUser,
      ...Object.values(colleagues)
        .filter((colleague: IColleagueUser) =>
          colleague.userData !== null &&
          emailSettings &&
          colleague.userData.id in emailSettings
        ).map((colleague: IColleagueUser) => colleague.userData)
    ];
    return {
      colleagues: eligibleUsers,
      emailSettings,
      selectedStore,
      isSubmitting
    };
  }
);
