import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {selectDataTimestamps} from '../../../../../features-as-modules/feature-core/store/core.selectors';
import {getDataTimeStamps} from '../../../../../features-as-modules/feature-core/store/core.actions';
import {Observable} from 'rxjs';
import {IDataTimeStamps} from '../../../../../features/core/models/data-time-stamps';
import {IStore} from '../../../../shared-models/store/store';

@Component({
  selector: 'app-shared-freshness-indicator-container',
  templateUrl: './shared-freshness-indicator-container.component.html',
  styleUrls: ['./shared-freshness-indicator-container.component.scss'],
})
export class SharedFreshnessIndicatorContainerComponent implements OnInit {
  @Input() dataType: string = 'stock';
  @Input() currentStore: IStore;
  dataTimeStamps$: Observable<{ [p: string]: IDataTimeStamps }>;

  constructor(
    private readonly store: Store,
  ) {
  }

  ngOnInit(): void {
    this.dataTimeStamps$ = this.store.select(selectDataTimestamps);
    this.store.dispatch(getDataTimeStamps());
  }

}
