
//#region IPosition
/**
 * Represents a position in a 2D space.
 *
 * The `IPosition` interface defines the position of an element in a 2D coordinate system
 * using `left` and `top` properties, both represented as strings.
 *
 * @interface IPosition
 *
 * @property {string} left - The horizontal position (left to right) of the element.
 * @property {string} top - The vertical position (top to bottom) of the element.
 *
 * @example
 * const position: IPosition = {
 *   left: '100',
 *   top: '200'
 * };
 */
export interface IPosition {
  /** The horizontal position (left to right) of the element. */
  left: string;

  /** The vertical position (top to bottom) of the element. */
  top: string;
}
//#endregion
