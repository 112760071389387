import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {IInitialState} from '../../../shared-utilities/models-old/initial-state/initial-state';
import * as UserAccessActions from './user-access.actions';
import {IGroupAccess} from '../../../shared/shared-models/user-access/group-access';
import {IUserAccessToken, IUserAccessTokenURL} from '../models/user-access-token';
import {ApplicationFeatureKeys, ApplicationFeaturesEnum} from '../../../shared/shared-utils/features/features';
import {looker_studio_base_url} from "../../../shared/shared-services/database-paths";
import {environment} from "../../../../environments/environment";

export interface UserAccessState extends IInitialState {
  isLoading: boolean;
  isUserAccessTokenLoading: boolean;
  groupAccess: IGroupAccess;
  userAccessToken: IUserAccessToken | IUserAccessTokenURL<unknown> | undefined;
  userAccessTokenFeature: ApplicationFeatureKeys | undefined;
}

export const initialUserAccessState: UserAccessState = {
  errors: [],
  isLoading: false,
  isUserAccessTokenLoading: false,
  groupAccess: {},
  userAccessToken: undefined,
  userAccessTokenFeature: undefined,
};

const createUserAccessReducer: ActionReducer<UserAccessState> = createReducer(
  initialUserAccessState,
  on(UserAccessActions.setUserAccessLoading, (state, {isLoading}) => ({
    ...state,
    isLoading,
  })),
  on(UserAccessActions.sendInviteLink, (state, _) => ({
    ...state,
    isLoading: true,
  })),
  on(UserAccessActions.sendInviteLinkSuccess, (state, _) => ({
    ...state,
    isLoading: false,
  })),
  on(UserAccessActions.sendInviteLinkFailure, (state, {error}) => ({
    ...state,
    isLoading: false,
    errors: [...state.errors, error],
  })),
  on(UserAccessActions.setPermissionsFailure, (state, {error}) => ({
    ...state,
    isLoading: false,
    errors: [...state.errors, error],
  })),
  on(UserAccessActions.setColleaguesUserAccess, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(UserAccessActions.setColleaguesUserAccessSuccess, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(UserAccessActions.setColleaguesUserAccessFailure, (state, {error}) => ({
    ...state,
    isLoading: false,
    errors: [...state.errors, error],
  })),
  on(UserAccessActions.getGroupAccessSettings, (state) => ({
    ...state,
  })),
  on(UserAccessActions.getGroupAccessSettingsSuccess, (state, {groupAccess}) => ({
    ...state,
    groupAccess,
  })),
  on(UserAccessActions.getGroupAccessSettingsFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
  })),
  on(UserAccessActions.getUserAccessToken, (state, {appFeature}) => ({
    ...state,
    isUserAccessTokenLoading: true,
    userAccessToken: undefined,
    userAccessTokenFeature: appFeature
  })),
  on(UserAccessActions.getUserAccessTokenSuccess, (state, {appFeature, userAccessToken}) => {
    let userAccessTokenReceived = userAccessToken;
    if (appFeature === ApplicationFeaturesEnum.DASHBOARD_REPORT && userAccessTokenReceived.token) {
      const baseURL = looker_studio_base_url(String(environment.lookerStudio.apiKey));
      const params: { [paramKey: string]: string; } = {
        userToken: userAccessToken.token,
        userTokenStock: userAccessToken.token
      }
      const encodedParams = encodeURIComponent(JSON.stringify(params));
      const fullUrl = `${baseURL}?params=${encodedParams}`;
      userAccessTokenReceived = {
        ...userAccessToken,
        baseUrl: baseURL,
        params,
        encodedParams: encodeURIComponent(JSON.stringify(params)),
        fullUrl
      } as unknown as IUserAccessTokenURL<{ userToken: string; }>
    }
    return {
      ...state,
      isUserAccessTokenLoading: false,
      userAccessToken: userAccessTokenReceived,
      userAccessTokenFeature: appFeature
    }
  }),
  on(UserAccessActions.getUserAccessTokenFailure, (state, {appFeature, error}) => ({
    ...state,
    errors: [...state.errors, error],
    isUserAccessTokenLoading: false,
    userAccessTokenFeature: appFeature
  })),
  on(UserAccessActions.setUserAccessToken, (state, {appFeature, userAccessToken}) => ({
    ...state,
    isUserAccessTokenLoading: false,
    userAccessToken: userAccessToken,
    userAccessTokenFeature: appFeature
  })),
)


export const userAccessReducer = (state: UserAccessState, action: Action): UserAccessState => createUserAccessReducer(state, action);
