<div class="supplier-search-component-wrapper">
  <ion-header *ngIf="isModal">
    <ion-toolbar>
      <div>
        <ion-title>{{ title }}</ion-title>
        <ion-title>
          <ion-icon (click)="close()" name="close-outline" size="large"></ion-icon>
        </ion-title>
      </div>
    </ion-toolbar>
  </ion-header>

  <div class="search-or-preview">
    <ion-input
      (ionChange)="searchOnKeyUp()"
      *ngIf="!preview"
      [(ngModel)]="searchString"
      class="search-bar"
      placeholder="Search Suppliers" type="search">
      <ion-icon name="search-circle-outline"></ion-icon>
    </ion-input>
    <h1 *ngIf="preview">
      Selected Suppliers
    </h1>
    <ng-container *ngIf="allowDrag">
      <ion-chip
        matTooltip="
        To select a supplier, click on it in the table. You can select multiple suppliers by clicking on each one.
        Once you’ve chosen your suppliers, click on any selected row and drag them to your schedule,
        then drop them onto the plus sign that appears.">
        How To Add Suppliers
        <app-icon-help-circle-outline></app-icon-help-circle-outline>
      </ion-chip>
    </ng-container>
  </div>
  <div [ngClass]="isModal? 'modal-grid-div' : 'grid-div'">
    <ag-grid-angular
      (gridReady)="onGridReady($event)"
      (gridSizeChanged)="onGridSizeChange()"
      [columnDefs]="gridColDefs"
      [columnTypes]="columnTypes"
      [defaultColDef]="generalColDefDefaults"
      [gridOptions]="gridOptions"
      [overlayNoRowsTemplate]="overlayTemplate"
      [rowData]="fuzzySuppliers"
      class="ag-theme-custom">
    </ag-grid-angular>
  </div>

  <ng-container>
    <div *ngIf="isModal; else notModal" class="buttons-div">
      <ion-button (click)="onViewSelectedClick()" [disabled]="selectedStockItemCount < 1 && !preview" fill="outline">
        {{ getPreviewButtonData }}
      </ion-button>
      <ion-button (click)="onGetSelectedStockItemsClick()" [disabled]="selectedStockItemCount < 1" fill="outline">
        Add {{ selectedStockItemCount !== 1 ? selectedStockItemCount + ' Suppliers' : selectedStockItemCount + ' Supplier' }}
      </ion-button>
    </div>

    <ng-template #notModal>
      <div class="not-modal-buttons-div">
        <ion-button (click)="onSelectAllSuppliersClick(selectedStockItemsMap.size >= selectedSuppliers.length)"
                    *ngIf="selectAll"
                    fill="outline">
          {{ selectedStockItemsMap.size >= selectedSuppliers.length ? 'UnSelect All' : 'Select All' }}
        </ion-button>
        <ion-button (click)="onViewSelectedClick()" [disabled]="selectedStockItemCount < 1 && !preview" fill="outline">
          {{ getPreviewButtonData }}
        </ion-button>
        <ion-button (click)="onGetSelectedStockItemsClick()" *ngIf="!allowDrag" [disabled]="selectedStockItemCount < 1"
                    fill="outline">
          {{ acceptButtonText[0] }} {{ selectedStockItemCount !== 1 ? selectedStockItemCount + ' ' + acceptButtonText[1] : selectedStockItemCount + ' ' + acceptButtonText[2] }}
        </ion-button>
      </div>
    </ng-template>
  </ng-container>

</div>
