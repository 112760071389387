import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {firebaseTimeStampToDate} from '../firestore/firestore.utils';
import {Timestamp} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class DateUtilsService {
  /**
   * Constructs the DateUtilsService.
   *
   * @param {DatePipe} datePipe - Angular's DatePipe for formatting dates.
   */
  constructor(private datePipe: DatePipe) {
  }

  /**
   * ### Formats a date property of a given object
   *
   * Converts a Firebase Timestamp or a standard date value into a formatted date string.
   *
   * @template DataType - The type of the data object.
   *
   * @param {string} format - The desired date format.
   * @param {DataType} data - The object containing the date property.
   * @param {string} property - The key of the date property in the object.
   * @param {boolean} isTimestamp - Whether the property is a Firebase Timestamp.
   *
   * @returns {string} The formatted date string or an empty string if the property is missing.
   *
   * @example
   * ```ts
   * const formattedDate = dateUtilsService.formatPropertyDate('yyyy-MM-dd', order, 'createdAt', true);
   * console.info(formattedDate); // e.g., '2025-03-28'
   * ```
   */
  formatPropertyDate<DataType>(
    format: string,
    data: DataType,
    property: string,
    isTimestamp: boolean
  ): string {
    if (data[property]) {
      const dataDate = isTimestamp
        ? firebaseTimeStampToDate(data[property] as Timestamp)
        : new Date(data[property] as string | number | Date);
      return this.datePipe.transform(dataDate, format) || '';
    }
    return '';
  }
}
