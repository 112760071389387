import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {InboxMessage, stockCodeDecode, StoreInfo} from '../../../../shared-utilities/models-old/datastructures';
import {AoReattemptModalComponent, ReattemptInput} from '../ao-reattempt-modal/ao-reattempt-modal.component';
import {FormControl, FormGroup} from '@angular/forms';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {StandardAlertsService} from '../../../../shared-utilities/services-old/standard-alerts.service';
import {leadingZero} from '../../../../shared-utilities/utils-old/formatting';
import {copyObj, objLen, updateObj} from '../../../../shared-utilities/functions-old/object-functions';

@Component({
  selector: 'app-failed-orders-alert',
  templateUrl: './failed-orders-alert.component.html',
  styleUrls: ['./failed-orders-alert.component.scss'],
})

export class FailedOrdersAlertComponent implements OnInit {
  @Input() failedAOMsgs: InboxMessage[];

  responses: { [orderId: string]: any }[] = [];
  batchSorted: string[][] = [];
  checkedBatches: { [batchIdx: number]: { [orderId: string]: boolean } } = {};
  // order: string[];
  selectedOrder: string[];
  // data: { [batch: string]: { [orderId: string]: any }} = {};
  dateStrings: { d: string; t: string }[] = [];
  // content: { [i: number]: { [orderId: string]: Content } } = {};
  suppliers: { [storeId: string]: { [supplierId: string]: { account: string; name: string; count?: number } } } = {};
  numChecked = 0;
  aggregatedData: { [batch: string]: { [orderId: string]: ReattemptInput } };
  storesInfo: { stores: { [id: string]: StoreInfo }; order: string[] };
  suppliersClean: { [orderId: string]: string } = {};

  // checksFG: FormGroup<FormGroup<{ [orderId: string]: FormControl<boolean> }>[]>;

  // TODO opening reattempt modal can take a while. Temp spinner.
  creatingModal: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/member-ordering
  protected readonly Object = Object;

  constructor(
    private firebase: FirebaseService,
    private modalController: ModalController,
    private alertControl: AlertController,
    private stdAlert: StandardAlertsService,
  ) {
  }

  ngOnInit() {
    // this.content = {};
    this.firebase.stores.subscribe(stores => {
      this.storesInfo = stores;
      this.failedAOMsgs.sort((a, b) => stores.order.indexOf(a.sender) - stores.order.indexOf(b.sender));
    });
    const fgs: FormGroup<{ [orderId: string]: FormControl<boolean> }>[] = [];

    for (const message of this.failedAOMsgs) {
      this.responses.push(message.payload.data);
      const idx = this.responses.length - 1;
      this.batchSorted.push(Object.keys(message.payload.data));
      this.batchSorted[idx].sort();
      const d: Date = new Date(message.timestamp);
      this.dateStrings.push({
        d: `${leadingZero(d.getDate())}/${leadingZero(d.getMonth() + 1)}/${d.getFullYear()}`,
        t: `${leadingZero(d.getHours())}:${leadingZero(d.getMinutes())}:${leadingZero(d.getSeconds())}`,
      });

      const controls = {};
      this.batchSorted[idx].forEach((orderId: string) => {
        controls[orderId] = new FormControl<boolean>(false);
        this.suppliersClean[orderId] = stockCodeDecode(this.responses[idx][orderId].supplier);
      });
      fgs.push(new FormGroup(controls));
    }
    // this.checksFG = new FormGroup<FormGroup<{[p: string]: FormControl<boolean>}>[]>(fgs);
  }

  close = async () => {
    const alert = await this.alertControl.create({
      header: 'Do Not Reattempt Auto Orders',
      subHeader: 'Do you want to not reattempt any failed orders?',
      message: `Closing this modal will make it so that no orders are reattempted.`,
      cssClass: 'custom-alert',
      buttons: ['No keep Me Here', {text: 'Close', role: 'y'}],
    });
    await alert.present();
    const {role} = await alert.onDidDismiss();

    if (role === 'y') {
      this.dismiss();
    }
  };

  getStoreInfo = (storeId: string): StoreInfo => this.storesInfo.stores[storeId];

  dismiss() {
    this.modalController.dismiss().then();
  }

  onCheckboxChange(i: number, event: { orderId: string; value: { [orderId: string]: boolean } }): void {
    let inc: 1 | -1;

    if (event.value[event.orderId]) {
      if (!this.checkedBatches[i]) {
        this.checkedBatches[i] = {};
      }
      this.checkedBatches[i][event.orderId] = true;
      inc = 1;
    } else {
      if (this.checkedBatches[i]) {
        delete this.checkedBatches[i][event.orderId];
      }
      inc = -1;

      if (objLen(this.checkedBatches[i]) === 0) {
        delete this.checkedBatches[i];
      }
    }
    this.numChecked += inc;
  }

  async openReattemptModal() {
    this.creatingModal = true;
    const filteredOrders: { [i: number]: { [orderId: string]: any } } = {};
    const batches = Object.keys(this.checkedBatches)
      .map((i) => +i).sort((a, b) => +a - +b);
    const storeNames: { [i: number]: string } = {};
    const timeStamps: { [i: number]: { d: string; t: string } } = {};

    for (const i of batches) {
      filteredOrders[i] = {};

      for (const orderId of Object.keys(this.checkedBatches[i])) {
        if (this.checkedBatches[i][orderId] && !this.aggregatedData?.[i]?.[orderId]) {
          const orderData = this.responses[i][orderId];
          filteredOrders[i][orderId] = copyObj(orderData);
        }
      }


      if (objLen(filteredOrders[i])) {
        if (i === 0 || (this.failedAOMsgs[i].sender !== this.failedAOMsgs[i - 1].sender)) {
          storeNames[i] = this.storesInfo.stores[this.failedAOMsgs[i].sender].name;
        }

        timeStamps[i] = this.dateStrings[i];
      } else {
        delete filteredOrders[i];
      }
    }

    const modal = await this.modalController.create({
      component: AoReattemptModalComponent, componentProps: {
        responses: filteredOrders, storeNames,
        timeStamps,
      }, cssClass: ['child-modal'],
    });
    await modal.present();
    const {data: modalData} = await modal.onDidDismiss();
    this.creatingModal = false;

    if (!modalData) {
      return;
    }

    if (!this.aggregatedData) {
      this.aggregatedData = {};
    }
    updateObj(this.aggregatedData, modalData, true);
  }

  async submit() {
    if (Object.keys(this.aggregatedData).length > 0) {
      // Conditionally setting alert content
      let header: string;
      let subHeader: string;
      if (this.numChecked !== this.getDistinctEmailCount()) {
        header = 'Some Orders Have Not Been Reattempted';
        subHeader = 'Would you like to only reattempt the orders that have been selected? All other orders will' +
          ' have to be reattempted from your inbox.';
      } else {
        header = 'Reattempt Auto Order?';
        subHeader = 'Are you sure you would like to reattempt the checked suppliers?';
      }

      const alert = await this.alertControl.create({
        header,
        subHeader,
        // message: `This order is from ${this.useValue.message.timestamp.toLocaleDateString()}.`,
        cssClass: 'custom-alert',
        buttons: ['Cancel', {text: 'Reattempt', role: 'y'}],
      });

      await alert.present();
      const {role} = await alert.onDidDismiss();


      if (role === 'y') {
        const promises = Object.keys(this.aggregatedData).map(async (idx) => {
        });
        this.dismiss();
        const complete = await this.stdAlert.indeterminateProgress({
          header: 'Sending Reattempts',
          subHeader: 'Reattempt options are being uploaded',
          autoClose: true,
        });
        Promise.all(promises).then(() => {
          complete();
        }).catch(error => {
          console.error('An error occurred:', error);
        });
      }
    }
  }

  getDistinctEmailCount(): number {
    const emailSet = new Set<string>();
    this.failedAOMsgs.forEach(msg => {
      Object.keys(msg.payload.data).forEach(email => {
        emailSet.add(email);
      });
    });
    return emailSet.size;
  }
}
