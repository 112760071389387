import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  RowDropZoneParams,
  SelectionChangedEvent,
} from 'ag-grid-community';
import {IPaginationData} from '../../../shared-models/pagination/pagination-data';
import {
  gridColumnTypes,
} from '../../../../shared-utilities/models-old/ngp-report-grid/defaults/column-types/column-types';
import {Store} from '@ngrx/store';
import {ModalController} from '@ionic/angular';
import {
  generalColDefDefaults,
} from '../../../../shared-utilities/models-old/ngp-report-grid/defaults/general/general-col-def-defaults';
import {ISupplierScheduled} from '../../../shared-models/stock/suppliers';
import {fuzzyFilterArrayOfObjects} from '../../../shared-utils/advanced filters/search';
import {getDeepCopyOfObject} from '../../../shared-utils/object/object.utils';
import {SharedModalBasicComponent} from '../shared-modal-basic/shared-modal-basic.component';
import {ISharedModalBasic} from '../../../shared-models/modals/shared-modal-basic';
import {Icons} from '../../../../shared-modules/shared-icons/icons';
import {
  getTypesenseFacetCounts,
} from '../../../../features-as-modules/feature-auto-ordering/store/auto-ordering.actions';
import {onHoldDecode} from '../../../../shared-utilities/models-old/datastructures';

@Component({
  selector: 'app-shared-supplier-search',
  templateUrl: './shared-supplier-search.component.html',
  styleUrls: ['./shared-supplier-search.component.scss'],
})
export class SharedSupplierSearchComponent {

  @Output() readonly stockItemsSelected = new EventEmitter<ISupplierScheduled[]>();
  @Output() readonly rowDragAndDropped = new EventEmitter<ISupplierScheduled[]>();
  @Output() readonly emitSelectedValues = new EventEmitter<ISupplierScheduled>();

  @Input() gridColDefs: ColDef[];
  @Input() gridOptions: GridOptions;
  @Input() title: string = 'Select Suppliers';
  @Input() overlayTemplate: string = 'No Supplier Found';
  @Input() multiple: boolean = false;
  @Input() selectAll: boolean = false;
  @Input() isModal: boolean = true;
  @Input() allowDrag: boolean = false;
  @Input() acceptButtonText: string[] = [];
  @Input() limit: number = 100;
  @Input() checkForDuplicates: boolean = false;
  @Input() facetCounts = {};

  selectedSuppliers: ISupplierScheduled[] = [];
  paginationData: IPaginationData;
  searchString: string = '';
  gridApi: GridApi;
  totalItems: number;
  selectedStockItemsMap: Map<string, ISupplierScheduled> = new Map<string, ISupplierScheduled>();
  preview: boolean = false;
  fuzzySuppliers: ISupplierScheduled[];
  protected readonly columnTypes = gridColumnTypes;
  protected readonly generalColDefDefaults = generalColDefDefaults;
  private readonly icons: typeof Icons = Icons;

  constructor(
    private readonly store: Store,
    private modalController: ModalController,
  ) {

  }

  get selectedStockItemCount(): number {
    return this.selectedStockItemsMap.size;
  }

  get getPreviewButtonData(): string {
    if (this.preview) {
      return `View All Suppliers`;
    } else {
      return this.selectedStockItemCount !== 1 ? `View Selected Suppliers` : `View Selected Supplier`;
    }
  }

  @Input() set setStockData(suppliers: { [p: string]: ISupplierScheduled }) {
    if (suppliers) {
      this.searchString = '';
      this.selectedSuppliers = Object.values(suppliers) ?? [];
      this.fuzzySuppliers = fuzzyFilterArrayOfObjects<ISupplierScheduled>(getDeepCopyOfObject(this.selectedSuppliers), this.searchString, ['supplierId', 'name']);
    }
  }

  @Input() set setSelectedSupplier(supplier: { id: string, select: boolean }) {
    if (!this.gridApi) {
      return;
    }
    if (!supplier.id) {
      return;
    }
    this.gridApi.forEachNode((node) => {
      if (node.data && node.data.supplierId === supplier.id) {
        node.setSelected(supplier.select);
        if (!supplier.select) {
          this.selectedStockItemsMap.delete(supplier.id);
        }
      }
    });
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
    this.gridApi.setGridOption('onRowDataUpdated', () => {
      this.restoreSelectedRows();
    });
    this.gridApi.addEventListener('selectionChanged', (event: SelectionChangedEvent) => {
      this.updateSelectedSupplier(event);
    });
    if (this.allowDrag) {
      this.onDragAndDropSupplier();
    }
    this.store.dispatch(getTypesenseFacetCounts());
  }

  onGridSizeChange(): void {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  onDragAndDropSupplier(): void {

    const container = document.querySelector(`.days`);
    if (!container) {
      return;
    }
    const dropZone: RowDropZoneParams = {
      getContainer: () => container as HTMLElement,
      onDragEnter: () => {
        container.classList.add('dragging');
      },
      onDragLeave: () => {
        container.classList.remove('dragging');
      },
      onDragStop: async dropParams => {
        const draggedRowData: ISupplierScheduled[] = [];
        dropParams.nodes.forEach((node: IRowNode<ISupplierScheduled>) => {
          draggedRowData.push(node.data);
        });
        const selectedItems = Array.from(this.selectedStockItemsMap.values());
        const itemsToEmit = selectedItems.length < 1 ? draggedRowData : selectedItems;
        const onHoldSuppliers = itemsToEmit.filter((item: ISupplierScheduled) => onHoldDecode(item.onHoldCode, false, true).includes("Purchase Orders"));
        const noItemsSuppliers = itemsToEmit.filter((item: ISupplierScheduled) => !this.facetCounts['regularSuppCode'][item.account]);

        if (onHoldSuppliers.length > 0 || noItemsSuppliers.length > 0) {
          const supplierIdArray = [
            ...onHoldSuppliers.map((supplier: ISupplierScheduled) => `${supplier.account} - ${supplier.name}\n`),
            ...noItemsSuppliers.map((supplier: ISupplierScheduled) => `${supplier.account} - ${supplier.name}\n`),
          ];
          const supplierIdSetArray = Array.from(new Set(supplierIdArray));
          const modalProps: ISharedModalBasic = {
            buttonAccept: true,
            buttonAcceptText: 'Confirm Selecting',
            buttonClose: true,
            buttonCloseCross: false,
            buttonCloseText: 'Cancel',
            buttonSecondary: true,
            buttonSecondaryText: 'Proceed Without Shown Suppliers',
            modalTitle: 'Warning: Supplier On Hold/Have no stock Items',
            modalTitleIcon: this.icons.helpCircleOutline,
            modalTitleIconColor: 'yellow',
            contextHeading: 'Please select how wish to continue',
            contextDescription: [
              'The Following Suppliers are either on hold or do not have any stock items linked to them',
              ...supplierIdSetArray,
            ],
          };

          const modalController = await this.modalController.create({
            component: SharedModalBasicComponent,
            componentProps: modalProps,
            cssClass: ['shared-basic-modal-css-medium'],
          });

          await modalController.present();
          const {data} = await modalController.onDidDismiss();
          if (data.buttonPressed === 'button-accept') {
            this.rowDragAndDropped.emit(itemsToEmit);
            container.classList.remove('dragging');
            dropParams.nodes.forEach((node: IRowNode<ISupplierScheduled>) => {
              const supplierId = (node.data).supplierId;
              if (this.selectedStockItemsMap.has(supplierId)) {
                node.setSelected(false);
              }
            });
            this.selectedStockItemsMap.clear();
            this.preview = false;
            this.searchString = '';
            this.gridApi.setGridOption('rowData', this.selectedSuppliers);
          } else if (data.buttonPressed === 'button-secondary') {
            const suppliersToRemove = new Set([...onHoldSuppliers, ...noItemsSuppliers]);

            const filteredItems = itemsToEmit.filter(
              (supplier: ISupplierScheduled) => !suppliersToRemove.has(supplier),
            );
            this.rowDragAndDropped.emit(filteredItems);
            container.classList.remove('dragging');
            dropParams.nodes.forEach((node: IRowNode<ISupplierScheduled>) => {
              const supplierId = (node.data).supplierId;
              if (this.selectedStockItemsMap.has(supplierId)) {
                node.setSelected(false);
              }
            });
            this.selectedStockItemsMap.clear();
            this.preview = false;
            this.searchString = '';
            this.gridApi.setGridOption('rowData', this.selectedSuppliers);
          } else {
            container.classList.remove('dragging');
          }
        } else {
          this.rowDragAndDropped.emit(itemsToEmit);
          container.classList.remove('dragging');
          dropParams.nodes.forEach((node: IRowNode<ISupplierScheduled>) => {
            const supplierId = (node.data).supplierId;
            if (this.selectedStockItemsMap.has(supplierId)) {
              node.setSelected(false);
            }
          });
          this.selectedStockItemsMap.clear();
          this.preview = false;
          this.searchString = '';
          this.gridApi.setGridOption('rowData', this.selectedSuppliers);
        }

      },
    };

    this.gridApi.addRowDropZone(dropZone);
  }

  updateSelectedSupplier(event?: SelectionChangedEvent): void {
    if (this.gridApi !== undefined) {
      if (this.multiple) {
        const selectedNodes = this.gridApi.getSelectedNodes();
        const newlySelectedSuppliers: typeof this.selectedSuppliers = selectedNodes.map((node): typeof this.selectedSuppliers[0] => node.data as typeof this.selectedSuppliers[0]);
        const displayedItems: typeof this.selectedSuppliers = [];
        if (this.checkForDuplicates) {
          newlySelectedSuppliers?.forEach((supplier: ISupplierScheduled) => {
            if (!this.selectedStockItemsMap.has(supplier.supplierId)) {
              this.emitSelectedValues.emit(supplier);
            }
          });
        }
        this.gridApi.forEachNode((node): void => {
          if (node.data) {
            displayedItems.push(node.data as ISupplierScheduled);
          }
        });
        const mergedSelection = new Map<string, ISupplierScheduled>(this.selectedStockItemsMap);
        displayedItems.forEach((item: ISupplierScheduled) => {
          if (mergedSelection.has(item.supplierId) && event?.source === "rowClicked") {
            mergedSelection.delete(item.supplierId);
          }
        });

        for (const item of newlySelectedSuppliers) {
          mergedSelection.set(item.supplierId, item);
        }
        this.selectedStockItemsMap = mergedSelection;
      } else {
        if (event?.source === "rowClicked") {
          const selectedNodes = this.gridApi.getSelectedNodes();
          this.selectedStockItemsMap = new Map();
          if (selectedNodes.length > 0) {
            const lastSelectedNode = selectedNodes[selectedNodes.length - 1];
            const selectedSupplier = lastSelectedNode.data as ISupplierScheduled;
            this.selectedStockItemsMap.set(selectedSupplier.supplierId, selectedSupplier);
          }
        }

      }
    }
  }

  restoreSelectedRows(): void {
    if (!this.gridApi) {
      return;
    }
    this.gridApi.forEachNode((node: IRowNode<ISupplierScheduled>) => {
      if (this.selectedStockItemsMap.has((node.data)?.supplierId)) {
        node.setSelected(true);
      }
    });
  }

  searchOnKeyUp(): void {
    this.gridApi.removeEventListener('selectionChanged', this.updateSelectedSupplier);
    this.gridApi.setGridOption(
      "quickFilterText",
      this.searchString,
    );
    this.gridApi.addEventListener('selectionChanged', (event: SelectionChangedEvent) => {
      this.updateSelectedSupplier(event);
    });
    this.restoreSelectedRows();
  }

  trackByFunction(index: number): number {
    return index;
  }

  onViewSelectedClick(): void {
    this.gridApi.removeEventListener('selectionChanged', this.updateSelectedSupplier);
    this.preview = !this.preview;
    this.gridApi.setGridOption(
      "quickFilterText",
      '',
    );
    if (this.preview) {
      const selectedArray = Array.from(this.selectedStockItemsMap.values());
      this.gridApi.setGridOption('rowData', selectedArray);
      this.restoreSelectedRows();
    } else {
      this.gridApi.setGridOption('rowData', this.selectedSuppliers);
      this.restoreSelectedRows();
    }
    this.gridApi.addEventListener('selectionChanged', (event: SelectionChangedEvent) => {
      this.updateSelectedSupplier(event);
    });
  }

  onGetSelectedStockItemsClick(): void {
    const selectedItems = Array.from(this.selectedStockItemsMap.values());
    this.stockItemsSelected.emit(selectedItems);
  }

  onSelectAllSuppliersClick(select: boolean): void {
    if (!this.gridApi) {
      return;
    }
    if (!select) {
      this.gridApi.forEachNode((node) => {
        node.setSelected(true);
        const supplier = node.data as ISupplierScheduled;
        this.selectedStockItemsMap.set(supplier.supplierId, supplier);
      });
    } else {
      this.gridApi.forEachNode((node) => {
        node.setSelected(false);
        const supplier = node.data as ISupplierScheduled;
        this.selectedStockItemsMap.delete(supplier.supplierId);
      });
    }

  }

  close(): void {
    void this.modalController.dismiss();
  };

  async showSelectionWarning(removedItems: number): Promise<void> {
    const componentProps: ISharedModalBasic = {
      buttonAccept: true,
      buttonAcceptText: 'Ok',
      buttonClose: false,
      buttonCloseCross: false,
      buttonCloseText: 'Cancel',
      modalTitle: 'Too Many Items Selected',
      modalTitleIcon: this.icons.infoSlabCircleOutline,
      modalTitleIconColor: 'yellow',
      contextHeading: `Warning: More Than ${this.limit} Items Selected`,
      contextDescription: [
        `${removedItems} items have been deselected.`,
        'To add more items, please do so separately.',
      ],
    };


    const modalController: HTMLIonModalElement = await this.modalController.create({
      component: SharedModalBasicComponent, componentProps, cssClass: ['shared-basic-modal-css-small'],
    });
    await modalController.present();
  }

}
