import {Component, Input} from '@angular/core';
import {IAutoOrderOrder} from '../../../../shared/shared-models/auto-ordering/auto-order-order';
import {DateUtilsService} from '../../../../shared/shared-utils/date/dateUtilsService.service';
import {INotification} from '../../models/notification';
import {
  AnimationDefault,
  AnimationDirection,
  Animations,
  IAnimation
} from '../../../../shared/shared-models/animations/animation';
import {Icons} from '../../../../shared-modules/shared-icons/icons';
import {Store} from '@ngrx/store';
import {
  AutoOrderStatusService
} from "../../../../features-as-modules/feature-auto-ordering/service/auto-order-status.service";

@Component({
  selector: 'app-notification-auto-order',
  templateUrl: './notification-auto-order.component.html',
  styleUrls: ['./notification-auto-order.component.scss'],
})
export class NotificationAutoOrderComponent {

  isAutoOrderLoading: boolean;

  protected readonly icons = Icons;
  protected readonly notificationOrderLoading: IAnimation = {
    ...AnimationDefault,
    animation: Animations.CIRCLE_DISAPPEAR_AND_REAPPEAR,
    backgroundColour: 'var(--notification-auto-order-animation-background)',
    direction: AnimationDirection.REVERSE,
    id: 'app-component-animation-auto-order-notification',
    fixedDimensions: {
      width: 100,
      height: 100,
    }
  };

  private autoOrder: IAutoOrderOrder;
  private notification: INotification;

  constructor(
    private dateUtilsService: DateUtilsService,
    private autoOrderStatusService: AutoOrderStatusService,
    private readonly store: Store,
  ) {
  }

  get getAutoOrder(): IAutoOrderOrder {
    return this.autoOrder;
  }

  get getGeneratedDate(): string {
    return this.dateUtilsService.formatPropertyDate<IAutoOrderOrder>(
      'MMM dd, yyyy',
      this.getAutoOrder,
      'generated',
      true
    );
  }

  get getIsComponentLoading(): boolean {
    return !this.isAutoOrderLoading && !!(this.getAutoOrder || this.getNotification);
  }

  get getNotification(): INotification {
    return this.notification;
  }

  get getNotificationOrderId(): string {
    return this.notification?.event;
  }

  get getPurchaseOrderNo(): string {
    return this.getAutoOrder?.purchaseOrderNo ? this.autoOrder.purchaseOrderNo.toString() : 'No P/o Found';
  }

  get getSupplierName(): string {
    return this.getAutoOrder?.supplierName || this.getAutoOrder?.supplierId;
  }

  @Input() set setAutoOrders(autoOrders: IAutoOrderOrder[]) {
    if (autoOrders.length > 0) {
      this.autoOrder = autoOrders.find((order: IAutoOrderOrder): boolean => order.orderId === this.getNotificationOrderId);
    }
  };

  @Input() set setIsAutoOrderLoading(isLoading: { [orderID: string]: boolean }) {
    this.isAutoOrderLoading = isLoading[this.getNotificationOrderId];
  };

  @Input() set setNotification(notify: INotification) {
    this.notification = notify;
  }

  getStatusIcon(
    order: IAutoOrderOrder,
    statusProperty: keyof Pick<IAutoOrderOrder, 'emailStatus' | 'iqStatus' | 'pdfStatus' | 'status'>
  ): Icons {
    return this.autoOrderStatusService.getStatusIcon(order, statusProperty)
  }

  getStatusIconColour(
    order: IAutoOrderOrder,
    statusProperty: keyof Pick<IAutoOrderOrder, 'emailStatus' | 'iqStatus' | 'pdfStatus' | 'status'>
  ): 'green' | 'green-stroke' | 'red' | 'red-stroke' | 'yellow' | 'yellow-stroke' | 'white' | 'white-stroke' |
    'primary' | 'secondary' | 'primary-stroke' | 'secondary-stroke' | 'black' | 'black-stroke' {
    return this.autoOrderStatusService.getStatusColour(order, statusProperty);
  }

  getStatusTooltip(
    order: IAutoOrderOrder,
    statusProperty: keyof Pick<IAutoOrderOrder, 'emailStatus' | 'iqStatus' | 'pdfStatus' | 'status'>
  ): string {
    return this.autoOrderStatusService.getStatusColour(order, statusProperty);
  }

  onTriggerAction(
    order: IAutoOrderOrder,
    statusProperty: keyof Pick<IAutoOrderOrder, 'emailStatus' | 'iqStatus' | 'pdfStatus' | 'status'>
  ): void {
    this.autoOrderStatusService.triggerAction(order, statusProperty);
  }

  onEmailRecipientClick(): void {
    const aa = 'this is busy being developed';
  }

  onDownloadPDFClick(): void {
    const aa = 'this is busy being developed';
  }

  onOrderFailClick(failedOn: string): void {
    const aa = 'this is busy being developed';
  }

}
