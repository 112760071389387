<app-modal-header [close]="close" [title]="'General Stock Settings ' + currentStore.name"></app-modal-header>

<ion-accordion-group>
  <ion-accordion value="disabledItems">
    <ion-item slot="header">
      <ion-label>Item Disabling Rules</ion-label>
    </ion-item>
    <div class="fixed-height" slot="content">
      <app-del-item-settings
        [isModal]="false"
        [lineColours]="lineColours$ | ngrxPush"
        [settings]="itemDisablingRules"
        [storeId]="currentStore.storeId"
        class="fixed-height">
      </app-del-item-settings>
    </div>
  </ion-accordion>
  <ion-accordion value="stockItemTags">
    <ion-item slot="header">
      <ion-label>Stock Item Tags</ion-label>
    </ion-item>
    <div class="fixed-height" slot="content">
      <app-settings-stock-tags
        [setTags]="(allTags$ | ngrxPush)">
      </app-settings-stock-tags>
    </div>
  </ion-accordion>
</ion-accordion-group>
