import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {NotificationService} from '../service/notification.service';
import * as NotificationActions from '../../notifications/store/notification.actions';
import * as CoreSharedActions from '../../../features-as-modules/feature-core/store/core.actions';
import {catchError, filter, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {selectUserAccessStoreList} from '../../../features-as-modules/feature-core/store/core.selectors';
import {
  path_notification_id,
  path_notifications,
  path_operational_stores_data_auto_ordering_auto_orders_order_id
} from '../../../shared/shared-services/database-paths';
import {INotification} from '../models/notification';
import {IAutoOrderOrder} from '../../../shared/shared-models/auto-ordering/auto-order-order';
import {selectSuppliersByUserSelectedStore} from '../../core/store-shared/shared.selectors';

@Injectable()
export class NotificationEffects {

  //todo on close accordion click do not trigger actions

  // Todo
  //todp delete messages modal
  // Implement Filters
  // (timestamp < 30 days or success === false )
  // and storeId in []

  // todo: align accordion header on mobile
  // close button for mobile

  // todo read is not adding it correctly to the array
  // todo cater for failures on getting the orders
  // todo add hover colour to icons

  // todo
  // change chips to toggles
  // same as auto order list item
  // retry buttons

  //#region GET USER NOTIFICATIONS ON ACCESS SUCCESS
  /**
   * Effect to trigger fetching notifications count for user stores when user access is successfully obtained.
   * @description This effect listens to the `getUserAccessSuccess` action, filters stores that have a length greater than zero,
   * and triggers the action to fetch notifications for user stores.
   */
  triggerGetNotificationCountForUserStores$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreSharedActions.getUserAccessSuccess),
      withLatestFrom(this.store.pipe(select(selectUserAccessStoreList))),
      filter(([_, storeIds]) => storeIds.length > 0),
      mergeMap(([_, storeIds]) =>
        of(NotificationActions.getAllNotificationsForUserStores({storeIds}))
      )
    )
  );

  //#endregion

  //#region GET NOTIFICATIONS PER STORE
  /**
   * Effect to fetch notifications for user stores.
   * @description This effect listens to the `getAllNotificationsForUserStores` action, fetches the notifications
   * for the provided store IDs, and dispatches either a success or failure action based on the outcome.
   * @property action - The original action dispatched to trigger the effect.
   * @property storeIds - The list of store IDs for which notifications are fetched.
   * @property notifications - The notifications fetched for the user stores.
   * @property error - The error that occurred when fetching notifications.
   */
  getAllNotificationsForUserStores$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.getAllNotificationsForUserStores),
      withLatestFrom(this.store.pipe(select(selectUserAccessStoreList))),
      mergeMap(([action, storeIds]) =>
        this.notificationService.getCollectionByFieldInValuesWithNotificationIdAsObservableValueChanges<INotification>(
          path_notifications(),
          'storeId',
          storeIds
        ).pipe(
          map((notifications: INotification[]) =>
            NotificationActions.getAllNotificationsForUserStoresSuccess({notifications, storeIds})
          ),
          catchError((error) =>
            of(NotificationActions.getAllNotificationsForUserStoresFailure({
              error: {
                ...error,
                action: JSON.stringify(action),
                actionType: action.type
              }
            }))
          )
        )
      )
    )
  );

  //#endregion

  //#region UPDATE NOTIFICATION
  /**
   * Effect to handle updating a notification in Firestore.
   *
   * - Listens for the `updateNotification` action.
   * - Calls `updateDocumentAsObservable` to update the notification in Firestore.
   * - Dispatches `updateNotificationSuccess` on success.
   * - Dispatches `updateNotificationFailure` on failure, including error details.
   */
  updateNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.updateNotification),
      mergeMap((action) =>
        this.notificationService.updateDocumentAsObservable<INotification>(
          path_notification_id(action.notification),
          {
            read: action.notification.read
          } as Partial<INotification>,
        ).pipe(
          map(() =>
            NotificationActions.updateNotificationSuccess()
          ),
          catchError((error) =>
            of(NotificationActions.updateNotificationFailure({
              error: {
                ...error,
                action: JSON.stringify(action),
                actionType: action.type
              }
            }))
          )
        )
      )
    )
  );

  //#endregion

  //#region GET NOTIFICATION AUTO ORDER
  /**
   * ### Effect to fetch Auto Order details for a notification
   *
   * Listens for `getNotificationAutoOrder` action, retrieves the auto-order document from Firestore,
   * and dispatches either a success or failure action.
   *
   * @listens {NotificationActions.getNotificationAutoOrder}
   *
   */
  getNotificationAutoOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.getNotificationAutoOrder),
      withLatestFrom(
        this.store.pipe(select(selectSuppliersByUserSelectedStore))
      ),
      mergeMap(([action, suppliers]) =>
        this.notificationService.getDocumentAsObservable<IAutoOrderOrder>(
          path_operational_stores_data_auto_ordering_auto_orders_order_id(
            action.notification.storeId,
            action.notification.event,
          )
        ).pipe(
          map((autoOrder: IAutoOrderOrder) =>
            NotificationActions.getNotificationAutoOrderSuccess({
              autoOrder: {
                ...autoOrder,
                supplierName: Object.keys(suppliers).length > 0
                  ? suppliers[autoOrder.supplierId].name : ''
              },
              notification: action.notification,
            })
          ),
          catchError((error) =>
            of(NotificationActions.getNotificationAutoOrderFailure({
              notification: action.notification,
              error: {
                ...error,
                action: JSON.stringify(action),
                actionType: action.type,
              }
            }))
          )
        )
      )
    )
  );

  getNotificationStockUpdate$t = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.getNotificationStockUpdate),
      map(({notification}) => NotificationActions.getNotificationStockUpdateSuccess({notification}))
    )
  );

  //#endregion

  constructor(
    private notificationService: NotificationService,
    private actions$: Actions,
    private readonly store: Store,
  ) {
  }
}
