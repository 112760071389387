import {CellRendererCurrencyComponent} from './cell-renderer-currency/cell-renderer-currency.component';
import {CellRendererDepartmentsComponent} from './cell-renderer-departments/cell-renderer-departments.component';
import {CellRendererIconsComponent} from './cell-renderer-icons/cell-renderer-icons.component';
import {CellRendererLineColorComponent} from './cell-renderer-line-color/cell-renderer-line-color.component';
import {CellRendererTextComponent} from './cell-renderer-text/cell-renderer-text.component';
import {CellRendererSelectedComponent} from './cell-renderer-selected/cell-renderer-selected.component';
import {CellRendererDisableItemComponent} from './cell-renderer-disable-item/cell-renderer-disable-item.component';
import {CellRendererMultiSelectComponent} from './cell-renderer-multi-select/cell-renderer-multi-select.component';
import {
  CellRendererMultiSelectArrayComponent,
} from './cell-renderer-multi-select-array/cell-renderer-multi-select-array.component';
import {
  CellRendererSupplierSearchCodeComponent,
} from './cell-renderer-supplier-search-code/cell-renderer-supplier-search-code.component';
import {
  CellRendererSupplierFacetCountsComponent,
} from './cell-renderer-supplier-facet-counts/cell-renderer-supplier-facet-counts.component';
import {
  CellRendererSupplierFacetCountsContainerComponent,
} from './cell-renderer-supplier-facet-counts-container/cell-renderer-supplier-facet-counts-container.component';

export const CELL_RENDERER_CURRENCY = CellRendererCurrencyComponent;
export const CELL_RENDERER_DISABLE_ITEM = CellRendererDisableItemComponent;
export const CELL_RENDERER_DEPARTMENTS = CellRendererDepartmentsComponent;
export const CELL_RENDERER_ICONS = CellRendererIconsComponent;
export const CELL_RENDERER_LINE_COLOUR = CellRendererLineColorComponent;
export const CELL_RENDERER_MULTI_SELECT_ARRAY = CellRendererMultiSelectArrayComponent;
export const CELL_RENDERER_MULTI_SELECT = CellRendererMultiSelectComponent;
export const CELL_RENDERER_TEXT = CellRendererTextComponent;
export const CELL_RENDERER_SUPPLIER_SEARCH_CODE = CellRendererSupplierSearchCodeComponent;
export const CELL_RENDERER_SELECTED = CellRendererSelectedComponent;
export const CELL_RENDERER_SUPPLIER_FACET_COUNTS = CellRendererSupplierFacetCountsComponent;
export const CELL_RENDERER_SUPPLIER_FACET_COUNTS_CONTAINER = CellRendererSupplierFacetCountsContainerComponent;

export const CELL_RENDERER_COMPONENTS = [
  CELL_RENDERER_CURRENCY,
  CELL_RENDERER_DISABLE_ITEM,
  CELL_RENDERER_DEPARTMENTS,
  CELL_RENDERER_ICONS,
  CELL_RENDERER_LINE_COLOUR,
  CELL_RENDERER_MULTI_SELECT_ARRAY,
  CELL_RENDERER_MULTI_SELECT,
  CELL_RENDERER_TEXT,
  CELL_RENDERER_SUPPLIER_FACET_COUNTS,
  CELL_RENDERER_SUPPLIER_FACET_COUNTS_CONTAINER,
  CELL_RENDERER_SUPPLIER_SEARCH_CODE,
  CELL_RENDERER_SELECTED,
];
