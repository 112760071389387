import {Injectable} from '@angular/core';
import {IEmailSettings} from 'src/app/features/settings-old/models/settings-models';

@Injectable({
  providedIn: 'root'
})
export class EmailConfigurationManager {
  updateSmtpConfig(settings: IEmailSettings, updates: Partial<{
    server: string;
    port: number;
    sslOnly: boolean;
  }>): IEmailSettings {
    return {
      ...settings,
      smtp: {
        ...settings.smtp,
        ...updates
      }
    };
  }

  updateImapConfig(settings: IEmailSettings, updates: Partial<{
    server: string;
    port: number;
    sslOnly: boolean;
  }>): IEmailSettings {
    return {
      ...settings,
      imap: {
        ...settings.imap,
        ...updates
      }
    };
  }

  updateImapEnabled(settings: IEmailSettings, enabled: boolean): IEmailSettings {
    return {
      ...settings,
      enableImap: enabled
    };
  }
}
