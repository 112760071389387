import {Component} from '@angular/core';
import {combineLatest} from 'rxjs';
import {Store} from '@ngrx/store';
import {
  selectCurrentPageAndTab,
  selectSelectedUserStore,
  selectUserAccess,
  selectUserStores
} from 'src/app/features-as-modules/feature-core/store/core.selectors';
import {
  setCurrentPageAndTab,
  setUserSelectedStoreAndGetSettings
} from 'src/app/features-as-modules/feature-core/store/core.actions';
import {IStore} from 'src/app/shared/shared-models/store/store';
import {take} from 'rxjs/operators';
import {FirebaseService} from 'src/app/shared-utilities/services-old/firebase.service';
import {initialViewTabStores} from './settings-page-constants';
import {IViewTabStores} from './models';
import {INavigationPage} from 'src/app/shared-utilities/models-old/page/page';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent {
  //#region Properties
  protected currentTab: INavigationPage['currentTab'];
  protected selectedStore: IStore;
  protected isAdminOfCurrentStore: boolean;
  protected tabs = ['email', 'store'];
  protected viewTabStores: IViewTabStores = initialViewTabStores;

  //#endregion

  constructor(
    private readonly store: Store,
    private readonly firebase: FirebaseService
  ) {
    this.firebase.updateLastSeen();
    this.initializeSelectors();
    this.store.select(selectCurrentPageAndTab)
      .pipe(take(1))
      .subscribe(({currentTab}: { currentTab: string }) => {
        if (!currentTab) {
          this.store.dispatch(setCurrentPageAndTab({
            currentSelectedPage: {currentPage: 'settings', currentTab: 'user'}
          }))
        }
      })
  }

  //#region Performance Methods
  protected trackByStore(_: number, store: IStore): string {
    return store.storeId;
  }

  //#endregion

  //#region Event Handlers
  protected onStoreSelect(event: IStore): void {
    const storeId = event.storeId;
    const store = this.viewTabStores[this.currentTab].stores.find(store => store.storeId === storeId) || null;
    if (store) this.store.dispatch(setUserSelectedStoreAndGetSettings({selectedStore: store}));
  }

  //#endregion

  //#region Initializations
  private initializeSelectors(): void {
    combineLatest([
      this.store.select(selectUserStores),
      this.store.select(selectSelectedUserStore),
      this.store.select(selectUserAccess),
      this.store.select(selectCurrentPageAndTab)
    ]).subscribe(([stores, selectedStore, userAccess, pageTab]) => {
      this.selectedStore = selectedStore;
      this.isAdminOfCurrentStore = userAccess?.adminAccessStores?.includes(selectedStore?.storeId);
      this.currentTab = pageTab.currentTab;
      this.viewTabStores.store.stores = stores.filter(store => userAccess?.adminAccessStores?.includes(store.storeId));
      this.viewTabStores.email.stores = stores
    })
  }

  //#endregion
}
