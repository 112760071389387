import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Store} from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class CollectionUsersMessagesService {
  constructor(
    private angularFirestore: AngularFirestore,
    private store: Store,
  ) {
  }

  // ====================================================================================================
  // Generic Get/Set/Delete
  // ====================================================================================================

  async setDocument<DataType>(
    path: string,
    data: DataType,
  ): Promise<void> {
    try {
      await this.angularFirestore
        .doc<DataType>(path)
        .set(data, {merge: true});
    } catch (error) {
      console.error(error);
    }
  }

  async getDocument<DataType>(
    path: string,
  ): Promise<DataType> {
    try {
      const docRef = this.angularFirestore.doc<DataType>(path);
      const docSnapshot = await docRef.get().toPromise();
      if (docSnapshot?.data()) {
        return docSnapshot.data();
      } else {
        return {} as DataType;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async deleteDocument<DataType>(
    path: string,
  ): Promise<void> {
    try {
      await this.angularFirestore
        .doc<DataType>(path)
        .delete();
    } catch (error) {
      console.error(`Error deleting document at path: ${path}\n`, error);
    }
  }

  batchDeleteMessages(messageIds: string[], userId: string, storeId: string): Promise<void> {
    const batch = this.angularFirestore.firestore.batch();
    messageIds.forEach((id: string) => {
      const messageRef = this.angularFirestore.doc(`/notifications/${id}`).ref;
      batch.delete(messageRef);
    });
    return batch.commit();
  }
}
