import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {CoreService} from '../../core/service/core.service';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

/**
 * Service for managing notifications.
 * This service extends the CoreService and provides methods for interacting
 * with Firestore to manage notifications.
 *
 * @extends CoreService - Inherits functionality from CoreService.
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationService extends CoreService {

  /**
   * Creates an instance of NotificationService.
   * @param {AngularFirestore} angularFirestore - The instance of AngularFirestore to interact with Firestore.
   */
  constructor(angularFirestore: AngularFirestore) {
    super(angularFirestore);
  }

  //region GET COLLECTION
  /**
   * Retrieves a collection of documents as an observable, filtering them based on a field's value
   * being within a specified array. Each document includes its notification ID.
   *
   * @template DataType - The type of data contained in the documents.
   * @param path - The Firestore collection path.
   * @param fieldName - The field to filter by.
   * @param values - The array of values to match against the specified field.
   * @returns An observable emitting an array of documents, each including its Firestore document ID as `notificationId`.
   */
  getCollectionByFieldInValuesWithNotificationIdAsObservableValueChanges<DataType>(
    path: string,
    fieldName: string,
    values: string[]
  ): Observable<(DataType)[]> {
    return this.angularFirestore.collection<DataType>(path, ref =>
      ref.where(fieldName, 'in', values)
    ).snapshotChanges().pipe(
      map(changes =>
        changes.map(change => ({
          notificationId: change.payload.doc.id,
          ...change.payload.doc.data()
        }))
      )
    );
  }

  //endregion


}
