<ion-header>
  <ion-toolbar>
    <ion-title class="title-computer">{{ title }}</ion-title>
    <div class="title-mobile">
      <app-icon-menu (click)="expandMenu($event)"></app-icon-menu>
      <div>{{ title }}</div>
    </div>

    <ion-buttons slot="end">

      <span *ngIf="getUserName" class="button-span">
        <ion-badge *ngIf="totalNotifications.unread > 0">{{ totalNotifications.unread }}</ion-badge>
        <app-icon-bell-outline
          (click)="getUserName ? viewNotifications() : null"
          class="message-icon">
        </app-icon-bell-outline>
<!--        <app-icon-bell-outline-->
<!--          (click)="getUserName ? viewMessages() : null"-->
<!--          class="message-icon">-->
<!--        </app-icon-bell-outline>-->
        <ng-container *ngIf="getUserStoreListCount">
          <app-icon-settings
            (click)="getUserName ? settings() : null"
            class="message-icon settings-icon">
          </app-icon-settings>
        </ng-container>
      </span>

      <div class="separator"></div>
      <div (click)="getUserName? onOpenMenuClick(): null" class="user-menu">
        <div class="menu-custom">
          <div *ngIf="user?.pp; else Letter">
            <img
              [src]="getProfilePicture"
              alt="Profile Picture"
              class="profile-picture">
          </div>
          <ng-template #Letter>
            <div class="letter">
              <div>
                {{ getFirstLetter }}
              </div>
            </div>
          </ng-template>
        </div>
        <div *ngIf="getUserName" class="user-name">
          {{
            getUserName.length > 10
              ? getUserName.substring(0, 10) + '...'
              : getUserName
          }}
          <app-icon-chevron-down class="chev-icon"></app-icon-chevron-down>
        </div>
      </div>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
