<ion-content>

  <div class="user-info">
    <div class="user-name">
      {{ user.userName }}
    </div>

    <ng-container *ngIf="pp[0] === 'picture'; else letter">
      <img [src]="pp[1]" alt="Profile Picture" class="profile-picture">
    </ng-container>
    <ng-template #letter>
      <div class="letter">
        <div>
          {{ pp[1] }}
        </div>
      </div>
    </ng-template>

  </div>

  <div class="options">
    <div (click)="onUserProfileClick()" class="item">
      <span>User Profile</span>
      <app-icon-person class="icon icon-fill"></app-icon-person>
    </div>
    <div (click)="onSignOutClick()" class="item">
      <span>Sign Out</span>
      <app-icon-log-in class="icon icon-stroke"></app-icon-log-in>
    </div>
    <div (click)="onFreshnessExpClick()" class="item">
      <span>How Does Gallix Work?</span>
      <app-icon-info-slab-circle-outline class="icon icon-fill"></app-icon-info-slab-circle-outline>
    </div>
  </div>

</ion-content>
