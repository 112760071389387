import {Component, Input} from '@angular/core';
import {INotification} from '../../models/notification';
import {IStore} from '../../../../shared/shared-models/store/store';
import {IUser} from '../../../../shared/shared-models/user-access/user';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {

  selectedStore: IStore;
  private notification: INotification;
  private user: IUser;

  get getNotification(): INotification {
    return this.notification;
  }

  get getUserDetail(): string {
    const id = this.notification.userId;
    const index = id.indexOf('@');
    let user = '';
    if (index > -1) {
      user = `${id.slice(0, index + 1)}...`;
    }
    user = this.user.userName || user;
    return user;
  }

  @Input() set setNotification(notify: INotification) {
    this.notification = notify;
  }

  @Input() set setUser(user: IUser) {
    this.user = user;
  }

  @Input() set setUserStores(stores: IStore[]) {
    if (stores?.length > 0) {
      const notify = this.getNotification;
      if (notify?.storeId) {
        this.selectedStore = stores.find((store: IStore) => store.storeId === notify.storeId);
      }
    }
  }

}
