import {IViewTabStores} from './models';

export const initialViewTabStores: IViewTabStores = {
  store: {
    stores: [],
  },
  email: {
    stores: [],
  },
  user: {
    stores: [],
  }
}
