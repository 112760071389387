import {Timestamp} from '@angular/fire/firestore';

//region NotificationTypeEnum
/**
 * Enum representing different types of notifications.
 * @property AUTO_ORDERS - Notification for auto orders.
 * @property STOCK_UPDATE - Notification for stock updates.
 */
export enum NotificationTypeEnum {
  /** Notification for auto orders. */
  AUTO_ORDERS = 'AUTO_ORDERS',

  /** Notification for stock updates. */
  STOCK_UPDATE = 'STOCK_UPDATE'
}

//endregion

//region NotificationTypeEnumHumanReadable
/**
 * Enum representing human-readable names for notification types.
 * @property AUTO_ORDERS - Human-readable label for auto order notifications.
 * @property STOCK_UPDATE - Human-readable label for stock update notifications.
 */
export enum NotificationTypeEnumHumanReadable {
  /** Human-readable label for auto order notifications. */
  AUTO_ORDERS = 'Auto Order',

  /** Human-readable label for stock update notifications. */
  STOCK_UPDATE = 'Stock Update'
}

//endregion

//region NotificationType
/**
 * Type representing the keys of NotificationTypeEnum.
 * @example
 * // Possible values: 'AUTO_ORDERS' | 'STOCK_UPDATE'
 */
export type NotificationType = keyof typeof NotificationTypeEnum;

//endregion

//region INotification
/**
 * Interface representing a notification.
 * @property event - The event name associated with the notification.
 * @property notificationId - The unique identifier for the notification.
 * @property response - The response message related to the event.
 * @property storeId - The store ID where the event occurred.
 * @property success - Indicates whether the event was successful.
 * @property timestamp - The timestamp of when the notification was created.
 * @property type - The type of notification.
 * @property userId - The ID of the user associated with the event.
 * @property read - An optional array of read events related to the notification.
 */
export interface INotification {
  /** The event name associated with the notification. */
  event: string;

  /** The unique identifier for the notification. */
  notificationId: string;

  /** The response message related to the event. */
  response: string;

  /** The store ID where the event occurred. */
  storeId: string;

  /** Indicates whether the event was successful. */
  success: boolean;

  /** Timestamp of when the notification was created. */
  timestamp: Timestamp;

  /** The type of notification. */
  type: NotificationType;

  /** The ID of the user associated with the event. */
  userId: string;

  /** An optional array of read events related to the notification. */
  read?: INotificationRead[];
}

//endregion

//region INotificationRead
/**
 * Interface representing a notification read event.
 * @property userId - The ID of the user who read the notification.
 * @property timestamp - The timestamp of when the notification was read.
 */
export interface INotificationRead {
  /** The ID of the user who read the notification. */
  userId: string;

  /** The timestamp of when the notification was read. */
  timestamp: Timestamp;
}

//endregion

//region INotificationPerStore
/**
 * Interface representing notification data for a specific store.
 * @property total - The total number of notifications for the store.
 * @property read - The number of read notifications for the store.
 * @property unread - The number of unread notifications for the store.
 * @property notifications - An array of notifications associated with the store.
 */
export interface INotificationPerStore {
  /** The total number of notifications for the store. */
  total: number;

  /** The number of read notifications for the store. */
  read: number;

  /** The number of unread notifications for the store. */
  unread: number;

  /** An array of notifications associated with the store. */
  notifications: INotification[];
}

//endregion

//region INotificationTotals
/**
 * Interface representing the totals for notifications.
 * @property total - The total number of notifications.
 * @property read - The number of read notifications.
 * @property unread - The number of unread notifications.
 */
export interface INotificationTotals {
  /** The total number of notifications. */
  total: number;

  /** The number of read notifications. */
  read: number;

  /** The number of unread notifications. */
  unread: number;
}

//endregion
//region INotificationChips
/**
 * Interface representing the state of notification filter chips.
 * @property allMessages - Indicates whether the "All Messages" chip is selected.
 * @property autoOrder - Indicates whether the "Auto Order" chip is selected.
 * @property stockUpdate - Indicates whether the "Stock Update" chip is selected.
 * @property readMessages - Indicates whether the "Read Messages" chip is selected.
 * @property unreadMessages - Indicates whether the "Unread Messages" chip is selected.
 * @property success - Indicates whether the "Success" chip is selected.
 * @property fail - Indicates whether the "Fail" chip is selected.
 */
export interface INotificationChips {
  /** Indicates whether the "All Messages" chip is selected. */
  allMessages: boolean;

  /** Indicates whether the "Auto Order" chip is selected. */
  autoOrder: boolean;

  /** Indicates whether the "Fail" chip is selected. */
  fail: boolean;

  /** Indicates whether the "Stock Update" chip is selected. */
  stockUpdate: boolean;

  /** Indicates whether the "Success" chip is selected. */
  success: boolean;

  /** Indicates whether the "Read Messages" chip is selected. */
  readMessages: boolean;

  /** Indicates whether the "Unread Messages" chip is selected. */
  unreadMessages: boolean;
}

//endregion

//region defaultNotificationChips
/**
 * Default state for notification filter chips.
 * @property allMessages - Default state for the "All Messages" chip.
 * @property autoOrder - Default state for the "Auto Order" chip.
 * @property stockUpdate - Default state for the "Stock Update" chip.
 * @property readMessages - Default state for the "Read Messages" chip.
 * @property unreadMessages - Default state for the "Unread Messages" chip.
 * @property success - Default state for the "Success" chip.
 * @property fail - Default state for the "Fail" chip.
 */
export const defaultNotificationChips: INotificationChips = {
  /** Default state for the "All Messages" chip. */
  allMessages: true,

  /** Default state for the "Auto Order" chip. */
  autoOrder: false,

  /** Default state for the "Fail" chip. */
  fail: false,

  /** Default state for the "Stock Update" chip. */
  stockUpdate: false,

  /** Default state for the "Success" chip. */
  success: false,

  /** Default state for the "Read Messages" chip. */
  readMessages: false,

  /** Default state for the "Unread Messages" chip. */
  unreadMessages: false,
};
//endregion

//region NotificationChipTypes
/**
 * Represents the possible types of notification chips.
 * Includes specific notification types as well as general categories.
 */
export type NotificationChipTypes = NotificationType | 'ALL' | 'READ' | 'UNREAD' | 'SUCCESS' | 'FAIL';
//endregion
