<div class="shared-modal-basic-container">

  <ion-header>
    <ion-toolbar>
      <ion-title>
        <div>
          <div>{{ modalTitle }}</div>
          <span></span>
          <ion-button (click)="onButtonPress('button-close')" *ngIf="buttonCloseCross" fill="clear">
            <app-display-icon *ngIf="buttonCloseCross" [icon]="icons.closeBoxOutline"></app-display-icon>
          </ion-button>
        </div>
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <div *ngIf="modalTitleIcon || contextHeading" class="context-header-container">
      <ng-container *ngIf="modalTitleIcon">
        <app-display-icon
          *ngIf="modalTitleIcon"
          [colour]="modalTitleIconColor" [icon]="modalTitleIcon" class="title-icon">
        </app-display-icon>
      </ng-container>
      <ng-container *ngIf="contextHeading">
        <div>{{ contextHeading }}</div>
      </ng-container>
    </div>

    <div *ngIf="contextDescription" class="context-description-container">
      <ng-container *ngFor="let contextItem of contextDescription; trackBy: trackByFunction">
        <div>{{ contextItem }}</div>
      </ng-container>
    </div>

    <div class="input-section">
      <ion-item>
        <ion-label position="floating">{{ inputHeading }}</ion-label>
        <ng-container *ngIf="inputType === 'string'">
          <ion-textarea
            [(ngModel)]="inputText"
            [placeholder]="inputPlaceholder">
          </ion-textarea>
        </ng-container>
        <ng-container *ngIf="inputType !== 'string'">
          <ion-input
            [(ngModel)]="inputText"
            [placeholder]="inputPlaceholder"
            [type]="inputType">
          </ion-input>
        </ng-container>

      </ion-item>
    </div>

  </ion-content>

  <ng-container *ngIf="buttonClose || buttonAccept">
    <ion-footer>
      <ion-button (click)="onButtonPress('button-close')" *ngIf="buttonClose" fill="outline">{{ buttonCloseText }}
      </ion-button>
      <ion-button (click)="onButtonPress('button-secondary')" *ngIf="buttonSecondary">{{ buttonSecondaryText }}
      </ion-button>
      <ion-button (click)="onButtonPress('button-accept')" *ngIf="buttonAccept">{{ buttonAcceptText }}</ion-button>
    </ion-footer>
  </ng-container>

</div>
