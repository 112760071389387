import {sItemIntToKey, sItemKeyToInt} from '../../../shared-utilities/models-old/datastructures';
import {ISearchableFields} from '../../shared-models/type-sense/default-searchable-fields';
import {
  IFacetCounts,
  IFacetFieldCounts,
  TypeSenseFacetCounts,
  TypeSenseHit,
  TypeSenseSearchResponse,
} from '../../shared-models/type-sense/type-sense-types';
import {
  IAdvancedFilterGroup,
  IFilter,
  IFilterGroup
} from '../../shared-components/components/shared-advanced-filters/utils/advanced-filter-groups';
import {
  filterTypeToSymbol,
  operatorMapping
} from '../../shared-components/components/shared-advanced-filters/utils/advanced-filter-types.utils';

/**
 * Converts an object of searchable fields into an array of field names based on their searchability.
 *
 * @function convertSearchableFields
 * @param {ISearchableFields} query_by - An object containing searchable fields and their configurations.
 * @returns {string[] | string} An array of field names that are marked as searchable, or an empty string if none are searchable.
 */
export function convertSearchableFields(query_by: ISearchableFields): string[] | string {
  const fieldArray: string[] = [];
  Object.keys(query_by).forEach((key: string): void => {
    if (query_by[key].value) {
      fieldArray.push(String(sItemKeyToInt[key]));
    }
  });
  return fieldArray.length > 0 ? fieldArray : '';
}

/**
 * Creates a map of facet counts from an array of TypeSense facet counts.
 *
 * @param {TypeSenseFacetCounts[]} facetsCount - The array of facet counts returned by a TypeSense search.
 *
 * @returns {IFacetCounts} A map where the keys are the facet field names (converted from `sItemIntToKey`) and the values
 * are objects that map each facet value to its count.
 */
export function createFacetCountsMap(facetsCount: TypeSenseFacetCounts[]): IFacetCounts {
  return facetsCount.reduce((acc, facet) => {
      acc[sItemIntToKey[facet.field_name]] = facet.counts
        .reduce((fieldAcc, {value, count}) => {
            fieldAcc[value] = count;
            return fieldAcc;
          },
          {} as IFacetFieldCounts,
        );
      return acc;
    },
    {} as IFacetCounts,
  );
}

/**
 * Maps a TypeSense search response to a generic type.
 *
 * @template MapToType - The type to map the TypeSense search result to.
 *
 * @param {TypeSenseSearchResponse<MapToType>} typeSenseData - The TypeSense search response containing hits to map.
 *
 * @returns {MapToType[]} An array of objects mapped to the generic type.
 */
export function mapTypeSenseObjectToGeneric<MapToType>(typeSenseData: TypeSenseSearchResponse<MapToType>): MapToType[] {
  return typeSenseData.hits.map((hit: TypeSenseHit<MapToType>) => {
    const item: Partial<MapToType> = {};
    Object.keys(hit.document).forEach((key: string): void => {
      const itemKey = sItemIntToKey[key];
      (item[itemKey] as unknown as MapToType) = hit.document[key];
    });
    (item['_tags'] as unknown as MapToType) = hit.document['_tags'] ?? [];
    (item['multipleSuppliers'] as unknown as MapToType) = hit.document['multipleSuppliers'] ?? [];
    return item as MapToType;
  });
}

function escapeString(value: string): string {
  return value.replace(/\\/g, '\\\\').replace(/"/g, '\\"');
}

function processFilter(filter: IFilter): string {
  let symbol = filterTypeToSymbol[filter.filterType];
  if (!symbol && filter.filterType !== 'partially equal to') {
    throw new Error(`Unsupported filter type: ${filter.filterType}`);
  }

  const fieldKey = filter.column;
  const mappedField = sItemKeyToInt[fieldKey];

  if (mappedField === undefined) {
    throw new Error(`Field ${fieldKey} not found in sItemKeyToInt mapping`);
  }

  // Todo This can be made into a switch case in future
  if (filter.filterType === 'range') {
    const values = String(filter.value).split(',');
    if (values.length !== 2) {
      throw new Error(`Invalid range value: ${filter.value} for column: ${fieldKey}`);
    }
    const [min, max] = values;
    return `${mappedField}:[${min}..${max}]`;
  } else if (filter.filterType === 'is one of' || filter.filterType === 'is not any of') {
    const values = String(filter.value).split(',');
    const formattedValues = values.map(val => {
      if (filter.columnType === 'string') {
        return `"${escapeString(val.trim())}"`;
      } else {
        return val.trim();
      }
    }).join(',');
    return `${mappedField}:${symbol}[${formattedValues}]`;
  } else {
    let value = filter.value;
    if (filter.columnType === 'string') {
      value = `${escapeString(String(value))}`;
    }
    if (filter.columnType === 'number') {
      if (isNaN(Number(value))) {
        throw new Error(`Invalid number value: ${value} for column: ${fieldKey}`);
      }
    }
    if (filter.columnType === 'boolean') {
      value = String(value).toLowerCase();
    }
    if (filter.columnType === 'date') {
      if (filter.filterType === 'less than (x) days' || filter.filterType === 'greater than (x) days') {
        const x = Number(filter.value);
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - x);
        value = new Date(currentDate).getTime();
      } else {
        value = new Date(String(value)).getTime();
      }
    }
    return `${mappedField}:${symbol}${value}`;
  }
}

function processFilterGroup(filterGroup: IFilterGroup): string {
  const operatorSymbol = operatorMapping[filterGroup.operator];
  if (!operatorSymbol) {
    throw new Error(`Unsupported operator: ${filterGroup.operator}`);
  }

  const results: string[] = [];

  for (const item of filterGroup.filtersAndGroups) {
    let result: string;
    if ('filtersAndGroups' in item) {
      result = processFilterGroup(item);
    } else {
      result = processFilter(item);
    }
    results.push(result);
  }

  return `(${results.join(` ${operatorSymbol} `)})`;
}

export function convertFilterObjectToTypesenseFilter(filterObject: IAdvancedFilterGroup): string {
  if (!filterObject.filterGroup) {
    throw new Error('Invalid filter object: missing filterGroup');
  }
  return processFilterGroup(filterObject.filterGroup);
}
