import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';

// 1️⃣ **Feature Modules**
import {AgGridAngular} from 'ag-grid-angular';
import {SharedModule} from '../shared-module/shared-module.module';
import {SharedIconsModule} from '../shared-icons/shared-icons.module';
import {SharedComponentsModule} from '../../shared/shared-components/shared-components.module';

// 2️⃣ **NgRx & State Management**
import {PushModule} from '@ngrx/component';

// 3️⃣ **Component Libraries**
import {CELL_EDITOR_COMPONENTS} from './components-cell-editors';
import {CELL_RENDERER_COMPONENTS} from './components-cell-renderers';
import {SHARED_GRID_COMPONENTS} from './components';

@NgModule({
  declarations: [
    ...CELL_EDITOR_COMPONENTS,
    ...CELL_RENDERER_COMPONENTS,
    ...SHARED_GRID_COMPONENTS,
  ],
  imports: [
    // 1️⃣ Angular & Core Modules
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,

    // 2️⃣ Material UI & Shared Components
    MatButtonModule,
    MatTooltipModule,

    // 3️⃣ Feature Modules
    SharedIconsModule,
    SharedModule,
    SharedComponentsModule,

    // 4️⃣ NgRx & State Management
    PushModule,

    // 5️⃣ Grid Components
    AgGridAngular,
  ],
  exports: [
    ...CELL_EDITOR_COMPONENTS,
    ...CELL_RENDERER_COMPONENTS,
    ...SHARED_GRID_COMPONENTS,
  ],
})
export class SharedGridModule {
}
