import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as SharedActions from '../store-shared/shared.actions';
import {catchError, map, mergeMap, switchMap, takeUntil, tap, withLatestFrom} from 'rxjs/operators';
import {
  selectSelectedUserStore,
  selectUser,
  selectUserStores
} from '../../../features-as-modules/feature-core/store/core.selectors';
import {select, Store} from '@ngrx/store';
import {
  CollectionSharedSuppliersService,
} from '../../../shared/shared-services/firebase/collection-shared-suppliers.service';
import {EMPTY, from, of, Subject} from 'rxjs';
import {IUser} from '../../../shared/shared-models/user-access/user';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {IColleagues} from '../../../shared/shared-models/user-access/user-access';
import {
  CollectionUserAccessColleaguesService,
} from '../../../shared/shared-services/firebase/collection-user-access-colleagues.service';
import {StorageService} from '../../../shared/shared-services/firebase/storage.service';
import {ISupplier} from '../../../shared/shared-models/stock/suppliers';
import {
  CollectionOperationalMessagesServiceService,
} from '../../../shared/shared-services/firebase/collection-operational-messages.service';
import {IMessage, IMessageAutoOrderUpdate} from '../models/message';
import {path_operational_stores_data_storeId_messages_from_app} from '../../../shared/shared-services/database-paths';
import {
  CollectionUsersMessagesService,
} from '../../../shared/shared-services/firebase/collection-users-messages.service';
import {selectSuppliersByUserSelectedStore} from './shared.selectors';

@Injectable()
export class SharedEffects {

  // '[Core Shared][Suppliers] Get Store Suppliers By User Selected Store'
  getStoreSuppliersByUserSelectedStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.getStoreSuppliersByUserSelectedStore),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectSuppliersByUserSelectedStore)),
        this.store.pipe(select(selectUserStores)),
      ),
      map(([{dispatch}, selectedUserStores, suppliers, userStores]) => {
        if (Object.keys(suppliers).length > 0) {
          if (dispatch) {
            return SharedActions.getStoreSuppliersByStore({store: selectedUserStores});
          } else {
            if (selectedUserStores?.[0]) {
              return SharedActions.getStoreSuppliersByStoreSuccess({
                store: selectedUserStores[0],
                suppliers
              });
            } else {
              return SharedActions.getStoreSuppliersByStoreSuccess({
                store: userStores[0],
                suppliers
              });
            }
          }
        } else {
          return SharedActions.getStoreSuppliersByStore({store: selectedUserStores});
        }
      }),
    ),
  );

  // '[Core Shared][Suppliers] Get Store Suppliers By Store',
  getStoreSuppliersByStore$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.getStoreSuppliersByStore),
    mergeMap(({store}) =>
      this.collectionSharedSuppliersService.getSuppliersByStore(store)
        .pipe(
          map((suppliers: { [supplierId: string]: ISupplier }) =>
            SharedActions.getStoreSuppliersByStoreSuccess({suppliers, store})),
          catchError((error: IError) => of(SharedActions.getStoreSuppliersByStoreFailure({error}))),
        ),
    ),
  ));

  // ''[Core Shared][Storage] Get Download URL from Storage',
  getDownloadUrlFromStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.getDownloadUrlFromStorage),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      mergeMap(([{storage}, store]) =>
        from(this.storageService.getDocumentDownloadUrl(storage, store))
          .pipe(
            map((documentUrl: string) => SharedActions.getDownloadUrlFromStorageSuccess({
              storage: {
                ...storage,
                url: documentUrl,
              },
            })),
            catchError((error: IError) => {
                return of(SharedActions.getDownloadUrlFromStorageFailure({error}));
              },
            ),
          ),
      ),
    ),
  );

  //  '[Core Shared][Messages] Get Messages From App for Auto Order Updates',
  getMessagesFromAppForAutoOrderUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.getMessagesFromAppForAutoOrderUpdates),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      mergeMap(([{queries, messageType}, store]) =>
        from(this.collectionOperationalMessagesServiceService.getMessagesUsingQuery<IMessageAutoOrderUpdate[]>(
          path_operational_stores_data_storeId_messages_from_app(store.storeId),
          queries,
        ))
          .pipe(
            map((messages: IMessage<IMessageAutoOrderUpdate[]>[]) => SharedActions
              .getMessagesFromAppForAutoOrderUpdatesSuccess({
                queries,
                messageType,
                messages,
                store,
              })),
            catchError((error: IError) =>
              of(SharedActions.getMessagesFromAppForAutoOrderUpdatesFailure({error})),
            ),
          ),
      ),
    ),
  );
  deleteOldMessages$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SharedActions.deleteOldMessages),
        withLatestFrom(this.store.pipe(select(selectUser)),
          this.store.pipe(select(selectSelectedUserStore))),
        tap(([{messageIds}, {id}, {storeId}]) => {
          void this.collectionUsersMessagesService.batchDeleteMessages(messageIds, id, storeId);
        }),
      ),
    {dispatch: false},
  );

  private stop$ = new Subject<void>();
  getColleagues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.getColleagues),
      switchMap(() =>
        this.store.pipe(
          select(selectUser),
          switchMap((user: IUser) => {
            if (!user.id) {
              return EMPTY;
            }

            return this.collectionUserAccessColleaguesService.getColleagues(user.id).pipe(
              takeUntil(this.stop$),
              map((users: IColleagues) => SharedActions.getColleaguesSuccess({users})),
              catchError((error: IError) => {
                const err: IError = {
                  status: error.status || 'Unknown Error',
                  httpResponseCode: error.httpResponseCode || 500,
                  error: error.error || error,
                  response: error.response || null,
                  request: error.request || null,
                };
                return of(SharedActions.getColleaguesFailure({error: err}));
              }),
            );
          }),
        ),
      ),
    ),
  );
  killColleaguesService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SharedActions.killColleaguesService),
        tap(() => {
          this.stop$.next();
        }),
      ),
    {dispatch: false},
  );

  constructor(
    private actions$: Actions,
    private collectionOperationalMessagesServiceService: CollectionOperationalMessagesServiceService,
    private collectionSharedSuppliersService: CollectionSharedSuppliersService,
    private collectionUserAccessColleaguesService: CollectionUserAccessColleaguesService,
    private collectionUsersMessagesService: CollectionUsersMessagesService,
    private storageService: StorageService,
    private readonly store: Store,
  ) {
  }

  killSubscription(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
