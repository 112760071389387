<div class="af-add-buttons">
  <ion-button (click)="onAddFilterClick()" fill="clear">
    <app-icon-plus [tooltip]="'Add Filter'"></app-icon-plus>
    Add Filter
  </ion-button>
  <ion-button (click)="onAddGroupClick()" fill="clear">
    <app-icon-playlist-plus [tooltip]="'Add Group'"></app-icon-playlist-plus>
    Add Group
  </ion-button>
  <ion-button (click)="onDeleteItemClick()" fill="clear">
    <app-icon-delete-outline [tooltip]="'Delete Group'"></app-icon-delete-outline>
    Delete Group
  </ion-button>
</div>

<div *ngIf="filterGroup?.filtersAndGroups?.length > 0" class="advanced-filter-group-container">

  <ng-container *ngFor="let group of filterGroup.filtersAndGroups; let i = index; trackBy: trackByFunction">

    <ng-container *ngIf="!getObjectHasProperty(group, 'operator')">
      <div class="item-filter-container">
        <app-shared-modal-filters-advanced-group-item
          (applyFilterToGrid)="triggerApplyFilterToSelectedGrid()"
          [setColDef]="colDefs"
          [setFilter]="group">
        </app-shared-modal-filters-advanced-group-item>
      </div>
    </ng-container>

    <ng-container
      *ngIf="getObjectHasProperty(group, 'operator')">
      <app-shared-modal-filters-advanced-group
        [predecessorID]="group.id"
        [setColDef]="colDefs"
        [setFilterGroups]="group">
      </app-shared-modal-filters-advanced-group>
    </ng-container>

    <div *ngIf="i + 1 < filterGroup.filtersAndGroups.length" class="advanced-filter-group-operator-buttons">
      <div
        (click)="onToggleOperator('AND')"
        [ngClass]="filterGroup.operator === 'AND' ? 'selected-button' : ''">
        <span>AND</span>
      </div>
      <div
        (click)="onToggleOperator('OR')"
        [ngClass]="filterGroup.operator === 'OR' ? 'selected-button' : ''">
        <span>OR</span>
      </div>
    </div>

  </ng-container>

</div>
