import {Component, Input, OnInit} from '@angular/core';
import {ModalController, PopoverController} from '@ionic/angular';
import {UserProfileConfigComponent} from '../user-profile-config/user-profile-config.component';
import {IUser} from '../../../../shared/shared-models/user-access/user';
import {FireAuthService} from '../../../../shared-utilities/services-old/fire-auth.service';
import {ISharedModalBasic} from '../../../../shared/shared-models/modals/shared-modal-basic';
import {Icons} from '../../../shared-icons/icons';
import {
  SharedModalBasicComponent
} from '../../../../shared/shared-components/components/shared-modal-basic/shared-modal-basic.component';
import {getImageURL} from '../../../../features-as-modules/feature-core/store/core.actions';
import {selectImageURL} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-menu-popup',
  templateUrl: './menu-popup.component.html',
  styleUrls: ['./menu-popup.component.scss'],
})
export class MenuPopupComponent implements OnInit {

  @Input() user: IUser;
  @Input() pp: string[];

  imageURL$: Observable<string>;
  imageUrl: string;

  constructor(
    private modalController: ModalController,
    private popoverController: PopoverController,
    private fireAuthService: FireAuthService,
    private readonly store: Store,
  ) {
  }

  ngOnInit(): void {
    const image = 'gallix_cloud_flow_chart.svg';
    this.store.dispatch(getImageURL({path: `/site_media/help/data_freshness/${image}`, image}));
    this.imageURL$ = this.store.select(selectImageURL(image));
    this.imageURL$.subscribe((image: string): void => {
      this.imageUrl = image;
    });
  }

  async onUserProfileClick(): Promise<void> {
    await this.popoverController.dismiss();
    const mc: HTMLIonModalElement = await this.modalController.create({
      component: UserProfileConfigComponent,
      cssClass: 'larger-modal',
    });
    await mc.present();
  }

  async onSignOutClick(): Promise<void> {
    await this.popoverController.dismiss();
    void this.fireAuthService.onSignOutRequest();
  }

  async onFreshnessExpClick(): Promise<void> {
    const componentProps: ISharedModalBasic = {
      buttonAccept: false,
      buttonAcceptText: '',
      buttonClose: true,
      buttonCloseCross: false,
      buttonCloseText: 'Close',
      modalTitle: 'Data Freshness Indicator Explanation',
      modalTitleIcon: Icons.infoSlabCircleOutline,
      modalTitleIconColor: 'yellow',
      contextHeading: 'NOTE: Explanation of the purpose and functionality of the coloured indicator dots.',
      contextDescription: [
        `This flow chart illustrates how Gallix interacts with your server and explains the meaning of the data freshness indicators.`,
        ``,
        `Remember, a red indicator doesn't necessarily mean your server is disconnected from Gallix; it just signals that the displayed data is outdated. Gallix remains usable, and any actions will process once the server is online.`,
        ``,
        `If the indicator is red and you need the latest stock data, try refreshing the page to trigger an update. If the indicator remains red for more than two minutes, please reach out to us.`,
        ``,
        `When the server is offline, your actions will queue and automatically process once the connection is restored.`,
      ],
      isImageVisible: true,
      imageUrl: this.imageUrl,
    };

    const modalController: HTMLIonModalElement = await this.modalController.create({
      component: SharedModalBasicComponent,
      componentProps: {
        ...componentProps,
        popoverReference: this.popoverController
      },
      cssClass: ['shared-basic-modal-css-larger'],
    });
    await modalController.present();
    await modalController.onDidDismiss();
  }
}
