<div id="progress-container">

  <ng-container *ngIf="!showCompletion; else completed">
    <div class="progress">
      <div class="header">
        {{ header }}
      </div>
      <div *ngIf="subHeader" class="sub-header">
        {{ subHeader }}
      </div>
      <div *ngIf="message" class="message">
        {{ message }}
      </div>
      <div class="progress-bar-wrapper">
        <ng-container *ngIf="!indeterminate; else showIndeterminate">
          <ion-progress-bar [value]="progress"></ion-progress-bar>
        </ng-container>
        <ng-template #showIndeterminate>
          <ion-progress-bar type="indeterminate"></ion-progress-bar>
        </ng-template>
        <i *ngIf="!indeterminate && showPercentage">{{ (progress * 100).toFixed(2) }}%</i>
      </div>
    </div>
  </ng-container>

  <ng-template #completed>
    <ng-container *ngIf="showCompletion === 'success'; else failed">
      <div class="complete">
        <div class="sub-header">
          {{ completion }}
        </div>
        <div class="buttons">
          <ion-button (click)="onClose()" color="light" size="small">
            {{ okButton }}
          </ion-button>
        </div>
      </div>
    </ng-container>
    <ng-template #failed>
      <div class="failed">
        <div class="sub-header">
          {{ completion }}
        </div>
        <div [class]="'buttons' + (retry ? ' bts2' : '')">
          <ion-button (click)="onClose()" color="light" size="small">
            {{ okButton }}
          </ion-button>
        </div>
      </div>
    </ng-template>
  </ng-template>

</div>
