import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {FirebaseService} from '../../../shared-utilities/services-old/firebase.service';
import {from, Observable, of} from 'rxjs';
import * as ShelfTalkerActions from './shelf-talkers.actions';
import {Action, select, Store} from '@ngrx/store';
import {catchError, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {selectSelectedUserStore} from '../../../features-as-modules/feature-core/store/core.selectors';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {TypeSenseSearchResponse} from '../../../shared/shared-models/type-sense/type-sense-types';
import {StockItem} from '../../../shared-utilities/models-old/datastructures';
import {mapTypeSenseObjectToGeneric} from '../../../shared/shared-utils/typesense-utils/typesense.utils';
import {IPaginationData} from '../../../shared/shared-models/pagination/pagination-data';
import {ISearchableFields} from '../../../shared/shared-models/type-sense/default-searchable-fields';
import {TypeSenseService} from '../../../shared/shared-services/type-sense/type-sense.service';
import {
  selectPrintOtherShelfTalkers,
  selectShelfTalkersPaginationData,
  selectShelfTalkersSortMethodForStoreId,
} from './shelf-talkers.selectors';
import {CollectionSharedStockService} from '../../../shared/shared-services/firebase/collection-shared-stock.service';
import {
  subQueryStockShelfTalkers,
  subQueryStockShelfTalkersExceptions,
} from '../../../shared-utilities/queries-old/sub-query-stock-2';

@Injectable()
export class ShelfTalkersEffects {

  // ====================================================================================================
  //
  // ====================================================================================================

  getTypesenseStockItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShelfTalkerActions.getShelfTalkerStockItems),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
      ),
      mergeMap(([_, store]) =>
        this.collectionSharedStockService.getStockItemsCast(store, subQueryStockShelfTalkers, 'objectID').pipe(
          map((stockItemsRaw: StockItem[]) => {
            const stockItems = stockItemsRaw
              .filter((item: StockItem) => !item._tags.includes('shelf_talker_exception'))
              .map((item: StockItem): StockItem => ({
                ...item,
                storeId: store.storeId,
                store: store.name,
              }));
            return ShelfTalkerActions.getShelfTalkerStockItemsSuccess({stockItems, store});
          }),
          catchError((error: IError) => {
            console.error(error);
            return of(ShelfTalkerActions.getShelfTalkerStockItemsFailure({error, store}));
          }),
        ),
      ),
    ),
  );

  getTypesenseStockItemsExceptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShelfTalkerActions.getShelfTalkersStockItemsExceptions),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
      ),
      mergeMap(([_, store]) =>
        this.collectionSharedStockService.getStockItemsCast(store, subQueryStockShelfTalkersExceptions, 'objectID').pipe(
          map((stockItemsRaw: StockItem[]) => {
            const stockItems = stockItemsRaw
              .map((item: StockItem): StockItem => ({
                ...item,
                storeId: store.storeId,
                store: store.name,
              }));
            return ShelfTalkerActions.getShelfTalkersStockItemsExceptionsSuccess({stockItems, store});
          }),
          catchError((error: IError) => {
            console.error(error);
            return of(ShelfTalkerActions.getShelfTalkersStockItemsExceptionsFailure({error, store}));
          }),
        ),
      ),
    ),
  );


  getOtherShelfTalkerStockItems$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ShelfTalkerActions.getOtherShelfTalkerStockItems,
        ShelfTalkerActions.getOtherItemsTypesenseShelfTalkersSearchResultsWithStoreId,
      ),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectPrintOtherShelfTalkers)),
      ),
      mergeMap(([_, store, {paginationData}]) =>
        this.typeSenseService.searchStockItems(
          paginationData?.searchedValue ?? '',
          1,
          paginationData?.pageSize ?? 20,
          store,
          {
            code: {value: true, name: 'Code'},
            desc: {value: true, name: 'Description'},
            suppCode: {value: true, name: 'suppCode'},
            barcode: {value: true, name: 'barcode'},
            binL: {value: true, name: 'binL'},
          },
        ).pipe(
          mergeMap((stockItemsRaw: TypeSenseSearchResponse<StockItem>) => {
            const updatedPaginationData: IPaginationData = {
              totalItems: stockItemsRaw.found,
              pageSize: paginationData?.pageSize ?? 20,
              currentPage: 1,
              searchedValue: paginationData?.searchedValue ?? '',
            };

            const mappedStockItems = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
            const stockItems = mappedStockItems.map((item: StockItem) => ({
              ...item,
              storeId: store.storeId,
              store: store.name,
            }));

            return [
              ShelfTalkerActions.getOtherShelfTalkerStockItemsSuccess({
                stockItems,
                paginationData: updatedPaginationData,
                store,
              }),
            ];
          }),
          catchError((error: IError) =>
            of(
              ShelfTalkerActions.getOtherShelfTalkerStockItemsFailure({
                error,
                store,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getPaginationResultsForAddStockItem$ = createEffect(() => this.actions$.pipe(
    ofType(ShelfTalkerActions.getPaginationResultsForShelfTalkerStockItem),
    withLatestFrom(
      this.store.pipe(select(selectSelectedUserStore)),
      this.store.pipe(select(selectShelfTalkersPaginationData)),
      this.store.pipe(select(selectShelfTalkersSortMethodForStoreId)),
    ),
    mergeMap(([{pageNumber}, store, paginationData, sortMethod]) =>
      from(this.typeSenseService.searchStockItems(
        paginationData?.searchedValue ?? '',
        pageNumber,
        paginationData?.pageSize ?? 20,
        store,
        {
          code: {value: true, name: 'Code'},
          desc: {value: true, name: 'Description'},
        },
        {
          ['_tags']: {
            ['in_shelf_talkers']: {name: 'in_shelf_talkers', value: true},
          },
        } as { [key: string]: ISearchableFields },
        sortMethod !== '' ? `${sortMethod}, 59:asc` : '59:asc',
      )).pipe(
        mergeMap((stockItemsRaw: TypeSenseSearchResponse<StockItem>) => {
          const paginationData: IPaginationData = {
            totalItems: stockItemsRaw.found,
            pageSize: stockItemsRaw?.request_params.per_page ?? 20,
            currentPage: pageNumber,
            searchedValue: '',
          };
          const mappedStockItems = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
          const stockItems = mappedStockItems.map((item: StockItem) => ({
            ...item,
            storeId: store.storeId,
            store: store.name,
          }));
          return [ShelfTalkerActions.getShelfTalkerStockItemsSuccess({
            stockItems,
            store,
          }),
          ];
        }),
        catchError((error: IError) => {
            return of(ShelfTalkerActions.getShelfTalkerStockItemsFailure({error, store}));
          },
        ),
      ),
    ),
  ));

  getOtherPaginationResultsForAddStockItem$ = createEffect(() => this.actions$.pipe(
    ofType(ShelfTalkerActions.getOtherPaginationResultsForShelfTalkerStockItem),
    withLatestFrom(
      this.store.pipe(select(selectSelectedUserStore)),
      this.store.pipe(select(selectPrintOtherShelfTalkers)),
    ),
    mergeMap(([{pageNumber}, store, {paginationData}]) =>
      from(this.typeSenseService.searchStockItems(
        paginationData?.searchedValue ?? '',
        pageNumber,
        paginationData?.pageSize ?? 20,
        store,
        {
          code: {value: true, name: 'Code'},
          desc: {value: true, name: 'Description'},
          suppCode: {value: true, name: 'suppCode'},
          barcode: {value: true, name: 'barcode'},
          binL: {value: true, name: 'binL'},
        },
      )).pipe(
        mergeMap((stockItemsRaw: TypeSenseSearchResponse<StockItem>) => {
          const paginationData: IPaginationData = {
            totalItems: stockItemsRaw.found,
            pageSize: stockItemsRaw?.request_params.per_page ?? 20,
            currentPage: pageNumber,
            searchedValue: stockItemsRaw?.request_params?.q ?? '',
          };
          const mappedStockItems = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
          const stockItems = mappedStockItems.map((item: StockItem) => ({
            ...item,
            storeId: store.storeId,
            store: store.name,
          }));
          return [ShelfTalkerActions.getOtherShelfTalkerStockItemsSuccess({
            stockItems,
            paginationData,
            store,
          }),
          ];
        }),
        catchError((error: IError) => {
            return of(ShelfTalkerActions.getOtherShelfTalkerStockItemsFailure({error, store}));
          },
        ),
      ),
    ),
  ));

  removeShelfTalkerTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShelfTalkerActions.removeShelfTalkerTag),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      switchMap(([{stockItems, updateStorage}, store]) => {
        if (Object.keys(stockItems).length > 0) {
          const updatedStockItems: { [code: string]: StockItem } = Object.values(stockItems).reduce((acc, item) => {
            acc[item.code] = {...item, ['_tags']: item._tags.filter(tag => tag !== 'in_shelf_talkers')};
            return acc;
          }, {} as { [code: string]: StockItem });
          const tagsMapping: { [code: string]: string[] } = Object.values(stockItems).reduce((acc, item) => {
            acc[item.code] = item._tags.filter(tag => tag !== 'in_shelf_talkers');
            return acc;
          }, {} as { [code: string]: string[] });
          return from(this.collectionSharedStockService.updateStockTags(tagsMapping, store)).pipe(
            map(() =>
              ShelfTalkerActions.removeShelfTalkerTagSuccess({
                removedStockItems: updatedStockItems,
                store,
                updateStorage,
              }),
            ),
            catchError((error: IError) => {
                console.error(error);
                return of(ShelfTalkerActions.removeShelfTalkerTagFailure({error, store}));
              },
            ),
          );
        } else {
          return of(ShelfTalkerActions.removeShelfTalkerTagSuccess({removedStockItems: {}, store, updateStorage}));
        }
      }),
    ),
  );

  removeShelfTalkerTagSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(ShelfTalkerActions.removeShelfTalkerTagSuccess),
        tap(({removedStockItems, store, updateStorage}) => {
          if (updateStorage) {
            const key = `printed-shelf-talkers-${store.storeId}`;
            const existing = localStorage.getItem(key);
            const existingData = existing ? JSON.parse(existing) : {};
            const timestamp = Date.now();
            const updatedItems: { [code: string]: StockItem & { printed: number } } = {};
            Object.keys(removedStockItems).forEach((stockId) => {
              updatedItems[stockId] = {
                ...removedStockItems[stockId],
                printed: timestamp,
              };
            });
            const mergedData = {...existingData, ...updatedItems};
            localStorage.setItem(key, JSON.stringify(mergedData));
          }
        }),
      ),
    {dispatch: false},
  );

  removeShelfTalkerTagException$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShelfTalkerActions.removeShelfTalkerExceptionsTag),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      switchMap(([{stockItems, updateStorage}, store]) => {
        if (Object.keys(stockItems).length > 0) {
          const updatedStockItems: { [code: string]: StockItem } = Object.values(stockItems).reduce((acc, item) => {
            acc[item.code] = {...item, ['_tags']: item._tags.filter((tag: string) => tag !== 'shelf_talker_exception')};
            return acc;
          }, {} as { [code: string]: StockItem });
          const tagsMapping: { [code: string]: string[] } = Object.values(stockItems).reduce((acc, item) => {
            acc[item.code] = item._tags.filter(tag => tag !== 'shelf_talker_exception');
            return acc;
          }, {} as { [code: string]: string[] });
          return from(this.collectionSharedStockService.updateStockTags(tagsMapping, store)).pipe(
            map(() =>
              ShelfTalkerActions.removeShelfTalkerExceptionsTagSuccess({
                removedStockItems: updatedStockItems,
                store,
                updateStorage,
              }),
            ),
            catchError((error: IError) => {
                console.error(error);
                return of(ShelfTalkerActions.removeShelfTalkerExceptionsTagFailure({error, store}));
              },
            ),
          );
        } else {
          return of(ShelfTalkerActions.removeShelfTalkerExceptionsTagSuccess({
            removedStockItems: {},
            store,
            updateStorage,
          }));
        }
      }),
    ),
  );

  addShelfTalkerExceptionsTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShelfTalkerActions.addShelfTalkerExceptionsTag),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      switchMap(([{stockItems}, store]) => {
        if (Object.keys(stockItems).length > 0) {
          const updatedStockItems: { [code: string]: StockItem } = Object.values(stockItems).reduce((acc, item) => {
            acc[item.code] = {...item, ['_tags']: [...item._tags, 'shelf_talker_exception']};
            return acc;
          }, {} as { [code: string]: StockItem });
          const tagsMapping: { [code: string]: string[] } = Object.values(stockItems).reduce((acc, item) => {
            acc[item.code] = [...item._tags, 'shelf_talker_exception'];
            return acc;
          }, {} as { [code: string]: string[] });
          return from(this.collectionSharedStockService.updateStockTags(tagsMapping, store)).pipe(
            map(() =>
              ShelfTalkerActions.addShelfTalkerExceptionsTagSuccess({
                store,
              }),
            ),
            catchError((error: IError) => {
                console.error(error);
                return of(ShelfTalkerActions.addShelfTalkerExceptionsTagFailure({error, store}));
              },
            ),
          );
        } else {
          return of(ShelfTalkerActions.addShelfTalkerExceptionsTagSuccess({
            store,
          }));
        }
      }),
    ),
  );


  setShelfTalkersSortMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShelfTalkerActions.setShelfTalkersSortMethod),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      switchMap(([{sortMethod}, store]) => {
        return of(ShelfTalkerActions.setShelfTalkersSortMethodSuccess({sortMethod, store}));
      }),
    ),
  );

  getTypesenseShelfTalkersSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShelfTalkerActions.getTypesenseShelfTalkersSearchResults),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectShelfTalkersPaginationData)),
      ),
      switchMap(([{idField}, store, paginationData]) => {
        const newPaginationData = {...paginationData, searchedValue: idField, currentPage: 1};
        return of(ShelfTalkerActions.getTypesenseShelfTalkersSearchResultsWithStoreId({
          paginationData: newPaginationData,
          store,
        }));
      }),
    ),
  );

  getOtherItemsTypesenseShelfTalkersSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShelfTalkerActions.getOtherItemsTypesenseShelfTalkersSearchResults),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectPrintOtherShelfTalkers)),
      ),
      switchMap(([{idField}, store, {paginationData}]) => {
        const newPaginationData = {...paginationData, searchedValue: idField, currentPage: 1};
        return of(ShelfTalkerActions.getOtherItemsTypesenseShelfTalkersSearchResultsWithStoreId({
          paginationData: newPaginationData,
          store,
        }));
      }),
    ),
  );

  setPageSizeForShelfTalkers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShelfTalkerActions.setPageSizeForShelfTalkers),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectShelfTalkersPaginationData)),
      ),
      map(([{pageSize}, store, {currentPage}]) => {
        return ShelfTalkerActions.setPageSizeForShelfTalkersAtStoreId({
          pageSize: pageSize,
          store: store,
          pageNumber: currentPage,
        });
      }),
    ),
  );

  setOtherPageSizeForShelfTalkers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShelfTalkerActions.setOtherPageSizeForShelfTalkers),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectPrintOtherShelfTalkers)),
      ),
      map(([{pageSize}, store, {paginationData}]) => {
        return ShelfTalkerActions.setOtherPageSizeForShelfTalkersAtStoreId({
          paginationData: {...paginationData, pageSize},
          store: store,
          pageNumber: paginationData?.currentPage ?? 1,
        });
      }),
    ),
  );


  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
    private typeSenseService: TypeSenseService,
    private collectionSharedStockService: CollectionSharedStockService,
    private readonly store: Store,
  ) {
  }
}
