import {createAction, props} from '@ngrx/store';
import {INotification} from '../models/notification';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {IAutoOrderOrder} from '../../../shared/shared-models/auto-ordering/auto-order-order';
import {TempStockUpdate} from "../components/notification-stock-update/notification-stock-update.component";

//#region GET NOTIFICATIONS PER STORE

/**
 * Action to fetch all notifications for user stores.
 * @property storeIds - The list of store IDs for which notifications are to be fetched.
 */
export const getAllNotificationsForUserStores = createAction(
  '[Notifications][Get Per User Stores] Get All Notifications For User Stores',
  props<{
    storeIds: string[],
  }>(),
);

/**
 * Action dispatched when fetching notifications for user stores succeeds.
 * @property storeIds - The list of store IDs for which notifications were fetched.
 * @property notifications - The list of notifications associated with the user stores.
 */
export const getAllNotificationsForUserStoresSuccess = createAction(
  '[Notifications][Get Per User Stores] Get All Notifications For User Stores Success',
  props<{
    storeIds: string[],
    notifications: INotification[]
  }>(),
);

/**
 * Action dispatched when fetching notifications for user stores fails.
 * @property error - The error that occurred during the fetching of notifications.
 */
export const getAllNotificationsForUserStoresFailure = createAction(
  '[Notifications][Get Per User Stores] Get All Notifications For User Stores Failure',
  props<{
    error: IError
  }>(),
);
//#endregion

//#region UPDATE NOTIFICATION
export const updateNotification = createAction(
  '[Notifications][Update] Update Notification',
  props<{
    notification: INotification,
  }>(),
);

export const updateNotificationSuccess = createAction(
  '[Notifications][Update] Update Notification Success',
);

export const updateNotificationFailure = createAction(
  '[Notifications][Update] Update Notification Failure',
  props<{
    error: IError
  }>(),
);

//#endregion

//#region GET NOTIFICATION AUTO ORDER
export const getNotificationAutoOrder = createAction(
  '[Notifications][Auto Order] Get Notification Auto Order',
  props<{
    notification: INotification,
  }>(),
);

export const getNotificationAutoOrderSuccess = createAction(
  '[Notifications][Auto Order] Get Notification Auto Order Success',
  props<{
    autoOrder: IAutoOrderOrder,
    notification: INotification,
  }>(),
);

export const getNotificationAutoOrderFailure = createAction(
  '[Notifications][Auto Order] Get Notification Auto Order Failure',
  props<{
    error: IError
    notification: INotification,
  }>(),
);
//#endregion

//#region CLEAR NOTIFICATION SECTION
export const clearNotificationSection = createAction(
  '[Notifications][Clear] Clear Notification Section',
  props<{
    clearErrors: boolean,
    clearNotifications: boolean
  }>(),
);
//#endregion

//#region GET NOTIFICATION STOCK UPDATE
export const getNotificationStockUpdate = createAction(
  '[Notifications][Stock Update] Get Notification Stock Update',
  props<{
    notification: INotification,
  }>(),
);

export const getNotificationStockUpdateSuccess = createAction(
  '[Notifications][Stock Update] Get Notification Stock Update Success',
  props<{
    // autoOrder: IAutoOrderOrder,
    notification: INotification,
    // stockUpdate: TempStockUpdate,
  }>(),
);

export const getNotificationStockUpdateFailure = createAction(
  '[Notifications][Stock Update] Get Notification Stock Update Failure',
  props<{
    error: IError
    notification: INotification,
    stockUpdate: TempStockUpdate,
  }>(),
);
//#endregion
