/**
 * Enum for application features.
 * Represents different features available in the application.
 *
 * @readonly
 * @enum {string}
 */
export enum ApplicationFeaturesEnum {
  /**
   * Feature for auto-ordering functionality.
   * @type {string}
   */
  AUTO_ORDERING = 'AUTO_ORDERING',

  /**
   * Feature for the community forum functionality.
   * @type {string}
   */
  COMMUNITY_FORUM = 'COMMUNITY_FORUM',

  /**
   * Feature for the dashboard functionality.
   * @type {string}
   */
  DASHBOARD = 'DASHBOARD',

  /**
   * Feature for dashboard report functionality.
   * @type {string}
   */
  DASHBOARD_REPORT = 'DASHBOARD_REPORT',

  /**
   * Feature for stock manager functionality.
   * @type {string}
   */
  STOCK_MANAGER = 'STOCK_MANAGER',
}

/**
 * Type representing the keys of the ApplicationFeaturesEnum.
 * This is a union type of all the string keys in the enum.
 *
 * @typedef {keyof typeof ApplicationFeaturesEnum} ApplicationFeatureKeys
 */
export type ApplicationFeatureKeys = keyof typeof ApplicationFeaturesEnum;

/**
 * Array of keys representing all the feature identifiers in the ApplicationFeaturesEnum.
 * These are the names of the enum members.
 *
 * @type {string[]}
 * @readonly
 */
export const FEATURES_KEYS = Object.keys(ApplicationFeaturesEnum);

/**
 * Array of values representing all the feature values in the ApplicationFeaturesEnum.
 * These are the string values assigned to each enum member.
 *
 * @type {string[]}
 * @readonly
 */
export const FEATURES_VALUES = Object.values(ApplicationFeaturesEnum);
