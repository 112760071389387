<div class="notification-auto-order-container">

  <ng-container
    *ngIf="getIsComponentLoading; else notificationAutoOrderLoading">

    <div class="header">Auto Order Information</div>


    <div class="content-container-main main-header">
      <div class="content-container">Supplier</div>
      <div class="content-container size-limit-200">Generated</div>
      <div class="content-container size-limit-150">Order #</div>

      <div class="content-container size-limit-50">
        <ng-container *ngTemplateOutlet="templateEmailStatus"></ng-container>
      </div>
      <div class="content-container size-limit-50">
        <ng-container *ngTemplateOutlet="templatePdfStatus"></ng-container>
      </div>
      <div class="content-container size-limit-50">
        <ng-container *ngTemplateOutlet="templateIQStatus"></ng-container>
      </div>

    </div>

    <ng-container *ngIf="getAutoOrder; else noOrderInformation">

      <div class="content-container-main">
        <div class="content-container">{{ getSupplierName }}</div>
        <div class="content-container size-limit-200">{{ getGeneratedDate }}</div>
        <div class="content-container size-limit-150">{{ getPurchaseOrderNo }}</div>

        <div class="content-container size-limit-50">
          <ng-container *ngTemplateOutlet="templateEmailVariations"></ng-container>
        </div>

        <div class="content-container size-limit-50">
          <ng-container *ngTemplateOutlet="templatePdfVariations"></ng-container>
        </div>

        <div class="content-container size-limit-50">
          <ng-container *ngTemplateOutlet="templateIQVariations"></ng-container>
        </div>

      </div>

      <div class="content-container-mobile">

        <div class="content-container">
          <div class="main-header">Supplier</div>
          <span></span>
          <div>
            {{ getSupplierName }}
          </div>
        </div>

        <div class="content-container">
          <div class="main-header">Generated</div>
          <span></span>
          <div>
            {{ getGeneratedDate }}
          </div>
        </div>

        <div class="content-container">
          <div class="main-header">Order #</div>
          <span></span>
          <div>
            {{ getPurchaseOrderNo }}
          </div>
        </div>

        <div class="content-container">
          <div class="main-header">
            <ng-container *ngTemplateOutlet="templateEmailStatus"></ng-container>
            Email Status
          </div>
          <span></span>
          <div>
            <ng-container *ngTemplateOutlet="templateEmailVariations"></ng-container>
          </div>
        </div>

        <div class="content-container">
          <div class="main-header">
            <ng-container *ngTemplateOutlet="templatePdfStatus"></ng-container>
            PDF Status
          </div>
          <span></span>
          <div>
            <ng-container *ngTemplateOutlet="templatePdfVariations"></ng-container>
          </div>
        </div>

        <div class="content-container">
          <div class="main-header">
            <ng-container *ngTemplateOutlet="templateIQStatus"></ng-container>
            IQ Status
          </div>
          <span></span>
          <div>
            <ng-container *ngTemplateOutlet="templateIQVariations"></ng-container>
          </div>
        </div>

      </div>

    </ng-container>

    <ng-template #noOrderInformation>
      <div class="content-container-main error">
        <div class="content-container">No Order Information Found, please contact support.</div>
      </div>
    </ng-template>

  </ng-container>
  <ng-template #notificationAutoOrderLoading>
    <div class="notification-loader">
      <app-shared-loading-indicator
        [animation]="notificationOrderLoading">
      </app-shared-loading-indicator>
    </div>

  </ng-template>


  <ng-template #templateEmailStatus>
    <app-display-icon
      [icon]="icons.emailVariant"
      [tooltip]="'Email Status'">
    </app-display-icon>
  </ng-template>

  <ng-template #templatePdfStatus>
    <app-display-icon
      [icon]="icons.pdfBox"
      [tooltip]="'PDF Status'">
    </app-display-icon>
  </ng-template>

  <ng-template #templateIQStatus>
    <app-display-icon
      [icon]="icons.logoIQ"
      [tooltip]="'IQ Status'">
    </app-display-icon>
  </ng-template>

  <ng-template #templateEmailVariations>
    <app-display-icon
      (click)="onTriggerAction(getAutoOrder, 'emailStatus')"
      [colour]="getStatusIconColour(getAutoOrder, 'emailStatus')"
      [icon]="getStatusIcon(getAutoOrder, 'emailStatus')"
      [tooltip]="getStatusTooltip(getAutoOrder, 'emailStatus')">
    </app-display-icon>
  </ng-template>

  <ng-template #templatePdfVariations>
    <app-display-icon
      (click)="onTriggerAction(getAutoOrder, 'pdfStatus')"
      [colour]="getStatusIconColour(getAutoOrder, 'pdfStatus')"
      [icon]="getStatusIcon(getAutoOrder, 'pdfStatus')"
      [tooltip]="getStatusTooltip(getAutoOrder, 'pdfStatus')">
    </app-display-icon>
  </ng-template>

  <ng-template #templateIQVariations>
    <app-display-icon
      (click)="onTriggerAction(getAutoOrder, 'iqStatus')"
      [colour]="getStatusIconColour(getAutoOrder, 'iqStatus')"
      [icon]="getStatusIcon(getAutoOrder, 'iqStatus')"
      [tooltip]="getStatusTooltip(getAutoOrder, 'iqStatus')">
    </app-display-icon>
  </ng-template>

</div>

