import {createReducer, on} from '@ngrx/store';
import * as StoreSettingsActions from './settings.actions';
import {IInitialState} from '../../../shared-utilities/models-old/initial-state/initial-state';
import {LineColour, TableNavSettings} from '../../../shared-utilities/models-old/datastructures';
import {IEmailSettings, IStoreSettings, IUserSettings} from '../models/settings-models';

/**
 * TODO TECHNICAL DEBT export interface SettingsState extends IInitialState {}
 * This needs to be looked at - cleaned and sorted
 * Also there are various actions, we can add a loading interface here to toggle each settings loading separate
 *
 */
export interface SettingsState extends IInitialState {
  storeSettings: { [storeId: string]: IStoreSettings };
  isSubmittingStoreSettings: boolean;
  userSettings: IUserSettings;
  isUserEmailSettingsLoading: boolean;
  isSubmittingEmailSettings: boolean;
  usersEmailSettings: { [storeId: string]: { [userId: string]: IEmailSettings } };
  isSelectedUserEmailSettingsLoading: boolean;
  selectedUserEmailSettings: IEmailSettings;
  allTags: { [storeId: string]: { name: string, global: boolean }[] };
}

export const initialOptionsState: SettingsState = {
  errors: [],
  storeSettings: {},
  isSubmittingStoreSettings: false,
  userSettings: {} as IUserSettings,
  isUserEmailSettingsLoading: false,
  isSubmittingEmailSettings: false,
  usersEmailSettings: {},
  isSelectedUserEmailSettingsLoading: false,
  selectedUserEmailSettings: {} as IEmailSettings,
  allTags: {},
};

export const SettingsReducer = createReducer(
  initialOptionsState,

  // ===========================================================================
  // get NGP Report Col Preview (Success)
  // ===========================================================================
  on(StoreSettingsActions.getNGPReportColPreviewSuccess, (state, {store, colPreview}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      ngpReportColPreviewSettings: colPreview,
    },
  })),

  // ===========================================================================
  // get NGP Report Col Preview (Failure)
  // ===========================================================================
  on(StoreSettingsActions.getNGPReportColPreviewFailure, (state, {errors, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      [store.storeId]: {
        ...state.userSettings[store.storeId],
        ngpReportColPreviewSettings: null,
      },
    },
    errors: [...state.errors, ...errors],
  })),

  // ===========================================================================
  // set NGP Report Preview Columns
  // ===========================================================================
  on(StoreSettingsActions.setNGPReportColPreviewSettingsSuccess, (state, {columnData, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      ngpReportColPreviewSettings: columnData,
    },
  })),

  on(StoreSettingsActions.setNGPReportColPreviewSettingsFailure, (state, {error, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      ngpReportColPreviewSettings: {},
    },
    errors: [...state.errors, error],
  })),

  // ===========================================================================
  // get Table Settings (Success & Failure)
  // ===========================================================================
  on(StoreSettingsActions.setTableNavSettingsSuccess, (state, {settings, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      tableNavigationSettings: settings,
    },
  })),
  on(StoreSettingsActions.getTableSettingsSuccess, (state, {tableNavSettings, fitToGrid, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      tableNavigationSettings: tableNavSettings,
      fitToGrid: fitToGrid,
    },
  })),

  on(StoreSettingsActions.setTableNavSettingsFailure, (state, {error, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      tableNavigationSettings: {} as TableNavSettings,
    },
    errors: [...state.errors, error],
  })),

  on(StoreSettingsActions.setFitToGridSuccess, (state, {fitToGrid}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      fitToGrid: fitToGrid
    },
  })),


  on(StoreSettingsActions.toggleFitToGrid, (state) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      fitToGrid: !state.userSettings.fitToGrid,
    },
  })),

  // ===========================================================================
  // get Disabled Rules (Success & Failure)
  // ===========================================================================
  on(StoreSettingsActions.getItemDisablingRulesSuccess, (state, {rulesDoc, store}) => ({
    ...state,
    storeSettings: {
      ...state.storeSettings,
      [store.storeId]: {
        ...state.storeSettings[store.storeId],
        disabledRules: rulesDoc,
      },
    },
  })),

  on(StoreSettingsActions.getItemDisablingRulesFailure, (state, {error, store}) => ({
    ...state,
    storeSettings: {
      ...state.storeSettings,
      [store.storeId]: {
        ...state.storeSettings[store.storeId],
        disabledRules: {},
      },
    },
    errors: [...state.errors, error],
  })),

  // ===========================================================================
  // get Line Colour (Success & Failure)
  // ===========================================================================
  on(StoreSettingsActions.getLineColourSuccess, (state, {colour, store}) => ({
    ...state,
    storeSettings: {
      ...state.storeSettings,
      [store.storeId]: {
        ...state.storeSettings[store.storeId],
        lineColour: colour,
      },
    },
  })),

  on(StoreSettingsActions.getLineColourFailure, (state, {error, store}) => ({
    ...state,
    storeSettings: {
      ...state.storeSettings,
      [store.storeId]: {
        ...state.storeSettings[store.storeId],
        lineColour: {} as LineColour,
      },
    },
    errors: [...state.errors, error],
  })),
  // ===========================================================================
  // get Stock Manager Column Preview Settings (Success & Failure)
  // ===========================================================================
  on(StoreSettingsActions.getStockManagerColPreviewSettingsSuccess, (state, {colPreview, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      stockManagerColPreviewSettings: colPreview,
    },
  })),

  on(StoreSettingsActions.setNGPReportColPreviewSettingsFailure, (state, {error}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      ngpReportColPreviewSettings: {},
    },
    errors: [...state.errors, error],
  })),

  // ===========================================================================
  // set Stock Manager Preview Columns
  // ===========================================================================
  on(StoreSettingsActions.setStockManagerPreviewColumnsSuccess, (state, {columnData, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      stockManagerColPreviewSettings: columnData,
    },
  })),

  on(StoreSettingsActions.setStockManagerPreviewColumnsFailure, (state, {error, store}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      stockManagerColPreviewSettings: {},
    },
    errors: [...state.errors, error],
  })),
  //#region get Stock Edit Threshold
  // ===========================================================================
  // get Stock Edit Threshold
  // ===========================================================================
  on(StoreSettingsActions.getStockEditThresholdsSuccess, (state, {thresholds, storeId}) => ({
    ...state,
    storeSettings: {
      ...state.storeSettings,
      [storeId]: {
        ...state.storeSettings[storeId],
        priceThresholds: thresholds,
      }
    }
  })),
  on(StoreSettingsActions.getStockEditThresholdsFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
  })),
  //#endregion
  //#region Get Price bands
  // ===========================================================================
  // Get Price bands
  // ===========================================================================
  on(StoreSettingsActions.getPriceBandsSuccess, (state, {priceBands, storeId}) => ({
    ...state,
    storeSettings: {
      ...state.storeSettings,
      [storeId]: {
        ...state.storeSettings[storeId],
        priceBands,
      }
    }
  })),
  on(StoreSettingsActions.getPriceBandsFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
  })),
  //#endregion
  // ===========================================================================
  // get All User Email Settings
  // ===========================================================================
  on(StoreSettingsActions.getUserEmailSettings, (state) => ({
    ...state,
    isSelectedUserEmailSettingsLoading: true,
    selectedUserEmailSettings: {} as IEmailSettings,
  })),
  on(StoreSettingsActions.getUserEmailSettingsSuccess, (state, {settings}) => ({
    ...state,
    isSelectedUserEmailSettingsLoading: false,
    selectedUserEmailSettings: settings
  })),
  on(StoreSettingsActions.getUserEmailSettingsFailure, (state, {error}) => ({
    ...state,
    isSelectedUserEmailSettingsLoading: false,
    selectedUserEmailSettings: {} as IEmailSettings,
    errors: [...state.errors, error],
  })),
  on(StoreSettingsActions.getAllUserEmailSettings, (state) => ({
    ...state,
    isUserEmailSettingsLoading: true,
  })),
  on(StoreSettingsActions.getAllUserEmailSettingsSuccess, (state, {usersEmailSettings, store}) => ({
    ...state,
    usersEmailSettings: {
      ...state.usersEmailSettings,
      [store.storeId]: usersEmailSettings,
    },
    isUserEmailSettingsLoading: false,
  })),
  on(StoreSettingsActions.getAllUserEmailSettingsFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
    isUserEmailSettingsLoading: false,
  })),
  on(StoreSettingsActions.testEmail, (state) => ({
    ...state,
    isUserEmailSettingsLoading: true,
    isSubmittingEmailSettings: true,
  })),
  on(StoreSettingsActions.testEmailSuccess, (state) => ({
    ...state,
    isUserEmailSettingsLoading: false,
    isSubmittingEmailSettings: false,
  })),
  on(StoreSettingsActions.testEmailFailure, (state) => ({
    ...state,
    isUserEmailSettingsLoading: false,
    isSubmittingEmailSettings: false,
  })),
  on(StoreSettingsActions.getStockItemTagsSuccess, (state, {tags, store}) => ({
    ...state,
    allTags: {
      ...state.allTags,
      [store.storeId]: tags,
    },
  })),
  on(StoreSettingsActions.getStockItemTagsFailure, (state, {error}) => ({
    ...state,
    errors: [
      ...state.errors,
      error,
    ],
  })),
  on(StoreSettingsActions.setStockItemTagsSuccess, (state, {tag, store}) => ({
    ...state,
    allTags: {
      ...state.allTags,
      [store.storeId]: [
        ...(state.allTags[store.storeId] ?? []),
        {name: tag, global: false},
      ],
    },
  })),
  on(StoreSettingsActions.deleteStockItemTagsSuccess, (state, {tag, store}) => ({
    ...state,
    allTags: {
      ...state.allTags,
      [store.storeId]: (state.allTags[store.storeId] ?? []).filter(
        (tags: { name: string; global: boolean; }) => tags.name !== tag,
      ),
    },
  })),
  on(StoreSettingsActions.deleteStockItemTagsFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
  })),
  //#region User Settings
  on(StoreSettingsActions.updateAllUserSettings, (state) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      isSubmitting: true
    }
  })),
  on(StoreSettingsActions.updateAllUserSettingsSuccess, (state, {
    fitToGrid,
    ngpPreviewColumns,
    stockPreviewColumns,
    tableNavSettings
  }) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      fitToGrid,
      ngpReportColPreviewSettings: ngpPreviewColumns,
      stockManagerColPreviewSettings: stockPreviewColumns,
      tableNavigationSettings: tableNavSettings,
      isSubmitting: false,
    },
  })),
  on(StoreSettingsActions.updateAllUserSettingsFailure, (state, {error}) => ({
    ...state,
    userSettings: {
      ...state.userSettings,
      isSubmitting: false
    },
    errors: [...state.errors, error]
  })),
  //#endregion
  //#region On Hand Settings
  on(StoreSettingsActions.updateOnHandSettings, (state) => ({
    ...state,
    isSubmittingStoreSettings: true
  })),
  on(StoreSettingsActions.updateOnHandSettingsSuccess, (state) => ({
    ...state,
    isSubmittingStoreSettings: false
  })),
  on(StoreSettingsActions.updateOnHandSettingsFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
    isSubmittingStoreSettings: false
  })),
  //#endregion
  //#region Stock Edit Thresholds
  on(StoreSettingsActions.updateStockEditThresholds, (state) => ({
    ...state,
    isSubmittingStoreSettings: true
  })),
  on(StoreSettingsActions.updateStockEditThresholdsSuccess, (state) => ({
    ...state,
    isSubmittingStoreSettings: false
  })),
  on(StoreSettingsActions.updateStockEditThresholdsFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
    isSubmittingStoreSettings: false
  })),
  //#endregion
);
