import {Component, Input, OnInit} from '@angular/core';
import {ModalController, PopoverController} from '@ionic/angular';
import {Icons} from "../../../shared-icons/icons";

export interface SelectModifyButton {
  text: string;
  cssClass?: string;
  name?: string;

  handler(checks: { [sKey: string]: boolean }, disabled?: string[]): { [sKey: string]: boolean };
}

export interface SelectMainButton {
  text: string;
  cssClass?: string;
  name?: string;

  handler(selection: string[]): void;
}

@Component({
  selector: 'app-select-popover',
  templateUrl: './select-popover.component.html',
  styleUrls: ['./select-popover.component.scss']
})
export class SelectPopoverComponent implements OnInit {
  @Input() title?: string;
  @Input() selection: { [key: string]: any };
  @Input() selectionClasses?: { [key: string]: string | string[] };
  @Input() selectionStyles?: { [key: string]: string };
  @Input() value?: string[] | string;
  @Input() order?: string[];
  @Input() multiple = false;
  @Input() search?: boolean;
  @Input() disabled?: string[];
  @Input() disabledTo: null | undefined | 'top' | 'bottom';
  @Input() selectAll = false;
  @Input() maxListHeight? = 195;
  @Input() minListHeight? = 190;
  @Input() selectModButtons?: SelectModifyButton[];
  @Input() additionalMainButton?: SelectMainButton;
  @Input() cancelText = 'CANCEL';
  @Input() okText = 'OK';

  @Input() isDeleteVisible = false;
  @Input() isAdditionalIconVisible = false;
  @Input() additionalIcon: Icons;
  @Input() selectionToPlaceIcons: { [key: string]: any };
  @Input() deleteHandler: (keyToDelete: string) => void;

  // TODO: This is temporary for the header height thing
  @Input() asModal?: boolean;
  allSelected = false;

  checks: { [sKey: string]: boolean } = {};
  checked: string;

  classes: { [key: string]: string } = {};
  protected readonly icons = Icons;
  private orderBU: string[];
  private controller: PopoverController | ModalController;

  constructor(
    private popoverControl: PopoverController,
    private modalController: ModalController,
  ) {
  }

  ngOnInit(): void {
    const allSKeysSorted = Object.keys(this.selection).sort();

    if (this.order) {
      const missing = allSKeysSorted.filter((sKey) => !this.order.includes(sKey));

      if (missing.length) {
        this.order = this.order.concat(missing);
      }
    } else {
      this.order = allSKeysSorted;
    }
    this.fillInChecks();
    this.search = this.search === undefined ? (this.order.length >= 15) : this.search;
    this.disabled = this.disabled ? this.disabled : [];
    this.moveDisabled();

    if (this.search) {
      this.orderBU = this.order.map(a => a);
    }

    for (const sKey of this.order) {
      this.classes[sKey] = 'select-option';

      if (this.selectionClasses?.[sKey]) {
        if (typeof this.selectionClasses[sKey] === 'string') {
          this.classes[sKey] += ' ' + this.selectionClasses[sKey];
        } else if (this.selectionClasses.hasOwnProperty(sKey)) {
          this.classes[sKey] += ' ' + (this.selectionClasses[sKey] as string[]).reduce((p, c) => p + ' ' + c);
        }
      }
    }

    if (this.value) {
      if (this.multiple) {
        this.value = typeof this.value === 'string' ? [this.value] : this.value;
        for (const sKey of this.value) {
          this.checks[sKey] = true;
        }
        if (this.value.length === this.order.length) {
          this.allSelected = true;
        }
      } else {
        this.value = typeof this.value === 'string' ? this.value : this.value[0];
        this.checked = this.value;
      }
    }

    if (!this.multiple) {
      this.selectAll = false;
    }

    this.controller = this.asModal ? this.modalController : this.popoverControl;

    const content = document.querySelector('.variable-list-div');
    const minHeightStyle = `min-height:${this.minListHeight}px !important;`;
    const maxHeightStyle = `max-height:${this.maxListHeight}px !important;`;
    content.setAttribute('style', `${minHeightStyle} ${maxHeightStyle}`);
  }

  selectSKey(sKey: string): void {
    if (this.disabled.includes(sKey)) {
      return;
    }
    if (this.multiple) {
      this.checks[sKey] = this.checks.hasOwnProperty(sKey) ? !this.checks[sKey] : true;

      if (this.checks[sKey]) {

        for (const sk of (this.orderBU ? this.orderBU : this.order)) {
          if (!this.checks[sk]) {
            return;
          }
        }
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    } else {
      this.checked = sKey;
    }
  }

  toggleAll(): void {
    let setValue = false;

    for (const sKey of this.order) {
      if (!this.checks[sKey] && !this.disabled.includes(sKey)) {
        setValue = true;
        break;
      }
    }

    for (const sKey of this.order) {
      if (!this.disabled.includes(sKey)) {
        this.checks[sKey] = setValue;
      }
    }
    this.allSelected = setValue;
  }

  onCancel(): void {
    void this.controller.dismiss(null, 'cancel').then();
  }

  onSubmit(): void {
    if (this.multiple) {
      const orderCheck: string[] = this.orderBU ? this.orderBU : this.order;
      if (this.allSelected) {
        const disable: string[] = [];
        this.disabled.forEach((d: string): void => {
          if (!this.value.includes(d)) {
            disable.push(d);
          }
        });
        void this.controller.dismiss(
          orderCheck.filter((sKey: string) => !disable.includes(sKey)),
          'ok-all'
        ).then();
      }
      let selection = [];
      for (const sKey of orderCheck) {
        if (this.checks[sKey]) {
          selection = selection.concat(sKey);
        }
      }
      void this.controller.dismiss(selection, 'ok').then();
    } else {
      void this.controller.dismiss(this.checked, 'ok').then();
    }
  }

  selectionModHandle(handler: (checks: { [sKey: string]: boolean }, disabled?: string[]) => {
    [sKey: string]: boolean;
  }): void {
    const checks = handler(this.checks, this.disabled);

    if (this.multiple) {
      this.allSelected = true;
      for (const sKey of Object.keys(checks)) {
        if (this.checks.hasOwnProperty(sKey)) {
          this.checks[sKey] = checks[sKey];
          if (!this.checks[sKey] && this.allSelected) {
            this.allSelected = false;
          }
        } else {
          console.error(`Key '${sKey}' not in original selection keys`);
        }
      }

    } else {
      const check = Object.keys(checks).filter(k => checks[k]);
      if (check.length > 1) {
        console.error('Multiple items set to true. Multiple selection is false.');
      }
      this.checked = check.length === 1 ? check[0] : null;
    }
  }

  async additionalHandle(handler: (selection: string[]) => void): Promise<void> {
    let selection = [];

    for (const sKey of (this.orderBU ? this.orderBU : this.order)) {
      if (this.checks[sKey]) {
        selection = selection.concat(sKey);
      }
    }
    handler(selection);
    void this.controller.dismiss().then();
  }

  searchOptions(event): void {
    let subStr = event.target.value;

    if (subStr) {
      subStr = subStr.toLowerCase();
      this.order = this.orderBU.filter(
        a => a.toLowerCase().includes(subStr) || (this.selection[a] as string).toLowerCase().includes(subStr)
      );
    } else {
      this.order = this.orderBU.map(a => a);
    }
  }

  trackByFunction(index: number): number {
    return index;
  }

  onDeleteClick(keyToDelete: string): void {
    delete this.selection[keyToDelete];
    const orderFindIndex = this.order.findIndex((key: string): boolean => key.toLowerCase() === keyToDelete.toLowerCase());
    if (orderFindIndex > -1) {
      this.order.splice(orderFindIndex, 1);
    }
    if (typeof this.value !== 'string') {
      const valueFindIndex = this.value.findIndex((key: string): boolean => key.toLowerCase() === keyToDelete.toLowerCase());
      if (valueFindIndex > -1) {
        this.value.splice(orderFindIndex, 1);
      }
    }
    this.deleteHandler(keyToDelete);
  }

  private fillInChecks(fill: boolean = false): void {
    const allKeys = this.orderBU ? this.orderBU : this.order;
    const toFill = allKeys.filter((sKey) => !this.checks.hasOwnProperty(sKey));

    toFill.forEach((sKey) => (this.checks[sKey] = fill));
  }

  private moveDisabled(): void {
    if (this.disabled.length === 0 || !this.disabledTo) {
      return;
    }
    this.disabled.sort((a, b) => this.order.indexOf(a) - this.order.indexOf(b));
    this.disabled.forEach((key) => this.order.splice(this.order.indexOf(key), 1));

    switch (this.disabledTo.toLowerCase()) {
      case 'top':
        this.order = this.disabled.concat(this.order);
        break;
      case 'bottom':
        this.order = this.order.concat(this.disabled);
        break;
      default:
        throw Error(`disabledTo invalid value: ${this.disabledTo}`);
    }
  }

}
