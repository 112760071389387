import {DisabledRules, StockItem} from '../../../shared-utilities/models-old/datastructures';
import {StockFunctions} from '../../../shared-utilities/functions-old/stock-functions';

/**
 * Adds an `originalValue` field to each item in the array, containing the original values of its properties.
 *
 * @template ObjectType - The type of objects in the array.
 *
 * @param {ObjectType[]} items - An array of objects to which the `originalValue` field will be added.
 *
 * @returns {(ObjectType & WithOriginalValue<ObjectType>)[]} An array of objects where each object has the `originalValue` field added.
 */
export function addOriginalValueFieldToItems<ObjectType>(items: ObjectType[]): (ObjectType & WithOriginalValue<ObjectType>)[] {
  return items.map((item: ObjectType): ObjectType & WithOriginalValue<ObjectType> => {
    const originalValue = {} as OriginalValue<ObjectType>;
    (Object.keys(item) as (keyof ObjectType)[]).forEach((property: keyof ObjectType): void => {
      originalValue[property] = {value: item[property] ?? 0} as unknown as OriginalValue<ObjectType>[keyof ObjectType];
    });

    originalValue['_tags'] = {value: item['_tags'] ?? []};
    originalValue['multipleSuppliers'] = {value: item['multipleSuppliers'] ?? []};
    return {...item, originalValue};
  });
}

/**
 * Interface representing an object that contains the original value of its properties.
 *
 * @interface WithOriginalValue
 * @template ValueType - The type of the value object whose properties will have original values.
 *
 * @property {OriginalValue<ValueType>} originalValue - The object containing original values for each property.
 */
interface WithOriginalValue<ValueType> {
  /** @member {OriginalValue<ValueType>} originalValue - The object containing original values for each property. */
  originalValue: OriginalValue<ValueType>;
}

/**
 * Type representing an object with original values for each of its properties.
 * Each property is wrapped in an object with a `value` field, and a `newlyAdded` field is also included.
 *
 * @template ObjectType - The type of the original object.
 *
 * @typedef {Object} OriginalValue
 * @property {{ value: ObjectType[Property] }} [Property] - Each property of the original object wrapped with a `value` field.
 * @property {{ value: boolean }} newlyAdded - Indicates whether the property was newly added.
 */
export type OriginalValue<ObjectType> = {
  [Property in keyof ObjectType]: { value: ObjectType[Property] };
} & {
  /** @member {{ value: boolean }} newlyAdded - A boolean indicating if the value was newly added. */
  newlyAdded: { value: boolean };
};

export const checkForDisabledItems = (stockItems: StockItem[], rulesDoc: DisabledRules, setOriginalValue?: boolean): StockItem[] => {

  stockItems.forEach((item: StockItem) => {
    item.disabled = StockFunctions.isDisabled(item, rulesDoc);
    if (setOriginalValue) {
      item.originalValue['disabled'] = {value: item.disabled};
    }
  });
  return stockItems;
};
