<span class="sub-heading">Action when on hand (stock) <b>is</b> zero</span>

<app-setting-section
  (textValueChange)="onOnHandZeroDescPrefixChange($event)"
  [textValue]="onHandZero.descPrefix"
  optionType="text"
  placeholder=""
  slug="onHandZeroDescriptionPrefix"
>
  <span textTitle>Description Prefix:</span>
</app-setting-section>

<app-setting-section
  (textValueChange)="onOnHandZeroDescSuffixChange($event)"
  [textValue]="onHandZero.descSuffix"
  optionType="text"
  placeholder=""
  slug="onHandZeroDescriptionSuffix"
>
  <span textTitle>Description Suffix:</span>
</app-setting-section>

<app-setting-section
  (selectedOptionChange)="onOnHandZeroLineColourChange($event)"
  [selectOptions]="onHandZero.lineColour"
  [selectedOption]="onHandZero.selectedLineColour"
  optionType="select"
  slug="onHandZeroLineColour"
>
  <span selectTitle>Line Colour:</span>
</app-setting-section>

<app-setting-section
  (multiSelectValuesChange)="onOnHandZeroOnHoldTypeChange($event)"
  [multiSelectValues$]="onHandZeroOnHoldType$"
  optionType="select-multiple"
  slug="onHandZeroOnHoldType"
>
  <span selectMultipleTitle>On Hold Type:</span>
</app-setting-section>

<hr />

<span class="sub-heading">Action when on hand (stock) is <b>not</b> zero</span>

<app-setting-section
  (textValueChange)="onOnHandNotZeroDescPrefixChange($event)"
  [textValue]="onHandNotZero.descPrefix"
  optionType="text"
  placeholder=""
  slug="onHandNotZeroDescriptionPrefix"
>
  <span textTitle>Description Prefix:</span>
</app-setting-section>

<app-setting-section
  (textValueChange)="onOnHandNotZeroDescSuffixChange($event)"
  [textValue]="onHandNotZero.descSuffix"
  optionType="text"
  placeholder=""
  slug="onHandNotZeroDescriptionSuffix"
>
  <span textTitle>Description Suffix:</span>
</app-setting-section>

<app-setting-section
  (selectedOptionChange)="onOnHandNotZeroLineColourChange($event)"
  [selectOptions]="onHandNotZero.lineColour"
  [selectedOption]="onHandNotZero.selectedLineColour"
  optionType="select"
  slug="onHandNotZeroLineColour"
>
  <span selectTitle>Line Colour:</span>
</app-setting-section>

<app-setting-section
  (multiSelectValuesChange)="onOnHandNotZeroOnHoldTypeChange($event)"
  [multiSelectValues$]="onHandNotZeroOnHoldType$"
  optionType="select-multiple"
  slug="onHandNotZeroOnHoldType"
>
  <span selectMultipleTitle>On Hold Type:</span>
</app-setting-section>

<div class="save-button-container">
  <button (click)="onOnHandSave($event)" type="button">Save</button>
</div>
