import {IDataTimeStamps, IFirebaseDataTimeStamps} from '../../../features/core/models/data-time-stamps';
import {firebaseTimeStampToDate} from '../../../shared/shared-utils/firestore/firestore.utils';

export function convertIDataTimeStampsToDates(dataTimeStamps: IFirebaseDataTimeStamps | undefined): IDataTimeStamps | undefined {
  if (!dataTimeStamps) {
    return undefined;
  }

  return {
    auto_order_export: firebaseTimeStampToDate(dataTimeStamps.auto_order_export),
    heartbeat: firebaseTimeStampToDate(dataTimeStamps.heartbeat),
    info_tables: firebaseTimeStampToDate(dataTimeStamps.info_tables),
    multiple_suppliers: firebaseTimeStampToDate(dataTimeStamps.multiple_suppliers),
    reporting: firebaseTimeStampToDate(dataTimeStamps.reporting),
    shelf_talkers: firebaseTimeStampToDate(dataTimeStamps.shelf_talkers),
    stock: firebaseTimeStampToDate(dataTimeStamps.stock),
    suppliers: firebaseTimeStampToDate(dataTimeStamps.suppliers),
    transactions: firebaseTimeStampToDate(dataTimeStamps.transactions),
    units_sold: firebaseTimeStampToDate(dataTimeStamps.units_sold),
  };
}
