import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest} from 'rxjs';
import {setUserSelectedStoreAndGetSettings} from 'src/app/features-as-modules/feature-core/store/core.actions';
import {selectUserAccess, selectUserStores} from 'src/app/features-as-modules/feature-core/store/core.selectors';
import {IStore} from 'src/app/shared/shared-models/store/store';

/**
 * TODO TECHNICAL DEBT export class NoAccessSettingsComponent {}
 * This needs to change to have subscriptions in the OnInit only
 * Also unsubscribe from the subscription or use either ngrxPush or async for subscriptions
 */
@Component({
  selector: 'app-no-access-settings',
  templateUrl: './no-access-settings.component.html',
  styleUrls: ['./no-access-settings.component.scss']
})
export class NoAccessSettingsComponent {

  protected allowedStores: IStore[] = [];

  constructor(
    private readonly store: Store
  ) {
    combineLatest([
      this.store.select(selectUserStores),
      this.store.select(selectUserAccess)
    ]).subscribe(([stores, userAccess]) => {
      this.allowedStores = stores.filter(store => userAccess.adminAccessStores.includes(store.storeId));
    })
  }

  protected trackByStoreId(_: number, store: IStore): string {
    return store.storeId;
  }

  protected onStoreSelect(storeId: IStore['storeId']): void {
    const store = this.allowedStores.find(store => store.storeId === storeId) || this.allowedStores[0];
    this.store.dispatch(setUserSelectedStoreAndGetSettings({selectedStore: store}));
  }
}
