import {Component, EventEmitter, Input, Optional, Output} from '@angular/core';
import {IonRouterOutlet, ModalController, PopoverController} from '@ionic/angular';
import {IUser} from '../../../../shared/shared-models/user-access/user';
import {Icons} from '../../../../shared-modules/shared-icons/icons';
import {MenuPopupComponent} from '../../../../shared-modules/shared-module/components/menu-popup/menu-popup.component';
import {
  MessagesModalPage,
} from '../../../../shared-modules/shared-module/components/messages-modal/messages-modal.page';
import {
  NavigableModalComponent,
} from '../../../../shared-modules/shared-module/components/navigable-modal/navigable-modal.component';
import {
  ApplicationSettingsComponent,
} from '../../../../shared-modules/shared-module/components/application-settings/application-settings.component';
import {IUserAccess} from '../../../../shared/shared-models/user-access/user-access';
import {INotificationTotals} from '../../../../features/notifications/models/notification';
import {
  NotificationModalContainerComponent
} from '../../../../features/notifications/components/notification-modal-container/notification-modal-container.component';
import {clearNotificationSection} from '../../../../features/notifications/store/notification.actions';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {

  @Input() title = '';
  @Input() user: IUser;
  @Input() userAccess: IUserAccess;

  @Output() readonly showSideNav: EventEmitter<string> = new EventEmitter<string>();

  icons: typeof Icons = Icons;
  totalNotifications: INotificationTotals = {
    total: 0,
    unread: 0,
    read: 0
  }

  constructor(
    private modalController: ModalController,
    private popoverController: PopoverController,
    private readonly store: Store,
    @Optional() private ionRouterOutlet: IonRouterOutlet,
  ) {
  }

  get getUserStoreListCount(): boolean {
    return this.userAccess?.storeList?.length ? this.userAccess.storeList.length > 0 : false;
  }

  get getFirstLetter(): string {
    return this.user.userName ? this.getUserName.charAt(0).toUpperCase() : 'M';
  }

  get getProfilePicture(): string {
    return this.user?.pp ? this.user?.pp : this.getFirstLetter;
  }

  get getUserName(): string {
    return this.user?.userName ? this.user.userName : null;
  }

  @Input() set setNotificationTotals(totals: INotificationTotals) {
    this.totalNotifications = totals;
  }

  expandMenu(event: Event): void {
    event.stopPropagation();
    this.showSideNav.emit('expand');
  }

  async viewNotifications(): Promise<void> {
    const modalController: HTMLIonModalElement = await this.modalController.create({
      component: NotificationModalContainerComponent,
      cssClass: ['notification-modal-css'],
      backdropDismiss: true,
    });
    await modalController.present();
    void modalController.onDidDismiss().then(() => {
      this.store.dispatch(clearNotificationSection({
        clearErrors: true,
        clearNotifications: false
      }));
    });
  }

  // TO be deleted
  async viewMessages(): Promise<void> {
    const modal = await this.modalController.create({
      component: MessagesModalPage,
      backdropDismiss: false
    });
    await modal.present();
  }

  async onOpenMenuClick(): Promise<void> {
    const pp: string[] = [
      this.user?.pp ? 'picture' : 'letter',
      this.getProfilePicture,
    ];
    const pc = await this.popoverController.create({
      component: MenuPopupComponent,
      componentProps: {user: this.user, pp},
      cssClass: 'menu-popup',
      event,
    });
    await pc.present();
    // Todo: Look at the deprecated event
  }

  async settings(): Promise<void> {
    const mc = await this.modalController.create({
      presentingElement: this.ionRouterOutlet == null ? await this.modalController.getTop() : this.ionRouterOutlet.nativeEl,
      component: NavigableModalComponent,
      componentProps: {root: ApplicationSettingsComponent},
      cssClass: 'settings-modal',
      backdropDismiss: false,
    });
    await mc.present();
  }
}
