import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  selectItemDisablingSettings,
  selectUserStoreSettingsIsSubmitting,
} from 'src/app/features/settings-old/store/settings.selectors';
import {
  DisabledRule,
  LineColour,
  onHoldDecode,
  onHoldTypes,
} from 'src/app/shared-utilities/models-old/datastructures';
import { updateOnHandSettings } from 'src/app/features/settings-old/store/settings.actions';
import type { IMultiSelect } from '../../models';
import type {
  IItemDisablingFormState,
  ILineColourWithNumber,
  IOnHandState,
} from './setting-item-disabling.models';
import { initialFormState } from './setting-item-disabling-rules-constants';

@Injectable({
  providedIn: 'root',
})
export class SettingItemDisablingFormService implements OnDestroy {
  #stateSubscription: Subscription;
  #currentState: IItemDisablingFormState | null = null;

  readonly #formStateSubject = new BehaviorSubject<IItemDisablingFormState>({
    ...initialFormState,
  });
  readonly #formState$: Observable<IItemDisablingFormState> =
    this.#formStateSubject.asObservable();

  constructor(private readonly store: Store) {}

  ngOnDestroy(): void {
    if (this.#stateSubscription) {
      this.#stateSubscription.unsubscribe();
    }
  }

  public initialize(): Observable<IItemDisablingFormState> {
    const currentFormState = this.#formStateSubject.value;
    this.#stateSubscription = this.store
      .select(selectItemDisablingSettings)
      .pipe(
        map(
          ({ rulesDoc, lineColours }) =>
            ({
              isSubmitting: false,
              onHandZero: {
                descPrefix: rulesDoc?.onHandZero?.descPrefix ?? '',
                descSuffix: rulesDoc?.onHandZero?.descSuffix ?? '',
                onHoldType: this.convertOnHoldCode(
                  rulesDoc?.onHandZero?.onHoldCode
                ),
                lineColour: this.convertLineColour(
                  lineColours,
                  rulesDoc?.onHandZero?.lineColour
                ),
              },
              onHandNotZero: {
                descPrefix: rulesDoc?.onHandNotZero?.descPrefix ?? '',
                descSuffix: rulesDoc?.onHandNotZero?.descSuffix ?? '',
                onHoldType: this.convertOnHoldCode(
                  rulesDoc?.onHandNotZero?.onHoldCode
                ),
                lineColour: this.convertLineColour(
                  lineColours,
                  rulesDoc?.onHandNotZero?.lineColour
                ),
              },
            } as IItemDisablingFormState)
        ),
        catchError((error) => {
          console.error('Failed to load store settings', error);
          return EMPTY;
        })
      )
      .subscribe((state) => {
        this.#currentState = state;
        currentFormState
          ? this.#formStateSubject.next({ ...currentFormState, ...state })
          : this.#formStateSubject.next(state);
      });
    this.#stateSubscription.add(
      this.store
        .select(selectUserStoreSettingsIsSubmitting)
        .subscribe((isSubmitting: boolean) => {
          if (currentFormState) {
            this.#formStateSubject.next({
              ...currentFormState,
              isSubmitting,
            });
          }
        })
    );

    return this.#formState$;
  }

  public submitForm(): void {
    if (
      !this.#currentState ||
      this.#currentState.isSubmitting ||
      !this.formHasChanges()
    )
      return;
    const onHandZeroData: DisabledRule = {
      descPrefix: this.#formStateSubject.value.onHandZero?.descPrefix,
      descSuffix: this.#formStateSubject.value.onHandZero?.descSuffix,
      onHoldCode: Object.values(
        this.#formStateSubject.value.onHandZero?.onHoldType || {}
      )
        .filter((type) => type.selected)
        .map((value) => parseInt(value.field as string))
        .reduce((sum, value) => sum + value, 0),
      lineColour: this.#formStateSubject.value.onHandZero?.lineColour?.find(
        (lineColour: ILineColourWithNumber) => lineColour.selected
      )?.num,
    };
    const onHandNotZeroData: DisabledRule = {
      descPrefix: this.#formStateSubject.value.onHandNotZero?.descPrefix,
      descSuffix: this.#formStateSubject.value.onHandNotZero?.descSuffix,
      onHoldCode: Object.values(
        this.#formStateSubject.value.onHandNotZero?.onHoldType || {}
      )
        .filter((type) => type.selected)
        .map((value) => parseInt(value.field as string))
        .reduce((sum, value) => sum + value, 0),
      lineColour: this.#formStateSubject.value.onHandNotZero?.lineColour?.find(
        (lineColour: ILineColourWithNumber) => lineColour.selected
      )?.num,
    };
    this.store.dispatch(
      updateOnHandSettings({
        onHandZero: onHandZeroData,
        onHandNotZero: onHandNotZeroData,
      })
    );
  }

  public updateFormState<
    T extends 'onHandZero' | 'onHandNotZero',
    K extends keyof IOnHandState
  >(
    section: T,
    field: K,
    value: K extends 'lineColour' ? string : IOnHandState[K]
  ): void {
    const sectionState =
      (this.#formStateSubject.value[section] as IOnHandState) ?? {};
    if (field === 'lineColour' && typeof value === 'string') {
      const currentLineColours = sectionState.lineColour ?? [];
      this.#formStateSubject.next({
        ...this.#formStateSubject.value,
        [section]: {
          ...sectionState,
          lineColour: currentLineColours.map((lineColour) => ({
            ...lineColour,
            selected: lineColour.name === value,
          })),
        },
      });
      return;
    }
    this.#formStateSubject.next({
      ...this.#formStateSubject.value,
      [section]: {
        ...sectionState,
        [field]: value,
      },
    });
  }

  private convertOnHoldCode(onHoldCode: number): IMultiSelect {
    let holdCodesList = [];
    if (onHoldCode) holdCodesList = onHoldDecode(onHoldCode, true);
    return Object.entries(onHoldTypes).reduce((acc, [key, value], index) => {
      if (value === 'NA') return acc;
      return {
        ...acc,
        [index]: {
          disabled: false,
          name: value,
          selected: holdCodesList.includes(key),
          field: key,
        },
      };
    }, {});
  }

  private convertLineColour(
    lineColour: LineColour,
    selectedLineColour: number
  ): ILineColourWithNumber[] {
    if (!lineColour) return [];
    return Object.entries(lineColour).map(([key, value]) => ({
      name: `(${key}) ${value.description}`,
      num: +key,
      colour: value.value,
      selected: selectedLineColour === +key,
    }));
  }

  private formHasChanges(): boolean {
    return (
      this.#currentState &&
      JSON.stringify(this.#formStateSubject.value) !==
        JSON.stringify(this.#currentState)
    );
  }
}
