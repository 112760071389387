<div class="card">
  <div class="card-body">
    <div class="input-section">
      <h3 for="tagInput">Add New Tag</h3>
      <div class="input-container">
        <input
          (keyup)="formatTag()"
          [(ngModel)]="newTag"
          id="tagInput"
          placeholder="Enter a tag (e.g., example_tag)"
          type="text"/>
        <button
          (click)="addTag()"
          [disabled]="!newTag || isTagInvalid"
          class="add-button"
          type="button">
          Add Tag
        </button>
      </div>
      <div *ngIf="isTagInvalid && newTag.length > 0" class="error-message">
        {{ tagErrorMessage }}
      </div>
    </div>

    <div class="output-section">
      <div class="label-section">
        <h3>Existing Tags</h3>
        <div class="color-type-container">
          <span class="tag global" disabled>global</span>
          <span class="tag">stores</span>
        </div>
      </div>
      <div class="tags-container">
        <button
          (click)="deleteTag(tag.name)"
          *ngFor="let tag of tags; trackBy: trackByFunction"
          [disabled]="tag.global"
          [ngClass]="{ global: tag.global }"
          class="tag"
          type="button">
          {{ tag.name }}
        </button>
      </div>
    </div>
  </div>
</div>
