import {Component, Type} from '@angular/core';
import {AlertController, IonNav, ModalController} from '@ionic/angular';
import {StoreConfigComponent} from '../store-config/store-config.component';
import {EditorsConfigComponent} from '../editors-config/editors-config.component';
import {RuleHumanID} from '../../../../shared-utilities/models-old/utils-old/rule-structure';

interface SettingsOptions {
  text: string;
  component?: Type<unknown>;
  disabled?: boolean;
  rules?: RuleHumanID[];
}

@Component({
  selector: 'app-application-settings',
  templateUrl: './application-settings.component.html',
  styleUrls: ['./application-settings.component.scss'],
})
export class ApplicationSettingsComponent {

  canNavigate = true;

  private readonly options: SettingsOptions[] = [
    {text: 'Store Configuration', component: StoreConfigComponent},
    {text: 'Configure Editors', component: EditorsConfigComponent},
  ];

  constructor(
    private alertControl: AlertController,
    private modalController: ModalController,
    private nav: IonNav,
  ) {
  }

  get menuOptions(): SettingsOptions[] {
    return this.options;
  }

  close = async (): Promise<void> => {
    if (!this.canNavigate) {
      const ac = await this.alertControl.create({
        header: 'Save before you go',
        subHeader: 'Closing this window now will discard your unsaved changes.',
        message: 'Would you like to save first?',
        cssClass: ['custom-alert'],
        buttons: ['Cancel', {text: 'Save', role: 'y'}]
      });
      await ac.present();
      const {role} = await ac.onDidDismiss();

      if (role !== 'y') {
        return;
      }
    }
    void this.modalController.dismiss().then();
  };

  async selectSettings(option: SettingsOptions): Promise<void> {
    if (option.disabled || !option.component) {
      return;
    }
    await this.nav.push(option.component, {});
  }

  trackByFunction(index: number): number {
    return index;
  }

}
