<app-modal-header [close]="onModalClose" title="User Profile"></app-modal-header>
<ion-content>
  <div class="wrapper">
    <div class="user-card">
      <div class="username-container">
        <div *ngIf="!isEditingUsername" class="current-username">
          <h3>{{ username }}</h3>
          <ion-icon
            (click)="onEditUserNameClick()"
            [matTooltip]="'Change Username'"
            name="pencil-outline">
          </ion-icon>
        </div>
        <form
          (ngSubmit)="saveUsername()"
          *ngIf="isEditingUsername"
          [formGroup]="usernameFormGroup"
          class="edit-form">
          <ion-item>
            <ion-label position="floating">New Username</ion-label>
            <ion-input formControlName="username" type="text"></ion-input>
          </ion-item>
          <div class="button-group">
            <ion-icon
              (click)="onChangeUserNameClick()"
              [hidden]="usernameFormGroup.invalid"
              [matTooltip]="'Save'"
              name="checkmark-done-outline">
            </ion-icon>
            <ion-icon
              (click)="isEditingUsername = false"
              [matTooltip]="'Cancel'"
              name="close-circle-outline">
            </ion-icon>
          </div>
        </form>
      </div>
      <div class="profile-picture-container">
        <div class="picture-wrapper">
          <ng-container *ngIf="profilePictureUrl; else noPicture">
            <img
              [src]="profilePictureUrl"
              alt="Profile Picture"
              class="profile-picture">
          </ng-container>
          <ng-template #noPicture>
            <div class="initial-avatar">{{ getUserNameInitials() }}</div>
          </ng-template>
          <ion-icon
            (click)="onEditProfilePictureClick()"
            [matTooltip]="'Change Profile Picture'"
            name="pencil-outline">
          </ion-icon>
          <input
            (change)="onProfilePictureChange($event)"
            hidden
            id="profilePictureInput"
            type="file">
        </div>
      </div>
      <div class="reset-password">
        <ion-button
          (click)="onResetPwdClick()"
          color="primary"
          expand="block">
          Reset Password
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
