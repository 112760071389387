export const PropertyToTitle = {
  avCost: 'Average Cost',  // To Double Check
  barcode: 'Barcode',
  binL: 'Bin Location',
  category: 'Category',
  code: 'Code',
  created: 'Created Date',  // To Double Check
  dep: 'Department',
  desc: 'Description',
  discExempt: 'Discount Exempt',  // To Double Check
  dlvColl: 'DelCol Outstanding',
  excludeSellingValue: 'Exclude Sell Value',
  genCode: 'General Code',
  gp1: 'GP',
  itemOrderPrice: 'Item Order Price',  // To Double Check
  itemOrderQuantity: 'Item Order Quantity',  // To Double Check
  lastMoved: 'Last Moved', // To Double Check
  lastPurchase: 'Date Last Purchased',
  lastSold: 'Date Last Sold',
  lastStockTake: 'Last Stock Take',  // To Double Check
  latestCost: 'Latest Cost',
  lineColourCode: 'Line Colour',
  modified: 'Modified',  // To Double Check
  minOrderLevel: 'Min Order Level',  // To Double Check
  maxDisc: 'Max Discount',  // To Double Check
  maxOrderLevel: 'Max Order Level',  // To Double Check
  multipleSuppliers: 'Other Suppliers',  // To Double Check
  nominalGP: 'Nominal GP',
  noDecimal: 'No Decimal',  // To Double Check
  onHand: 'On Hand',
  onHoldCode: 'On Hold Code',
  ordLvl: 'Order Level',
  packSize: 'Pack Size',
  prvSellingPri: 'Prev Sell Price',
  prvSellingPriDate: 'Prev Sell Price Date',
  purchaseOrder: 'Purchase Orders',
  recommendedGP: 'Recommended GP',
  regularSuppCode: 'Supplier',
  reportItemCode: 'Report Item Code',  // To Double Check
  reportItemFactor: 'Report Item Factor',  // To Double Check
  salesOrder: 'Sales Order',  // To Double Check
  sellIntoNegative: 'Selling Into Negative',  // To Double Check
  sellUnderCost: 'Selling Under Cost',  // To Double Check
  stockId: 'Stock ID',  // To Double Check
  subDep: 'Sub Department',
  suppCode: 'Supplier Stock Code',
  suppUsedLast1: 'Supplier Used Last 1',  // To Double Check
  suppUsedLast2: 'Supplier Used Last 2',  // To Double Check
  suppUsedLast3: 'Supplier Used Last 3',  // To Double Check
  suppUsedLast4: 'Supplier Used Last 4',  // To Double Check
  suppUsedLast5: 'Supplier Used Last 5',  // To Double Check
  suppUsedLastDate1: 'GRV Date',
  suppUsedLastDate2: 'Prev GRV Date',
  suppUsedLastDate3: 'Supplier Used Last Date 3', // To Double Check
  suppUsedLastDate4: 'Supplier Used Last Date 4', // To Double Check
  suppUsedLastDate5: 'Supplier Used Last Date 5', // To Double Check
  suppUsedLastPrice1: 'Supplier Used Last Price 1',  // To Double Check
  suppUsedLastPrice2: 'Supplier Used Last Price 2',  // To Double Check
  suppUsedLastPrice3: 'Supplier Used Last Price 3',  // To Double Check
  suppUsedLastPrice4: 'Supplier Used Last Price 4',  // To Double Check
  suppUsedLastPrice5: 'Supplier Used Last Price 5',  // To Double Check
  sellPriExcl1: 'Price (excl)',
  sellPriIncl1: 'Price',
  unitsYear: 'Units 12 Months',
  vatR: 'VAT Rate',  // To Double Check
  web: 'Web',  // To Double Check
  user: 'User',  // To Double Check
};
