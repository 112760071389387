<div
  [ngClass]="params.data.originalValue?.[params.colDef.field]?.value !== value ? 'edited' : ''"
  class="cell-renderer-text">

  <div *ngIf="checkSubDepCode(); else notExist">
    <div *ngIf="value !== '0'; else noDep">
      <ng-container *ngIf="params?.colDef?.field === 'dep' && departments ">
        {{ value }} - {{ getDepartmentName(params.data.dep) }}
      </ng-container>

      <ng-container *ngIf="params.colDef.field === 'subDep' && subDepartments">
        {{ value }} - {{ getSubDepartmentName(params.data.dep) }}
      </ng-container>

    </div>
    <ng-template #noDep>
      <div class="error-text">
        This Department Does Not Exist
      </div>
    </ng-template>
  </div>

  <ng-template #notExist>
    <div class="error-text">Please Select A New Sub Department</div>
  </ng-template>
  <ng-container *ngIf="params.data.originalValue?.[params.colDef.field]?.value !== value">
    <app-icon-backspace-outline
      (click)="onUndoButtonClick()"
      [tooltip]="'Previous Value = ' +params.data.originalValue[params.colDef.field].value">
    </app-icon-backspace-outline>
  </ng-container>

</div>
