<div class="supp-code-div">
  <span *ngIf="params.data.linkAcc">
    <span
      [matTooltip]="'Linked To' + params.data.linkAcc"
      class="mdi mdi-link-variant">
    </span>
  </span>
  <span
    *ngIf="onHoldDecode(params.data.onHoldCode, false ,true) !== null &&
      onHoldDecode(params.data.onHoldCode, false ,true).length > 0 ">
  <span
    [matTooltip]="'On Hold For: ' + getOnHoldCodeArray(params.data.onHoldCode)"
    class="mdi mdi-lock-outline">
  </span>
  </span>
</div>
