<div id="notification-modal-container-container">

  <ion-header>
    <ion-toolbar>
      <ion-title>
        <div>
          <div>Notifications</div>
        </div>
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <app-notification-modal
    [setIsUserNotificationsLoading]="(isUserNotificationsLoading$ | ngrxPush)"
    [setNotifications]="(notifications$ | ngrxPush)"
    [setSelectedUserStore]="(userSelectedStore$ | ngrxPush)"
    [setUser]="(user$ | ngrxPush)">
  </app-notification-modal>

</div>
