<div [ngStyle]="{'width': 400 + 'px'}" class="supp-selector-div">

  <input
    #input
    (focus)="toggleDropdown()"
    *ngIf="!params?.data?._tags?.includes('disabled')"
    [formControl]="searchControl"
    class="input"
    placeholder="Search Suppliers..."/>

  <!--  <div (click)="toggleDropdown()" *ngIf="!params?.data?._tags?.includes('disabled')" class="toggle-button"> Options-->
  <!--    <app-icon-chevron-down></app-icon-chevron-down>-->
  <!--  </div>-->

  <div #optionsList *ngIf="showOptions" [ngClass]="{'options-list': true, 'above': displayAbove}">
    <div class="scrollable-options">
      <div *ngFor="let option of filteredOptions; trackBy: trackByFunction ">
        <label>
          <input
            (change)="toggleSelection(option.key)"
            [checked]="selectedValues.includes(option.key)"
            [disabled]="params.data?._tags?.includes('disabled')"
            type="checkbox"/>
          {{ option.key }} - {{ option.description }}
        </label>
      </div>
    </div>
    <button (click)="onConfirmButtonClick()" class="buttons" type="button">OK</button>
    <button (click)="onCancelButtonClick()" class="buttons" type="button">Cancel</button>
  </div>

  <app-display-icon
    (click)="onUndoChangesClick()"
    *ngIf="params.data.originalValue[params.colDef.field].value !== value"
    [icon]="icons.backSpaceOutline"
    class="backspace">
  </app-display-icon>

</div>
