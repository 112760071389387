import {
  FilterOperations,
  IAdvancedFilterGroup,
  IFilter,
  IFilterGroup
} from '../../../shared/shared-components/components/shared-advanced-filters/utils/advanced-filter-groups';
import {SharedFilterState} from './shared-filter.reducer';
import {getDeepCopyOfObject} from '../../../shared/shared-utils/object/object.utils';

export function addFilterOrGroupToGroup(filterGroup: IFilterGroup, newItem: IFilter | IFilterGroup, predecessorId: number): IFilterGroup {
  if (filterGroup.id === predecessorId) {
    filterGroup.filtersAndGroups.push(newItem);
    return filterGroup;
  }
  filterGroup.filtersAndGroups = filterGroup.filtersAndGroups.map((item) => {
    if ('filtersAndGroups' in item) {
      return addFilterOrGroupToGroup(item, newItem, predecessorId);
    }
    return item;
  });
  return filterGroup;
}

export function findAndReplaceFilterWithinAdvancedFilterGroup(
  advancedFilterGroup: IAdvancedFilterGroup,
  newFilter: IFilter
): IAdvancedFilterGroup {
  const {filterGroup} = advancedFilterGroup;
  for (let i = 0; i < filterGroup.filtersAndGroups.length; i++) {
    const item = filterGroup.filtersAndGroups[i];
    if ('id' in item && item.id === newFilter.id) {
      filterGroup.filtersAndGroups[i] = newFilter;
      return advancedFilterGroup;
    }
    if ('filtersAndGroups' in item) {
      const foundGroup = findAndReplaceFilterWithinAdvancedFilterGroup(
        {
          filterGroup: item,
          grid: advancedFilterGroup.grid,
          id: advancedFilterGroup.id,
          name: advancedFilterGroup.name,
          saved: advancedFilterGroup.saved
        },
        newFilter
      );

      if (foundGroup) return advancedFilterGroup;
    }
  }
  return advancedFilterGroup;
}

export function getCopyOfAdvancedFilterGroup(advancedFilterGroup: IAdvancedFilterGroup, saved: boolean, date: Date): IAdvancedFilterGroup {
  const selectedAdvancedFilterGroup = getDeepCopyOfObject(advancedFilterGroup);
  selectedAdvancedFilterGroup.saved = saved;
  selectedAdvancedFilterGroup.lastModified = date;
  return selectedAdvancedFilterGroup;
}

export function onCreateAdvancedFilterGroup(advancedFilterGroups: IAdvancedFilterGroup[], newAdvancedFilterGroup: IAdvancedFilterGroup): IAdvancedFilterGroup[] {
  const copyOfAdvancedFilterGroups = getDeepCopyOfObject(advancedFilterGroups);
  copyOfAdvancedFilterGroups.unshift(newAdvancedFilterGroup);
  return copyOfAdvancedFilterGroups;
}

export function onGenerateCopyOfAdvancedFilterGroup(
  advancedFilterGroup: IAdvancedFilterGroup,
  advancedFilterIDCounter: number
): IAdvancedFilterGroup {
  const copy = getDeepCopyOfObject(advancedFilterGroup);
  return {
    ...copy,
    id: advancedFilterIDCounter + 1,
    name: `Copy of ${advancedFilterGroup.name}`,
    saved: false,
    lastModified: new Date()
  } as IAdvancedFilterGroup;
}

export function onGenerateNewAdvancedFilterGroup(advancedFilterGroupLength: number, advancedFilterIDCounter: number, grid: string): IAdvancedFilterGroup {
  return {
    filterGroup: {id: advancedFilterIDCounter + 1, operator: 'AND', filtersAndGroups: []},
    grid,
    id: advancedFilterIDCounter + 1,
    isApplied: false,
    name: `${advancedFilterGroupLength + 1} New Advanced Filter Group`,
    saved: false,
    lastModified: new Date(),
  } as IAdvancedFilterGroup;
}

// ====================================================================================
// Action Helpers
// ====================================================================================
export function onActionAddFilterAndGroupToSelectedAdvancedFilterGroup(state: SharedFilterState, predecessorID: number, shouldAddFilter: boolean): SharedFilterState {
  const selectedAdvancedFilterGroup = getCopyOfAdvancedFilterGroup(
    state.selectedAdvancedFilterGroup,
    false,
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  );
  if (selectedAdvancedFilterGroup.id === predecessorID) {
    selectedAdvancedFilterGroup.filterGroup = {
      id: state.advancedFilterIDCounter + 1,
      filtersAndGroups: [],
      operator: 'AND'
    } as IFilterGroup;
    if (shouldAddFilter) {
      selectedAdvancedFilterGroup.filterGroup.filtersAndGroups.push({id: state.advancedFilterIDCounter + 2} as IFilter);
    }
  } else {
    selectedAdvancedFilterGroup.filterGroup = addFilterOrGroupToGroup(selectedAdvancedFilterGroup.filterGroup, {} as IFilterGroup, predecessorID);
  }
  const advancedFilterGroups = getDeepCopyOfObject(state.advancedFilterGroups);
  const index = advancedFilterGroups.findIndex((afg: IAdvancedFilterGroup): boolean => afg.id === selectedAdvancedFilterGroup.id);
  advancedFilterGroups[index] = selectedAdvancedFilterGroup;
  return {
    ...state,
    advancedFilterGroups,
    advancedFilterIDCounter: shouldAddFilter ? state.advancedFilterIDCounter + 2 : state.advancedFilterIDCounter + 1,
    selectedAdvancedFilterGroup
  }
}

export function onActionAddFilterToSelectedAdvancedFilterGroup(state: SharedFilterState, predecessorID: number): SharedFilterState {
  const selectedAdvancedFilterGroup = getCopyOfAdvancedFilterGroup(
    state.selectedAdvancedFilterGroup,
    false,
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  );
  if (selectedAdvancedFilterGroup.id === predecessorID) {
    selectedAdvancedFilterGroup.filterGroup.filtersAndGroups = [
      ...selectedAdvancedFilterGroup?.filterGroup?.filtersAndGroups,
      {id: state.advancedFilterIDCounter + 1} as IFilterGroup
    ]
  } else {
    selectedAdvancedFilterGroup.filterGroup = addFilterOrGroupToGroup(
      selectedAdvancedFilterGroup.filterGroup,
      {id: state.advancedFilterIDCounter + 1} as IFilter,
      predecessorID
    );
  }
  const advancedFilterGroups = getDeepCopyOfObject(state.advancedFilterGroups);
  const index = advancedFilterGroups.findIndex((afg: IAdvancedFilterGroup): boolean => afg.id === selectedAdvancedFilterGroup.id);
  advancedFilterGroups[index] = selectedAdvancedFilterGroup;
  return {
    ...state,
    advancedFilterGroups,
    advancedFilterIDCounter: state.advancedFilterIDCounter + 1,
    selectedAdvancedFilterGroup
  }
}

export function onActionAddGroupToSelectedAdvancedFilterGroup(state: SharedFilterState, predecessorID: number): SharedFilterState {
  const selectedAdvancedFilterGroup = getCopyOfAdvancedFilterGroup(
    state.selectedAdvancedFilterGroup,
    false,
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  );
  if (selectedAdvancedFilterGroup.id === predecessorID) {
    selectedAdvancedFilterGroup.filterGroup.filtersAndGroups = [
      ...selectedAdvancedFilterGroup.filterGroup.filtersAndGroups,
      {
        id: state.advancedFilterIDCounter + 1,
        operator: 'AND',
        filtersAndGroups: []
      } as IFilterGroup
    ]
  } else {
    selectedAdvancedFilterGroup.filterGroup = addFilterOrGroupToGroup(
      selectedAdvancedFilterGroup.filterGroup,
      {
        id: state.advancedFilterIDCounter + 1,
        operator: 'AND',
        filtersAndGroups: []
      } as IFilterGroup,
      predecessorID
    );
  }
  const advancedFilterGroups = getDeepCopyOfObject(state.advancedFilterGroups);
  const index = advancedFilterGroups.findIndex((afg: IAdvancedFilterGroup): boolean => afg.id === selectedAdvancedFilterGroup.id);
  advancedFilterGroups[index] = selectedAdvancedFilterGroup;
  return {
    ...state,
    advancedFilterGroups,
    advancedFilterIDCounter: state.advancedFilterIDCounter + 1,
    selectedAdvancedFilterGroup
  }
}


export function deleteFilterWithinAdvancedFilterGroup(
  advancedFilterGroup: IAdvancedFilterGroup,
  filterId: number
): IAdvancedFilterGroup {
  const {filterGroup} = advancedFilterGroup;
  filterGroup.filtersAndGroups = filterGroup.filtersAndGroups.filter((item: IFilterGroup | IFilter): boolean => {
    if ('id' in item && item.id === filterId) {
      return false;
    }
    if ('filtersAndGroups' in item) {
      const filteredGroup = deleteFilterWithinAdvancedFilterGroup({
        filterGroup: item,
        grid: advancedFilterGroup.grid,
        id: advancedFilterGroup.id,
        name: advancedFilterGroup.name,
        saved: advancedFilterGroup.saved
      }, filterId);
      const index = filterGroup.filtersAndGroups.indexOf(item);
      filterGroup.filtersAndGroups[index] = filteredGroup.filterGroup;
    }
    return true;
  });

  return advancedFilterGroup;
}

export function deleteFilterOrGroupWithinAdvancedFilterGroup(
  advancedFilterGroup: IAdvancedFilterGroup,
  filterId: number
): IAdvancedFilterGroup {
  const {filterGroup} = advancedFilterGroup;
  filterGroup.filtersAndGroups = filterGroup.filtersAndGroups.filter((item: IFilterGroup | IFilter): boolean => {
    if ('id' in item && item.id === filterId) {
      return false;
    }
    if ('filtersAndGroups' in item) {
      const filteredGroup = deleteFilterOrGroupWithinAdvancedFilterGroup(
        {
          filterGroup: item,
          grid: advancedFilterGroup.grid,
          id: advancedFilterGroup.id,
          name: advancedFilterGroup.name,
          saved: advancedFilterGroup.saved
        },
        filterId
      );
      const index = filterGroup.filtersAndGroups.indexOf(item);
      filterGroup.filtersAndGroups[index] = filteredGroup.filterGroup;
    }
    return true;
  });

  return advancedFilterGroup;
}

export function onSetSelectedAdvancedFilterGroupOperator(
  state: SharedFilterState,
  groupID: number,
  newOperator: FilterOperations
): SharedFilterState {
  const selectedAdvancedFilterGroup = getCopyOfAdvancedFilterGroup(
    state.selectedAdvancedFilterGroup,
    false,
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  );
  const updateGroupOperatorById = (filterGroup: IFilterGroup, targetID: number, operator: FilterOperations): IFilterGroup => {
    if (filterGroup.id === targetID) {
      return {
        ...filterGroup,
        operator
      };
    }
    return {
      ...filterGroup,
      filtersAndGroups: filterGroup.filtersAndGroups.map((item) => {
        if ('filtersAndGroups' in item) {
          return updateGroupOperatorById(item , targetID, operator);
        }
        return item;
      })
    };
  };
  selectedAdvancedFilterGroup.filterGroup = updateGroupOperatorById(
    selectedAdvancedFilterGroup.filterGroup,
    groupID,
    newOperator
  );
  const advancedFilterGroups = getDeepCopyOfObject(state.advancedFilterGroups);
  const index = advancedFilterGroups.findIndex((afg: IAdvancedFilterGroup): boolean => afg.id === selectedAdvancedFilterGroup.id);
  advancedFilterGroups[index] = selectedAdvancedFilterGroup;
  return {
    ...state,
    advancedFilterGroups,
    selectedAdvancedFilterGroup
  };
}
