import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AutoOrderingState} from './auto-ordering.reducer';
import {selectSelectedUserStore} from '../../feature-core/store/core.selectors';
import {IAutoOrderOrder} from '../../../shared/shared-models/auto-ordering/auto-order-order';
import {
  selectMessagesFromAppAutoOrderUpdateAsOrders,
  selectSuppliersByUserSelectedStore,
} from '../../../features/core/store-shared/shared.selectors';
import {getDeepCopyOfObject} from '../../../shared/shared-utils/object/object.utils';
import {IStore} from '../../../shared/shared-models/store/store';
import {onHoldDecode, StockItem} from '../../../shared-utilities/models-old/datastructures';
import {IAutoOrderingOrderSettings} from '../../../features/settings-old/models/settings-models';

export const selectAutoOrderingState = createFeatureSelector<AutoOrderingState>('auto-ordering');

// ====================================================================================================
// Get Auto Order Orders
// ====================================================================================================
export const selectAutoOrderListSorting = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => {
    return {
      sortBy: state.orderListSortBy,
    };
  },
);

export const selectAutoOrderSelectedOrders = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.autoOrderSelectedOrders,
);

export const selectIsAutoOrderOrdersLoading = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.isAutoOrderOrdersLoading,
);

export const selectIsAutoOrderDoNotAskAgainActive = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.isDeleteAutoOrderDoNotAskAgainActive,
);

export const selectAutoOrderOrders = createSelector(
  selectAutoOrderingState,
  selectSelectedUserStore,
  (state: AutoOrderingState, store) => state.autoOrderOrders[store.storeId] || [],
);


export const selectAutoOrderLatestFirebaseQueries = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.autoOrderLatestFirebaseQueries,
);

export const selectAutoOrderOrdersAdditionalInfo = createSelector(
  selectAutoOrderOrders,
  selectSuppliersByUserSelectedStore,
  selectMessagesFromAppAutoOrderUpdateAsOrders,
  (stateOrders: IAutoOrderOrder[], suppliers, messageOrders) => {
    const orders: IAutoOrderOrder[] = [...getDeepCopyOfObject(stateOrders), ...getDeepCopyOfObject(messageOrders)];
    if (orders.length > 0) {
      if (Object.keys(suppliers).length > 0) {
        orders.forEach((order: IAutoOrderOrder): void => {
          let id: string;
          try {
            id = encodeURIComponent(decodeURIComponent(order.supplierId));
          } catch {
            id = encodeURIComponent(order.supplierId);
          }
          if (id != null) {
            order.createdSequence = order.createdSequence === -1 ? -1 : 0;
            order.supplierName = suppliers[id].name;
            order.linkedToSupplierId = suppliers[id].linkAcc;
            order.linkedToSupplierName = order.linkedToSupplierId ? suppliers[order.linkedToSupplierId].name : '';
            order.onHold = onHoldDecode(suppliers[id].onHoldCode, false, true);
            order.orderTotal = order.preparedInfo?.total ? order.preparedInfo.total : undefined;
            order.supplierMinOrder = suppliers[id].minOrder;
          }

        });
      } else {
        orders.forEach((order: IAutoOrderOrder): void => {
          order.createdSequence = order.createdSequence === -1 ? -1 : 0;
          order.supplierName = '';
          order.linkedToSupplierId = undefined;
          order.linkedToSupplierName = '';
          order.onHold = undefined;
          order.orderTotal = order.preparedInfo?.total ? order.preparedInfo.total : undefined;
          order.supplierMinOrder = 0;
        });
      }
    }
    return orders;
  },
);

export const selectSelectedAutoOrderItems = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.selectedAutoOrderItems,
);

export const selectIsAutoOrderOrderItemsLoading = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.isAutoOrderOrderItemsLoading,
);

export const selectAutoOrderingOpenOrderFilters = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.autoOrderingOrderFilters,
);


// ====================================================================================================
// Get Auto Order Stock Items
// ====================================================================================================
export const selectAutoOrderStockItemStreamCount = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.autoOrderStockItemsStreamCount,
);

export const selectIsSelectedAutoOrderStockItemsLoading = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.isAutoOrderOrderStockItemsLoading,
);

export const selectAutoOrderStockItemsState = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => {
    return state.autoOrderStockItems;
  },
);

export const selectSelectedAutoOrderStockItems = createSelector(
  selectAutoOrderStockItemsState,
  selectSelectedUserStore,
  (autoOrderStockItems, store: IStore) => {
    return autoOrderStockItems[store.storeId];
  },
);

export const selectStockItemsBySupplierIdByStreams = createSelector(
  selectSelectedAutoOrderStockItems,
  selectAutoOrderSelectedOrders,
  (streams: { [p: string]: { [p: string]: StockItem[] } }, orderIds: string[]) => {
    if (!streams || !orderIds || orderIds.length !== 1) {
      return {};
    }
    return streams[orderIds[0]] || {};
  },
);

export const selectStockItemsCombinedQueries = createSelector(
  selectStockItemsBySupplierIdByStreams,
  (queries: { [p: string]: StockItem[] }) => {
    if (queries['mainSupplierStockData']?.length > -1 && queries['additionalSupplierStockData']?.length > -1) {
      return Object.keys(queries).reduce((combined: StockItem[], streamKey: string) => {
        const stockItems = queries[streamKey];
        return combined.concat(stockItems);
      }, []);
    } else {
      return [] as StockItem[];
    }
  },
);

// ====================================================================================================
// Stock Item Deletion Rules
// ====================================================================================================
export const selectIsAutoOrderStockDeletionLoading = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.isAutoOrderStockDeletionRulesLoading,
);

export const selectAutoOrderStockDeletionRules = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.disabledRules,
);

// ====================================================================================================
// Recipients and Suppliers
// ====================================================================================================
export const selectIsAutoOrderSupplierEmailsLoading = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.isAutoOrderSupplierEmailsLoading,
);

export const selectAutoOrderSupplierEmails = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.autoOrderSupplierEmails,
);


// ====================================================================================================
// Order Settings
// ====================================================================================================
export const selectIsAutoOrderOrderSettingsLoading = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.isAutoOrderOrderSettingsLoading,
);

export const selectAutoOrderOrderSettings = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.autoOrderOrderSettings || {} as IAutoOrderingOrderSettings,
);

// ====================================================================================================
// Schedule Auto Delete Settings
// ====================================================================================================
export const selectAutoOrderScheduleDeleteSettings = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.scheduleDeleteSettings,
);
export const selectAddStockItemTypesenseData = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.addStockItemsData,
);
export const selectAddItemFilter = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.addStockItemsData.filter,
);

export const selectAddItemLineColours = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.addStockItemsData.lineColour,
);

export const selectAddItemFacetCounts = createSelector(
  selectAutoOrderingState,
  (state: AutoOrderingState) => state.addStockItemsData.facetCounts,
);
