<div class="no-access-container">
  <div class="no-access-content">
    <div class="header">
      <app-icon-lock-closed-outline class="icon"></app-icon-lock-closed-outline>
      <h1>Access Denied</h1>
    </div>
    <div class="body">
      <p>You do not have access to this store's settings.</p>
      <div class="allowed-stores-container">
        <h3>Stores you have access to:</h3>
        <div class="allowed-stores">
          <button (click)="onStoreSelect(store.storeId)" *ngFor="let store of allowedStores; trackBy: trackByStoreId"
                  class="store-button"
                  type="button">
            {{ store.name.toUpperCase() }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TODO TECHNICAL DEBT - Move Styles to SCSS -->
<style>
  .no-access-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: var(--ion-color-dark);
    user-select: none;

    @media screen and (max-width: 768px) {
      align-items: start;
    }

    p, h3 {
      margin: 0;
    }
  }

  .no-access-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    height: fit-content;
    width: 35%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--form-background-color);

    @media screen and (max-width: 1500px) {
      width: 50%;
    }

    @media screen and (max-width: 1200px) {
      width: 60%;
    }

    @media screen and (max-width: 768px) {
      max-height: 100%;
      width: 100%;
    }

    .header {
      display: flex;
      align-items: center;
      color: var(--ion-color-warning);
      fill: var(--ion-color-warning);
      padding-bottom: 1rem;
      border-bottom: 1px solid rgba(var(--ion-color-dark-rgb), 0.3);

      .icon {
        width: 2rem;
        aspect-ratio: 1 / 1;
        transform: translateY(1px);
      }

      h1 {
        margin: 0;
        font-size: 2em;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .allowed-stores-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .allowed-stores {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;

          @media screen and (max-width: 768px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 0.5rem;
            justify-items: stretch;
            overflow-y: auto;
            max-height: calc(100dvh - 85%);
          }

          .store-button {
            background-color: transparent;
            padding: 0.5rem 0.5rem;
            border: 1px solid var(--ion-color-primary);
            border-radius: 5px;
            color: var(--ion-color-primary);

            &:hover {
              background-color: var(--ion-color-primary);
              color: var(--form-background-color);
            }

            &:focus-visible {
              outline: 1px solid var(--ion-color-primary);
              outline-offset: 2px;
            }

            &:active {
              transform: scale(0.95);
            }
          }
        }
      }
    }
  }
</style>
