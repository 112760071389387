import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IMultiSelect } from '../../models';
import { SettingItemDisablingFormService } from './setting-item-disabling-form.service';
import {
  ILineColourWithNumber,
  ItemDisablingOnHand,
} from './setting-item-disabling.models';
import { initialItemDisablingOnHand } from './setting-item-disabling-rules-constants';

@Component({
  selector: 'app-setting-item-disabling-rules',
  templateUrl: './setting-item-disabling-rules.component.html',
  styleUrls: ['../../styles/shared-component-styles.scss'],
})
export class SettingItemDisablingRulesComponent implements OnInit {
  protected onHandZeroOnHoldType$: Observable<IMultiSelect>;
  protected onHandNotZeroOnHoldType$: Observable<IMultiSelect>;
  protected onHandZero: ItemDisablingOnHand = { ...initialItemDisablingOnHand };
  protected onHandNotZero: ItemDisablingOnHand = {
    ...initialItemDisablingOnHand,
  };

  constructor(private readonly formService: SettingItemDisablingFormService) {}

  ngOnInit(): void {
    const formState$ = this.formService.initialize();
    formState$.subscribe((state) => {
      this.onHandZero.descPrefix = state.onHandZero?.descPrefix;
      this.onHandZero.descSuffix = state.onHandZero?.descSuffix;
      this.onHandZero.lineColour = state.onHandZero?.lineColour;
      this.onHandZero.selectedLineColour =
        state.onHandZero?.lineColour?.find(
          (lineColour: ILineColourWithNumber) => lineColour.selected
        )?.name || '';
      this.onHandNotZero.descPrefix = state.onHandNotZero?.descPrefix;
      this.onHandNotZero.descSuffix = state.onHandNotZero?.descSuffix;
      this.onHandNotZero.lineColour = state.onHandNotZero?.lineColour;
      this.onHandNotZero.selectedLineColour =
        state.onHandNotZero?.lineColour?.find(
          (lineColour: ILineColourWithNumber) => lineColour.selected
        )?.name || '';
    });
    this.onHandZeroOnHoldType$ = formState$.pipe(
      map((state) => state.onHandZero?.onHoldType)
    );
    this.onHandNotZeroOnHoldType$ = formState$.pipe(
      map((state) => state.onHandNotZero?.onHoldType)
    );
  }

  protected onSubmit(event: Event): void {
    event.preventDefault();
    this.formService.submitForm();
  }

  protected onOnHandZeroDescPrefixChange(descPrefix: string): void {
    this.formService.updateFormState('onHandZero', 'descPrefix', descPrefix);
  }

  protected onOnHandZeroDescSuffixChange(descSuffix: string): void {
    this.formService.updateFormState('onHandZero', 'descSuffix', descSuffix);
  }

  protected onOnHandZeroLineColourChange(lineColour: string): void {
    this.formService.updateFormState('onHandZero', 'lineColour', lineColour);
  }

  protected onOnHandZeroOnHoldTypeChange(onHoldType: IMultiSelect): void {
    this.formService.updateFormState('onHandZero', 'onHoldType', onHoldType);
  }

  // OnHandNotZero
  protected onOnHandNotZeroDescPrefixChange(descPrefix: string): void {
    this.formService.updateFormState('onHandNotZero', 'descPrefix', descPrefix);
  }

  protected onOnHandNotZeroDescSuffixChange(descSuffix: string): void {
    this.formService.updateFormState('onHandNotZero', 'descSuffix', descSuffix);
  }

  protected onOnHandNotZeroLineColourChange(lineColour: string): void {
    this.formService.updateFormState('onHandNotZero', 'lineColour', lineColour);
  }

  protected onOnHandNotZeroOnHoldTypeChange(onHoldType: IMultiSelect): void {
    this.formService.updateFormState('onHandNotZero', 'onHoldType', onHoldType);
  }

  protected onOnHandSave(event: Event): void {
    event.preventDefault();
    this.formService.submitForm();
  }
}
