import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ColDef} from 'ag-grid-community';
import {IAdvancedFilterGroup} from '../utils/advanced-filter-groups';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {
  selectAdvancedFilterGroupsBySelectedGrid,
  selectAppliedAdvancedFilterGroup,
  selectIsAdvancedFilterGroupsLoading,
  selectSelectedAdvancedFilterGroup
} from '../../../../../features/core/store-shared-filter/shared-filter.selectors';
import {ISharedModalFiltersAdvanced} from 'src/app/shared/shared-models/modals/shared-modal-filters-advanced';
import {selectFiltersForUserSelectedStore} from '../../../../../features/stock-manager/store/stock-manager.selectors';
import {ISearchableFields} from '../../../../shared-models/type-sense/default-searchable-fields';

@Component({
  selector: 'app-shared-modal-filters-advanced-container',
  templateUrl: './shared-modal-filters-advanced-container.component.html',
  styleUrls: ['./shared-modal-filters-advanced-container.component.scss'],
})
export class SharedModalFiltersAdvancedContainerComponent implements ISharedModalFiltersAdvanced, OnInit {

  @Input() buttonCloseCross: boolean;
  @Input() colDefs: ColDef[];
  @Input() colDefsIgnore: string[];
  @Input() gridReference: string;
  @Input() modalTitle: string;

  advancedFilterGroups$: Observable<IAdvancedFilterGroup[]>;
  appliedAdvancedFilterGroup$: Observable<IAdvancedFilterGroup>;
  isAdvancedFilterGroupsLoading$: Observable<boolean>;
  selectedAdvancedFilterGroup$: Observable<IAdvancedFilterGroup>;
  selectedStoreFilters$: Observable<{ [key: string]: ISearchableFields }>;

  constructor(
    private cdr: ChangeDetectorRef,
    private readonly store: Store
  ) {
  }

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.advancedFilterGroups$ = this.store.select(selectAdvancedFilterGroupsBySelectedGrid);
    this.appliedAdvancedFilterGroup$ = this.store.select(selectAppliedAdvancedFilterGroup);
    this.isAdvancedFilterGroupsLoading$ = this.store.select(selectIsAdvancedFilterGroupsLoading);
    this.selectedAdvancedFilterGroup$ = this.store.select(selectSelectedAdvancedFilterGroup);
    this.selectedStoreFilters$ = this.store.select(selectFiltersForUserSelectedStore);
  }


}
