import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {canLoadGuard} from './shared/guards/can-load.guard';
import {canActivateGuard} from './shared/guards/can-activate.guard';
import {canActivateChildGuard} from './shared/guards/can-activate-child.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginPageModule),
    canMatch: [canLoadGuard],
  },
  {
    path: 'home',
    loadChildren: () => import('./features/home/home.module').then(m => m.HomePageModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./features/settings/settings-page.module').then(m => m.SettingsPageModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
    canMatch: [canLoadGuard],
    canActivate: [canActivateGuard],
    canActivateChild: [canActivateChildGuard],
  },
  {
    path: 'dashboard-reporting',
    loadChildren: () => import('./features/dashboard-reporting/dashboard-reporting.module').then(m => m.DashboardReportingModule),
    canMatch: [canLoadGuard],
    canActivate: [canActivateGuard],
    canActivateChild: [canActivateChildGuard],
  },
  {
    path: 'user-access',
    loadChildren: () => import('./features/user-access/user-access.module').then(m => m.UserAccessPageModule),
    canMatch: [canLoadGuard],
    canActivate: [canActivateGuard],
    canActivateChild: [canActivateChildGuard],
  },
  {
    path: 'admin-info',
    loadChildren: () => import('./features/admin-info/admin-info.module').then(m => m.AdminInfoPageModule),
    canMatch: [canLoadGuard],
    canActivate: [canActivateGuard],
    canActivateChild: [canActivateChildGuard],
  },
  {
    path: 'auto-ordering',
    loadChildren: () => import('./features-as-modules/feature-auto-ordering/auto-ordering.module').then(m => m.AutoOrderingPageModule),
    canMatch: [canLoadGuard],
    canActivate: [canActivateGuard],
    canActivateChild: [canActivateChildGuard],
  }, {
    path: 'barcode-scanner',
    loadChildren: () => import('./features-as-modules/feature-barcode-scanner/barcode-scanner.module').then(m => m.BarcodeScannerPageModule),
    canMatch: [canLoadGuard],
    canActivate: [canActivateGuard],
    canActivateChild: [canActivateChildGuard],
  },
  {
    path: 'ngp-report',
    loadChildren: () => import('./features/ngp-report/ngp-report.module').then(module => module.NgpReportModule),
    canMatch: [canLoadGuard],
    canActivate: [canActivateGuard],
    canActivateChild: [canActivateChildGuard],
  },
  {
    path: 'save-user-settings',
    loadChildren: () => import('./shared-modules/shared-module/modules-old/save-user-settings/save-user-settings.module').then(m => m.SaveUserSettingsPageModule),
    canMatch: [canLoadGuard],
    canActivate: [canActivateGuard],
    canActivateChild: [canActivateChildGuard],
  }, {
    path: 'shelf-talkers',
    loadChildren: () => import('./features/shelf-talkers/shelf-talkers.module').then(m => m.ShelfTalkersPageModule),
    canMatch: [canLoadGuard],
    canActivate: [canActivateGuard],
    canActivateChild: [canActivateChildGuard],
  },
  {
    path: 'stock-manager',
    loadChildren: () => import('./features/stock-manager/stock-manager.module').then(m => m.StockManagerModule),
    canMatch: [canLoadGuard],
    canActivate: [canActivateGuard],
    canActivateChild: [canActivateChildGuard],
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
