import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {IStore} from '../../../../shared/shared-models/store/store';
import {Store} from '@ngrx/store';
import {selectSelectedUserStore, selectUser} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {Icons} from '../../../../shared-modules/shared-icons/icons';
import {INotificationPerStore} from '../../models/notification';
import {selectAllNotifications, selectIsUserNotificationsLoading} from '../../store/notification.selectors';
import {IUser} from '../../../../shared/shared-models/user-access/user';
import {getStoreSuppliersByUserSelectedStore} from "../../../core/store-shared/shared.actions";

@Component({
  selector: 'app-notification-modal-container',
  templateUrl: './notification-modal-container.component.html',
  styleUrls: ['./notification-modal-container.component.scss'],
})
export class NotificationModalContainerComponent implements OnInit {

  userSelectedStore$: Observable<IStore>;
  isUserNotificationsLoading$: Observable<boolean>;
  notifications$: Observable<{ [storeId: string]: INotificationPerStore }>;
  user$: Observable<IUser>;

  protected readonly icons = Icons;

  constructor(
    private readonly store: Store
  ) {
  }

  ngOnInit(): void {
    this.userSelectedStore$ = this.store.select(selectSelectedUserStore);
    this.isUserNotificationsLoading$ = this.store.select(selectIsUserNotificationsLoading);
    this.notifications$ = this.store.select(selectAllNotifications);
    this.user$ = this.store.select(selectUser);
    this.store.dispatch(getStoreSuppliersByUserSelectedStore({dispatch: false}));
  }

}
