import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

// 1️⃣ **NgRx & State Management**
import {PushModule} from '@ngrx/component';

// 2️⃣ **Component Libraries**
import {SwitchUIComponent} from './switch-ui/switch-ui.component';

@NgModule({
  declarations: [
    SwitchUIComponent,
  ],
  imports: [
    CommonModule,
    PushModule,
  ],
  exports: [
    SwitchUIComponent,
  ],
})
export class UiComponentsModule {
}
