import {inject} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {FireAuthService} from '../../shared-utilities/services-old/fire-auth.service';
import {ModalController} from '@ionic/angular';
import {Store} from '@ngrx/store';
import {IUserAccess} from '../shared-models/user-access/user-access';
import {selectUser, selectUserAccess} from '../../features-as-modules/feature-core/store/core.selectors';
import {
  AccessFlags,
  pageRules,
  RuleHumanID,
  RuleInfo
} from '../../shared-utilities/models-old/utils-old/rule-structure';
import {ISharedModalBasic} from '../shared-models/modals/shared-modal-basic';
import {Icons} from '../../shared-modules/shared-icons/icons';
import {
  SharedModalBasicComponent
} from '../shared-components/components/shared-modal-basic/shared-modal-basic.component';
import {IUser} from "../shared-models/user-access/user";

/**
 * Validates if the user has the necessary access to a given page.
 *
 * This function checks user access rules and permissions to ensure the user
 * is authorized to access a specific page. If the user doesn't have access,
 * it redirects them to the homepage and shows a modal with the failed access rules.
 *
 * @param {string} page - The page identifier to validate access for.
 * @returns {Promise<boolean | UrlTree>} - Returns `true` if the user has access,
 *         otherwise returns a `UrlTree` for redirecting the user.
 *
 * @throws {Promise<UrlTree>} If the user doesn't have the required access,
 *         the function will display an insufficient access modal and redirect.
 */
export const validateAccess = async (page: string): Promise<boolean | UrlTree> => {
  const authService: FireAuthService = inject(FireAuthService);
  const router: Router = inject(Router);
  const store: Store = inject(Store);

  let userAccess: IUserAccess | null = null;

  store.select(selectUserAccess)
    .subscribe((access: IUserAccess): void => {
      userAccess = access && Object.keys(access).length > 0 ? access : null;
    });

  const pageRuleIds: false | (RuleHumanID | AccessFlags)[] = pageRules(page);

  if (pageRuleIds !== false && pageRuleIds.length === 0) return true;
  if (userAccess?.isTechoAdmin) return true;
  if (!userAccess?.storeList) return false;
  if (pageRuleIds === false) return false;

  const failed: RuleInfo[] = [];
  for (const ruleID of pageRuleIds) {
    let allowed: true | RuleInfo;
    userAccess?.storeList?.forEach((storeId: string) => {
      allowed = authService.hasAccess(storeId, {ruleID});
    });
    if (allowed !== true) failed.push(allowed);
  }

  if (failed.length === 0) return true;
  const failedText: string[] = failed.reduce((acc: string[], ruleInfo: RuleInfo) => {
    acc.push(ruleInfo.humanID);
    return acc;
  }, []);

  await displayInsufficientAccessModal(failedText);
  return router.createUrlTree(['/']);
};

/**
 * Displays a modal indicating insufficient access to the user.
 *
 * This function shows a modal with information about the user's failed access
 * attempts. It presents a message listing the rules or resources the user
 * doesn't have access to, and provides an option to close the modal.
 *
 * @param {string[]} failedPages - An array of strings representing the failed access
 *        rules, pages or resources.
 * @returns {Promise<void>} - A promise that resolves when the modal is presented.
 */
const displayInsufficientAccessModal = async (
  failedPages: string[]
): Promise<void> => {
  const modalController: ModalController = inject(ModalController);
  const componentProps: ISharedModalBasic = {
    buttonAccept: false,
    buttonAcceptText: '',
    buttonClose: true,
    buttonCloseCross: false,
    buttonCloseText: `Confirm`,
    modalTitle: 'Insufficient Access',
    modalTitleIcon: Icons.infoSlabCircleOutline,
    modalTitleIconColor: 'red',
    contextHeading: `NOTE: You do not have permission to access the following.`,
    contextDescription: [
      ...failedPages
    ],
  };
  const modalControl: HTMLIonModalElement = await modalController.create({
    backdropDismiss: false,
    component: SharedModalBasicComponent,
    componentProps,
    cssClass: ['shared-basic-modal-css'],
  });
  await modalControl.present();
}

/**
 * Checks if a user is logged in by verifying the presence of a user ID in the store.
 *
 * This function checks whether a user is logged in by inspecting the store for the
 * user data. It returns `true` if no user is logged in (i.e., user ID is `null`),
 * and `false` if a user is logged in (i.e., user ID is present).
 *
 * @returns {Promise<boolean>} - A promise that resolves to `true` if the user
 *          is not logged in, and `false` if the user is logged in.
 */
export const isUserLoggedIn = async (): Promise<boolean> => {
  const store: Store = inject(Store);
  let loggedInUser: IUser | null = null;
  store.select(selectUser)
    .subscribe((user: IUser): void => {
      loggedInUser = user?.id ? user : null;
    });
  return loggedInUser?.id === null;
}
