import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {ICellEditorParams} from 'ag-grid-community';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {selectSharedGridVisibleFieldsByCurrentPage} from '../../store/shared-grid.selectors';
import {LineColour, StockItem} from '../../../../shared-utilities/models-old/datastructures';
import {Icons} from '../../../shared-icons/icons';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {IDepartment, ISubDepartment} from '../../../../shared/shared-models/stock/departments';
import {GridHeaderMenuActionTypes} from '../../../../shared/shared-models/grid-menu/header-menu-action-types';
import {GridHeaderMenuTypeChange} from '../../../../shared/shared-models/grid-menu/header-menu-type-changes';
import {
  selectDepAndSubDepForUserSelectedStore,
  selectLineColours,
  selectStoreSettingsForSelectedUserStore,
} from '../../../../features/stock-manager/store/stock-manager.selectors';
import {
  getStoreDepartmentsForStockManager,
  setStockManagerMenuActions,
  updateStockManagerSingleVisibleField,
} from '../../../../features/stock-manager/store/stock-manager.actions';
import {NGPReport} from '../../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {ISharedStoreSettings} from '../../../../shared/shared-models/firebase/shared-store-settings';
import {getDeepCopyOfObject} from '../../../../shared/shared-utils/object/object.utils';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-cell-editor-select-dropdown',
  templateUrl: './cell-editor-select-dropdown.component.html',
  styleUrls: ['./cell-editor-select-dropdown.component.scss'],
})
export class CellEditorSelectDropdownComponent implements ICellEditorAngularComp, OnInit {

  @ViewChild('input', {static: true}) input: ElementRef;
  @ViewChild('selectElement', {static: true}) selectElement: ElementRef<HTMLSelectElement>;

  value: string | number;
  params: ICellEditorParams;
  width: number;
  selectorWidth: number;
  colDefField: string;
  lineColourObj = {};
  options: { key: string; description?: string; value?: string | number | boolean; }[] = [];
  currentVisibleFields: { [key: string]: boolean } = {};
  departments: IDepartment[] = [];
  currentPage: string;
  storeSettings: ISharedStoreSettings;

  selectedStoreLineColour$: Observable<LineColour>;
  departments$: Observable<IDepartment[]>;
  visibleFields$: Observable<{ [key: string]: boolean }>;
  currentPage$: Observable<string>;
  storeSettings$: Observable<ISharedStoreSettings>;
  protected readonly icons: typeof Icons = Icons;

  constructor(
    private readonly store: Store,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.subscribeToStore();
    this.adjustSelectorWidth();
  }

  agInit(params: ICellEditorParams<NGPReport | StockItem, string | number>): void {
    this.params = {...params};
    this.params.data = {...params.data};
    this.params.colDef = {...params.colDef};
    this.colDefField = params.colDef.field;
    this.value = this.params.data[this.colDefField];
    this.width = this.params.colDef.width;
    this.params.data.originalValue = {...params.data.originalValue};
    this.params.data.originalValue[this.colDefField] = {...params.data.originalValue[this.colDefField]};
  }

  subscribeToStore(): void {
    this.storeSettings$ = this.store.select(selectStoreSettingsForSelectedUserStore);
    this.storeSettings$.pipe(take(1)).subscribe((storeSettings: ISharedStoreSettings) => {
      this.storeSettings = storeSettings;
    });
    switch (this.colDefField) {
      case 'lineColourCode':
        this.selectedStoreLineColour$ = this.store.select(selectLineColours);
        this.selectedStoreLineColour$.subscribe((lineColours: LineColour): void => {
          this.lineColourObj = {...lineColours};
          this.options = this.getLineColours();
        });
        break;
      case 'dep':
        this.departments$ = this.store.select(selectDepAndSubDepForUserSelectedStore);
        this.departments$.subscribe((departments: IDepartment[]): void => {
          if (!departments) {
            this.store.dispatch(getStoreDepartmentsForStockManager());
          }
          this.departments = [];
          this.departments = departments;
          this.cdr.markForCheck();
          this.options = this.getDepartments(departments);
        });
        break;
      case 'subDep':
        this.departments$ = this.store.select(selectDepAndSubDepForUserSelectedStore);
        this.departments$.subscribe((departments: IDepartment[]): void => {
          this.departments = departments;
          this.cdr.markForCheck();
          this.options = this.getSubDepartments(departments);
        });
        break;
      case 'hasPromo':
        this.options[0] = {key: 'True', description: 'True', value: true};
        this.options[1] = {key: 'False', description: 'False', value: false};
        break;

    }
    this.cdr.detectChanges();
    this.visibleFields$ = this.store.select(selectSharedGridVisibleFieldsByCurrentPage);
    this.visibleFields$.subscribe((visibleFields: { [p: string]: boolean }) => {
      this.currentVisibleFields = {...visibleFields};
    });
  }

  adjustSelectorWidth(): void {
    if (this.colDefField !== 'subDep') {
      this.selectorWidth = this.width - 60;
    } else {
      this.selectorWidth = this.width - 75;
    }
  }

  getValue(): string | number {
    this.checkVisibleFields();
    if (this.departments) {
      this.checkForError();
    }
    const type = this.params.colDef.cellDataType;
    GridUtils.updateIsEditedAndForceRefresh(this.params);
    GridUtils.updateSingleItem(this.params.data as NGPReport | StockItem, this.colDefField, this.value, this.store, this.currentPage);
    if (type === 'text') {
      return this.params.data[this.colDefField] === '' ? String(this.params.data.originalValue[this.colDefField].value) : this.value.toString();
    } else if (type === 'number') {
      return String(this.value) === '' ? String(this.params.data.originalValue[this.colDefField].value) : +this.value;
    }
  }

  checkForError(): void {
    if (this.colDefField === 'dep') {
      const containsValue = this.options.some(option => option.key === this.value);
      if (!containsValue) {
        this.value = '0';
      }
    }
    if (this.storeSettings?.linkDepartmentsEnabled) {
      this.params.data.isError = GridUtils.checkSubDepCode(this.params, this.departments);
    }
    GridUtils.updateErrorAndForceRefresh(this.params, this.departments);
  }

  checkVisibleFields(): void {
    const currentVisibleFields = {...this.currentVisibleFields};
    if (this.colDefField === 'dep' && this.params.data[this.colDefField] !== this.params.data.originalValue[this.colDefField].value && this.storeSettings?.linkDepartmentsEnabled) {
      if (!currentVisibleFields.subDep) {
        this.store.dispatch(updateStockManagerSingleVisibleField({
          colId: 'subDep',
          value: true,
        }));

        this.store.dispatch(setStockManagerMenuActions({
          gridHeaderMenu: {
            action: GridHeaderMenuActionTypes.editing,
            colId: 'subDep',
            typeOfChange: GridHeaderMenuTypeChange.isEditing,
            value: true,
          },
        }));
      }
    }

    if (this.colDefField === 'subDep') {
      if (!currentVisibleFields.dep) {
        const containsValue = this.options.some(
          (option: { description: string; key: string; value?: string | number; }) => option.key === this.value);
        if (!containsValue) {
          this.store.dispatch(updateStockManagerSingleVisibleField({
            colId: 'dep',
            value: true,
          }));
          this.store.dispatch(setStockManagerMenuActions({
            gridHeaderMenu: {
              action: GridHeaderMenuActionTypes.editing,
              colId: 'dep',
              typeOfChange: GridHeaderMenuTypeChange.isEditing,
              value: true,
            },
          }));
        }
      }
    }
  }

  selectText(): void {
    setTimeout((): void => {
      this.input.nativeElement.focus();
      this.input.nativeElement.select();
    }, 1);
  }

  refresh(params: ICellEditorParams<NGPReport | StockItem, string | number>): boolean {
    GridUtils.updateIsEditedAndForceRefresh(this.params);
    return false;
  }

  getDepartments(departments: IDepartment[]): { key: string; description?: string; value?: string | number; }[] {
    const deps: { key: string; description?: string; value?: string | number; }[] = [];
    departments?.forEach((department: IDepartment): void => {
      deps.push({key: department?.department.toString(), description: department?.name});
    });
    return deps.sort((a, b) => a.description > b.description ? 1 : -1);
  };

  getSubDepartments(departments: IDepartment[]): { key: string; description?: string; value?: string | number; }[] {
    if (this.params.data.dep !== '0' && this.storeSettings?.linkDepartmentsEnabled) {
      const subDeps: { key: string; description?: string; value?: string | number; }[] = [];
      const dep = departments.find((department: IDepartment): boolean => department.department === this.params.data.dep);
      dep.subDepartments?.forEach((sub: ISubDepartment): void => {
        subDeps.push({key: sub.subDepartment.toString(), description: sub.name});
      });
      return subDeps.sort((a, b) => a.description > b.description ? 1 : -1);
    } else if (this.params.data.dep !== '0') {
      const subDeps: { key: string; description?: string; value?: string | number; }[] = [];
      const subDepartments = departments.flatMap((dep: IDepartment) =>
        Object.values(dep.subDepartments),
      );
      subDepartments?.forEach((sub: ISubDepartment): void => {
        subDeps.push({key: sub.subDepartment.toString(), description: sub.name});
      });
      return subDeps.sort((a, b) => a.description > b.description ? 1 : -1);
    } else {
      return [];
    }
  }

  getLineColours(): { description: string, key: string, value: string | number }[] {
    return Object.keys(this.lineColourObj)
      .map((key: string) => ({
        key,
        description: this.lineColourObj[key].description,
        value: this.lineColourObj[key].value,
      }))
      .sort((a, b) => a.description > b.description ? 1 : -1);
  }

  onSelectionChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.value = inputElement.value;
    this.params.data[this.params.colDef.field] = inputElement.value ?? this.params.data.originalValue[this.params.colDef.field].value;
    GridUtils.updateErrorAndForceRefresh(this.params, this.departments);
    GridUtils.updateIsEditedAndForceRefresh(this.params);
  }

  onUndoChangesClick(): void {
    this.value = this.params.data.originalValue[getDeepCopyOfObject(this.colDefField)].value;
    this.params.data[getDeepCopyOfObject(this.colDefField)] = this.value;
    if (this.departments) {
      this.checkForError();
    }
    GridUtils.removeIsEditingFlagAndDispatch(this.params.data as NGPReport | StockItem, getDeepCopyOfObject(this.colDefField), this.store, this.currentPage);
    GridUtils.updateIsEditedAndForceRefresh(this.params);
  }

  afterGuiAttached(): void {
    this.input.nativeElement.focus();
  }


  trackByFunction(index: number): number {
    return index;
  }

}
