<app-modal-header [close]="close" [title]="'Notifications'"></app-modal-header>

<ion-content>
  <app-shared-store-tabs
    (emitStoreChange)="selectStore($event)"
    [isMessagesModal]="true"
    [isModal]="true"
    [setStores]="(userStores$ | ngrxPush)"
    [setUserSelectedStore]="(userSelectedStore$ | ngrxPush)">
  </app-shared-store-tabs>

  <!-- Use selectedMessages length to check if we have data -->
  <ion-grid *ngIf="selectedMessages; else spinner" id="messages">
    <ng-container *ngIf="selectedMessages?.length; else empty">
      <ion-accordion-group>
        <ion-accordion>
          <ion-item slot="header">
            <ion-label [color]="filteredMessageTypes || selectedDates ? 'success' : ''">Filters</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <button (click)="filterMsgTypes($event)" mat-raised-button type="button">
              Filter Notification Types
              <span *ngIf="filteredMessageTypes">*</span>
            </button>
            <button (click)="filterDates($event)" mat-raised-button type="button">
              Filter Dates
              <span *ngIf="selectedDates">*</span>
            </button>
            <button (click)="deleteOldMessages()" *ngIf="groupedByAge.olderMessages.length > 0"
                    class="delete-button"
                    mat-raised-button
                    type="button">
              Delete Old Notifications
            </button>
          </div>
        </ion-accordion>
      </ion-accordion-group>

      <!-- If no filtered result, show grouped accordions -->
      <ion-accordion-group *ngIf="!filtered ? true : false; else filteredTemplate" [value]="initialOpenGroup">
        <ion-accordion *ngIf="groupedByAge.currentWeek.length" value="currentWeek">
          <ion-item color="light" slot="header">
            <ion-label>This Week</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <ng-container *ngFor="let notification of groupedByAge.currentWeek; trackBy: trackByFunction">
              <ng-container *ngTemplateOutlet="messageTemplate; context: { notification: notification }"></ng-container>
            </ng-container>
          </div>
        </ion-accordion>
        <ion-accordion *ngIf="groupedByAge.currentMonth.length" value="currentMonth">
          <ion-item color="light" slot="header">
            <ion-label>This Month</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <ng-container *ngFor="let notification of groupedByAge.currentMonth; trackBy: trackByFunction">
              <ng-container *ngTemplateOutlet="messageTemplate; context: { notification: notification }"></ng-container>
            </ng-container>
          </div>
        </ion-accordion>
        <ion-accordion *ngIf="groupedByAge.olderMessages.length" value="olderMessages">
          <ion-item color="light" slot="header">
            <ion-label>Old Notifications</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <ng-container *ngFor="let notification of groupedByAge.olderMessages; trackBy: trackByFunction">
              <ng-container *ngTemplateOutlet="messageTemplate; context: { notification: notification }"></ng-container>
            </ng-container>
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </ng-container>

    <!-- Template for filtered messages -->
    <ng-template #filteredTemplate>
      <div class="ion-padding">
        <ng-container *ngFor="let notification of filtered; trackBy: trackByFunction">
          <ng-container *ngTemplateOutlet="messageTemplate; context: { notification: notification }"></ng-container>
        </ng-container>
      </div>
    </ng-template>

    <!-- If there are no messages -->
    <ng-template #empty>
      <div id="no-messages">
        <i>Inbox is empty :)</i>
      </div>
    </ng-template>
  </ion-grid>
</ion-content>

<!-- Template for a single message -->
<ng-template #messageTemplate let-notification="notification">
  <ion-row [class]="'msg ' + (expandedMessage === notification.event ? 'expanded' : '')">
    <ion-col size="auto">
      <span>{{ getDate(notification.timestamp) | date:'shortDate' }}</span>
    </ion-col>
    <ion-col size="auto">
      <span>{{ getDate(notification.timestamp) | date:'shortTime' }}</span>
    </ion-col>
    <ion-col (click)="expand(notification.event)" class="preview truncate">
      <ng-container *ngIf="notification && ['STOCK_UPDATE', 'AUTO_ORDER_RESULT'].includes(notification.type)">
        <ng-container *ngIf="!notification.success; else successTemplate">
          <ion-icon [class]="!sentButIMAPFailed[notification.event] ? 'api-error' : 'api-warn'"
                    name="alert-circle-outline"></ion-icon>
        </ng-container>
        <ng-template #successTemplate>
          <ion-icon class="api-success" name="checkmark-circle-outline"></ion-icon>
        </ng-template>
      </ng-container>
      {{ previews[notification.event] }}
    </ion-col>
    <ion-col size="auto">
      <ion-icon (click)="deleteMessage(notification.id)" name="trash-outline"></ion-icon>
    </ion-col>
  </ion-row>
  <ion-grid *ngIf="getExpansion(notification.event)"
            [hidden]="expandedMessage !== notification.event || hide[notification.event]"
            class="msg-expansion">
    <ng-container
      *ngComponentOutlet="getExpansion(notification.event); injector: injectors[notification.event]"></ng-container>
    <ion-row>
      <ion-icon (click)="expandedMessage = null" name="caret-up-outline"></ion-icon>
    </ion-row>
  </ion-grid>
</ng-template>

<ng-template #spinner>
  <div id="loading-indicator">
    <ion-card>
      <ion-card-header>
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
      </ion-card-header>
      <ion-card-content>
        loading...
      </ion-card-content>
    </ion-card>
  </div>
</ng-template>
