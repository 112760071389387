
//#region ISettingState
/**
 * ### Interface representing the state configuration for the setting section component
 *
 * The `ISettingState` interface holds the state of the setting section component, including
 * visibility controls for the dropdowns and tooltips.
 *
 * @interface ISettingState
 *
 * @property {boolean} isOpen - Controls visibility of the single select dropdown.
 * @property {boolean} isMultiSelectOpen - Controls visibility of the multi-select dropdown.
 * @property {boolean} showTooltip - Controls visibility of the component tooltip.
 *
 * @example
 * const settingState: ISettingState = {
 *   isOpen: true,
 *   isMultiSelectOpen: false,
 *   showTooltip: true
 * };
 */
export interface ISettingState {
  /** Controls visibility of the single select dropdown. */
  isOpen: boolean;

  /** Controls visibility of the multi-select dropdown. */
  isMultiSelectOpen: boolean;

  /** Controls visibility of the component tooltip. */
  showTooltip: boolean;
}
//#endregion

//#region initialSettingState
/**
 * ### Default initial state values for the setting section component
 *
 * This constant provides the default initial state for the setting section component,
 * setting all visibility controls to `false` by default.
 *
 * @const {ISettingState}
 */
export const initialSettingState: ISettingState = {
  isOpen: false,
  isMultiSelectOpen: false,
  showTooltip: false
};
//#endregion
