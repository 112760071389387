
//#region getNextFocusIndex
/**
 * ### Calculates the index of the next focusable option based on the current position and direction
 *
 * This function computes the next index in a list based on the current focused item index and the
 * desired navigation direction (`up` or `down`). It ensures that the index stays within the valid
 * bounds of the list (from 0 to `totalItems - 1`).
 *
 * @param {number} currentIndex - The index of the currently focused item in the list.
 *                               This should be a valid index, or `-1` to represent no item being focused.
 * @param {number} totalItems - The total number of items in the list to ensure navigation stays within bounds.
 * @param {'up' | 'down'} direction - The direction of navigation. Use `'down'` to move forward or `'up'` to move backward.
 * @returns {number} The index of the next focusable item, bounded by the list size.
 *                   If moving out of bounds, it returns the closest valid index (0 or `totalItems - 1`).
 */
const getNextFocusIndex = (
  currentIndex: number,
  totalItems: number,
  direction: 'up' | 'down'
): number => {
  if (currentIndex < 0) return 0;

  if (direction === 'down') {
    return Math.min(currentIndex + 1, totalItems - 1);
  } else {
    return Math.max(currentIndex - 1, 0);
  }
};

//#endregion

//#region getDropdownOptions
/**
 * ### Retrieves all dropdown option elements from a specified container
 *
 * This function selects the dropdown container within the provided parent container
 * using a CSS selector, then collects and returns all child elements with the
 * class `.option` (representing dropdown options).
 *
 * @param {HTMLElement} container - The parent container element that contains the dropdown.
 *                                  This is the root element where the dropdown selector will be applied.
 * @param {string} selector - A CSS selector used to identify the dropdown container
 *                            within the parent element. This allows targeting different dropdowns
 *                            within the container if needed.
 * @returns {HTMLElement[]} An array of `HTMLElement` objects representing the dropdown options.
 *                          If no options are found or the dropdown container is not found,
 *                          an empty array is returned.
 *
 * @example
 * // Assuming a dropdown inside a parent element
 * const options = getDropdownOptions(parentElement, '.dropdown-container');
 * console.info(options); // Logs all dropdown option elements
 */
export const getDropdownOptions = (
  container: HTMLElement,
  selector: string
): HTMLElement[] => {
  const dropdownContainer = container.querySelector(selector);
  if (!dropdownContainer) return [];
  return Array.from(dropdownContainer.querySelectorAll<HTMLElement>('.option'));
};

//#endregion

//#region handleKeyboardNavigation
/**
 * ### Manages keyboard navigation events for a dropdown component.
 *
 * This function handles keyboard interactions such as `ArrowDown`, `ArrowUp`, and `Escape`
 * for navigating through the dropdown options, opening, or closing the dropdown. It is typically
 * used to manage keyboard accessibility for dropdown-like UI components.
 *
 * @param {KeyboardEvent} event - The keyboard event triggered by the user, which determines the
 *                                action (e.g., navigation or closing the dropdown).
 * @param {boolean} isOpen - A flag indicating whether the dropdown is currently open.
 * @param {number} totalOptions - The total number of options in the dropdown.
 * @param {number} currentIndex - The index of the currently selected option in the dropdown.
 * @param {Object} callbacks - An object containing callback functions that are invoked based on
 *                             the keyboard event.
 * @param {() => void} callbacks.onOpen - A callback function called when the dropdown should be opened.
 * @param {() => void} callbacks.onClose - A callback function called when the dropdown should be closed.
 * @param {(index: number) => void} callbacks.onIndexChange - A callback function called when the
 *                                                           selected index changes. It takes the new
 *                                                           index of the selected option as a parameter.
 *
 * @returns {void} This function does not return anything, it triggers side effects via the callbacks.
 *
 * @example
 * // Example usage: Handling key events for a dropdown
 * handleKeyboardNavigation(event, isOpen, totalOptions, currentIndex, {
 *   onOpen: () => openDropdown(),
 *   onClose: () => closeDropdown(),
 *   onIndexChange: (index) => updateSelectedOption(index),
 * });
 */
export const handleKeyboardNavigation = (
  event: KeyboardEvent,
  isOpen: boolean,
  totalOptions: number,
  currentIndex: number,
  callbacks: {
    onOpen(): void;
    onClose(): void;
    onIndexChange(index: number): void;
  }
): void => {
  if (['ArrowDown', 'ArrowUp', 'Escape'].includes(event.key)) {
    event.preventDefault();
  }

  if (!isOpen) {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      callbacks.onOpen();
    }
    return;
  }

  switch (event.key) {
    case 'ArrowDown':
      callbacks.onIndexChange(getNextFocusIndex(currentIndex, totalOptions, 'down'));
      break;
    case 'ArrowUp':
      callbacks.onIndexChange(getNextFocusIndex(currentIndex, totalOptions, 'up'));
      break;
    case 'Escape':
      callbacks.onClose();
      break;
  }
};

//#endregion

//#region handleInitialKeyPress
/**
 * ### Handles initial keyboard interaction to determine if the dropdown should respond
 *
 * This function listens for specific keyboard events (`ArrowDown`, `ArrowUp`, or `Escape`) and determines
 * if the dropdown should handle the key press based on its current state. It also prevents the default behavior
 * of the `ArrowDown`, `ArrowUp`, and `Escape` keys when they are pressed to prevent unintended browser actions.
 *
 * @param {KeyboardEvent} event - The keyboard event triggered by a user interaction.
 *                               This contains information about the key pressed and other relevant event details.
 * @param {boolean} isOpen - The current state of the dropdown (whether it's open or closed).
 *                           This helps determine whether the dropdown should respond to the key press.
 * @returns {boolean} Returns `true` if the dropdown should handle the key press (for example, when navigating the dropdown),
 *                   and `false` otherwise (e.g., when the dropdown is closed and no action should occur).
 *                   If the dropdown is closed and an arrow key is pressed, it returns `true` to open the dropdown.
 */
export const handleInitialKeyPress = (
  event: KeyboardEvent,
  isOpen: boolean
): boolean => {
  if (['ArrowDown', 'ArrowUp', 'Escape'].includes(event.key)) {
    event.preventDefault();
  }
  if (!isOpen) {
    return event.key === 'ArrowDown' || event.key === 'ArrowUp';
  }

  return true;
};

//#endregion
