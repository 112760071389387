/**
 * An object containing various column types that can be applied to various columns.
 *
 * @example
 * // Defined Types:
 * - alignRight: Aligns text on the right.
 * - cellEdit: displays the cell as editing.
 * - cellNormal: displays the cell as normal.
 */
export const gridColumnTypes = {
  alignRight: {
    cellStyle: {
      textAlign: 'right',
    },
  },
  cellEdit: {
    cellClassRules: {
      'cellEdit': (params) => params.colDef.editable === true && !params.data?.['_tags']?.includes('disabled'),
    },
  },
  cellNormal: {
    cellStyle: {
      borderRadius: 'initial !important',
      background: 'initial !important',
      border: 'initial !important',
      outline: 'initial !important',
    },
  },
};

export interface ICellStyles {
  cellStyle: {
    textAlign?: string;
    borderRadius?: string;
    background?: string;
    border?: string;
    borderBottom?: string;
    outline?: string;
  };
}

export interface IGridColumnTypes {
  alignRight: ICellStyles;
  cellEdit: ICellStyles;
  cellNormal: ICellStyles;
}
