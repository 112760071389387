import {createAction, props} from '@ngrx/store';
import {ISharedModalBasic} from '../../../shared/shared-models/modals/shared-modal-basic';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {IGroupAccess, IGroupAccessPerStore} from '../../../shared/shared-models/user-access/group-access';
import {IUser} from '../../../shared/shared-models/user-access/user';
import {ApplicationFeatureKeys} from '../../../shared/shared-utils/features/features';
import {IUserAccessToken, IUserAccessTokenURL} from "../models/user-access-token";

export const sendInviteLink = createAction(
  '[User Access][Invite Link] Send Invite Link',
  props<{ userId: string, editedPermissions: IGroupAccessPerStore }>(),
);

export const sendInviteLinkSuccess = createAction(
  '[User Access][Invite Link] Send Invite Link Success',
  props<{ userId: string }>(),
);

export const sendInviteLinkFailure = createAction(
  '[User Access][Invite Link] Send Invite Link Failure',
  props<{ error: IError }>(),
);

export const setPermissionsFailure = createAction(
  '[User Access] Set Permissions Failure',
  props<{ error: IError }>(),
);

export const setUserAccessLoading = createAction(
  '[User Access] Set User Access Loading',
  props<{ isLoading: boolean }>(),
);

export const showUserAccessModal = createAction(
  '[User Access] Show User Access Modal',
  props<{ modalProps: ISharedModalBasic, editedPermissions?: IGroupAccessPerStore, userId?: string }>(),
);

export const setColleaguesUserAccess = createAction(
  '[User Access] Set Colleagues User Access',
  props<{ userId: string, editedPermissions: IGroupAccessPerStore, merge: boolean, newUser: boolean }>(),
);

export const setColleaguesUserAccessSuccess = createAction(
  '[User Access] Set Colleagues User Access Success',
);

export const setColleaguesUserAccessFailure = createAction(
  '[User Access] Set Colleagues User Access Failure',
  props<{ error: IError }>(),
);

export const getGroupAccessSettings = createAction(
  '[User Access] Get Group Access Settings',
);

export const getGroupAccessSettingsSuccess = createAction(
  '[User Access] Get Group Access Settings Success',
  props<{ groupAccess: IGroupAccess }>(),
);

export const getGroupAccessSettingsFailure = createAction(
  '[User Access] Get Group Access Settings Failure',
  props<{ error: IError }>(),
);

export const setColleaguesUserAccessForNonAccessibleUser = createAction(
  '[User Access] Set Colleagues User Access For Non Accessible User',
  props<{ userId: string, editedPermissions: IGroupAccessPerStore }>(),
);

export const setColleaguesUserAccessForNonAccessibleUserSuccess = createAction(
  '[User Access] Set Colleagues User Access For Non Accessible User Success',
);

export const setColleaguesUserAccessForNonAccessibleUserFailure = createAction(
  '[User Access] Set Colleagues User Access For Non Accessible User Failure',
  props<{ error: IError }>(),
);

export const setNewServerAdminUser = createAction(
  '[User Access][Server] Set New Server Admin User',
  props<{ userId: string, editedPermissions: IGroupAccessPerStore }>(),
);

export const setNewServerAdminUserSuccess = createAction(
  '[User Access][Server] Set New Server Admin User Success',
  props<{ userId: string }>(),
);

export const setNewServerAdminUserFailure = createAction(
  '[User Access][Server] Set New Server Admin User Failure',
  props<{ error: IError }>(),
);


/**
 * Action to initiate the process of getting a user access token.
 * The action includes the application feature for which the access token is being requested.
 *
 * @param {ApplicationFeatureKeys} appFeature - The feature of the application for which the user access token is being requested.
 */
export const getUserAccessToken = createAction(
  '[User Access][User Access Token] Get User Access Token',
  props<{ appFeature: ApplicationFeatureKeys }>(),
);

/**
 * Action dispatched when the user access token is successfully retrieved.
 * The action includes the application feature, the user information, and the retrieved user access token.
 *
 * @param {ApplicationFeatureKeys} appFeature - The feature of the application for which the user access token was retrieved.
 * @param {IUser} user - The user information associated with the access token.
 * @param {IUserAccessToken} userAccessToken - The user access token that was successfully retrieved.
 */
export const getUserAccessTokenSuccess = createAction(
  '[User Access][User Access Token] Get User Access Token Success',
  props<{ appFeature: ApplicationFeatureKeys, userAccessToken: IUserAccessToken }>(),
);

/**
 * Action dispatched when there is a failure to retrieve the user access token.
 * The action includes the application feature and the error information related to the failure.
 *
 * @param {ApplicationFeatureKeys} appFeature - The feature of the application for which the user access token was requested.
 * @param {IError} error - The error information regarding why the request to retrieve the user access token failed.
 */
export const getUserAccessTokenFailure = createAction(
  '[User Access][User Access Token] Get User Access Token Failure',
  props<{ appFeature: ApplicationFeatureKeys, error: IError }>(),
);

/**
 * Action to set the user access token in the state.
 * This action updates the state with the user access token associated with a specific application feature.
 * If `userAccessToken` is `null` or `undefined`, it indicates the removal or absence of a token.
 *
 * @param {ApplicationFeatureKeys} appFeature - The feature of the application for which the user access token is being set.
 * @param {IUserAccessToken | IUserAccessTokenURL<unknown> | null | undefined} userAccessToken -
 * The user access token (or URL) to be set in the state.
 * It can be `null` or `undefined` if the token needs to be removed or is unavailable.
 */
export const setUserAccessToken = createAction(
  '[User Access][User Access Token] set User Access Token',
  props<{
    appFeature: ApplicationFeatureKeys | undefined,
    userAccessToken: IUserAccessToken | IUserAccessTokenURL<unknown> | null | undefined
  }>(),
);
