import {createAction, props} from '@ngrx/store';
import {StockItem} from '../../../shared-utilities/models-old/datastructures';
import {IPaginationData} from '../../../shared/shared-models/pagination/pagination-data';
import {IStore} from '../../../shared/shared-models/store/store';
import {IError} from '../../../shared-utilities/models-old/error/error';

export const getShelfTalkerStockItems = createAction(
  '[Shelf Talkers][Firebase] Get ShelfTalker Stock Items',
);

export const getShelfTalkerStockItemsSuccess = createAction(
  '[Shelf Talkers][Firebase] Get ShelfTalker Stock Items Success',
  props<{ stockItems: StockItem[], store: IStore }>(),
);

export const getShelfTalkerStockItemsFailure = createAction(
  '[Shelf Talkers][Firebase] Get ShelfTalker Stock Items Failure',
  props<{ error: IError, store: IStore }>(),
);

export const getShelfTalkersStockItemsExceptions = createAction(
  '[Shelf Talkers][Firebase] Get ShelfTalker Stock Items exceptions',
);

export const getShelfTalkersStockItemsExceptionsSuccess = createAction(
  '[Shelf Talkers][Firebase] Get ShelfTalker Stock Items exceptions Success',
  props<{ stockItems: StockItem[], store: IStore }>(),
);

export const getShelfTalkersStockItemsExceptionsFailure = createAction(
  '[Shelf Talkers][Firebase] Get ShelfTalker Stock Items exceptions Failure',
  props<{ error: IError, store: IStore }>(),
);

export const getOtherShelfTalkerStockItems = createAction(
  '[Shelf Talkers][Typesense] Get Other ShelfTalker Stock Items',
);

export const getOtherShelfTalkerStockItemsSuccess = createAction(
  '[Shelf Talkers][Typesense] Get Other ShelfTalker Stock Items Success',
  props<{ stockItems: StockItem[], paginationData: IPaginationData, store: IStore }>(),
);

export const getOtherShelfTalkerStockItemsFailure = createAction(
  '[Shelf Talkers][Typesense] Get Other ShelfTalker Stock Items Failure',
  props<{ error: IError, store: IStore }>(),
);

export const removeShelfTalkerTag = createAction(
  '[Shelf Talkers][Remove Tag] Remove ShelfTalker Tag',
  props<{ stockItems: { [code: string]: StockItem }, updateStorage: boolean }>(),
);

export const removeShelfTalkerTagSuccess = createAction(
  '[Shelf Talkers][Remove Tag] Remove ShelfTalker Tag Success',
  props<{ removedStockItems: { [code: string]: StockItem }, store: IStore, updateStorage: boolean }>(),
);

export const removeShelfTalkerTagFailure = createAction(
  '[Shelf Talkers][Remove Tag] Remove ShelfTalker Tag Failure',
  props<{ error: IError, store: IStore }>(),
);

export const removeShelfTalkerExceptionsTag = createAction(
  '[Shelf Talkers][Remove Tag] Remove ShelfTalker Exceptions Tag',
  props<{ stockItems: { [code: string]: StockItem }, updateStorage: boolean }>(),
);

export const removeShelfTalkerExceptionsTagSuccess = createAction(
  '[Shelf Talkers][Remove Tag] Remove ShelfTalker Exceptions Tag Success',
  props<{ removedStockItems: { [code: string]: StockItem }, store: IStore, updateStorage: boolean }>(),
);

export const removeShelfTalkerExceptionsTagFailure = createAction(
  '[Shelf Talkers][Remove Tag] Remove ShelfTalker Exceptions Tag Failure',
  props<{ error: IError, store: IStore }>(),
);

export const addShelfTalkerExceptionsTag = createAction(
  '[Shelf Talkers][Add Tag] Add ShelfTalker Exceptions Tag',
  props<{ stockItems: { [code: string]: StockItem } }>(),
);

export const addShelfTalkerExceptionsTagSuccess = createAction(
  '[Shelf Talkers][Add Tag] Add ShelfTalker Exceptions Tag Success',
  props<{ store: IStore }>(),
);

export const addShelfTalkerExceptionsTagFailure = createAction(
  '[Shelf Talkers][Add Tag] Add ShelfTalker Exceptions Tag Failure',
  props<{ error: IError, store: IStore }>(),
);

export const getPaginationResultsForShelfTalkerStockItem = createAction(
  '[Shelf Talkers][Pagination] Get Pagination Results ShelfTalker For Stock Item [Grid]',
  props<{ pageNumber: number }>(),
);

export const getTypesenseShelfTalkersSearchResults = createAction(
  '[Shelf Talkers][Search] Get Typesense ShelfTalkers Search Results [Grid]',
  props<{ idField: string }>(),
);

export const getTypesenseShelfTalkersSearchResultsWithStoreId = createAction(
  '[Shelf Talkers][Search] Get Typesense ShelfTalkers Search Results With Store Id [Grid]',
  props<{ paginationData: IPaginationData, store: IStore }>(),
);

export const getOtherItemsTypesenseShelfTalkersSearchResults = createAction(
  '[Shelf Talkers][Search] Get Other Items Typesense ShelfTalkers Search Results [Grid]',
  props<{ idField: string }>(),
);

export const getOtherItemsTypesenseShelfTalkersSearchResultsWithStoreId = createAction(
  '[Shelf Talkers][Search] Get Other Items Typesense ShelfTalkers Search Results With Store Id [Grid]',
  props<{ paginationData: IPaginationData, store: IStore }>(),
);

export const getOtherPaginationResultsForShelfTalkerStockItem = createAction(
  '[Shelf Talkers][Pagination] Get Other Pagination Results ShelfTalker For Stock Item [Grid]',
  props<{ pageNumber: number }>(),
);


export const setShelfTalkersSortMethod = createAction(
  '[Shelf Talkers][Sort] Set ShelfTalkers Sort Method',
  props<{ sortMethod: string }>(),
);

export const setShelfTalkersSortMethodSuccess = createAction(
  '[Shelf Talkers][Sort] Set ShelfTalkers Sort Method Success',
  props<{ sortMethod: string, store: IStore }>(),
);

export const setShelfTalkersSortMethodFailure = createAction(
  '[Shelf Talkers][Sort] Set ShelfTalkers Sort Method Failure',
  props<{ error: IError, store: IStore }>(),
);

export const setPageSizeForShelfTalkers = createAction(
  '[Shelf Talkers][Pagination] Set Page Size For Shelf Talkers',
  props<{ pageSize: number }>(),
);

export const setPageSizeForShelfTalkersAtStoreId = createAction(
  '[Shelf Talkers][Pagination] Set Page Size For Shelf Talkers At Store Id',
  props<{ pageSize: number; store: IStore, pageNumber: number }>(),
);

export const setOtherPageSizeForShelfTalkers = createAction(
  '[Shelf Talkers][Pagination] Set Other Page Size For Shelf Talkers',
  props<{ pageSize: number }>(),
);

export const setOtherPageSizeForShelfTalkersAtStoreId = createAction(
  '[Shelf Talkers][Pagination] Set Other Page Size For Shelf Talkers At Store Id',
  props<{ paginationData: IPaginationData; store: IStore, pageNumber: number }>(),
);

