import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {NOTIFICATION_COMPONENTS} from './components';
import {PushModule} from '@ngrx/component';
import {SharedModule} from '../../shared-modules/shared-module/shared-module.module';
import {IonicModule} from '@ionic/angular';
import {SharedIconsModule} from '../../shared-modules/shared-icons/shared-icons.module';
import {SharedComponentsModule} from '../../shared/shared-components/shared-components.module';
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    ...NOTIFICATION_COMPONENTS
  ],
  providers: [
    DatePipe
  ],
    imports: [
        CommonModule,
        PushModule,
        IonicModule,
        SharedIconsModule,
        SharedModule,
        SharedComponentsModule,
        FormsModule
    ]
})
export class NotificationsModule {
}
