import {Component, Input} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AutoOrderEmailStatusTypes, IAutoOrderOrder} from '../../../shared-models/auto-ordering/auto-order-order';
import {Icons} from '../../../../shared-modules/shared-icons/icons';
import {ModalController} from '@ionic/angular';
import {getDeepCopyOfObject} from '../../../shared-utils/object/object.utils';
import {
  clearAutoOrderSupplierEmails,
  sendAutoOrderEmails,
  updateAutoOrderOrderEmailRecipients,
} from '../../../../features-as-modules/feature-auto-ordering/store/auto-ordering.actions';
import {Store} from '@ngrx/store';
import {ISharedModalBasic} from "../../../shared-models/modals/shared-modal-basic";
import {SharedModalBasicComponent} from "../shared-modal-basic/shared-modal-basic.component";

@Component({
  selector: 'app-shared-modal-order-recipients',
  templateUrl: './shared-modal-order-recipients.component.html',
  styleUrls: ['./shared-modal-order-recipients.component.scss'],
})
export class SharedModalOrderRecipientsComponent {
  iconColour: 'green' | 'red' | 'yellow' | 'white' | 'primary' | 'secondary' | 'black' = 'red';
  modalTitle = `Email Recipients`;
  order: IAutoOrderOrder;
  recipients: string[] = [];
  supplierEmails: { [suppId: string]: { selected: string[]; additional: string[] } } = {};

  recipientForm: FormGroup;
  protected modalController: ModalController;
  protected readonly icons: typeof Icons = Icons;

  constructor(
    private formBuilder: FormBuilder,
    private readonly store: Store
  ) {
    this.recipientForm = this.formBuilder.group({
      newRecipients: this.formBuilder.array<FormControl<string>>([])
    });
  }

  get getNewRecipientsArray(): FormArray {
    return this.recipientForm.get('newRecipients') as FormArray<FormControl<string>>;
  }

  get getNewRecipientsControlArray(): FormControl<string>[] {
    return (this.recipientForm.get('newRecipients') as FormArray<FormControl<string>>).controls;
  }

  @Input() set setModalController(modalController: ModalController) {
    this.modalController = modalController;
  }

  @Input() set setOrder(order: IAutoOrderOrder) {
    if (order) {
      this.order = getDeepCopyOfObject(order);
      this.recipients = getDeepCopyOfObject(order.recipients);
      this.switchOrderEmailStatus(order.emailStatus);
    }
  }

  @Input() set setSupplierEmails(emails: { [suppId: string]: { selected: string[]; additional: string[] } }) {
    if (emails && Object.keys(emails).length > 0) {
      this.supplierEmails = emails;
    }
  }

  getCanRecipientBeDeleted(): boolean {
    return this.order.emailStatus !== 'SENT' && this.order.emailStatus !== 'PENDING';
  }

  onClose(): void {
    this.store.dispatch(clearAutoOrderSupplierEmails());
    void this.modalController.dismiss();
  }

  onRecipientAdd(): void {
    this.getNewRecipientsArray.push(new FormControl('', [Validators.required, Validators.email]));
  }

  onRecipientDelete(index: number): void {
    this.recipients.splice(index, 1);
    const uniqueRecipients: string[] = this.convertFormControlsToEmailArray();
    this.store.dispatch(updateAutoOrderOrderEmailRecipients({
      orderId: this.order.orderId,
      recipients: uniqueRecipients
    }));
  }

  onRecipientDeleteFromNew(index: number): void {
    this.getNewRecipientsArray.removeAt(index);
  }

  onResendEmails(): void {
    this.switchOrderEmailStatus('PENDING');
    this.store.dispatch(sendAutoOrderEmails({orderId: this.order.orderId}));
    void this.modalController.dismiss().then();
    void this.showBusySendingEmail(this.order.recipients);
  }

  onSaveAndSendNewEmails(): void {
    const uniqueRecipients: string[] = this.convertFormControlsToEmailArray();
    this.store.dispatch(updateAutoOrderOrderEmailRecipients({
      orderId: this.order.orderId,
      recipients: uniqueRecipients
    }));
    this.switchOrderEmailStatus('PENDING');
    this.store.dispatch(sendAutoOrderEmails({orderId: this.order.orderId}));
    void this.modalController.dismiss().then();
    void this.showBusySendingEmail(uniqueRecipients);
  }

  trackByFunction(index: number): number {
    return index;
  }

  private convertFormControlsToEmailArray(): string[] {
    const formControls = this.getNewRecipientsArray.controls;
    const newRecipientEmails: string[] = [];
    formControls.forEach((control: FormControl<string>) => {
      if (control.value && control.value !== '') {
        newRecipientEmails.push(control.value);
      }
    });
    const allRecipients: string[] = [...this.recipients, ...newRecipientEmails];
    return [...new Set(allRecipients)];
  }

  private switchOrderEmailStatus(newStatus: AutoOrderEmailStatusTypes): void {
    if (this.order.emailStatus !== newStatus) {
      this.order.emailStatus = newStatus;
    }
    switch (this.order.emailStatus) {
      case 'SENT':
        this.iconColour = 'green';
        this.modalTitle = 'Order Email Recipients - Succeeded';
        break;
      case 'FAILED':
        this.iconColour = 'red';
        this.modalTitle = 'Order Email Recipients - Failed';
        break;
      case 'PENDING':
        this.iconColour = 'yellow';
        this.modalTitle = 'Order Email Recipients Busy Processing';
        break;
      case 'SKIP':
        this.iconColour = 'green';
        this.modalTitle = 'Order Email Recipients - Skipped Send';
        break;
      default:
        break;
    }
  }

  private async showBusySendingEmail(emails: string[]): Promise<void> {
    const modalProps: ISharedModalBasic = {
      buttonAccept: false,
      buttonAcceptText: '',
      buttonClose: false,
      buttonCloseCross: false,
      buttonCloseText: '',
      modalTitle: `Generating Email`,
      modalTitleIcon: this.icons.emailCheckOutline,
      modalTitleIconColor: 'yellow',
      contextHeading: `Sending Emails`,
      contextDescription: [
        `The server is busy sending emails to the following emails:`,
        ...emails
      ],
      cssClass: ['shared-basic-modal-css-medium'],
    };
    const busyModalController: HTMLIonModalElement = await this.modalController.create({
      component: SharedModalBasicComponent,
      componentProps: modalProps,
      cssClass: [...modalProps.cssClass ?? 'shared-basic-modal-css'],
    });
    await busyModalController.present();
    setTimeout(async (): Promise<void> => {
      await busyModalController.dismiss();
    }, 2000);
  }
}
