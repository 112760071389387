import {IStore} from '../../shared-models/store/store';
import {convertToDecimal} from '../number/number.utils';
import {StockItem, VatRates} from '../../../shared-utilities/models-old/datastructures';
import {StockFunctions} from '../../../shared-utilities/functions-old/stock-functions';

/**
 * Calculates the gross profit (GP) from the selling price and cost price, updating the provided object.
 *
 * This function takes an object, a store, and a format for decimal places. It calculates the nominal, previous,
 * and difference in gross profit (GP) based on VAT rates and cost prices, and returns an updated object with the GP values.
 *
 * @template ObjectType - The type of the object to be updated.
 * @param {ObjectType} obj - The object containing selling price and cost price information.
 * @param {IStore} store - The store object containing store-specific information StoreID and Sequence.
 * @param {number} formatDecimalPlaces - The number of decimal places to format the values.
 * @param {VatRates} vatRates - the vat Rate conversion pulled from firebase
 * @returns {ObjectType} - The updated object with calculated GP values.
 *
 * @example
 * const product = { sellPriIncl1: 100, latestCost: 60, suppUsedLastPrice2: 55, vatR: 'A' };
 * const store = { storeId: 1 };
 * const updatedProduct = calculateGPFromPrice(product, store, 2);
 * console.warn(updatedProduct.diffGP); // Output: Calculated GP difference with 2 decimal places
 */
export function calculateGPFromPrice<ObjectType>(obj: ObjectType, store: IStore, formatDecimalPlaces: number, vatRates?: VatRates): ObjectType {
  const previousCostPrice = +obj['suppUsedLastPrice2'] && +obj['suppUsedLastPrice2'] !== 0 ? +obj['suppUsedLastPrice2'] : +obj['latestCost'];
  const storeVatRates = StockFunctions.getVatRateForStore(store.storeId);
  const convertedVatRate = storeVatRates[obj['vatR']].vatRate;
  let nominalGP = +calculateGPValue(convertedVatRate, +obj['sellPriIncl1'], +obj['latestCost']);
  let previousGP = +obj['prevGP'] ? +obj['prevGP'] : calculateGPValue(convertedVatRate, +obj['sellPriIncl1'], previousCostPrice);
  const differenceGP = nominalGP - previousGP;
  return {
    ...obj,
    ['diffGP']: +convertToDecimal(differenceGP, formatDecimalPlaces),
    ['nominalGP']: +convertToDecimal(Number(nominalGP), formatDecimalPlaces),
    //   ['prevCostPrice']: +convertToDecimal(previousCostPrice, formatDecimalPlaces),
    //   ['prevGP']: +convertToDecimal(previousGP, formatDecimalPlaces),
  };
}

/**
 * Calculates the gross profit (GP) value based on VAT rate, selling price, and latest cost price.
 *
 * The function calculates the GP as a percentage based on the provided VAT rate, selling price (including VAT),
 * and the latest cost price. Optionally, a new selling price can be provided to replace the original selling price.
 *
 * @param {number} vatRate - The VAT rate to apply in the calculation.
 * @param {number} sellPriceIncl1 - The selling price including VAT.
 * @param {number} latestCostPrice - The latest cost price of the product.
 * @param {number} [newSellPrice] - Optional new selling price to replace the original selling price in the calculation.
 * @returns {number} - The calculated GP value as a percentage.
 *
 * @example
 * const vatRate = 15;
 * const sellPriceIncl1 = 100;
 * const latestCostPrice = 60;
 * const gp = calculateGPValue(vatRate, sellPriceIncl1, latestCostPrice);
 * console.warn(gp); // Output: GP percentage
 */
export function calculateGPValue(vatRate: number, sellPriceIncl1: number, latestCostPrice: number, newSellPrice?: number): number {
  const price: number = ![undefined, null].includes(newSellPrice) ? newSellPrice : sellPriceIncl1;
  const x: number = price / (1 + (vatRate / 100));
  return ((x - latestCostPrice) / x) * 100;
}

/**
 * Calculates the price (including VAT) from a desired GP percentage.
 *
 * @param store - The store object containing store-specific information StoreID and Sequence.
 * @param stockItem - The object containing selling price and cost price information.
 * @returns               The calculated selling price (including VAT).
 */

export function calculatePriceFromGpValue(
  store: IStore,
  stockItem: StockItem,
): number {
  const storeVatRatesObj = StockFunctions.getVatRateForStore(store.storeId);
  const vatRate = storeVatRatesObj[stockItem['vatR']].vatRate;
  const previousCostPrice = +stockItem['latestCost'];
  let gpFraction = stockItem.nominalGP / 100;
  if (gpFraction >= 1) {
    gpFraction = 0.9999;
  }
  const exVatPrice: number = Number(previousCostPrice) / (1 - gpFraction);
  return Number(exVatPrice * (1 + vatRate / 100));
}

