import {ErrorHandler} from '@angular/core';

export class GlobalErrorHandler implements ErrorHandler {

  handleError(error: Error): void {
    if (error?.message?.includes('ChunkLoadError')) {
      console.warn('ChunkLoadError detected, forcing a reload...');
      window.location.reload();
      return;
    }
    /**
     * NG0904: unsafe value used in a resource URL context
     * This error is thrown on the LookerStudio for Dashboard Reporting
     */
    if (error?.message?.includes('NG0904')) {
      return;
    }
    console.error('Unhandled Error:', error);
  }
}
