import {Injectable, OnDestroy} from '@angular/core';
import {ColDef} from 'ag-grid-community';
import {Store} from '@ngrx/store';
import {BehaviorSubject, EMPTY, Observable, Subscription} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import * as SettingsActions from 'src/app/features/settings-old/store/settings.actions';
import {TableNavSettings} from 'src/app/shared-utilities/models-old/datastructures';
import {
  selectAllUserSettings,
  selectUserSettingsIsSubmitting
} from 'src/app/features/settings-old/store/settings.selectors';
import {IError} from 'src/app/shared-utilities/models-old/error/error';

import {EnterEndOptionsType, IMultiSelect, IUserSettingsFormState, TabEndOptionsType} from '../../models';
import {getColumnName, isColumnDisabled, isColumnSelected} from './user-settings.helpers';
import {USER_SETTINGS} from './user-settings.constants';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsFormService implements OnDestroy {

  //#region Properties
  private stateSubscription: Subscription;
  private currentState: IUserSettingsFormState | null = null;
  private readonly formStateSubject = new BehaviorSubject<IUserSettingsFormState>({
    fitToTable: false
  });
  private readonly formState$: Observable<IUserSettingsFormState> = this.formStateSubject.asObservable();
  //#endregion

  //#region constructor
  constructor(
    private readonly store: Store
  ) {
  }

  //#endregion

  //#region ngOnDestroy
  ngOnDestroy(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }

  //#endregion

  //#region Public Methods
  /**
   * Initializes the form service by setting up state subscriptions
   * @returns Observable of the form state
   */
  public initialize(): Observable<IUserSettingsFormState> {
    const currentFormState = this.formStateSubject.value;
    this.stateSubscription = this.store.select(selectAllUserSettings).pipe(
      map(({navSettings, fitToGrid, ngpSettings, stockSettings}) => ({
        fitToTable: fitToGrid,
        ngpPreview: this.convertNGPPreviewColumns(ngpSettings),
        stockPreview: this.convertStockPreviewColumns(stockSettings),
        tabEnd: navSettings ? navSettings.tabEnd : '',
        enterEnd: navSettings ? navSettings.enterEnd : '',
      } as IUserSettingsFormState)),
      catchError((error) => {
        console.error('Failed to load user settings', error);
        return EMPTY;
      })
    ).subscribe((state: IUserSettingsFormState) => {
      this.currentState = state;
      currentFormState ?
        this.formStateSubject.next({...currentFormState, ...state}) :
        this.formStateSubject.next(state);
    });
    this.stateSubscription.add(this.store.select(selectUserSettingsIsSubmitting)
      .subscribe((isSubmitting: boolean) => {
        this.updateFormState({isSubmitting})
      }));
    return this.formState$;
  }

  /**
   * Updates the fitToTable setting in the form state
   */
  public updateFitToTable(value: boolean): void {
    this.updateFormState({fitToTable: value});
  }

  /**
   * Updates the tab end behavior setting
   */
  public updateTabEnd(value: TabEndOptionsType | null): void {
    this.updateFormState({tabEnd: value});
  }

  /**
   * Updates the enter end behavior setting
   */
  public updateEnterEnd(value: EnterEndOptionsType | null): void {
    this.updateFormState({enterEnd: value});
  }

  /**
   * Updates the NGP preview columns setting
   */
  public updateNgpPreview(value: IMultiSelect | null): void {
    this.updateFormState({ngpPreview: value});
  }

  /**
   * Updates the stock preview columns setting
   */
  public updateStockPreview(value: IMultiSelect | null): void {
    this.updateFormState({stockPreview: value});
  }

  /**
   * Submits the form state to the store
   * Transforms preview data and dispatches single update action
   */
  public submitForm(): void {
    try {
      if (this.isFormUnchanged() || this.currentState.isSubmitting) {
        return; // No need to update if nothing changed or if already submitting
      }
      const formState = this.formStateSubject.value;
      const ngpPreviewColumns = this.transformPreviewForFirestore(formState.ngpPreview);
      const stockPreviewColumns = this.transformPreviewForFirestore(formState.stockPreview);
      const tableNavSettings: TableNavSettings = {
        tabEnd: formState.tabEnd,
        enterEnd: formState.enterEnd
      };

      this.store.dispatch(SettingsActions.updateAllUserSettings({
        fitToGrid: formState.fitToTable,
        ngpPreviewColumns,
        stockPreviewColumns,
        tableNavSettings
      }));
    } catch (error) {
      this.handleSubmitError(error);
    }
  }

  //#endregion

  //#region Preview Methods
  /**
   * Transforms preview settings for Firestore storage
   */
  private transformPreviewForFirestore(
    preview: IMultiSelect | undefined
  ): { [key: number]: string } {
    if (!preview) return {};
    return Object.entries(preview).reduce((acc, [key, item]) => {
      if (!item.selected) return acc;
      acc[Number(key)] = item.field;
      return acc;
    }, {} as { [key: number]: string });
  }

  /**
   * Converts grid column definitions to MultiSelect format
   */
  private convertPreviewColumns(
    options: ColDef<unknown>[],
    firestoreValues: { [key: number]: string }
  ): IMultiSelect {
    if (!firestoreValues) return {};
    const fields: IMultiSelect = {};
    for (const [idx, option] of options.entries()) {
      fields[idx] = {
        name: getColumnName(option.headerName, option.field),
        field: option.field,
        selected: isColumnSelected(option, firestoreValues),
        disabled: isColumnDisabled(option)
      };
    }
    return fields;
  }

  private convertNGPPreviewColumns(firestoreValues: { [key: number]: string }): IMultiSelect {
    return this.convertPreviewColumns(
      USER_SETTINGS.tableSettings.ngpEditsPreview.options,
      firestoreValues
    );
  }

  private convertStockPreviewColumns(firestoreValues: { [key: number]: string }): IMultiSelect {
    return this.convertPreviewColumns(
      USER_SETTINGS.tableSettings.stockEditsPreview.options,
      firestoreValues
    );
  }

  //#endregion

  //#region Helpers
  /**
   * Updates form state with partial state changes
   */
  private updateFormState<K extends keyof IUserSettingsFormState>(
    updates: Pick<IUserSettingsFormState, K>
  ): void {
    if (this.currentState.isSubmitting) return;
    const newState = {...this.formStateSubject.value};

    Object.keys(updates).forEach((key: string) => {
      const stateKey = key as K;
      const value = updates[stateKey];

      if (value === null) {
        delete newState[stateKey];
      } else {
        newState[stateKey] = value;
      }
    });

    this.formStateSubject.next(newState);
  }

  /**
   * Checks if the form state has changed from the current state
   */
  private isFormUnchanged(): boolean {
    return this.currentState &&
      (JSON.stringify(this.formStateSubject.value) === JSON.stringify(this.currentState));
  }

  /**
   * Handles form submission errors that occur before Firebase operations
   * (e.g. validation errors, transformation errors)
   */
  private handleSubmitError(error: unknown): void {
    const submitError: IError = {
      error: error instanceof Error ? error.message : 'Failed to prepare settings update',
      status: 'error',
      httpResponseCode: 400,
      response: error,
      request: 'submitForm'
    };

    this.store.dispatch(SettingsActions.updateAllUserSettingsFailure({error: submitError}));
  }

  //#endregion
}
