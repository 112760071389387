<app-modal-header [close]="close" title="Failed Auto Orders"></app-modal-header>

<ion-content>
  <!--  useValue.alreadyChecked?.[orderId]-->
  <ion-grid *ngIf="storesInfo && !creatingModal; else spinner" id="messages">
    <ng-container *ngIf="failedAOMsgs && failedAOMsgs.length">
      <ng-container *ngFor="let message of failedAOMsgs; let i = index">
        <h5 *ngIf="i === 0 || message.sender !== failedAOMsgs[i - 1].sender"
            style="display: flex; justify-content: center; align-items: center"
        >
          {{ storesInfo.stores[message.sender].name }}
        </h5>
        <ion-row style="padding-top: 10px">
          <ion-col size="auto"><span>{{ dateStrings[i].d }}</span></ion-col>
          <ion-col size="auto"><span>{{ dateStrings[i].t }}</span></ion-col>
        </ion-row>
        <ion-grid class="msg-expansion">
        </ion-grid>
      </ng-container>
    </ng-container>
  </ion-grid>

  <ion-row class="reattempt-button">
    <ion-col>
      <ion-button (click)="openReattemptModal()" [disabled]="numChecked <= 0 || creatingModal" color="light">Resolve
      </ion-button>
      <ion-button (click)="submit()" [disabled]="!aggregatedData" color="light">Submit</ion-button>
    </ion-col>
  </ion-row>
</ion-content>

<ng-template #spinner>
  <div class="spinner">
    <ion-spinner></ion-spinner>
  </div>
</ng-template>
