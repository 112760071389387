import {IAutoOrderOrderSettingsFirestore} from '../../../shared/shared-models/auto-ordering/auto-order-order';

export type MessageType =
  'ACCESS_CHANGE' |
  'AUTO_ORDERS' |
  'AUTO_ORDER_RESULT' |
  'AUTO_ORDERS_U_UPDATE' |
  'SERVER_CONFIG' |
  'SERVER_TEST' |
  'SIGN_UP' |
  'STOCK_UPDATE';

export enum MessageTypesEnum {
  'ACCESS_CHANGE' = 'ACCESS_CHANGE',
  'AUTO_ORDERS' = 'AUTO_ORDERS',
  'AUTO_ORDER_RESULT' = 'AUTO_ORDER_RESULT',
  'AUTO_ORDERS_U_UPDATE' = 'AUTO_ORDERS_U_UPDATE',
  'SERVER_CONFIG' = 'SERVER_CONFIG',
  'SERVER_TEST' = 'SERVER_TEST',
  'SIGN_UP' = 'SIGN_UP',
  'STOCK_UPDATE' = 'STOCK_UPDATE',
}

export interface IMessage<DataType> {
  payload: {
    data: DataType[] | {
      [key: string]: DataType
    }
  }
  sender: string;
  timestamp: Date | { seconds: number, nanoseconds: number },
  type: MessageType
}

export interface IMessageAutoOrderUpdate {
  [supplierId: string]: IAutoOrderOrderSettingsFirestore & {
    userId: string
  };
}
