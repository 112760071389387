<!-- Loading Container -->
<ng-container *ngIf="(formState$ | ngrxPush).isSubmitting">
  <div class="loading-container">
    <app-shared-loading-indicator [animation]="emailSettingsAnimation"></app-shared-loading-indicator>
  </div>
</ng-container>

<ng-container *ngIf="!((formState$ | ngrxPush).isSubmitting)">
  <!-- User Container -->
  <div class="user-select-container">
    <!-- Search for User Container -->
    <div class="search-user-container">
      <ion-searchbar
        (ionChange)="emailSettingsManager.onQueryChange($event)"
        [debounce]="300"
        [placeholder]="'Search for user'"
        [value]="(formState$ | ngrxPush).userQuery">
      </ion-searchbar>
    </div>
    <!-- User Select Container -->
    <div class="users-container">
      <button
        (click)="emailSettingsManager.onSelectColleague(colleague.id)"
        *ngFor="let colleague of (formState$ | ngrxPush).filteredColleagues; trackBy: trackByColleagueId"
        [ngClass]="{ 'selected': colleague.id === (formState$ | ngrxPush).selectedColleague}"
        class="user-container"
        type="button">
        <div class="profile-picture-container">
          <ng-container *ngIf="colleague.pp; else noPP">
            <img
              [alt]="colleague.userName + 'Profile Picture'"
              [src]="colleague.pp"
              class="profile-picture"/>
          </ng-container>
          <ng-template #noPP>
            <span class="initial-avatar">
              {{ getUserNameInitials(colleague.userName) }}
            </span>
          </ng-template>
        </div>
        <div class="info-container">
          <div class="user-name">{{ colleague.userName }}</div>
          <small class="user-id">{{ colleague.id }}</small>
        </div>
      </button>
    </div>
  </div>

  <!-- Email Settings Container -->
  <div class="email-settings-container">
    <div class="form">
      <div class="header-container">
        <h3>Email Settings for <span>{{ (formState$ | ngrxPush).selectedColleague }}</span></h3>
      </div>
      <div class="content-container">
        <table>
          <!-- General Settings -->
          <tr>
            <td class="label">
              <p>Sender Address</p>
            </td>
            <td>
              <input
                (input)="emailSettingsManager.onEmailChange($event)"
                [class.error]="(formState$ | ngrxPush).formErrors?.email"
                [class.required]="true"
                [value]="(formState$ | ngrxPush).selectedColleagueEmailSettings?.email || ''"
                aria-label="Sender Address"
                name="sender-address"
                placeholder="sender@example.com"
                type="email">
            </td>
            <td class="label">
              <p>User Name</p>
            </td>
            <td>
              <input
                (input)="emailSettingsManager.onUserChange($event)"
                [class.error]="(formState$ | ngrxPush).formErrors?.user"
                [class.required]="true"
                [value]="(formState$ | ngrxPush).selectedColleagueEmailSettings?.user || ''"
                aria-label="Email Address"
                name="user-name"
                placeholder="user@example.com"
                type="email">
            </td>
          </tr>
          <tr>
            <td class="label">
              <p>Password</p>
            </td>
            <td>
              <input
                (input)="emailSettingsManager.onPasswordChange($event)"
                [class.error]="(formState$ | ngrxPush).formErrors?.pwd"
                [class.required]="true"
                [value]="(formState$ | ngrxPush).selectedColleagueEmailSettings?.pwd || ''"
                aria-label="password"
                class="masked-input"
                name="email-settings-pwd"
                type="text">
            </td>
            <td></td>
            <td></td>
          </tr>
          <!-- SMTP Settings -->
          <tr>
            <td colspan="4">
              <div class="section-header">
                <h4>SMTP</h4>
                <div class="dashed-line"></div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="label">
              <p>Server Address</p>
            </td>
            <td>
              <input
                (input)="emailSettingsManager.onSmtpServerChange($event)"
                [class.error]="(formState$ | ngrxPush).formErrors?.smtp?.server"
                [class.required]="true"
                [value]="(formState$ | ngrxPush).selectedColleagueEmailSettings?.smtp?.server || ''"
                aria-label="Server Address"
                name="server-address"
                placeholder="smtp.server.co.za"
                type="email">
            </td>
            <td class="label">
              <p>Port Number</p>
            </td>
            <td>
              <input
                (input)="emailSettingsManager.onSmtpPortChange($event)"
                [class.error]="(formState$ | ngrxPush).formErrors?.smtp?.port"
                [class.required]="true"
                [value]="(formState$ | ngrxPush).selectedColleagueEmailSettings?.smtp?.port || ''"
                aria-label="Port Number"
                max="65535"
                min="1"
                name="port-number"
                placeholder="587"
                type="number">
            </td>
          </tr>
          <tr>
            <td class="label">
              <p>Use SSL/TLS</p>
            </td>
            <td class="switch-cell">
              <app-switch-ui
                (checkedChange)="emailSettingsManager.onSmtpSslOnlyChange($event)"
                [checked]="(formState$ | ngrxPush).selectedColleagueEmailSettings?.smtp?.sslOnly || false"
                [size]="0.6">
              </app-switch-ui>
            </td>
          </tr>
          <!-- IMAP Settings -->
          <tr>
            <td colspan="4">
              <div class="section-header">
                <h4>IMAP</h4>
                <div class="dashed-line"></div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="label">
              <p>Enable IMAP</p>
            </td>
            <td class="switch-cell">
              <app-switch-ui
                (checkedChange)="emailSettingsManager.onEnableImapChange($event)"
                [checked]="(formState$ | ngrxPush).selectedColleagueEmailSettings?.enableImap || false"
                [size]="0.6">
              </app-switch-ui>
            </td>
          </tr>
          <tr>
            <td class="label">
              <p>Server Address</p>
            </td>
            <td>
              <input
                (input)="emailSettingsManager.onImapServerChange($event)"
                [class.error]="(formState$ | ngrxPush).formErrors?.imap?.server"
                [class.required-if-enabled]="(formState$ | ngrxPush).selectedColleagueEmailSettings?.enableImap"
                [disabled]="!(formState$ | ngrxPush).selectedColleagueEmailSettings?.enableImap"
                [value]="(formState$ | ngrxPush).selectedColleagueEmailSettings?.imap?.server || ''"
                aria-label="Server Address"
                name="imap-server-address"
                placeholder="imap.server.co.za"
                type="text">
            </td>
          </tr>
          <tr>
            <td class="label">
              <p>Port Number</p>
            </td>
            <td>
              <input
                (input)="emailSettingsManager.onImapPortChange($event)"
                [class.error]="(formState$ | ngrxPush).formErrors?.imap?.port"
                [class.required-if-enabled]="(formState$ | ngrxPush).selectedColleagueEmailSettings?.enableImap"
                [disabled]="!(formState$ | ngrxPush).selectedColleagueEmailSettings?.enableImap"
                [value]="(formState$ | ngrxPush).selectedColleagueEmailSettings?.imap?.port || ''"
                aria-label="Port Number"
                max="65535"
                min="1"
                name="imap-port-number"
                placeholder="993"
                type="number">
            </td>
          </tr>
          <tr>
            <td class="label">
              <p>Sent Mail Folder</p>
            </td>
            <td>
              <div class="info-text">
                <p>Emails will be organized into a new folder named <b>"Gallix"</b>, with
                  subfolders categorizing them by type.</p>
              </div>
            </td>
          </tr>
          <tr>
            <td class="label">
              <p>Use SSL/TLS</p>
            </td>
            <td class="switch-cell">
              <app-switch-ui
                (checkedChange)="emailSettingsManager.onImapSslOnlyChange($event)"
                [checked]="(formState$ | ngrxPush).selectedColleagueEmailSettings?.imap?.sslOnly || false"
                [disabled]="!(formState$ | ngrxPush).selectedColleagueEmailSettings?.enableImap"
                [size]="0.6">
              </app-switch-ui>
            </td>
          </tr>
        </table>
      </div>
      <div class="save-button-container">
        <button
          (click)="emailSettingsManager.saveAndTestSettings()"
          [disabled]="!emailSettingsManager.isDirty || emailSettingsManager.hasValidationErrors"
          type="button">
          Save & Test Email Settings
        </button>
      </div>
    </div>
  </div>
</ng-container>
