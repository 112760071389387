import {Injectable} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {environment} from '../../../../environments/environment';
import {IStorage} from '../../shared-models/storage/storage';
import {IStore} from '../../shared-models/store/store';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private angularFireStorage: AngularFireStorage
  ) {
  }

  async getDocumentDownloadUrl(storage: IStorage, store: IStore): Promise<string> {
    try {
      const url = `${environment.storageUrl}/${storage.folder}/${store.storeId}/${storage.document}.${storage.extension}`;
      const storageRef = this.angularFireStorage.refFromURL(url);
      const downloadUrl = storageRef.getDownloadURL().toPromise();
      return await downloadUrl as string;
    } catch (error) {
      console.error('Error fetching document download URL:', error);
      throw new Error('Failed to get document download URL');
    }
  }

}
