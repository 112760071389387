import {FbQuery} from '../services-old/firebase.service';
import {sItemKeyToInt} from '../models-old/datastructures';

/**
 * @constant subQueryStock
 * A query that is used to retrieve data from the DB.
 * This relates to Stock 2.
 */
export const subQueryStock: FbQuery[] = [
  {q: 'where', p: ['_tags', 'array-contains', 'in_ngp']},
  {q: 'orderBy', p: [sItemKeyToInt.suppUsedLastDate1]},
];

export const subQueryStockShelfTalkers: FbQuery[] = [
  {q: 'where', p: ['_tags', 'array-contains', 'in_shelf_talkers']},
];
export const subQueryStockShelfTalkersExceptions: FbQuery[] = [
  {q: 'where', p: ['_tags', 'array-contains', 'shelf_talker_exception']},
];
