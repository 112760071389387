import {Component, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {Icons} from '../../../shared-icons/icons';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {IFacetCounts} from '../../../../shared/shared-models/type-sense/type-sense-types';
import {
  selectAddItemFacetCounts,
} from '../../../../features-as-modules/feature-auto-ordering/store/auto-ordering.selectors';

@Component({
  selector: 'app-cell-renderer-supplier-facet-counts-container',
  templateUrl: './cell-renderer-supplier-facet-counts-container.component.html',
  styleUrls: ['./cell-renderer-supplier-facet-counts-container.component.scss'],
})
export class CellRendererSupplierFacetCountsContainerComponent implements ICellRendererAngularComp, OnInit {

  value: boolean;
  params: ICellRendererParams;
  colDefField: string;
  colDefFieldType: string;

  readonly icons: typeof Icons = Icons;
  facetCounts$: Observable<IFacetCounts>;

  constructor(private readonly store: Store) {
  }

  agInit(params: ICellRendererParams): void {
    this.params = {...params};
    this.params.data = {...params.data};
    this.params.colDef = {...params.colDef};
    this.colDefField = params.colDef.field;
    this.value = this.params.data[this.colDefField];
  }

  ngOnInit(): void {
    this.colDefFieldType = typeof this.params.value;
    this.value = this.value as typeof this.params.value;
    this.facetCounts$ = this.store.select(selectAddItemFacetCounts);
  }

  getValue(): string | number | boolean {
    return this.value;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

}
