<div class="shared-modal-recipients-container">

  <ion-header>
    <ion-toolbar>
      <ion-title>
        <div>
          <div>{{ modalTitle }}</div>
          <span></span>
        </div>
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <div class="context-header-container">
      <ng-container>
        <app-display-icon
          [colour]="iconColour"
          [icon]="icons.infoSlabCircleOutline"
          class="title-icon">
        </app-display-icon>
      </ng-container>
      <ng-container>
        <div>Email Recipients for {{ order.supplierId }}</div>
      </ng-container>
    </div>

    <div class="context-description-container">
      <ng-container>
        <div>Please note this will update the recipients for this order and not for the supplier.</div>
        <div *ngIf="order.emailStatus === 'SKIP'">Sending emails on this order have been skipped.</div>
        <div></div>
        <div>The following are the recipients on the order:</div>
        <ng-container *ngIf="recipients.length > 0 || getNewRecipientsArray.length > 0;else NoRecipients">
          <ng-container *ngFor="let email of recipients; let i = index; trackBy: trackByFunction">
            <div class="email-recipient">
              <div>{{ email }}</div>
              <ng-container *ngIf="!getCanRecipientBeDeleted()">
                <app-display-icon
                  [colour]="'white'"
                  [icon]="icons.closeCircle"
                  [tooltip]="'Delete ' + email">
                </app-display-icon>
              </ng-container>
              <ng-container *ngIf="getCanRecipientBeDeleted()">
                <app-display-icon
                  (click)="onRecipientDelete(i)"
                  [colour]="'red'"
                  [icon]="icons.deleteOutline"
                  [tooltip]="'Delete ' + email">
                </app-display-icon>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #NoRecipients>
          <div class="no-recipients">
            <div>There are no recipients selected.</div>
          </div>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="getNewRecipientsArray.length > 0">
        <div class="new-recipients">The following are the new recipients:
          <ng-container *ngIf="getNewRecipientsArray.length > 0 ">
            <ng-container
              *ngFor="let emailCtrl of getNewRecipientsControlArray; let i = index; trackBy: trackByFunction">
              <div [formGroup]="recipientForm" class="email-recipient">
                <ion-item class="input-item" lines="none">
                  <ion-input
                    [formControl]="emailCtrl"
                    [placeholder]="'Enter additional email '"
                    type="email">
                  </ion-input>
                </ion-item>
                <ng-container>
                  <app-display-icon
                    (click)="onRecipientDeleteFromNew(i)"
                    [colour]="'red'"
                    [icon]="icons.deleteOutline"
                    [tooltip]="'Delete ' + emailCtrl.value">
                  </app-display-icon>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

    </div>

  </ion-content>

  <ion-footer>
    <ion-button (click)="onClose()" fill="outline">Close</ion-button>
    <ng-container *ngIf="getNewRecipientsArray.length > 0">
      <ion-button
        (click)="onSaveAndSendNewEmails()"
        [disabled]="order.pdfStatus === 'FAILED'"
        [matTooltip]="order.pdfStatus === 'FAILED'
        ? 'You can not save new recipients if PDF has failed'
        : 'Save new recipients'">
        Save and Send
      </ion-button>
    </ng-container>
    <ng-container>
      <ion-button
        [matTooltip]="'You can not send emails if PDF has failed'"
        (click)="onResendEmails()"
        [disabled]="recipients.length < 1 || order.pdfStatus === 'FAILED'"
        fill="outline">
        Send Emails
      </ion-button>
    </ng-container>
    <ion-button (click)="onRecipientAdd()" fill="outline">Add Recipient</ion-button>
  </ion-footer>

</div>
