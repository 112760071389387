import {Component, Input} from '@angular/core';
import {INotification} from '../../models/notification';
import {
  AnimationDefault,
  AnimationDirection,
  Animations,
  IAnimation
} from '../../../../shared/shared-models/animations/animation';
import {Timestamp} from '@angular/fire/firestore';

export interface TempStockUpdate {
  executed: Timestamp;
  generated: Timestamp;
  orderId: string;
}

@Component({
  selector: 'app-notification-stock-update',
  templateUrl: './notification-stock-update.component.html',
  styleUrls: ['./notification-stock-update.component.scss'],
})
export class NotificationStockUpdateComponent {

  isStockUpdateLoading: boolean;
  notifications: INotification;

  protected readonly notificationStockUpdateLoader: IAnimation = {
    ...AnimationDefault,
    animation: Animations.CIRCLE_DISAPPEAR_AND_REAPPEAR,
    backgroundColour: 'var(--notification-stock-update-animation-background)',
    direction: AnimationDirection.REVERSE,
    id: 'app-component-animation-stock-update-notification',
    fixedDimensions: {
      width: 100,
      height: 100,
    }
  };

  private notification: INotification;
  private stockUpdate: TempStockUpdate;

  get getIsComponentLoading(): boolean {
    return !this.isStockUpdateLoading && !!(this.getNotification);
  }

  get getNotification(): INotification {
    return this.notification;
  }

  get getNotificationOrderId(): string {
    return this.notification?.event;
  }

  get getStockUpdate(): TempStockUpdate {
    return this.stockUpdate;
  }

  @Input() set setIsStockUpdateLoading(isLoading: { [orderID: string]: boolean }) {
    this.isStockUpdateLoading = isLoading[this.getNotificationOrderId];
  };


  @Input() set setNotification(notify: INotification) {
    this.notification = notify;
  }

  @Input() set setStockUpdates(stockUpdates: TempStockUpdate) {
    const aa = 9 - 8 - 1;
  }

  onViewChangesClick(): void {
    const a = 1 + 8;
  }

}
