import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {IInitialState} from '../../../shared-utilities/models-old/initial-state/initial-state';
import {StockItem} from '../../../shared-utilities/models-old/datastructures';
import {IPaginationData} from '../../../shared/shared-models/pagination/pagination-data';
import * as ShelfTalkerActions from './shelf-talkers.actions';

export interface ShelfTalkersState extends IInitialState {
  isLoading: boolean;
  isRemoveTagsLoading: boolean
  stockItems: { [storeId: string]: StockItem[] },
  exceptions: { [storeId: string]: StockItem[] },
  otherStockItems: { [storeId: string]: StockItem[] },
  removedStockItems: { [storeId: string]: { [code: string]: StockItem } },
  removedExceptions: { [storeId: string]: { [code: string]: StockItem } },
  paginationData: { [storeId: string]: IPaginationData },
  otherStockItemsPaginationData: { [storeId: string]: IPaginationData },
  sortMethod: { [storeId: string]: string },
}

export const initialShelfTalkersState: ShelfTalkersState = {
  // Initial --------------------------------
  errors: [],
  // ShelfTalkers ------------------------------
  isLoading: false,
  isRemoveTagsLoading: false,
  stockItems: {},
  exceptions: {},
  otherStockItems: {},
  removedStockItems: {},
  removedExceptions: {},
  paginationData: {},
  otherStockItemsPaginationData: {},
  sortMethod: {},
};

const createShelfTalkersReducer: ActionReducer<ShelfTalkersState> = createReducer(
  initialShelfTalkersState,
  on(ShelfTalkerActions.getShelfTalkerStockItems, (state: ShelfTalkersState) => ({
    ...state,
    isLoading: true,
  })),
  on(ShelfTalkerActions.getShelfTalkerStockItemsSuccess, (state: ShelfTalkersState, {
    stockItems,
    store,
  }) => ({
    ...state,
    isLoading: false,
    stockItems: {
      ...state.stockItems,
      [store.storeId]: stockItems,
    },
  })),
  on(ShelfTalkerActions.getShelfTalkerStockItemsFailure, (state: ShelfTalkersState, {error, store}) => ({
    ...state,
    isLoading: false,
    errors: [...state.errors, error],
  })),
  on(ShelfTalkerActions.getShelfTalkersStockItemsExceptions, (state: ShelfTalkersState) => ({
    ...state,
    isLoading: true,
  })),
  on(ShelfTalkerActions.getShelfTalkersStockItemsExceptionsSuccess, (state: ShelfTalkersState, {
    stockItems,
    store,
  }) => ({
    ...state,
    isLoading: false,
    exceptions: {
      ...state.stockItems,
      [store.storeId]: stockItems,
    },
  })),
  on(ShelfTalkerActions.getShelfTalkersStockItemsExceptionsFailure, (state: ShelfTalkersState, {error, store}) => ({
    ...state,
    isLoading: false,
    errors: [...state.errors, error],
  })),
  on(ShelfTalkerActions.getOtherShelfTalkerStockItemsSuccess, (state: ShelfTalkersState, {
    stockItems,
    paginationData,
    store,
  }) => ({
    ...state,
    isLoading: false,
    otherStockItems: {
      ...state.stockItems,
      [store.storeId]: stockItems,
    },
    otherStockItemsPaginationData: {
      ...state.paginationData,
      [store.storeId]: paginationData,
    },
  })),
  on(ShelfTalkerActions.getOtherShelfTalkerStockItemsFailure, (state: ShelfTalkersState, {error, store}) => ({
    ...state,
    isLoading: false,
    errors: [...state.errors, error],
  })),
  on(ShelfTalkerActions.removeShelfTalkerTag, (state: ShelfTalkersState) => ({
    ...state,
    isRemoveTagsLoading: true,
  })),
  on(ShelfTalkerActions.removeShelfTalkerTagSuccess, (state: ShelfTalkersState, {removedStockItems, store}) => ({
    ...state,
    isRemoveTagsLoading: false,
    stockItems: {
      ...state.stockItems,
      [store.storeId]: state.stockItems[store.storeId]?.filter(
        item => !Object.keys(removedStockItems).includes(item.code),
      ) || [],
    },
    removedStockItems: {
      ...state.removedStockItems,
      [store.storeId]: {
        ...state.removedStockItems[store.storeId],
        ...removedStockItems,
      },
    },
  })),
  on(ShelfTalkerActions.removeShelfTalkerTagFailure, (state: ShelfTalkersState, {error, store}) => ({
    ...state,
    isRemoveTagsLoading: false,
    errors: [...state.errors, error],
  })),
  on(ShelfTalkerActions.removeShelfTalkerExceptionsTag, (state: ShelfTalkersState) => ({
    ...state,
    isRemoveTagsLoading: true,
  })),
  on(ShelfTalkerActions.removeShelfTalkerExceptionsTagSuccess, (state: ShelfTalkersState, {
    removedStockItems,
    store,
  }) => ({
    ...state,
    isRemoveTagsLoading: false,
    exceptions: {
      ...state.exceptions,
      [store.storeId]: state.exceptions[store.storeId]?.filter(
        item => !Object.keys(removedStockItems).includes(item.code),
      ) || [],
    },
    removedExceptions: {
      ...state.removedExceptions,
      [store.storeId]: {
        ...state.removedExceptions[store.storeId],
        ...removedStockItems,
      },
    },
  })),
  on(ShelfTalkerActions.removeShelfTalkerExceptionsTagFailure, (state: ShelfTalkersState, {error, store}) => ({
    ...state,
    isRemoveTagsLoading: false,
    errors: [...state.errors, error],
  })),
  on(ShelfTalkerActions.setShelfTalkersSortMethodSuccess, (state: ShelfTalkersState, {sortMethod, store}) => ({
    ...state,
    sortMethod: {
      ...state.sortMethod,
      [store.storeId]: sortMethod,
    },
  })),
  on(ShelfTalkerActions.setShelfTalkersSortMethodFailure, (state: ShelfTalkersState, {error, store}) => ({
    ...state,
    errors: [...state.errors, error],
  })),
  on(ShelfTalkerActions.getTypesenseShelfTalkersSearchResultsWithStoreId, (state: ShelfTalkersState, {
    paginationData,
    store,
  }) => ({
    ...state,
    paginationData: {
      ...state.paginationData,
      [store.storeId]: paginationData,
    },
  })),
  on(ShelfTalkerActions.getOtherItemsTypesenseShelfTalkersSearchResultsWithStoreId, (state: ShelfTalkersState, {
    paginationData,
    store,
  }) => ({
    ...state,
    otherStockItemsPaginationData: {
      ...state.otherStockItemsPaginationData,
      [store.storeId]: paginationData,
    },
  })),
  on(ShelfTalkerActions.setPageSizeForShelfTalkersAtStoreId, (state: ShelfTalkersState, {pageSize, store}) => ({
    ...state,
    paginationData: {
      ...state.paginationData,
      [store.storeId]: {
        ...state.paginationData[store.storeId],
        pageSize,
      },
    },
  })),
  on(ShelfTalkerActions.setOtherPageSizeForShelfTalkersAtStoreId, (state: ShelfTalkersState, {
    paginationData,
    store,
  }) => ({
    ...state,
    otherStockItemsPaginationData: {
      ...state.otherStockItemsPaginationData,
      [store.storeId]: paginationData,
    },
  })),
  // ====================================================================================================
  //
  // ====================================================================================================
);

export const shelfTalkersReducer = (state: ShelfTalkersState, action: Action): ShelfTalkersState => createShelfTalkersReducer(state, action);
