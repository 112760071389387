import {INotification} from '../../features/notifications/models/notification';

// ========================================================================
// LOOKER STUDIO
// ========================================================================

export const looker_studio_base_url = (lookerStudioApiKey: string): string => {
  return `https://lookerstudio.google.com/embed/reporting/${lookerStudioApiKey}/page/hUYBF`;
}

// ========================================================================
// ADMIN
// ========================================================================
export const path_admin_cloud_emailer_email_orders = (orderId: string): string => {
  return `admin/cloud/emailer/email/orders/${orderId}`;
};

export const path_admin_cloud_emailer_email_test = (): string => {
  return `admin/cloud/emailer/email/test`;
};

export const path_admin_functions_orders_instructions_delete_order = (): string => {
  return `admin/functions/orders/instructions/delete_order`;
};

// ========================================================================
// COMMUNITY FORUM
// ========================================================================

// ========================================================================
// GLOBAL
// ========================================================================
export const path_global_global_stock_tags = (): string => {
  return `global/global_stock_tags`;
};

export const path_global_settings_shared_settings_price_bands = (): string => {
  return `global/settings/shared_settings/price_bands`;
};

// ========================================================================
// NOTIFICATIONS
// ========================================================================

export const path_notifications = (): string => {
  return `notifications`;
};

export const path_notification_id = (notification: INotification): string => {
  return `notifications/${notification.notificationId}`;
};

// ========================================================================
// OBSERVATION
// ========================================================================

// ========================================================================
// OPERATIONAL
// ========================================================================
export const path_operational_stores_data_auto_ordering_auto_orders_GET = (storeId: string): string => {
  return `operational/stores_data/${storeId}/data/auto_ordering/auto_orders/orders`;
};

export const path_operational_stores_data_auto_ordering_auto_orders_order_id = (storeId: string, orderId: string): string => {
  return `operational/stores_data/${storeId}/data/auto_ordering/auto_orders/orders/${orderId}`;
};

export const path_operational_stores_data_auto_ordering_auto_orders_order_items_GET = (storeId: string, orderId: string): string => {
  return `operational/stores_data/${storeId}/data/auto_ordering/auto_orders/orders/${orderId}/orderItems`;
};

export const path_operational_stores_data_singular_docs_stock_item_deletion_rules = (storeId: string): string => {
  return `operational/stores_data/${storeId}/data/singular_documents/stock_item_deletion_rules`;
};

export const path_operational_stores_data_storeId_data_auto_ordering_orders_orderId_orderItems = (storeId: string, orderId: string): string => {
  return `/operational/stores_data/${storeId}/data/auto_ordering/auto_orders/orders/${orderId}/orderItems`;
};

export const path_operational_stores_data_storeId_data_auto_ordering_supplier_emails = (storeId: string): string => {
  return `/operational/stores_data/${storeId}/data/auto_ordering/supplier_emails/emails`;
};

export const path_operational_stores_data_storeId_data_auto_ordering_supplier_emails_suppId = (storeId: string, suppId: string): string => {
  return `/operational/stores_data/${storeId}/data/auto_ordering/supplier_emails/emails/${suppId}`;
};

export const path_operational_stores_data_storeId_messages_from_app = (storeId: string): string => {
  return `/operational/stores_data/${storeId}/messages/from_app`;
};

// ========================================================================
// SERVER_ACCESS
// ========================================================================

// ========================================================================
// SERVERS
// ========================================================================
export const path_servers_serverId_serviceInfo_version = (serverId: string): string => {
  return `servers/${serverId}/service_info/version`;
};

// ========================================================================
// SHARED
// ========================================================================
export const path_shared_store_data_last_updated_stock_tags = (storeId: string): string => {
  return `shared/stores_data/${storeId}/data/last_updates/stock/tags`;
};

export const path_shared_store_data_stock = (storeId: string): string => {
  return `shared/stores_data/${storeId}/data/stock`;
};

export const path_shared_store_data_suppliers_GET = (storeId: string): string => {
  return `shared/stores_data/${storeId}/data/suppliers`;
};

export const path_shared_store_data_suppliers_supplier_info = (storeId: string, supplierId: string): string => {
  return `shared/stores_data/${storeId}/data/suppliers/${supplierId}`;
};

export const path_shared_stores_data_storeId_data_singular_documents_store_settings = (storeId: string): string => {
  return `shared/stores_data/${storeId}/data/singular_documents/store_settings`;
};
export const path_shared_stores_data_storeId_data_singular_documents_timestamps = (storeId: string): string => {
  return `shared/stores_data/${storeId}/data/singular_documents/data_timestamps`;
};

// ========================================================================
// STORES
// ========================================================================
export const path_stores_storeId_docs_typesense_key = (storeId: string): string => {
  return `stores/${storeId}/docs/typesense_key`;
};

export const path_stores_storeId_settings_advanced_filter = (storeId: string, filterId: string): string => {
  return `stores/${storeId}/settings/advanced_filters/filters/${filterId}`;
};

export const path_stores_storeId_settings_advanced_filter_groups = (storeId: string): string => {
  return `stores/${storeId}/settings/advanced_filters/filters`;
};

export const path_stores_storeId_settings_ao_order_expiration = (storeId: string): string => {
  return `stores/${storeId}/settings/auto_order_expiration`;
};

export const path_stores_storeId_settings_disable_stock_item_rules = (storeId: string): string => {
  return `stores/${storeId}/settings/disable_stock_item_rules`;
};

export const path_stores_storeId_settings_price_bands = (storeId: string): string => {
  return `stores/${storeId}/settings/price_bands`;
};

export const path_stores_storeId_settings_shelf_talkers_settings = (storeId: string): string => {
  return `stores/${storeId}/settings/shelf_talkers_settings`;
};

export const path_stores_storeId_settings_stock_edit_thresholds = (storeId: string): string => {
  return `stores/${storeId}/settings/stock_edit_thresholds`;
};

export const path_stores_storeId_settings_store_tags = (storeId: string): string => {
  return `stores/${storeId}/settings/store_tags`;
};

// ========================================================================
// USER ACCESS
// ========================================================================
export const path_user_access_userId = (userId: string): string => {
  return `/user_access/${userId}`;
};

export const path_user_access_user_id_docs_user_token = (userId: string): string => {
  return `/user_access/${userId}/docs/user_token`;
};

// ========================================================================
// USERS
// ========================================================================
export const path_users_userId_settings_fit_to_grid = (userId: string): string => {
  return `users/${userId}/settings/fit_to_grid`;
};

export const path_users_userId_settings_ngp_edits_preview = (userId: string): string => {
  return `users/${userId}/settings/ngp_edits_preview`;
};

export const path_users_userId_settings_stock_edits_preview = (userId: string): string => {
  return `users/${userId}/settings/stock_edits_preview`;
};

export const path_users_userId_settings_stores_storeId_mailer_settings = (userId: string, storeId: string): string => {
  return `users/${userId}/settings/stores/${storeId}/mailer_settings`;
};

export const path_users_userId_settings_stores_storeId_orders_settings = (userId: string, storeId: string): string => {
  return `users/${userId}/settings/stores/${storeId}/order_settings`;
};

export const path_users_userId_settings_table_navigation = (userId: string): string => {
  return `users/${userId}/settings/table_navigation`;
};

export const path_users_userId_settings_saved_preferences_visible_Fields_feature =
  (userId: string, feature: string): string => {
    return `users/${userId}/settings/saved_preferences/visible_fields/${feature}`;
  };
