import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {ISelectOption} from '../../../shared-models/custom-select/custom-select';
import {getDeepCopyOfObject} from '../../../shared-utils/object/object.utils';

@Component({
  selector: 'app-shared-custom-select',
  templateUrl: './shared-custom-select.component.html',
  styleUrls: ['./shared-custom-select.component.scss'],
  animations: [
    trigger('dropdownAnimation', [
      transition(':enter', [
        style({opacity: 0, transform: 'scaleY(0.8)'}),
        animate('200ms ease-out', style({opacity: 1, transform: 'scaleY(1)'})),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({opacity: 0, transform: 'scaleY(0.8)'})),
      ]),
    ]),
  ],
})
export class SharedCustomSelectComponent implements OnInit {

  @Input() selected: string[] = [];
  @Input() placeholder: string = 'Select';
  @Input() maxSelections?: number;


  @Output() readonly selectionChange = new EventEmitter<{ [key: string]: ISelectOption }>();


  public isDropdownOpen = false;
  public searchText = '';
  public optionKeys: string[] = [];
  public highlightedIndex: number = 0;
  options: { [key: string]: ISelectOption } = {};

  private tempOptions: { [key: string]: ISelectOption } | null = null;

  constructor(private elementRef: ElementRef) {
  }

  get currentOptions(): { [key: string]: ISelectOption } {
    return this.tempOptions || this.options;
  }

  get filteredOptionKeys(): string[] {
    if (!this.searchText) {
      return this.optionKeys;
    }
    return this.optionKeys.filter((key: string) =>
      this.currentOptions[key].name.toLowerCase().includes(this.searchText.toLowerCase()),
    );
  }

  get selectedCount(): number {
    return this.optionKeys.filter((key: string) => this.options[key].selected).length;
  }

  @Input() set setOptions(options: { [optionId: string]: ISelectOption }) {
    this.options = options;
    this.setSelected();
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.cancelSelection();
    }
  }


  ngOnInit(): void {
    this.optionKeys = Object.keys(this.options);
  }

  setSelected(): void {
    this.selected.forEach((storeId: string) => {
      this.options[storeId].selected = true;
    });
  }

  toggleDropdown(): void {
    if (!this.isDropdownOpen) {
      this.tempOptions = getDeepCopyOfObject(this.options);
      this.isDropdownOpen = true;
      this.searchText = '';
      this.highlightedIndex = 0;
    } else {
      this.isDropdownOpen = false;
    }
  }

  cancelSelection(): void {
    this.tempOptions = null;
    this.isDropdownOpen = false;
  }

  confirmSelection(): void {
    if (this.tempOptions) {
      this.options = getDeepCopyOfObject(this.tempOptions);
      this.selectionChange.emit(this.options);
      this.optionKeys = Object.keys(this.options);
    }
    this.tempOptions = null;
    this.isDropdownOpen = false;
  }

  toggleOption(key: string, event: MouseEvent): void {
    event.stopPropagation();
    if (this.currentOptions[key].disabled) {
      return;
    }
    const currentSelections = this.optionKeys.filter((k: string) => this.currentOptions[k].selected);
    if (!this.currentOptions[key].selected && this.maxSelections && currentSelections.length >= this.maxSelections) {
      return;
    }
    if (this.tempOptions) {
      this.tempOptions[key].selected = !this.tempOptions[key].selected;
    }
  }

  onSearchChange(): void {
    this.highlightedIndex = 0;
  }

  onKeyDown(event: KeyboardEvent): void {
    if (!this.isDropdownOpen) {
      return;
    }
    const filteredOptions = this.filteredOptionKeys;
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        if (this.highlightedIndex < filteredOptions.length - 1) {
          this.highlightedIndex++;
        }
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (this.highlightedIndex > 0) {
          this.highlightedIndex--;
        }
        break;
      case 'Enter':
        event.preventDefault();
        const key = filteredOptions[this.highlightedIndex];
        if (key) {
          this.toggleOption(key, event as unknown as MouseEvent);
        }
        break;
      case 'Escape':
        event.preventDefault();
        this.cancelSelection();
        break;
    }
  }

  removeChip(key: string, event: MouseEvent): void {
    event.stopPropagation();
    if (this.options[key].disabled) {
      return;
    }
    this.options[key].selected = false;
    this.selectionChange.emit(this.options);
  }

  trackByFunction(index: number): number {
    return index;
  }
}
