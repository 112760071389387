import { Injectable } from '@angular/core';
import {AlertController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ForceReloadService {

  private update: string;
  private features?: string[];
  private delay?: number;

  private interval: NodeJS.Timeout;

  constructor(
    private alertControl: AlertController,
  ) { }

  message(overrides?: {update: string; features?: string[]; delay?: number}): string {
    const update = overrides && overrides.update ? overrides.update : this.update;
    const features = overrides && overrides.features ? overrides.features : this.features;
    let msg = 'Gallix is still in development, updates like this will become less frequent in the future';
    msg += update ? `<br><b>UPDATE: ${update}</b><br>` : '<br>Unnamed Patch<br>';

    if (features) {
      features.forEach(f => { msg += `&#8195;* ${f}<br>`; });
    } else {
      msg += 'Just bug fixes and stuff';
    }
    return msg;
  }

  register(options: {update: string; features?: string[]; delay?: number}): void {
    this.update = options.update;
    this.features = options.features;
    this.delay = options.delay;
    this.alert(false).then();
  }

  alertMessage = async (ignorable: boolean = true,
                        overrides?: {update: string; features?: string[]; delay?: number}): Promise<string> => {
    const delay = overrides && overrides.delay ? overrides.delay : this.delay;
    const severity = `SEVERITY: ${ignorable ? 'Low, you can delay the refresh to finish up what your doing. ' +
      `Ill remind you in ${(delay / 1000).toFixed(0)}s.` :
      'HIGH. We apologize for the inconvenience but the site requires an immediate refresh.'}`;
    const buttons: any[] = ['Reload'];

    if (ignorable) {
      buttons.push({text: 'Remind Me', role: 'remind'});
    }
    const ac = await this.alertControl.create({
      header: 'Site Reload Required', subHeader: 'We apologize for the inconvenience but Gallix needs to be refreshed to ' +
        'receive a new update.', message: severity + '<br><br>' + this.message(overrides), buttons,
      backdropDismiss: false, cssClass: ['custom-alert', ignorable ? 'warn' : 'error']
    });
    await ac.present();
    const {role} = await ac.onDidDismiss();
    return role;
  };

  private alert = async (ignorable: boolean = true) => {
    const role = await this.alertMessage(ignorable);

    if (role === 'remind') {
      this.interval = setTimeout(this.alert, this.delay);
    } else {
      window.location.reload();
    }
  };
}
